import { Redirect, useLocation, useParams } from 'react-router-dom'

const RedirectToNewEAPFlow = () => {
  const { brand } = useParams<{
    brand: string
  }>()

  const { search } = useLocation()

  return (
    <Redirect
      to={`/${brand?.replaceAll(' ', '-')}/eap/initial-details${search}`}
    />
  )
}

export default RedirectToNewEAPFlow
