import moment from 'moment'
import { ExistenceType, formatPhoneNumber } from '../EAP/EAP-constants'
export const PHP_PREFERENCE_Dependent = 'dependent'
export const PHP_PREFERENCE_SELF = 'self'
export const PHP_USER_TYPE_SELF = 'myself'
export const PHP_USER_TYPE_DEPENDENT = 'dependent'
export const SEATTLE_STORM_ID = 'cc5b8dc4-004d-4bc0-90f2-8dc0be7bab53'

export const formatMemberID = (memberID: any) => {
  if (memberID?.length === 11 && numberPattern.test(memberID)) {
    return memberID
  }
  return null
}
export const formatGroupID = (groupID: any) => {
  console.log(groupID)
  if (groupID?.length >= 6 && alphanumberPattern.test(groupID)) {
    return groupID
  }
  return null
}

export interface PHPPatientCreationRequestInterface {
  firstName: string
  lastName: string
  email: string
  dateOfBirth: string
  currentAddress: {
    street: string
    city: string
    state: string
    zipCode: string
  }
  phoneNumber: string
  gender: string
  patientType: string
  primarySubscriberDetails: null | {
    firstName: string
    lastName: string
    dateOfBirth: string
    email: string
  }
  emergencyContact: {
    name: string
    phoneNumber: string
  }
  insuranceDetails?: {
    insurerName: string | undefined
    memberId: string
    groupId?: string
  }
  visitorId: string // check on this
  password: string
  token: string //hard code to string and later integrate
  insuranceId?: string | null

  providerId: string
  existenceType: string | null
  reasonforBookingAppointment: string
  relationship: string
  IsThisTheprimarysubscriber: boolean
}

export const getPHPFinalPayload = (
  primarySubscriberDetails: any,
  appointmentDetails: any,
  values: any,
  visitorId: any,
  patientTypeId: any,
  existenceType: ExistenceType | null,
  insuranceDocumentID: string | null,
  token: any
) => {
  const PHPFinalPayload: PHPPatientCreationRequestInterface = {
    firstName: primarySubscriberDetails.firstName,
    lastName: primarySubscriberDetails.lastName,
    email: primarySubscriberDetails.email,
    dateOfBirth: moment(primarySubscriberDetails.dateOfBirth).format(
      'MM/DD/YYYY'
    ),
    currentAddress: {
      street: primarySubscriberDetails.addressLine1,
      city: primarySubscriberDetails.city,
      state: primarySubscriberDetails.state,
      zipCode: primarySubscriberDetails.zipCode,
    },
    phoneNumber: `${formatPhoneNumber(primarySubscriberDetails.phoneNumber)}`,
    gender: primarySubscriberDetails.gender
      ? primarySubscriberDetails.gender.split(':')[0]
      : '',
    patientType: patientTypeId,

    primarySubscriberDetails: primarySubscriberDetails.psFirstName
      ? {
          firstName: primarySubscriberDetails.psFirstName,
          lastName: primarySubscriberDetails.psLastName,
          dateOfBirth:
            primarySubscriberDetails?.psDateOfBirth &&
            moment(primarySubscriberDetails?.psDateOfBirth).format(
              'MM/DD/YYYY'
            ),
          email: primarySubscriberDetails.psEmail,
        }
      : null,
    emergencyContact: {
      name: primarySubscriberDetails.emergencyContactName,
      phoneNumber: `${formatPhoneNumber(
        primarySubscriberDetails.emergencyContactPhoneNumber
      )}`,
    },
    visitorId: visitorId,
    password: values.password,
    token: token,
    providerId: appointmentDetails.providerId,
    existenceType: existenceType,
    reasonforBookingAppointment: encodeURIComponent(
      primarySubscriberDetails.reasonforBookingAppointment
    ),
    relationship: primarySubscriberDetails.Relationship,
    IsThisTheprimarysubscriber:
      primarySubscriberDetails.primarySubscriber === 1 ? true : false,
  }

  PHPFinalPayload.insuranceId = insuranceDocumentID ?? null //upload and get the insurance ID

  PHPFinalPayload.insuranceDetails = {
    insurerName: getInsuranceIDFromName(
      primarySubscriberDetails?.InsuranceCompany
    ),
    memberId: formatMemberID(primarySubscriberDetails?.memberID),
    groupId: formatGroupID(primarySubscriberDetails?.groupID),
  }

  return PHPFinalPayload
}
export const inputRadioOptions = [
  {
    name: 'Primary Subscriber',
    radioValue: 1,
  },
  {
    name: 'Dependent ',
    radioValue: 2,
  },
]
export const insuranceCompanyNameList = [
  {
    name: 'Providence Health Plan',
    companyId: '2038',
  },
]

export const numberPattern = new RegExp(/^[0-9]*$/)
export const alphanumberPattern = new RegExp(/^[a-z0-9]+$/i)

const getInsuranceIDFromName = (name: string): string | undefined => {
  const AvailableInsuranceIDs: { [key: string]: string } = {
    'Providence Health Plan': '2038',
  }
  return AvailableInsuranceIDs[name]
}
