import { Modal } from 'antd'
import { ErrorRedirecting } from '../../assets'

interface ErrorBoundaryRedirectionModalInterface {
  shouldDisplayErrorRedirectionModal?: boolean
}

const ErrorBoundaryRedirectionModal: React.FC<
  ErrorBoundaryRedirectionModalInterface
> = ({ shouldDisplayErrorRedirectionModal }) => {
  return (
    <Modal
      footer={null}
      destroyOnClose={false}
      visible={shouldDisplayErrorRedirectionModal}
      maskClosable={false}
      closable={false}
      className="max-w-sm md:max-w-2xl !w-fit error-modal mt-[10%] md:mt-[8%] px-8 md:px-10 rounded-lg bg-opacity-50 md:min-w-[36rem]"
    >
      <div className="flex flex-col items-center justify-center m-4 md:m-8 p-8">
        <img
          src={ErrorRedirecting}
          alt="Red coloured alert icon"
          className="max-w-[8rem]"
        />
        <div className="text-teal-blue text-lg font-bold text-center">
          Something went wrong
        </div>
        <div className="text-md font-light text-center">
          You are being redirected...
        </div>
      </div>
    </Modal>
  )
}

export default ErrorBoundaryRedirectionModal
