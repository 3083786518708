import { useContext, useEffect } from 'react'
import { AppointmentPageContext } from '../../appointment-page-middleware'
import {
  TimeRangeButtonObjectInterface,
  TimeRangeButtonObjectProps,
} from '../../interfaces/time-range-filter-interfaces'
import MorningIconSVG from '../../../../../assets/Icons/morning-svg'
import { Themes, formatTimeUI } from '../../../EAP-constants'
import AfternoonIconSVG from '../../../../../assets/Icons/afternoon-svg'
import EveningIconSVG from '../../../../../assets/Icons/evening-svg'

export const TimeRangeButtons: React.FC = () => {
  const { selectedTimeBlock, updateSelectedTimeBlock, range, updateRange } =
    useContext(AppointmentPageContext)

  useEffect(() => {
    if (
      selectedTimeBlock < 0 &&
      !(range[0] === 7 * 60 && range[1] === 21 * 60)
    ) {
      updateRange([7 * 60, 21 * 60])
    }
  }, [selectedTimeBlock])

  return (
    <div className="lg:w-full lg:py-2 flex-1 border-b border-solid border-x-0 border-t-0 border-[#DCD8D0] lg:!border-solid lg:!border-t-0 lg:!border-b-0">
      <div className="text-medium-grey m-1 p-1 font-medium text-sm">
        <div className="flex flex-col lg:flex-row justify-between">
          <div className="mr-2 hidden lg:block">Preferred Time </div>
          <div
            className="mr-2 underline text-teal-blue cursor-pointer"
            onClick={() => {
              updateSelectedTimeBlock(-1)
            }}
          >
            Clear selection
          </div>
        </div>
      </div>
      <div className="flex flex-col lg:flex-row justify-between">
        {TimeRangeButtonList.map((timeButton, index) => {
          return (
            <TimeRangeButton
              icon={timeButton.icon}
              timeRange={timeButton.timeRange}
              text={timeButton.text}
              index={index}
              darkIcon={timeButton.darkIcon}
              selectedTimeBlocks={selectedTimeBlock}
              updateSelectedTimeBlocks={updateSelectedTimeBlock}
              key={index}
            />
          )
        })}
      </div>
    </div>
  )
}

export const TimeRangeButton: React.FC<TimeRangeButtonObjectProps> = ({
  icon,
  text,
  timeRange,
  index,
  darkIcon,
}) => {
  const { selectedTimeBlock, updateRange, updateSelectedTimeBlock } =
    useContext(AppointmentPageContext)
  const onClick = () => {
    updateSelectedTimeBlock(index)
    const [start, end] = timeRange
    updateRange([start, end - 60])
  }
  /** focus:bg-teal-blue selected:bg-teal-blue **/
  return (
    <div
      className={`flex flex-col lg:flex-row border-[#DCD8D0] border-solid pl-2 pr-2 py-2 my-2 lg:my-0 w-full md:w-11/12 rounded-lg mr-2 last:mr-0 md:mr-0 justify-evenly group transition ease-in-out duration-300 cursor-pointer
        ${selectedTimeBlock === index ? 'bg-teal-blue md:bg-teal-blue' : 'md:bg-[#FAFAFA] md:hover:bg-teal-blue'} 
        `}
      onClick={onClick}
      data-testid={`opt-${text}`}
    >
      {/* Icon */}
      <div
        className={`flex justify-center items-center ${selectedTimeBlock < 0 || !(selectedTimeBlock === index)
          ? 'block'
          : 'hidden'
          }`}
      >
        {icon}
      </div>
      <div
        className={`flex justify-center items-center ${selectedTimeBlock === index && !(selectedTimeBlock < 0)
          ? 'block'
          : 'hidden'
          }`}
      >
        {darkIcon}
      </div>
      <div className="flex flex-col items-center justify-center">
        <div className="text-xs flex flex-row w-full justify-center">
          <div
            className={`md:mr-2 text-center md:text-start transition ease-in-out duration-300 group-hover:text-white group-focus:text-white group-selected:text-white
            ${selectedTimeBlock === index ? 'text-white' : 'text-black'}`}
          >
            {text}
          </div>
        </div>
        {/* Name */}
        <div
          className={`text-xs font-normal filter-button-time-range transition ease-in-out duration-300 group-hover:text-white group-focus:text-white group-selected:text-white
          ${selectedTimeBlock === index ? 'text-white' : 'text-black'}
          `}
        >
          ({formatTimeUI(timeRange[0]).slice(0, 5)} -{' '}
          {formatTimeUI(timeRange[1])})
        </div>
        {/* Time */}
      </div>
      <div
        className={`lg:hidden text-center text-xs font-normal transition ease-in-out duration-300 group-hover:text-white group-focus:text-white group-selected:text-white
          ${selectedTimeBlock === index ? 'text-white' : 'text-black'}
          `}
      >
        ({formatTimeUI(timeRange[0])} - {formatTimeUI(timeRange[1])})
      </div>
    </div>
  )
}

export const TimeRangeButtonList: TimeRangeButtonObjectInterface[] = [
  {
    icon: <MorningIconSVG theme={Themes.LIGHT} />,
    darkIcon: <MorningIconSVG theme={Themes.DARK} />,
    timeRange: [7 * 60, 12 * 60 - 1],
    text: 'Morning',
    index: 0,
  },
  {
    icon: <AfternoonIconSVG theme={Themes.LIGHT} />,
    darkIcon: <AfternoonIconSVG theme={Themes.DARK} />,
    timeRange: [12 * 60, 16 * 60 - 1],
    text: 'Afternoon',
    index: 1,
  },
  {
    icon: <EveningIconSVG theme={Themes.LIGHT} />,
    darkIcon: <EveningIconSVG theme={Themes.DARK} />,
    timeRange: [16 * 60, 21 * 60],
    text: 'Evening',
    index: 2,
  },
]
