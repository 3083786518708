import { Col, Row } from 'antd'
import { girlMeditationImage } from '../../../assets'
import OnboardingForm from './onboarding-form'
import '../your-details.scss'
const CompanyOnboardingPage = () => {
  return (
    <Row className="sm:mx-10 sm:mt-10 sm:mt-[3vh] xl:mt-[3vh] xs:rounded-2xl xs:-mt-4">
      <Col
        md={12}
        lg={12}
        className="items-center justify-center xl:pt-[2vh] mb-10 md:mb-0"
      >
        <div className=" md:flex md:flex-col  font-bold text-3xl text-teal-blue poppins text-center items-center justify-center">
          {'We look forward to having you on '}{' '}
          <span className="text-shade">{' Trusana'}</span>
          <img
            className="w-full sm:max-w-lg md:max-w-md lg:max-w-lg xl:max-w-xl 2xl:max-w-2xl mt-20 md:mt-16 px-8 pt-4 md:px-0 md:pt-0"
            src={girlMeditationImage}
            alt="Girl meditation landing page image"
          />{' '}
        </div>
      </Col>
      <Col
        xs={24}
        sm={24}
        md={12}
        lg={12}
        className=" justify-center items-center md:max-w-[45%] sm:mt-[3vh]"
      >
        <div
          className="formContainer"
          style={{
            maxHeight: '70vh',
            boxShadow: '0px 12px 20px rgba(14, 31, 53, 0.2)',
          }}
        >
          <OnboardingForm />
        </div>
      </Col>
    </Row>
  )
}
export default CompanyOnboardingPage
