import React, { useContext } from 'react'
import NewInputDate from '../../../common/new-form/new-input-date/new-input-date'
import NewInputText from '../../../common/new-form/new-input-text/new-input-text'
import { FormInvalidMessage } from '../../../common/form/form.utils'
import { alphabetPattern } from '../../EAP/EAP-constants'
import NewInputSelect, {
  fromSelectOptions,
} from '../../../common/new-form/new-input-select/new-input-select'
import { MetadataContext } from '../../../common'
import PhpFormSectionInfo from '../php-form-section-info/php-form-section-info'
import moment from 'moment'
interface PersonalInformationInterface {
  // incrementStepper: () => void
  // decrementStepper: () => void
  updateUserDoB: (userDoB: moment.Moment) => void
  updateUserState: (userState: string) => void
}

const PersonalInformation: React.FC<PersonalInformationInterface> = ({
  updateUserDoB,
  updateUserState,
}) => {
  const { states } = useContext(MetadataContext)
  return (
    <div className="mt-8">
      <PhpFormSectionInfo
        sectionNumber={1}
        title={'Personal Information'}
        description={'Provide details of the person who will be receiving care'}
      />
      <div className="mt-4">
        <div className="flex flex-col md:flex-row md:gap-8">
          <NewInputText
            label={'First Name'}
            name={'firstName'}
            placeholder={'Enter First Name'}
            isRequired={true}
            aria-required={true}
            pattern={alphabetPattern}
            patternMessage={FormInvalidMessage('First Name')}
            maxLength={50}
            min={{
              value: 2,
              message: FormInvalidMessage(''),
            }}
            // onChange={checkAndUpdateStepper}
          />

          <NewInputText
            label={'Last Name'}
            name={'lastName'}
            placeholder={'Enter Last Name'}
            isRequired={true}
            aria-required={true}
            pattern={alphabetPattern}
            patternMessage={FormInvalidMessage('Last Name')}
            maxLength={50}
            min={{
              value: 2,
              message: FormInvalidMessage(''),
            }}
            // onChange={checkAndUpdateStepper}
          />
        </div>
        <div className="flex flex-col md:flex-row md:gap-8">
          <NewInputDate
            label={'Date of Birth'}
            name={'dateOfBirth'}
            isRequired={true}
            aria-required={true}
            placeholder={'MM/DD/YYYY or MM-DD-YYYY'}
            shouldDisableFutureDates={true}
            onChange={(value) => {
              value && updateUserDoB(value)
            }}
          />
          <NewInputText
            label={'Email Address'}
            name={'email'}
            placeholder={'Enter Email Address'}
            isEmail={true}
            isRequired={true}
            maxLength={65}
            min={{
              value: 3,
              message: FormInvalidMessage(''),
            }}
            max={{
              value: 65,
              message: FormInvalidMessage(''),
            }}
          />
        </div>
        <div className="flex flex-col md:flex-row md:gap-8">
          <NewInputSelect
            label={'State of Residence'}
            name={'state'}
            placeholder={'Select State'}
            isRequired={true}
            aria-required={true}
            options={fromSelectOptions(states, 'name', 'code')}
            onChange={(value) => {
              value && updateUserState(value)
            }}
          />
          <NewInputSelect
            label={''}
            name={'state'}
            placeholder={'Select State'}
            options={fromSelectOptions(states, 'name', 'code')}
            onChange={(value) => {
              value && updateUserState(value)
            }}
            className={'hidden'}
          />
        </div>
      </div>
    </div>
  )
}

export default PersonalInformation
