import {
  TrusanaLogo,
  TrusanaLogoTest,
  TrusanaLogoMobile,
  TrusanaLogoMobileTest,
} from '../../../assets'
import { companyCodePathIdentifier } from '../../../routing/route.constants'
import { redirectToHelthieWebsite } from '../../EAP/EAP-constants'
import {
  mixpanelTrackGetStartedClicked,
  mixpanelTrackHeaders,
  mixpanelTrackMemberLogin,
} from '../../../common/utils/mixpanel'
import { navigationLinks } from '../landing-page-constants'
import { isProductionEnv } from '../../../configuration/config'
import { useHistory } from 'react-router'

const HeaderSection: React.FC<{ activeSection: string | null }> = ({
  activeSection,
}: {
  activeSection: string | null
}) => {
  const currentUrl = window.location.origin + location.pathname
  const history = useHistory()

  const onGetStarted = () => {
    history.push(companyCodePathIdentifier)
    mixpanelTrackGetStartedClicked()
  }

  return (
    <div className="flex flex-col mx-auto justify-center bg-white lg:drop-shadow-md lg:rounded-b-xl w-full sticky top-0 z-[990] header-section">
      <div className="flex flex-row max-w-screen-xl bg-white justify-between px-4 md:px-16 h-16 md:h-[80px] items-center w-full drop-shadow-md lg:drop-shadow-none rounded-b-xl lg:rounded-none mx-auto">
        <a href="#">
          <img
            src={isProductionEnv() ? TrusanaLogo : TrusanaLogoTest}
            alt="Trusana Logo"
            className="hidden md:block h-8 md:h-[30px]"
          />
          <img
            src={isProductionEnv() ? TrusanaLogoMobile : TrusanaLogoMobileTest}
            alt="Trusana Logo"
            className="block h-[38px] md:hidden"
          />
        </a>
        <div className="hidden lg:flex flex-row items-center gap-12 font-normal h-full w-fit">
          {navigationLinks.map((navigationLink, index) => {
            return (
              <a
                className={`${
                  activeSection === navigationLink.path
                    ? 'border-b-4 border-x-0 border-t-0 border-warmth border-solid font-medium'
                    : null
                } flex items-center text-shade hover:text-shade hover:font-medium text-base h-full hover:border-b-4 hover:border-x-0 hover:border-t-0 hover:border-warmth hover:border-solid`}
                href={'#' + navigationLink.path} // https://stackoverflow.com/questions/28623446/hover-effect-expand-bottom-border
                key={index}
                data-testid={`nav-${navigationLink.path}`}
                onClick={() => {
                  mixpanelTrackHeaders(navigationLink.title)
                }}
              >
                {navigationLink.title}
              </a>
            )
          })}
        </div>
        <div className="flex flex-row gap-2 md:gap-4 xl:gap-6 w-fit">
          <a
            className="rounded-full px-4 md:px-8 w-fit cursor-pointer select-none text-sm md:text-base font-medium max-w-sm flex items-center justify-center text-shade py-2 border-shade border border-solid hover:text-shade"
            onClick={() => {
              redirectToHelthieWebsite()
              mixpanelTrackMemberLogin({
                location: currentUrl,
              })
            }}
            data-testid="btn-login"
          >
            Login
          </a>
          <a
            className="text-onyx hover:text-shade rounded-full px-4 md:px-8 w-fit cursor-pointer select-none text-sm md:text-base font-medium max-w-sm flex items-center justify-center text-black py-2 bg-yellow-ochre"
            onClick={onGetStarted}
          >
            Get started
          </a>
        </div>
      </div>
      <div
        className={`flex flex-row max-w-screen-lg bg-ice justify-between px-4 h-12 items-center w-full lg:hidden mobile-header ${
          activeSection === null ? 'collapsed' : null
        }`}
      >
        <div className="flex max-w-screen-lg justify-center items-center flex-row gap-8 md:gap-32 mx-auto">
          {navigationLinks.map((navigationLink, index) => {
            return (
              <a
                className={`${
                  activeSection === navigationLink.path
                    ? 'border-b-4 border-x-0 border-t-0 border-warmth border-solid font-medium'
                    : null
                } flex items-center text-shade hover:text-shade hover:font-medium text-base h-12 hover:border-b-4 hover:border-x-0 hover:border-t-0 hover:border-warmth hover:border-solid`}
                href={'#' + navigationLink.path}
                key={index}
                onClick={() => {
                  mixpanelTrackHeaders(navigationLink.title)
                }}
                data-testid={`nav-${navigationLink.path}`}
              >
                {navigationLink.title}
              </a>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default HeaderSection
