/* istanbul ignore file */
import RedErrorIcon from '../error-icon/red-error-icon'
import { redirectToHelthieWebsite } from '../../pages/EAP/EAP-constants'

export interface ErrorModalPHPInterface {
  handleClick: () => void
  mainText: string
  buttonText: string
  isPhpTimeoutPage?: boolean
}

const ErrorModalPHP: React.FC<ErrorModalPHPInterface> = ({
  handleClick,
  mainText,
  buttonText,
  isPhpTimeoutPage,
}) => {
  const checkButtonAndRedirect = (buttonText: string) => {
    if (buttonText.toLocaleLowerCase() === 'login') {
      redirectToHelthieWebsite()
    }
  }

  return (
    <div className="rounded-lg flex flex-col md:flex-row">
      <div className="md:w-36">
        <RedErrorIcon isPhpTimeoutPage={isPhpTimeoutPage} />
      </div>
      <div className="flex flex-col w-full justify-center">
        <div className="flex flex-col justify-center items-center">
          <div
            className={
              isPhpTimeoutPage
                ? 'text-sm text-center px-4 md:mr-8 pt-4 md:pb-4'
                : 'text-sm text-center p-4 md:mr-8'
            }
          >
            {mainText}
          </div>{' '}
          {buttonText && (
            <div className="flex items-center justify-center py-4 md:py-2">
              <button
                className={
                  isPhpTimeoutPage
                    ? 'text-white cursor-pointer text-xs py-4 md:py-2 rounded-md bg-teal-blue border-none px-6 py-2 md:mr-8 mx-4'
                    : 'text-white cursor-pointer text-xs md:py-1 rounded-full bg-shade border-none px-6 py-2 md:mr-8'
                }
                onClick={() => {
                  handleClick()
                  checkButtonAndRedirect(buttonText)
                }}
              >
                {buttonText}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default ErrorModalPHP
