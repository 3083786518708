import { Input, Menu, Typography } from 'antd'
import SpecializationCheckboxItem from './specialisation-checkbox'
import { SearchOutlined } from '@ant-design/icons'
import { useContext, useState, useEffect } from 'react'
import { MetadataContext } from '../../../../../../common'
import {
  mixpanelTrackSpecialisationSearch,
  mixpanelTrackSelectedSpecialisations,
} from '../../../../../../common/utils/mixpanel'

interface SpecialisationMenuItemsInterface {
  selectedItems: string[]
  setSelectedItems: (items: string[]) => void
  updateSelectedItems: (specialisation: string, shouldAdd: boolean) => void
}

const { Text } = Typography

const SpecialisationMenuItems: React.FC<SpecialisationMenuItemsInterface> = ({
  selectedItems,
  setSelectedItems,
  updateSelectedItems,
}) => {
  const { specializations } = useContext(MetadataContext)
  const [menuItems, setMenuItems] = useState<string[]>(
    specializations.map((specialization) => specialization.name)
  )
  const [searchValue, setSearchValue] = useState<string>('')
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value)
  }

  useEffect(() => {
    if (searchValue === '') return
    const mixpanelSpecialisationSearchDebounce = setTimeout(() => {
      mixpanelTrackSpecialisationSearch({ searchText: searchValue })
    }, 3000)
    return () => {
      clearTimeout(mixpanelSpecialisationSearchDebounce)
    }
  }, [searchValue])

  useEffect(() => {
    if (selectedItems.length <= 0) return
    const mixpanelSpecialisationSelectDebounce = setTimeout(() => {
      mixpanelTrackSelectedSpecialisations({
        selectedSpecialisations: selectedItems,
      })
    }, 3000)
    return () => {
      clearTimeout(mixpanelSpecialisationSelectDebounce)
    }
  }, [selectedItems])

  useEffect(() => {
    if (searchValue === '') {
      setMenuItems(specializations.map((specialization) => specialization.name))
    } else {
      setMenuItems(
        specializations
          .map((specialization) => specialization.name)
          .filter((menuItem) => {
            return menuItem.toLowerCase().includes(searchValue.toLowerCase())
          })
      )
    }
  }, [searchValue])

  return (
    <div
      onClick={(event: any) => event.stopPropagation()}
      className="rounded-lg md:relative !font-normal !text-xs lg:text-sm"
    >
      <div className="hidden lg:absolute pointer-events-none rounded-lg bottom-0 left-0 right-0 h-10 bg-gradient-to-t from-white to-transparent z-10"></div>
      {/* TODO: Warning: [antd: Menu] `children` will be removed in next major version. Please use `items` instead. */}
      <Menu className="rounded-lg lg:max-h-[25rem] overflow-y-scroll">
        <Menu.Item className="!p-0 ml-1 mr-1 !h-16">
          <div className="ml-1 mr-1 mt-1" key="searchkey">
            <div className="flex flex-row justify-center items-start">
              <Input
                prefix={<SearchOutlined />}
                onClick={(
                  e: React.MouseEvent<HTMLInputElement, MouseEvent>
                ) => {
                  e.stopPropagation()
                }}
                placeholder="Search"
                bordered={false}
                className="rounded-xl !bg-[#0000000D] h-8"
                onChange={handleSearchChange}
                value={searchValue}
                data-testid="filter-concerns-search-box"
              />
              <div
                className="px-2 py-1 underline text-teal-blue hidden lg:block"
                onClick={() => {
                  setSelectedItems([])
                }}
                data-testid="filter-concerns-clear"
              >
                Clear
              </div>
            </div>
          </div>

          <Text className="font-normal text-sm text-teal-blue ml-1">
            Note: Max selection is three
          </Text>
        </Menu.Item>
        {menuItems.map((menuItem, index) => {
          return (
            <Menu.Item key={index}>
              <SpecializationCheckboxItem
                specialisation={menuItem}
                checked={
                  selectedItems.filter(
                    (selectedItem) => selectedItem === menuItem
                  ).length === 1
                }
                updateSelectedItems={updateSelectedItems}
                selectedItems={selectedItems}
              />
            </Menu.Item>
          )
        })}
      </Menu>
    </div>
  )
}

export default SpecialisationMenuItems
