import { useEffect, useRef } from 'react'
import { SingleTherapistModel } from '../../pages/EAP/appointment-page/interfaces/therapist-model'
import { getAppliedFilters } from '../form/form.utils'
import {
  mixpanelTrackFilterAndSearchApplied,
  mixpanelTrackFilterApplied,
  mixpanelTrackProvidersNoResult,
} from '../utils/mixpanel'
import moment from 'moment'

interface UseTrackAppointmentPageInterface {
  therapistResponseData: SingleTherapistModel[]
  selectedDaysArray: boolean[]
  range: [number, number]
  selectedSpecialisationItems: string[]
  selectedPopulationItems: string[]
  isNoMatch: boolean
}

const useTrackAppointmentPage = ({
  therapistResponseData,
  selectedDaysArray,
  range,
  selectedSpecialisationItems,
  selectedPopulationItems,
  isNoMatch,
}: UseTrackAppointmentPageInterface) => {
  const mixpanelTimeoutRef = useRef<NodeJS.Timeout | null>(null)

  useEffect(() => {
    mixpanelTrackFilterApplied(
      getAppliedFilters(
        selectedDaysArray,
        range,
        selectedSpecialisationItems,
        selectedPopulationItems
      )
    )

    if (therapistResponseData.length > 0) {
      if (mixpanelTimeoutRef.current) {
        clearTimeout(mixpanelTimeoutRef.current)
      }
      mixpanelTimeoutRef.current = setTimeout(() => {
        mixpanelTrackFilterAndSearchApplied(
          {
            ...getAppliedFilters(
              selectedDaysArray,
              range,
              selectedSpecialisationItems,
              selectedPopulationItems
            ),
          },
          {
            providers: therapistResponseData.map((therapist) => {
              return {
                id: therapist.id,
                searchType: therapist?.searchType,
                availableTimeSlots: therapist?.availableTimeSlots
                  .slice(0, 9)
                  .map((timeslot) => {
                    return moment
                      .parseZone(timeslot)
                      .format('MM/DD/YY hh:mm:ss')
                  }),
              }
            }),
          },
          therapistResponseData.length
        )
      }, 3000)
      if (isNoMatch == true) {
        mixpanelTrackProvidersNoResult()
      }
    } else {
      mixpanelTrackFilterAndSearchApplied(
        {
          ...getAppliedFilters(
            selectedDaysArray,
            range,
            selectedSpecialisationItems,
            selectedPopulationItems
          ),
        },
        {
          providers: [],
        },
        therapistResponseData.length
      )
    }
    return () => {
      if (mixpanelTimeoutRef.current) {
        clearTimeout(mixpanelTimeoutRef.current)
      }
    }
  }, [therapistResponseData])
}

export default useTrackAppointmentPage
