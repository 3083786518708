import {
  homePath,
  eapFormPathWithParams,
  appointmentConfirmation,
  userAccountSelectionPathWithParams,
  phpFormPathWithParams,
  termsPath,
  onboardingFormPath,
  eapNewFormPathWithParams,
  companyCodePathIdentifier,
  changeProviderPathWithParams,
  customFromRedirectionBHCPath,
  customRedirectFromProvidenceOrgPath,
  faqPath,
} from './route.constants'
import { NewEAPFlow } from '../pages'
import AppointmentConfirmation from '../pages/EAP/appointment-page/appointment-confirmation'
import PHPFlow from '../pages/PHP/php-flow'
import { MarketingSupport } from '../marketing'
import TermsOfUse from '../marketing/pages/terms-and-conditions/terms-of-use'
import NonDiscrimination from '../marketing/pages/terms-and-conditions/non-discrimination'
import PrivacyPractices from '../marketing/pages/terms-and-conditions/privacy-practices'
import CompanyOnboardingPage from '../pages/EAP/company-onboarding/company-onboarding-page'
import CompanyCode from '../pages/company-code-page/company-code'
import RedirectToProvidenceFlows from '../pages/redirect-to-providence-flows/redirect-to-providence-flows'
import RedirectToNewEAPFlow from '../pages/redirect-to-neweap-flow/redirect-to-neweap-flow'
import LandingPage from '../pages/landing-page/landing-page'
import ChangeProviderFlow from '../pages/change-provider/change-provider-flow'
import { FAQSection } from '../pages/landing-page'

export interface RouteType {
  path?: string
  component: React.ReactNode
  exact: boolean
}

export const getBackgroundImageOnRoute = (routePath: string) => {
  if (routePath.includes('details'))
    return {
      image: null,
      title: null,
      className: 'h-[20vh] w-full',
    }
  return {
    image: null,
    title: null,
    className: 'h-[20vh] w-full',
  }
}

export const getTitleOnRoute = (routePath: string) => {
  switch (routePath) {
    case homePath:
      return 'Join the Trusana family headline'
    case 'Other':
      return 'Create your account with Trusana'
    default:
      return ''
  }
}

export const routes: RouteType[] = [
  {
    path: homePath,
    component: <LandingPage />,
    exact: true,
  },
  {
    path: onboardingFormPath,
    component: <CompanyOnboardingPage />,
    exact: true,
  },
  {
    path: '/welcome/support',
    component: <MarketingSupport />,
    exact: true,
  },
  {
    path: termsPath + '/termsofuse',
    component: <TermsOfUse />,
    exact: true,
  },
  {
    path: faqPath,
    component: <FAQSection isLandingPageFaq={false} />,
    exact: false,
  },
  {
    path: termsPath + '/nondiscrimination',
    component: <NonDiscrimination />,
    exact: true,
  },
  {
    path: termsPath + '/privacy',
    component: <PrivacyPractices />,
    exact: true,
  },
  {
    path: customFromRedirectionBHCPath,
    component: <RedirectToProvidenceFlows />,
    exact: true,
  },
  {
    path: customRedirectFromProvidenceOrgPath,
    component: <RedirectToProvidenceFlows />,
    exact: true,
  },
  {
    path: companyCodePathIdentifier,
    component: <CompanyCode />,
    exact: true,
  },
  {
    path: eapFormPathWithParams,
    component: <RedirectToNewEAPFlow />,
    exact: false,
  },
  {
    path: eapNewFormPathWithParams,
    component: <NewEAPFlow />,
    exact: false,
  },
  {
    path: userAccountSelectionPathWithParams,
    // component: <QuestionnaireUserAccountSelection />,
    component: <RedirectToNewEAPFlow />,
    exact: false,
  },
  {
    path: appointmentConfirmation,
    component: <AppointmentConfirmation />,
    exact: false,
  },
  {
    path: phpFormPathWithParams,
    component: <PHPFlow />,
    exact: false,
  },
  {
    path: changeProviderPathWithParams,
    component: <ChangeProviderFlow />,
    exact: false,
  },
]
