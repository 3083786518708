/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FocusEventHandler } from 'react'
import { Form, Input } from 'antd'
import classnames from 'classnames'
import {
  DEFAULT_INPUT_LENGTH,
  LABEL_ALIGN_TYPE,
} from '../../form/form.constants'
import { formRules, Validator } from '../../form/form.utils'
import '../new-form.scss'
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons'
import NewFormLabel from '../new-form-label/new-form-label'

export interface InputPasswordProps {
  maxLength?: number
  min?: {
    value: number
    message: string
  }
  max?: {
    value: number
    message: string
  }
  isAllowClear?: boolean
  isDisabled?: boolean
  label?: string | any
  htmlLabel?: string | any
  name?: string | any
  isRequired?: boolean
  labelAlign?: keyof typeof LABEL_ALIGN_TYPE
  pattern?: RegExp
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  className?: string
  inputContainerStyle?: string
  validateTrigger?: string | string[]
  validator?: Validator
  patternMessage?: string
  prefix?: React.ReactNode
  placeholder?: string
  value?: string | ReadonlyArray<string> | number | undefined
  onBlur?: FocusEventHandler<HTMLInputElement>
  isURL?: boolean
  isEmail?: boolean
  isFullWidth?: boolean
}

const NewInputPassword: React.FC<InputPasswordProps> = (
  props: InputPasswordProps
) => {
  const {
    min,
    max,
    label,
    htmlLabel,
    name,
    labelAlign,
    isRequired,
    validator,
    onChange,
    className,
    pattern,
    patternMessage,
    isDisabled,
    isAllowClear,
    maxLength,
    validateTrigger,
    prefix,
    value,
    inputContainerStyle,
    isURL = false,
    isEmail = false,
    isFullWidth = false,
  } = props

  return (
    <label
      htmlFor={htmlLabel ? htmlLabel : label}
      className={`php-form-element flex flex-col ${
        isFullWidth ? 'md:flex-1' : 'md:basis-1/2'
      } font-bold text-shade `}
    >
      {isRequired ? (
        <NewFormLabel label={label} />
      ) : (
        <span className="pb-2">{label}</span>
      )}
      <Form.Item
        label={label}
        name={name}
        labelAlign={labelAlign}
        validateTrigger={validateTrigger}
        className={classnames('block php-password', className)}
        rules={formRules(
          label,
          isRequired,
          validator,
          pattern,
          patternMessage,
          min,
          max,
          isURL,
          isEmail
        )}
        validateFirst={true}
      >
        <Input.Password
          iconRender={(passwordVisible) =>
            passwordVisible ? (
              <div>
                <EyeTwoTone /> <span>{'Hide'}</span>
              </div>
            ) : (
              <div>
                <EyeInvisibleOutlined /> <span>{'Show'}</span>
              </div>
            )
          }
          suffix={<div>show</div>}
          className={classnames(
            'form-item-input-width',
            'new-input-password-input',
            inputContainerStyle
          )}
          disabled={isDisabled}
          id={name}
          onChange={onChange}
          allowClear={isAllowClear}
          maxLength={maxLength || DEFAULT_INPUT_LENGTH}
          prefix={prefix}
          // placeholder={placeholder}
          value={value}
          type={isEmail ? 'email' : 'text'}
          // visibilityToggle={false}
        />
      </Form.Item>
    </label>
  )
}

export default NewInputPassword
