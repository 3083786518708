import { InfoCircleOutlined } from '@ant-design/icons'

interface ExclamationInfoMessageProps {
  message: string
  isBackgroundShaded: boolean
}

const ExclamationInfoMessage: React.FC<ExclamationInfoMessageProps> = ({
  message,
  isBackgroundShaded = false,
}) => {
  return (
    <div
      className={` ${isBackgroundShaded ? 'bg-[#FAFAFA]' : ''} text-[#3A6975]`}
      data-testid="exclamation-info-message"
    >
      <InfoCircleOutlined data-testid="info-icon" /> {message}
    </div>
  )
}

export default ExclamationInfoMessage
