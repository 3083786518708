export interface DynamicConfig {
  apiBaseUrl: string
  clientId: string
  authority: string
  environment: string
  redirectUri: string
  scopes: string[]
  xUserID: string
  subscription: string
  memberLoginUrl: string
  recaptchaKey: string
  providerAvailabilityDays: number
  mixpanelToken: string
  splashScreenTimer: number
  phpInsuranceFileSize: number
  inactivityPopupTimer: number
  formattedAddressFields: string
}

// Replace these with your own clientId and TenantID
const clientID = ''
const tenantId = ''
const apiScope = ['']

export const defaultConfig: DynamicConfig = {
  //****** demo api config ******
  // apiBaseUrl: 'https://apim-trusana-dev.azure-api.net/apimdemo/',
  // subscription: '8599b165861141a2aa140f90e4f16b0f',
  // ****** demo api config ******
  apiBaseUrl: 'https://apim-trusana-dev.azure-api.net/apimdev/',
  subscription: 'afd575c658454b049612d6564237b0a0',
  environment: 'dev',
  clientId: clientID,
  scopes: apiScope,
  redirectUri: 'http://localhost:3000/admin',
  authority: 'https://login.microsoftonline.com/' + tenantId,
  xUserID: '3008a190-0f06-4720-9e7c-18e7dcb6b504',
  memberLoginUrl: 'https://securestaging.gethealthie.com/users/sign_in',
  recaptchaKey: '6LdgzWgjAAAAAKQbkzUgkXV7AAiBlc2SiTttW8Ch',
  providerAvailabilityDays: 60,
  mixpanelToken: '980a36ffa3ee40d18b927b462ee793e3',
  splashScreenTimer: 30,
  phpInsuranceFileSize: 5242880, // 5 MB,
  inactivityPopupTimer: 5, // in minutes
  formattedAddressFields:
    'street_address|street_number|route|neighborhood|sublocality_level_5|sublocality_level_4|sublocality_level_3|sublocality_level_2|sublocality_level_1|sublocality|administrative_area_level_7|administrative_area_level_6|administrative_area_level_5|administrative_area_level_4|administrative_area_level_3|administrative_area_level_2',
}

class GlobalConfig {
  config: DynamicConfig = defaultConfig
  notDefinedYet = true

  public get(): DynamicConfig {
    if (this.notDefinedYet) {
      throw new Error(
        'Global config has not been defined yet. Be sure to call the getter only after the config has been downloaded and set. Probable cause is accessing globalConfig in static context.'
      )
    } else {
      return this.config
    }
  }

  public set(value: DynamicConfig): void {
    if (this.notDefinedYet) {
      this.config = value
      this.notDefinedYet = false
    } else {
      throw new Error(
        'Global config has already been defined and now has been called second time. This is probably not intended.'
      )
    }
  }
}

export const globalConfig = new GlobalConfig()

export const isProductionEnv = () => {
  return globalConfig.get().environment === 'production'
}

export const globalConfigUrl = 'appConfiguration.json'
