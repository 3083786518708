export const v1_identifier = 'v1'
export const v2_identifier = 'v2'
export const v3_identifier = 'v3'
export const v4_identifier = 'v4'
export const v5_identifier = 'v5'
export const v6_identifier = 'v6'
export const v7_identifier = 'v7'

export const API_URLs = {
  v1: {
    GET_MetadataStates: v1_identifier + '/api/MetaData/States',
    GET_MetadataStateAges: v1_identifier + '/api/MetaData/StateAges',
    GET_MetadataRelations: v1_identifier + '/api/MetaData/Relations',
    GET_MetadataFeatureFlags: v1_identifier + '/api/Metadata/FeatureFlags',

    GET_Genders: v1_identifier + '/api/MetaData/Genders',
    GET_PatientTypes: v1_identifier + '/api/MetaData/PatientTypes',
    GET_MetadataContactTypes: v1_identifier + '/api/MetaData/ContactTypes',
    GET_Organizations: v1_identifier + '/api/Metadata/Organizations',
    POST_AppointmentCreate:
      v1_identifier + '/api/Appointment/CreateAppointment',
    GET_AppointmentAvailableTimeSlots:
      v1_identifier + '/api/Appointment/AvailableTimeslots',
    GET_OrganizationValidate: v1_identifier + '/api/Organization/Validate',
    POST_Patients: v1_identifier + '/api/Patients',
    POST_SendOTP: v1_identifier + '/api/Otp/Send',
    POST_CheckOTP: v1_identifier + '/api/Otp/Validate',
    POST_JoinWaitList: v1_identifier + '/api/PatientWaitLists',
    POST_Visitors: v1_identifier + '/api/Visitor',
    POST_IDUpload: v1_identifier + '/api/Document',
    POST_Feedback: v1_identifier + '/api/Feedback',
    GET_PatientExistence: v1_identifier + '/api/Patients/Existence',
    POST_EmailExistence: v1_identifier + '/',
    POST_OnboardingOrganizations:
      v1_identifier + '/api/OnboardingOrganizations',
    POST_InsuranceUpload: v1_identifier + '/api/Document/InsuranceUpload',
    GET_MetadataCareMemberTypes:
      v1_identifier + '/api/MetaData/CareMemberTypes',
    GET_Specializations: v1_identifier + '/api/MetaData/Specializations',
    POST_Population: v1_identifier + '/api/MetaData',
    POST_ChangeProviderSignIn: v1_identifier + '/api/ChangeProvider/SignIn',
    POST_ChangeProvider_ProvidersAvailability:
      v1_identifier + '/api/ChangeProvider/ProvidersAvailability',
    POST_ChangeProviderBookAppointment:
      v1_identifier + '/api/ChangeProvider/BookAppointment',
    POST_ChangeProviderFeedback: v1_identifier + '/api/ChangeProvider/Feedback',
    POST_ChangeProviderAvailableTimeSlots:
      v1_identifier + '/api/ChangeProvider/AvailableTimeSlots',
    GET_OrganizationForm: v1_identifier + '/api/MetaData/OrganizationForm',
    GET_Geocoding_GetByZipCode: v1_identifier + '/api/Address/PreFillZipCode',
    GET_Places_AutoComplete: v1_identifier + '/api/Address/PlacesAutoComplete',
    GET_Place_Details: v1_identifier + '/api/Address/PlaceDetails',
  },

  v2: {
    GET_Providers: v2_identifier + '/api/v2/Providers',
    GET_AppointmentAvailableTimeSlots:
      v2_identifier + '/api/v2/Appointment/AvailableTimeslots',
    POST_AppointmentCreate:
      v2_identifier + '/api/v2/Appointment/CreateAppointment',
    POST_Patients: v2_identifier + '/api/v2/Patients',
    GET_PatientExistence: v2_identifier + '/api/v2/Patients/Existence',
    POST_VisitorEntities: v2_identifier + '/api/v2/VisitorEntities',
    PUT_VisitorEntities: v2_identifier + '/api/v2/VisitorEntities',
    POST_ChangeProvider_ProvidersAvailability:
      v2_identifier + '/api/v2/ChangeProvider/ProvidersAvailability',
  },
  v3: {
    POST_AppointmentCreate:
      v3_identifier + '/api/v3/Appointment/CreateAppointment',
    GET_AppointmentAvailableTimeSlots:
      v3_identifier + '/api/v3/Appointment/AvailableTimeslots',
    GET_Providers: v3_identifier + '/api/v3/Providers',
    GET_PatienceExistence: v3_identifier + '/api/v3/PatientExistence/Existence',
  },
  v4: {
    GET_AppointmentAvailableTimeSlots:
      v4_identifier + '/api/v4/Appointment/AvailableTimeslots',
    GET_Providers: v4_identifier + '/api/v4/Providers',
  },
  v5: {
    GET_AppointmentAvailableTimeSlots:
      v5_identifier + '/api/v5/Appointment/AvailableTimeslots',
    GET_Provider: v5_identifier + '/api/v5/Providers',
  },
  v6: {
    POST_Provider: v6_identifier + '/api/v6/Providers',
    POST_AppointmentAvailableTimeSlots:
      v6_identifier + '/api/v6/Appointment/AvailableTimeslots',
  },
  v7: {
    POST_Provider: v7_identifier + '/api/v7/Providers',
    POST_AppointmentAvailableTimeSlots:
      v7_identifier + '/api/v7/Appointment/AvailableTimeslots',
  },
}
