import { useContext, useEffect, useState } from 'react'
import { LoaderContext } from '../loader/loader-middleware'
import APIUtils from './api-utils'

interface useGetProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any
  ok?: boolean
  status?: number
}

interface getProps {
  url: string
  isCancelled?: boolean
}

function useGet<T extends useGetProps>({
  url,
  isCancelled = false,
}: getProps): T {
  const [data, setData] = useState({} as T)
  const { setLoading } = useContext(LoaderContext)

  useEffect(() => {
    if (isCancelled) {
      return
    } else {
      const getData = async () => {
        let result: T = {} as T
        try {
          setLoading(true)
          result = await APIUtils().get(url)
        } finally {
          setLoading(false)
          setData(result)
        }
      }

      getData()
    }
  }, [url])

  return data
}

export default useGet
