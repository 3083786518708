import { Form } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { useEffect, useState } from 'react'
import { APICallType, InputText, useAPICall } from '../../../common'
import { FormInvalidMessage } from '../../../common/form/form.utils'
import { API_URLs } from '../../../configuration/api-urls'
import { alphabetPattern } from '../EAP-constants'
import '../your-details.scss'
import FormHeading from './form-heading'
import MessagePopup from './request-successful-bubble'
import SubmitClear from './submit-clear'
import { useLocation } from 'react-router-dom'

const OnboardingForm = () => {
  const location = useLocation<any>()
  const [form] = useForm()
  const { apiCall } = useAPICall()
  const [submitSuccesful, setIsSubmitSuccessful] = useState(false)

  const onFinish = (values: any) => {
    const request = {
      organization: values.orgName,
      name: values.name,
      email: values.email,
      contactName: values.contactName,
      contactEmail: values.contactEmail,
    }

    apiCall(
      API_URLs.v1.POST_OnboardingOrganizations,
      APICallType.POST,
      JSON.stringify(request)
    ).then((response: any) => {
      if (response) {
        setIsSubmitSuccessful(true)
        handleClear()
      }
    })
  }

  useEffect(() => {
    form.setFieldsValue({ orgName: location?.state?.OrgName })
    form.validateFields(['orgName'])
  }, [location?.state?.OrgName])

  const handleClear = () => {
    form.resetFields()
  }

  return (
    <div className="formCard w-full" style={{ maxHeight: '110vh' }}>
      <FormHeading />
      <Form onFinish={onFinish} form={form}>
        <div className="formFields">
          <InputText
            label={'Organization Name'}
            name={'orgName'}
            isRequired={true}
            aria-required={true}
            min={{
              value: 2,
              message: FormInvalidMessage(''),
            }}
          />
          <InputText
            label={'Name'}
            name={'name'}
            isRequired={true}
            aria-required={true}
            pattern={alphabetPattern}
            patternMessage={FormInvalidMessage('Name')}
            maxLength={50}
            min={{
              value: 2,
              message: FormInvalidMessage(''),
            }}
          />
          <InputText
            label={'Email Address'}
            name={'email'}
            isEmail={true}
            isRequired={true}
            maxLength={65}
            min={{
              value: 3,
              message: FormInvalidMessage(''),
            }}
            max={{
              value: 65,
              message: FormInvalidMessage(''),
            }}
          />
          <h3 className="font-semibold text-[#314259] my-6">
            Details of the person we should contact
          </h3>
          <InputText
            label={'Name'}
            name={'contactName'}
            isRequired={true}
            aria-required={true}
            pattern={alphabetPattern}
            patternMessage={FormInvalidMessage('Name')}
            maxLength={50}
            min={{
              value: 2,
              message: FormInvalidMessage(''),
            }}
          />
          <InputText
            label={'Email Address'}
            name={'contactEmail'}
            isEmail={true}
            isRequired={true}
            maxLength={65}
            min={{
              value: 3,
              message: FormInvalidMessage(''),
            }}
            max={{
              value: 65,
              message: FormInvalidMessage(''),
            }}
          />
        </div>
        {submitSuccesful ? (
          <MessagePopup
            title="Request successfully submitted"
            message="Our team will contact your organization very soon"
          />
        ) : (
          <SubmitClear onClear={handleClear} />
        )}
      </Form>
    </div>
  )
}

export default OnboardingForm
