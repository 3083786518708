import React from 'react'
import { Modal } from 'antd'
import './modal.scss'

interface CommonModalProps {
  handleClose: () => void
  isModalOpen: boolean
  children: React.ReactNode
  title: string
  className?: string
}

const CommonModal: React.FC<CommonModalProps> = ({
  handleClose,
  isModalOpen,
  children,
  title,
  className,
}) => (
  <Modal
    wrapClassName="common-modal-wrapper"
    title={title}
    visible={isModalOpen}
    onCancel={handleClose}
    footer={null}
    className={className}
  >
    {children}
  </Modal>
)
export default CommonModal
