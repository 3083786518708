import { FilterFilled } from '@ant-design/icons'
import { Collapse, Drawer, Typography } from 'antd'
import { useContext, useEffect, useState } from 'react'
import './search-and-filter.scss'
import DaysOfTheWeekSelect from './filters/days-select'
import { AppointmentPageContext } from '../appointment-page-middleware'
import SpecializationDropdown from './filters/specialisation/specialisation-dropdown'
//import Checkbox, { CheckboxChangeEvent } from 'antd/lib/checkbox'
//import LanguageSelection from './filters/language-selection'
import PopulationSelection from './filters/population-selection'
import { MetadataContext } from '../../../../common'

import {
  // AvailableLanguages,
  DaysOfTheWeekArray,
  FilterOptions,
} from '../../EAP-constants'
import {
  FilterOptionsMobile,
  // FilterTypes,
} from '../../../../common/utils/mixpanel-constants'
import {
  TimeRangeButton,
  TimeRangeButtonList,
  TimeRangeButtons,
} from './filters/time-range-buttons'
import { changeProviderApptPath } from '../../../../routing/route.constants'
import { useLocation } from 'react-router-dom'
import FilterContainer from '../../../../common/filter-container/filter-container'
import { calculateSelectedDays } from '../../../../common/form/form.utils'
import SpecializationCheckboxItem from './filters/specialisation/specialisation-checkbox'
// import SearchBox from './search/search-box'

const SearchAndFilter: React.FC = () => {
  const [isFilterToolbarOpen, setIsFilterToolbarOpen] = useState(true)
  const { Panel } = Collapse
  const toggleFilterToolbar = () => {
    setIsFilterToolbarOpen(!isFilterToolbarOpen)
  }

  const location = useLocation()

  const isChangeProviderFlow = location.pathname.includes(
    changeProviderApptPath
  )

  const [selectedFilterOption, setSelectedFilterOption] = useState<string>(
    FilterOptions[0]
  )

  const {
    resetFilters,
    toggleFilterDrawer,
    isFilterDrawerOpen,
    updateSelectedTimeBlock,
    selectedTimeBlock,
    selectedDaysArray,
    updateSelectedDaysArray,
    // languageSelection,
    selectedPopulationItems,
    updateSelectedPopulationItems,
  } = useContext(AppointmentPageContext)

  const [selectedItems, setSelectedItems] = useState<string[]>(
    selectedPopulationItems
  )
  const updateSelectedItems = (item: string, shouldAdd: boolean) => {
    setSelectedItems((prevSelectedItems) => {
      if (shouldAdd) {
        return [...prevSelectedItems, item]
      } else {
        return prevSelectedItems.filter((existingItem) => existingItem !== item)
      }
    })
  }

  const expandIcon = (
    <div className="flex flex-row justify-center items-center">
      <div
        className="text-teal-blue underline text-end md:mr-4 block font-normal whitespace-pre"
        onClick={resetFilters}
        data-testid="btn-clear-filters"
      >
        Clear filters
      </div>
      <div
        className={`toggle-btn ${
          isFilterToolbarOpen ? 'active' : ''
        } hidden bg-teal-blue h-7 w-8 md:flex w-full flex items-start rounded-lg justify-start min-w-[2rem]`}
      >
        <div className="arrow"></div>
      </div>
    </div>
  )

  const selectAllDaysClick = () => {
    const allTrue = selectedDaysArray.every((value) => value === true)
    if (allTrue) {
      updateSelectedDaysArray(Array(7).fill(false))
      return
    }
    updateSelectedDaysArray(Array(7).fill(true))
    return
  }

  const daysFilterOverlayComponent = (
    <div className="flex flex-col items-center gap-2 bg-snow drop-shadow-lg p-2 rounded-lg -mt-2">
      <div
        className="w-full text-end px-4 text-teal-blue underline cursor-pointer"
        onClick={selectAllDaysClick}
        data-testid={`filter-select-none-all-days`}
      >
        Select{' '}
        {selectedDaysArray.every((value) => value === true)
          ? 'none'
          : 'all days'}
      </div>
      {DaysOfTheWeekArray.Desktop.map((day, index) => {
        return (
          <div
            className={`px-4 py-2 w-full cursor-pointer  transition duration-300 font-medium text-xs border rounded-lg border border-[#DCD8D0] border-solid
              ${
                selectedDaysArray[index]
                  ? 'bg-teal-blue text-white'
                  : 'bg-[#FAFAFA] hover:bg-teal-blue hover:text-white'
              }
            `}
            key={index}
            onClick={() => {
              handleDayClick(index)
            }}
            data-testid={`opt-${day}`}
          >
            {day}
          </div>
        )
      })}
    </div>
  )

  const handleDayClick = (index: number) => {
    const newSelectedArray = [...selectedDaysArray]
    newSelectedArray[index] = !newSelectedArray[index]
    updateSelectedDaysArray(newSelectedArray)
  }

  // const languageFilterOverlayComponent = (
  //   <div className="flex flex-col items-center justify-start gap-2 bg-snow drop-shadow-lg p-2 rounded">
  //     {AvailableLanguages.map((language, index) => {
  //       return (
  //         <div
  //           className={`flex flex-row gap-2 items-baseline px-4 py-2 w-full bg-[#FAFAFA] cursor-pointer hover:bg-teal-blue hover:text-white transition duration-300 font-medium text-xs border rounded-lg border border-[#DCD8D0] border-solid
  //       ${
  //         languageSelection === language
  //           ? 'bg-teal-blue text-white md:bg-teal-blue'
  //           : ''
  //       }`}
  //           key={index}
  //           onClick={() => {
  //             updateLanguageSelection(language)
  //           }}
  //         >
  //           {language}
  //         </div>
  //       )
  //     })}
  //   </div>
  // )

  const { Text } = Typography

  useEffect(() => {
    setSelectedItems(selectedPopulationItems)
  }, [selectedPopulationItems])

  const { populations } = useContext(MetadataContext)

  const populationItems = populations[0].values.map(
    (population) => population.name
  )

  const populationOverlayComponent = (
    <div
      className="flex flex-col items-start justify-start bg-snow drop-shadow-lg p-2 rounded -mt-2 overflow-y-auto"
      style={{ maxHeight: '325px' }}
    >
      <div className="flex flex-row ml-2">
        <Text className="font-normal text-sm text-teal-blue">
          Note: Max selection is three
        </Text>
      </div>

      <div className="ml-2 mt-2">
        {populationItems.map((population, index) => {
          return (
            <div
              key={index}
              // className={`px-4 pt-1 pb-0.5 w-full cursor-pointer bg-[#FAFAFA] transition duration-300 font-medium text-xs border rounded-lg border border-[#DCD8D0] border-solid `}
            >
              <SpecializationCheckboxItem
                specialisation={population}
                key={index}
                checked={
                  selectedItems.filter(
                    (selectedItem) => selectedItem === population
                  ).length === 1
                }
                updateSelectedItems={updateSelectedItems}
                selectedItems={selectedItems}
              />
            </div>
          )
        })}
      </div>
    </div>
  )

  const timeFilterOverlayComponent = (
    <div className="flex flex-col justify-between items-center p-2 bg-snow gap-2 rounded drop-shadow-lg -mt-2">
      {TimeRangeButtonList.map((timeButton, index) => {
        return (
          <TimeRangeButton
            icon={timeButton.icon}
            timeRange={timeButton.timeRange}
            text={timeButton.text}
            index={index}
            darkIcon={timeButton.darkIcon}
            selectedTimeBlocks={selectedTimeBlock}
            updateSelectedTimeBlocks={updateSelectedTimeBlock}
            key={index}
          />
        )
      })}
    </div>
  )

  const onFilterVisibleChange = (visible: boolean, title: string) => {
    if (title === 'Populations' && !visible) {
      updateSelectedPopulationItems(selectedItems)
    }
  }

  return (
    <div
      className={`md:mt-${
        isChangeProviderFlow ? '3' : '8'
      } md:mx-4 md:rounded-lg w-full search-and-filter `}
    >
      <Collapse
        ghost
        bordered={false}
        defaultActiveKey={[1]}
        onChange={toggleFilterToolbar}
        className={`${
          isFilterToolbarOpen ? '!rounded-b-lg' : '!rounded-lg'
        } hidden lg:block !justify-between !mx-4 !bg-[#F0F3F463] !text-sm !text-teal-blue !font-bold !border-solid md:!border-y md:!border-x rounded-t-lg md:rounded-t-none !border-[#DCD8D0]`}
      >
        <Panel
          key={'1'}
          header={<CollapseHeader />}
          extra={expandIcon}
          className={`${isFilterToolbarOpen ? '' : '!rounded-b-lg'}`}
          showArrow={false}
        >
          <div
            className={`flex flex-col lg:flex-row items-center bg-white px-2 py-1 text-base rounded-lg md:rounded-b-lg md:rounded-t-none mt-4 md:mt-0 md:border-x-0 md:border-b-0 border-[#DCD8D0] border-solid border md:border-t-0 !bg-[#F0F3F463] lg:max-h-[6.25rem]`}
          >
            <SpecializationDropdown />
            <FilterContainer
              overlay={daysFilterOverlayComponent}
              title="Preferred day"
              currentText={calculateSelectedDays(selectedDaysArray)}
              onClear={() => {
                updateSelectedDaysArray(Array(7).fill(false))
              }}
              //disabled
            />
            <FilterContainer
              overlay={timeFilterOverlayComponent}
              title="Preferred Time"
              currentText={
                ['Select', 'Morning', 'Afternoon', 'Evening'][
                  selectedTimeBlock + 1
                ]
              }
              onClear={() => {
                updateSelectedTimeBlock(-1)
              }}
              // disabled={true}
            />
            <FilterContainer
              overlay={populationOverlayComponent}
              title="Populations"
              currentText={
                selectedItems.length > 0
                  ? selectedItems.length > 1
                    ? selectedItems.length + ' Selected'
                    : selectedItems[0]
                  : 'Select'
              }
              // currentText={selectedItems[0]+selectedItems[1]+selectedItems[2]}
              onClear={() => {
                if (selectedItems.length > 0) {
                  updateSelectedPopulationItems([])
                }
              }}
              onVisibleChange={onFilterVisibleChange}
            />
            {/* <FilterContainer
              overlay={languageFilterOverlayComponent}
              title="Languages"
              currentText={languageSelection}
              onClear={() => {
                updateLanguageSelection(AvailableLanguages[0])
              }}
            /> */}
          </div>
        </Panel>
      </Collapse>
      <div
        className={`flex flex-row justify-between items-center mx-4 lg:hidden px-2 rounded-lg mobile-filters border-solid border-[#DCD8D0]`}
        onClick={toggleFilterDrawer}
      >
        <CollapseHeader isFilterDrawerOpen={false} />
      </div>
      <Drawer
        title={<CollapseHeader isFilterDrawerOpen={isFilterDrawerOpen} />}
        className="block lg:hidden search-and-filter"
        placement="right"
        width={'400px'}
        visible={isFilterDrawerOpen}
        onClose={toggleFilterDrawer}
        destroyOnClose={true}
      >
        <div className="flex flex-row">
          <div className="flex flex-col w-1/3">
            {FilterOptions.map((filterOption, index) => {
              return (
                <div
                  key={index}
                  className={`p-1 m-1 text-xs cursor-pointer select-none ${
                    selectedFilterOption === filterOption
                      ? 'font-bold'
                      : 'text-slate-400'
                  }`}
                  onClick={() => setSelectedFilterOption(filterOption)}
                >
                  {filterOption}
                </div>
              )
            })}
          </div>
          <div className="flex flex-col w-2/3">
            {selectedFilterOption === FilterOptionsMobile.SPECIALISATION ? (
              <SpecializationDropdown />
            ) : selectedFilterOption ===
              FilterOptionsMobile.DAYS_OF_THE_WEEK ? (
              <DaysOfTheWeekSelect />
            ) : selectedFilterOption === FilterOptionsMobile.TIME_RANGE ? (
              <TimeRangeButtons />
            ) : selectedFilterOption === FilterOptionsMobile.POPULATION ? (
              <PopulationSelection />
            ) : //populationOverlayComponent
            // ) : selectedFilterOption === FilterTypes.LANGUAGE ? (
            //   <LanguageSelection />
            null}
          </div>
        </div>
      </Drawer>
    </div>
  )
}

const CollapseHeader: React.FC<{ isFilterDrawerOpen?: boolean }> = ({
  isFilterDrawerOpen = true,
}) => {
  const { resetFilters } = useContext(AppointmentPageContext)
  return (
    <div className="flex flex-row justify-between w-full md:mr-4 my-2 lg:my-0 cursor-pointer">
      <div className="flex flex-row items-center justify-start w-fit text-teal-blue">
        <FilterFilled className="text-shade" />
        <div className="ml-2">Filters</div>
      </div>
      <div
        className={`text-teal-blue underline text-end font-normal lg:hidden ${
          isFilterDrawerOpen ? '' : 'hidden'
        }`}
        onClick={resetFilters}
      >
        Clear filters
      </div>
    </div>
  )
}

export default SearchAndFilter
