/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FunctionComponent, useContext, useState } from 'react'
import './php-insurance-upload.scss'
import axios from 'axios'
import APIErrorMessagePopup from '../error-message-popup/api-error-message-popup'
import { globalConfig } from '../../configuration/config'
import {
  mixpanelTrackUploadAttempt,
  mixpanelTrackUploadSuccess,
} from '../utils/mixpanel'
import { PHPRegistrationContext } from '../../pages/PHP/php-registration/php-registration-middleware'
import ImageUploadValidation from '../id-upload/image-upload-validation'
import PhpImageUploadButton from './php-image-upload-button'
import PhpImageUploadProgress from './php-image-upload-progress'
import { API_URLs } from '../../configuration/api-urls'
import PhpImageThumbNail from './php-image-thumbnail'
interface IdVerificationUploadProps {
  shouldDisplayImage?: boolean
  imgData: any
  setImgData: React.Dispatch<any>
  isImageUploaded: boolean
  setIsImageUploaded: React.Dispatch<React.SetStateAction<boolean>>
  shouldDisplayValidation: boolean
  setShouldDisplayValidation: React.Dispatch<boolean>
}

const InsuranceVerificationUpload: FunctionComponent<
  IdVerificationUploadProps
> = ({
  imgData,
  setImgData,
  isImageUploaded,
  setIsImageUploaded,
  shouldDisplayValidation,
  setShouldDisplayValidation,
}) => {
  const [uploadProgress, setUploadProgress] = useState(0)
  const [shouldDisplayProgress, setShouldDisplayProgress] = useState(false)
  const [shouldDisplayImage, setShouldDisplayImage] = useState(false)
  const [shouldDisplayUpload, setShouldDisplayUpload] = useState(false)
  const [greaterthanLimit, setGreaterThanLimit] = useState(false)
  const [isRightFileType, setIsRightFileType] = useState(true)
  const [shouldDisplayErrorModal, setShouldDisplayErrorModal] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const {
    updateInsuranceDocumentID,
    setIsInsuranceImageUploaded,
    isInsuranceDetailsPresent,
  } = useContext(PHPRegistrationContext)
  const globalConfigData = globalConfig.get()
  const baseUrl = globalConfigData.apiBaseUrl
  const headersForUpload = {
    'X-User-ID': globalConfigData.xUserID,
    'Strict-Transport-Security': 'max-age=31536000;includeSubDomains',
    subscription: globalConfigData.subscription,
  }

  const toggleModal = () => {
    setShouldDisplayErrorModal(!shouldDisplayErrorModal)
  }

  const handleImageUploadCancel = () => {
    setShouldDisplayUpload(true)
    setShouldDisplayImage(false)
    setIsImageUploaded(false)
    setImgData(null)
    setUploadProgress(0)
    setIsInsuranceImageUploaded(false)
    if (!isInsuranceDetailsPresent) {
      setShouldDisplayValidation(true)
    }
  }

  const onImageChange = (e: any) => {
    setShouldDisplayProgress(true)
    setUploadProgress(0)
    const file = e?.dataTransfer?.files[0] || e.target.files[0]
    const reader = new FileReader()
    const _url = reader.readAsDataURL(file)
    reader.onloadend = (e: any) => {
      setImgData({
        fileName: file.name,
        size: file.size,
        data: reader.result,
      })
      if (
        file.type === 'image/png' ||
        file.type === 'image/jpg' ||
        file.type === 'image/jpeg' ||
        file.type === 'application/pdf'
      ) {
        setIsRightFileType(true)
        setShouldDisplayValidation(false)
        uploadAPICall(file)
      } else {
        if (isInsuranceDetailsPresent) {
          setShouldDisplayValidation(false)
        }
        updateInsuranceDocumentID(null)
        setIsRightFileType(false)
        setShouldDisplayProgress(false)
        setImgData(null)
      }
    }
  }

  const onSuccess = (res: any, file: any) => {
    setShouldDisplayProgress(false)
    setShouldDisplayImage(true)
    setIsImageUploaded(true)
    mixpanelTrackUploadSuccess()
    updateInsuranceDocumentID(res?.data?.documentId)
    setIsInsuranceImageUploaded(true)
  }
  const uploadAPICall = (file: any) => {
    mixpanelTrackUploadAttempt()
    setShouldDisplayProgress(true)
    const formData = new FormData()
    formData.append('File', file)

    if (file.size > globalConfig.get().phpInsuranceFileSize) {
      setGreaterThanLimit(true)
      setImgData(null)
      if (isInsuranceDetailsPresent) {
        setShouldDisplayValidation(false)
      }
      setShouldDisplayValidation(true)
    } else {
      setGreaterThanLimit(false)
      const postUrl = baseUrl + API_URLs.v1.POST_InsuranceUpload
      axios
        .post(postUrl, formData, {
          headers: headersForUpload,
          onUploadProgress: (progressEvent: any) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            )
            setUploadProgress(percentCompleted)
          },
        })
        .then((data: any) => {
          onSuccess(data, file)
          Promise.resolve()
        })
        .catch((error: any) => {
          setErrorMessage(error.message)
          toggleModal()
        })
        .finally(() => {
          setShouldDisplayProgress(false)
        })
    }
  }

  return (
    <div className="php-insurance">
      <div className="formItems">
        <div className="my-6">
          {imgData === null && (
            <PhpImageUploadButton
              shouldDisplayUpload={shouldDisplayUpload}
              shouldDisplayImage={shouldDisplayImage}
              onChange={onImageChange}
            />
          )}
          <PhpImageUploadProgress
            shouldDisplayProgress={shouldDisplayProgress}
            greaterThanLimit={greaterthanLimit}
            uploadProgress={uploadProgress}
          />
          <PhpImageThumbNail
            shouldDisplayImage={shouldDisplayImage || isImageUploaded}
            imgData={imgData}
            handleImageUploadCancel={handleImageUploadCancel}
            thumbnailText={'Photo uploaded successfully'}
            isPDF={imgData?.data.startsWith('data:application/pdf')}
          />
          {isRightFileType === false ? (
            <div className="text-xs text-[#CD4D3C]">
              File type is not supported
            </div>
          ) : null}
          <APIErrorMessagePopup
            toggleModal={toggleModal}
            shouldDisplayErrorModal={shouldDisplayErrorModal}
            errorMessage={errorMessage}
          />
        </div>
      </div>
    </div>
  )
}

export default InsuranceVerificationUpload
