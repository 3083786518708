import { globalConfig } from '../../configuration/config'

type APIUtilsReturnType = () => {
  get: <T>(apiUrl: string) => Promise<T>
  makeAPICall: <T>(
    apiUrl: string,
    type: string,
    body?: string,
    signal?: AbortSignal
  ) => Promise<T>
}

export enum APICallType {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
}

export enum metaData {
  populations = 'Population',
}

export const ErrorMessageForDuplicatePatientEntry = 'Duplicate Data'

const APIUtils: APIUtilsReturnType = () => {
  const globalConfigData = globalConfig.get()
  const baseUrl = globalConfigData.apiBaseUrl
  const headers = {
    'Content-Type': 'application/json',
    'X-User-ID': globalConfigData.xUserID,
    'Strict-Transport-Security': 'max-age=31536000; includeSubDomains',
    subscription: globalConfigData.subscription,
  }

  function get<T>(apiUrl: string): Promise<T> {
    const request = {
      method: APICallType.GET,
      headers,
    }
    return fetch(baseUrl + apiUrl, request)
      .then((response) => {
        if (response.ok) {
          return response.json()
        }
        return response
      })
      .then((data) => data as T)
  }

  function makeAPICall<T>(
    apiUrl: string,
    type: string,
    body?: string,
    signal?: AbortSignal
  ): Promise<T> {
    const request = {
      method: type,
      body: body || null,
      headers,
      signal,
    }
    return fetch(baseUrl + apiUrl, request)
      .then((response) => {
        if (response.ok) {
          return response.json()
        } else {
          if (response.status === 409) {
            throw new Error(ErrorMessageForDuplicatePatientEntry)
          } else {
            return response.text().then((text) => {
              {
                throw new Error(text)
              }
            })
          }
        }
      })
      .then((data) => {
        return data as T
      })
  }

  return {
    get,
    makeAPICall,
    APICallType,
  }
}

export default APIUtils
