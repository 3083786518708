import moment from 'moment'

export const getAvailableDateAndSlots = (availableTimeSlots: any[]) => {
  const dateDictionary: any = {}

  if (
    Array.isArray(availableTimeSlots) &&
    availableTimeSlots.length !== 0 &&
    availableTimeSlots !== undefined
  ) {
    availableTimeSlots.forEach((item) => {
      if (Array.isArray(item.timeSlots) && item.timeSlots.length !== 0) {
        const dateAvailable = moment.parseZone(item.date).format('MM-DD-YYYY')
        dateDictionary[dateAvailable] = item.timeSlots
      }
    })
  }
  return dateDictionary
}
