/* istanbul ignore file */
import { Button, FormInstance } from 'antd'
import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react'
import { InputText, InputNum, useAPICall, APICallType } from '../../common'
import { FormInvalidMessage } from '../form/form.utils'
import { API_URLs } from '../../configuration/api-urls'
import './contact-details.scss'
import { InfoCircleOutlined } from '@ant-design/icons'
import OTPVerification from './otp-verification'
import OTPVerifiedIconSVG from '../../assets/Icons/otp-verified-icon-svg'
import { EAPRegistrationContext } from '../../pages/EAP/registration/eap-registrationMiddleware'
interface ContactDetailsProps {
  shouldDisableEmail?: boolean
  shouldDisablePhoneNumber?: boolean
  shouldDisplayHeading?: boolean
  shouldDisplayVerifyButton?: boolean
  shouldDisplayEmailVerificationErrorMessage?: boolean
  form?: FormInstance<any>
}

enum OTPChannelEnum {
  email = 'email',
  sms = 'sms',
}

enum OTPInputFieldNames {
  email = 'emailOtpInput',
  sms = 'mobileOtpInput',
}

const ContactDetails: FunctionComponent<ContactDetailsProps> = ({
  shouldDisableEmail = false,
  shouldDisablePhoneNumber = false,
  shouldDisplayHeading = false,
  shouldDisplayVerifyButton = false,
  shouldDisplayEmailVerificationErrorMessage = false,
  form,
}) => {
  // const PhoneNumberPattern = new RegExp(`^\\([0-9]{3}\\)-[0-9]{3}-[0-9]{4}$`)
  const [emailOtpInputField, setEmailOtpInputField] = useState(false)
  const [mobileOtpInputField, setMobileOtpInputField] = useState(false)
  const [showEmailResendButton, setShowEmailResendButton] = useState(false)
  const [showMobileResendButton, setShowMobileResendButton] = useState(false)
  const [isEmailVerifyButtonClicked, setIsEmailVerifyButtonClicked] =
    useState(false)
  const [isMobileVerifyButtonClicked, setIsMobileVerifyButtonClicked] =
    useState(false)
  const [shouldDisplayEmailVerifyButton, setShouldDisplayEmailVerifyButton] =
    useState(false)
  const [shouldDisplayPhoneVerifyButton, setShouldDisplayPhoneVerifyButton] =
    useState(false)
  const [isEmailEditButtonClicked, setIsEmailEditButtonClicked] =
    useState(false)
  const [isMobileEditButtonClicked, setIsMobileEditButtonClicked] =
    useState(false)
  const { apiCall } = useAPICall()
  const {
    isEmailOtpValidated,
    toggleEmailOtpValidation,
    isMobileOtpValidated,
    toggleMobileOtpValidation,
  } = useContext(EAPRegistrationContext)
  const countryCode = '+1 '

  useEffect(() => {
    if (form?.getFieldValue('email')) {
      form?.validateFields(['email'])
    }
  }, [isEmailOtpValidated])
  useEffect(() => {
    if (form?.getFieldValue('phoneNumber')) {
      form?.validateFields(['phoneNumber'])
    }
  }, [isMobileOtpValidated])

  const sendOTP = async (OTPChannel: string) => {
    let request = {}
    if (OTPChannel === OTPChannelEnum.email) {
      setEmailOtpInputField(true)
      request = {
        channel: OTPChannel,
        recipient: form?.getFieldValue('email'),
      }
    } else {
      setMobileOtpInputField(true)
      request = {
        channel: OTPChannel,
        recipient: countryCode + form?.getFieldValue('phoneNumber'),
      }
    }
    await apiCall(
      API_URLs.v1.POST_SendOTP,
      APICallType.POST,
      JSON.stringify(request)
    )
  }

  const handleVerifyButton = (channel: string) => {
    if (channel === OTPChannelEnum.email) {
      sendOTP('email')
      setTimeout(() => setShowEmailResendButton(true), 30000)
      setIsEmailVerifyButtonClicked(true)
    } else {
      sendOTP('sms')
      setTimeout(() => setShowMobileResendButton(true), 30000)
      setIsMobileVerifyButtonClicked(true)
    }
  }

  const handleEditButton = (channel: string) => {
    if (channel === OTPChannelEnum.email) {
      setIsEmailVerifyButtonClicked(false)
      setEmailOtpInputField(false)
      toggleEmailOtpValidation(false)
      setIsEmailEditButtonClicked(true)
    } else {
      setIsMobileVerifyButtonClicked(false)
      setMobileOtpInputField(false)
      toggleMobileOtpValidation(false)
      setIsMobileEditButtonClicked(true)
    }
  }

  const setEmailVerified = () => {
    toggleEmailOtpValidation(true)
  }

  const setMobileVerified = () => {
    toggleMobileOtpValidation(true)
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const customEmailValidator = (_: any, value: any) => {
    if (shouldDisplayVerifyButton && isEmailOtpValidated) {
      return Promise.resolve()
    }
    if (shouldDisplayVerifyButton && isEmailOtpValidated === false) {
      return Promise.reject(new Error('Please verify email'))
    }
    return Promise.resolve()
  }
  //eslint-disable-next-line @typescript-eslint/no-unused-vars
  const customPhoneNumberValidator = (_: any, value: any) => {
    if (shouldDisplayVerifyButton && isMobileOtpValidated) {
      return Promise.resolve()
    }
    if (shouldDisplayVerifyButton && isMobileOtpValidated === false) {
      return Promise.reject(new Error('Please verify phone number'))
    }
    return Promise.resolve()
  }
  const handleInputEmailChange = () => {
    if (
      form?.getFieldValue('email') &&
      form?.getFieldError('email').length > 0
    ) {
      setShouldDisplayEmailVerifyButton(false)
    } else {
      setShouldDisplayEmailVerifyButton(true)
    }
  }
  const handleInputPhoneNumberChange = () => {
    if (
      form?.getFieldValue('phoneNumber') &&
      form?.getFieldError('phoneNumber').length > 0
    ) {
      setShouldDisplayPhoneVerifyButton(false)
    } else {
      setShouldDisplayPhoneVerifyButton(true)
    }
  }

  return (
    <>
      {shouldDisplayHeading ? (
        <div className="text-lg font-semibold mb-2 text-shade">
          Contact details
        </div>
      ) : null}
      <div className="formItems">
        <span className="verification">
          <label htmlFor="Email Address">
            <InputText
              label={'Email Address'}
              name={'email'}
              placeholder={'Email Address'}
              isDisabled={
                shouldDisableEmail ||
                isEmailVerifyButtonClicked ||
                (shouldDisplayVerifyButton && isEmailOtpValidated)
              }
              onChange={handleInputEmailChange}
              isEmail={true}
              isRequired={true}
              maxLength={65}
              min={{
                value: 3,
                message: FormInvalidMessage(''),
              }}
              max={{
                value: 65,
                message: FormInvalidMessage(''),
              }}
              validator={customEmailValidator}
              validateTrigger={['onBlur', 'onChange', 'onSubmit']}
            />
          </label>
        </span>
        {shouldDisplayEmailVerificationErrorMessage ? (
          <div className="invalid-otp-text">Please verify email</div>
        ) : null}
        <span>
          {shouldDisplayVerifyButton ? (
            isEmailOtpValidated ? (
              <>
                <OTPVerifiedIconSVG />
                <Button
                  type="primary"
                  className="verify-button"
                  onClick={() => handleEditButton('email')}
                >
                  <span className="validate-otp-button-text">Edit</span>
                </Button>
              </>
            ) : isEmailVerifyButtonClicked ? (
              <Button
                type="primary"
                className="verify-button"
                onClick={() => handleEditButton('email')}
              >
                <span className="validate-otp-button-text">Edit</span>
              </Button>
            ) : (
              <Button
                type="primary"
                className="verify-button"
                onClick={() => handleVerifyButton('email')}
                disabled={!shouldDisplayEmailVerifyButton}
              >
                <span className="verify-button-text">Verify</span>
              </Button>
            )
          ) : null}
        </span>
        {shouldDisplayVerifyButton && (
          <div className="verify-information">
            {emailOtpInputField ? (
              <>
                {isEmailOtpValidated === false ? (
                  <>
                    {' '}
                    <InfoCircleOutlined /> OTP sent to{' '}
                    {form?.getFieldValue('email')}
                    {showEmailResendButton && (
                      <Button
                        type="primary"
                        className="resend-button"
                        onClick={() => sendOTP('email')}
                      >
                        <span className="resend-button">Resend</span>
                      </Button>
                    )}
                  </>
                ) : null}
              </>
            ) : (
              <>
                {isEmailOtpValidated === false ? (
                  <>
                    <InfoCircleOutlined /> Please verify your email address
                    before proceeding further{' '}
                  </>
                ) : null}
              </>
            )}
          </div>
        )}
        {emailOtpInputField && isEmailOtpValidated === false ? (
          <OTPVerification
            enteredEmailorMobile={form?.getFieldValue('email')}
            fieldName={OTPInputFieldNames.email}
            emailOtpVerified={isEmailOtpValidated}
            setEmailVerified={setEmailVerified}
            isEmailEditButtonClicked={isEmailEditButtonClicked}
            form={form}
          />
        ) : null}

        <span className="verification">
          <label htmlFor="Phone Number">
            <InputNum
              isRequired={true}
              label={'Phone Number'}
              name={'phoneNumber'}
              placeholder={'Phone Number'}
              isPhoneNumber={true}
              onChange={handleInputPhoneNumberChange}
              isDisabled={
                shouldDisablePhoneNumber ||
                isMobileVerifyButtonClicked ||
                (shouldDisplayVerifyButton && isMobileOtpValidated)
              }
              validator={customPhoneNumberValidator}
              validateTrigger={['onBlur', 'onChange', 'onSubmit']}
            />
          </label>
        </span>
        <span>
          {shouldDisplayVerifyButton ? (
            isMobileOtpValidated === true ? (
              <>
                <OTPVerifiedIconSVG />
                <Button
                  type="primary"
                  className="verify-button"
                  onClick={() => handleEditButton('sms')}
                >
                  <span className="validate-otp-button-text">Edit</span>
                </Button>
              </>
            ) : isMobileVerifyButtonClicked === true ? (
              <Button
                type="primary"
                className="verify-button"
                onClick={() => handleEditButton('sms')}
              >
                <span className="validate-otp-button-text">Edit</span>
              </Button>
            ) : (
              <Button
                type="primary"
                className="verify-button"
                onClick={() => handleVerifyButton('sms')}
                disabled={!shouldDisplayPhoneVerifyButton}
              >
                <span className="verify-button-text">Verify</span>
              </Button>
            )
          ) : null}
        </span>
        {shouldDisplayVerifyButton && (
          <div className="verify-information">
            {mobileOtpInputField ? (
              <>
                {isMobileOtpValidated === false ? (
                  <>
                    <InfoCircleOutlined /> OTP sent to{' '}
                    {form?.getFieldValue('phoneNumber')}
                    {showMobileResendButton && (
                      <Button
                        type="primary"
                        className="resend-button"
                        onClick={() => sendOTP('sms')}
                      >
                        <span className="resend-button">Resend</span>
                      </Button>
                    )}
                  </>
                ) : null}
              </>
            ) : (
              <>
                {isMobileOtpValidated === false ? (
                  <>
                    <InfoCircleOutlined /> Please verify your email address
                    before proceeding further{' '}
                  </>
                ) : null}
              </>
            )}
          </div>
        )}

        {mobileOtpInputField && isMobileOtpValidated === false ? (
          <OTPVerification
            enteredEmailorMobile={
              countryCode + form?.getFieldValue('phoneNumber')
            }
            fieldName={OTPInputFieldNames.sms}
            mobileOtpVerified={isMobileOtpValidated}
            setMobileVerified={setMobileVerified}
            isMobileEditButtonClicked={isMobileEditButtonClicked}
            form={form}
          />
        ) : null}
      </div>
    </>
  )
}

export default ContactDetails
