const Selected: React.FC = () => {
  return (
    <svg
      width="22"
      height="21"
      viewBox="0 0 22 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.714844 10.5C0.714844 4.70101 5.41585 0 11.2148 0C17.0138 0 21.7148 4.70101 21.7148 10.5C21.7148 16.299 17.0138 21 11.2148 21C5.41585 21 0.714844 16.299 0.714844 10.5Z"
        fill="#1E4953"
      />
      <path
        d="M5.46484 10.8846L9.15715 13.9038L15.2148 6.5"
        stroke="white"
        strokeWidth="2"
      />
    </svg>
  )
}

export default Selected
