/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FocusEventHandler } from 'react'
import { Form, Input } from 'antd'
import classnames from 'classnames'
import {
  DEFAULT_INPUT_LENGTH,
  LABEL_ALIGN_TYPE,
} from '../../form/form.constants'
import { formRules, Validator } from '../../form/form.utils'
import '../new-form.scss'
import NewFormLabel from '../new-form-label/new-form-label'

export interface InputTextAreaProps {
  maxLength?: number
  min?: {
    value: number
    message: string
  }
  max?: {
    value: number
    message: string
  }
  isAllowClear?: boolean
  isDisabled?: boolean
  label?: string | any
  htmlLabel?: string | any
  name?: string | any
  isRequired?: boolean
  labelAlign?: keyof typeof LABEL_ALIGN_TYPE
  pattern?: RegExp
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
  onFocus?: (e: React.FocusEvent<HTMLTextAreaElement>) => void
  className?: string
  inputContainerStyle?: string
  validateTrigger?: string | string[]
  validator?: Validator
  patternMessage?: string
  prefix?: React.ReactNode
  placeholder?: string
  value?: string | ReadonlyArray<string> | number | undefined
  onBlur?: FocusEventHandler<HTMLInputElement>
  isURL?: boolean
  isEmail?: boolean
  innerRef?: any
  defaultValue?: string
}

const { TextArea } = Input
const NewInputTextArea: React.FC<InputTextAreaProps> = (
  props: InputTextAreaProps
) => {
  const {
    min,
    max,
    label,
    htmlLabel,
    name,
    labelAlign,
    isRequired,
    validator,
    className,
    pattern,
    patternMessage,
    isDisabled,
    isAllowClear,
    maxLength,
    validateTrigger,
    value,
    inputContainerStyle,
    isURL = false,
    isEmail = false,
    innerRef,
    defaultValue,
    placeholder,
    onChange,
    onFocus,
  } = props

  return (
    <label
      htmlFor={htmlLabel ? htmlLabel : label}
      className="php-form-element flex flex-col md:basis-1/2 font-bold text-shade "
    >
      {isRequired ? (
        <NewFormLabel label={label} />
      ) : (
        <span className="pb-2">{label}</span>
      )}
      <Form.Item
        label={label}
        name={name}
        labelAlign={labelAlign}
        validateTrigger={validateTrigger}
        className={classnames('block php-text-area', className)}
        rules={formRules(
          label,
          isRequired,
          validator,
          pattern,
          patternMessage,
          min,
          max,
          isURL,
          isEmail
        )}
        validateFirst={true}
        initialValue={defaultValue}
      >
        <TextArea
          aria-label={label}
          className={classnames('form-item-input-width', inputContainerStyle)}
          disabled={isDisabled}
          id={name}
          allowClear={isAllowClear}
          maxLength={maxLength || DEFAULT_INPUT_LENGTH}
          value={value}
          ref={innerRef}
          // defaultValue={''}
          style={{ resize: 'none' }}
          placeholder={placeholder}
          onChange={onChange}
          onFocus={onFocus}
        />
      </Form.Item>
    </label>
  )
}

export default NewInputTextArea
