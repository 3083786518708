import React from 'react'

interface NewFormLabelProps {
  label: string
}

const NewFormLabel: React.FC<NewFormLabelProps> = ({ label }) => {
  return (
    <span className="pb-2">
      {label}
      <span style={{ content: '*', color: '#DE0202' }}>*</span>
    </span>
  )
}

export default NewFormLabel
