import { femaleDoctor } from '../../../../assets'
import React, { useEffect, useState, useContext } from 'react'
import {
  APICallType,
  MetadataContext,
  getAvailableDateAndSlots,
  useAPICall,
} from '../../../../common'
import { tryParseInt } from '../../../../common/utils/common-utils'
import { AvailableSlotsRequestInterface } from '../interfaces/available-slots-request-interface'
import { SingleTherapistSlotsInterface } from '../interfaces/single-therapist-slot-interface'
import { SingleTherapistModel } from '../interfaces/therapist-model'
import SingleTherapistCard from '../single-therapist-card'
import { AppointmentPageContext } from '../appointment-page-middleware'
import { getAppliedFilters } from '../../../../common/form/form.utils'
import { changeProviderApptPath } from '../../../../routing/route.constants'
import { ChangeProviderContext } from '../../../change-provider/change-provider-middleware'
import { API_URLs } from '../../../../configuration/api-urls'

interface TherapistCardListProps {
  data: any
  isResetConfirmation?: boolean
  checkAvailability: (id: string) => void
  bookAppointment: (
    date: string | Date,
    clickNextPath: string,
    providerId: string,
    providerName?: string
  ) => void
  clickNextPath: string
  description: string | null
  shouldDisplayBottomBorder: boolean
  predictionData: any
  visitorId: string
}
export let getTherapistTimeSlots: (therapist: SingleTherapistModel) => void

const TherapistCardList: React.FC<TherapistCardListProps> = ({
  data,
  isResetConfirmation,
  checkAvailability,
  bookAppointment,
  clickNextPath,
  description,
  predictionData,
  shouldDisplayBottomBorder,
  visitorId,
}) => {
  const { apiCall } = useAPICall<SingleTherapistSlotsInterface[]>()
  const [isLoadingTherapistsSlots, setIsLoadingTherapistsSlots] = useState<
    Record<string, boolean>
  >({})
  const { organizationData } = useContext(MetadataContext)
  const {
    selectedDaysArray,
    range,
    selectedSpecialisationItems,
    selectedPopulationItems,
    isRetryWithoutFilters,
    therapistSlotsData,
    setTherapistSlotsData,
    useTherapistInView,
  } = useContext(AppointmentPageContext)
  const isChangeProviderFlow = location.pathname.includes(
    changeProviderApptPath
  )
  const { userId } = useContext(ChangeProviderContext)

  getTherapistTimeSlots = (therapist) => {
    setIsLoadingTherapistsSlots((prevData: any) => ({
      ...prevData,
      [therapist.id]: true,
    }))
    const availableSlotsRequestObject: AvailableSlotsRequestInterface = {
      providerId: therapist.id,
      isCalendarView: false,
      organizationId: `${organizationData?.id}`,
    }
    if (!isRetryWithoutFilters) {
      availableSlotsRequestObject.Filters = getAppliedFilters(
        selectedDaysArray,
        range,
        selectedSpecialisationItems,
        selectedPopulationItems
      )
    } else {
      availableSlotsRequestObject.Filters = {}
    }
    if (isChangeProviderFlow) {
      availableSlotsRequestObject.userId = userId
    } else {
      availableSlotsRequestObject.visitorId = visitorId
    }
    apiCall(
      isChangeProviderFlow
        ? API_URLs.v1.POST_ChangeProviderAvailableTimeSlots
        : API_URLs.v7.POST_AppointmentAvailableTimeSlots,
      APICallType.POST,
      JSON.stringify(availableSlotsRequestObject)
    )
      .then((data: SingleTherapistSlotsInterface[]) => {
        setTherapistSlotsData((prevData: any) => ({
          ...prevData,
          [therapist.id]: data,
        }))
        setIsLoadingTherapistsSlots((prevData: any) => ({
          ...prevData,
          [therapist.id]: false,
        }))
      })
      .catch(() => {
        setIsLoadingTherapistsSlots((prevData: any) => ({
          ...prevData,
          [therapist.id]: false,
        }))
      })
  }

  return (
    <div>
      {description ? (
        <div className="mx-8 mt-4 text-lg font-semibold text-shade select-none">
          {description}
        </div>
      ) : null}
      {data.map((therapist: SingleTherapistModel, index: number) => {
        const ref = useTherapistInView(() => {
          getTherapistTimeSlots(therapist)
        })

        return (
          <div key={therapist.id} ref={ref}>
            <SingleTherapistCard
              style={{ animationDelay: `${index * 0.2}s` }}
              isResetConfirmation={isResetConfirmation}
              therapistId={therapist.id}
              name={therapist.fullName}
              ex={therapist.credentials}
              therapistSlotsData={therapistSlotsData[therapist.id] || []}
              therapistavailableTimeSlots={getAvailableDateAndSlots(
                therapistSlotsData[therapist.id]
              )}
              experience={tryParseInt(therapist.experience)}
              description={therapist.shortBio}
              image={
                therapist.avatarFileName ? therapist.avatarUrl : femaleDoctor
              }
              checkAvailability={checkAvailability}
              bookAppointment={bookAppointment}
              clickNextPath={clickNextPath}
              predictionData={predictionData}
              matchingKeyphrases={therapist?.highlights?.keyPhraseTags}
              keyPhraseTags={therapist?.keyPhraseTags}
              populationTags={therapist?.populationTags}
              rerankerScore={therapist?.rerankerScore}
              visitorId={visitorId}
              isLoadingTherapistsSlots={isLoadingTherapistsSlots[therapist.id]}
            />
          </div>
        )
      })}
      {shouldDisplayBottomBorder ? (
        <div className="h-[2px] mx-8 bg-shade"></div>
      ) : null}
    </div>
  )
}

export default TherapistCardList
