import React, { createContext, FunctionComponent, useEffect, useState,RefObject } from 'react'

import { AvailableLanguages } from '../EAP-constants'
import { SingleTherapistSlotsInterface } from './interfaces/single-therapist-slot-interface'
import { useInView } from 'react-intersection-observer'

export enum AppointmentType {
  VIDEO_CALL = 'Video call',
  PHONE_CALL = 'Phone call',
  UNSELECTED = '',
}

interface AppointmentDetailsInterface {
  abbr: string
  therapistName: string
  providerId: string
}

export interface AppointmentPageContextInterface {
  therapistSlotsData: any; 
  setTherapistSlotsData: React.Dispatch<React.SetStateAction<any>>; 
  
  matchTherapist: string
  updateMatchTherapist: (newTherapist: string) => void

  bookingDate: any // TODO: Replace 'any' with the appropriate type
  updateBookingDate: (bokingDate: any) => void

  searchData: string
  updateSearchData: (searchQuery: string) => void

  languageSelection: string
  updateLanguageSelection: (language: string) => void

  selectedDaysArray: boolean[]
  updateSelectedDaysArray: (selectedDays: boolean[]) => void

  range: [number, number]
  updateRange: ([min, max]: [number, number]) => void

  appointmentType: AppointmentType
  updateAppointmentType: (appointmentType: AppointmentType) => void

  appointmentDetails: AppointmentDetailsInterface
  updateAppointmentDetails: (
    appointmentDetails: AppointmentDetailsInterface
  ) => void

  isAppointmentConfirmed: boolean
  updateIsAppointmentConfirmed: (isAppointmentConfirmed: boolean) => void

  therapistID: string | null
  updateTherapistID: (therapistID: string | null) => void

  selectedTimeBlock: number
  updateSelectedTimeBlock: (selectedTimeBlock: number) => void

  selectedSpecialisationItems: string[]
  updateSelectedSpecialisationItems: (items: string[]) => void

  selectedPopulationItems: string[]
  updateSelectedPopulationItems: (items: string[]) => void

  isFilterDrawerOpen: boolean
  toggleFilterDrawer: () => void

  isSpeciasilationFilterOpen: boolean
  updateIsSpeciasilationFilterOpen: (
    isSpeciasilationFilterOpen: boolean
  ) => void

  isPopulationFilterOpen: boolean
  updateIsPopulationFilterOpen: (isPopulationFilterOpen: boolean) => void

  isRetryWithoutFilters: boolean
  updateIsRetryWithoutFilters: (isRetryWithoutFilters: boolean) => void

  resetFilters: () => void
  resetAppointment: () => void
  clearAppointmentContextAfterPatientCreation: () => void

 useTherapistInView: (callback: () => void) => any;

  reasonForChangeProvider: string
  updateReasonForChangeProvider: (reason: string) => void
}

export const AppointmentPageContext = createContext(
  {} as AppointmentPageContextInterface
)

const AppointmentPageMiddleware: FunctionComponent<{
  children: React.ReactNode
}> = ({ children }) => {
  const [therapistSlotsData, setTherapistSlotsData] = useState<Record<string, SingleTherapistSlotsInterface[]>>(
    {}
  );;

  const [matchTherapist, setMatchTherapist] = useState('')
  const updateMatchTherapist = (newTherapist: string) => {
    setMatchTherapist(newTherapist)
  }

  const [bookingDate, setBookingDate] = useState(null)
  const updateBookingDate = (bookingDate: any) => {
    setBookingDate(bookingDate)
  }

  const [searchData, setSearchData] = useState('')
  const updateSearchData = (searchQuery: string) => {
    setSearchData(searchQuery)
  }

  const [languageSelection, setLanguageSelection] = useState(
    AvailableLanguages[0]
  )
  const updateLanguageSelection = (language: string) => {
    setLanguageSelection(language)
  }

  const [selectedDaysArray, setSelectedDaysArray] = useState(
    new Array(7).fill(false)
  )
  const updateSelectedDaysArray = (selecteDaydArray: boolean[]) => {
    setSelectedDaysArray(selecteDaydArray)
  }

  const [range, setRange] = useState<[number, number]>([7 * 60, 21 * 60]) // Default range: 7 AM (7*60) to 9 PM (21*60) in minutes
  const updateRange = (range: [number, number]) => {
    setRange(range)
  }
  const [appointmentType, setAppointmentType] = useState(
    AppointmentType.UNSELECTED
  )
  const updateAppointmentType = (appointmentType: AppointmentType) => {
    setAppointmentType(appointmentType)
  }

  const [therapistID, setTherapistID] = useState<string | null>(null)
  const updateTherapistID = (therapistID: string | null) => {
    setTherapistID(therapistID)
  }

  const [isSpeciasilationFilterOpen, setIsSpeciasilationFilterOpen] =
    useState(false)
  const updateIsSpeciasilationFilterOpen = (
    isSpeciasilationFilterOpen: boolean
  ) => {
    setIsSpeciasilationFilterOpen(isSpeciasilationFilterOpen)
  }

  const [isPopulationFilterOpen, setIsPopulationFilterOpen] = useState(false)
  const updateIsPopulationFilterOpen = (isPopulationFilterOpen: boolean) => {
    setIsPopulationFilterOpen(isPopulationFilterOpen)
  }

  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState<boolean>(false)
  const toggleFilterDrawer = () => {
    setIsFilterDrawerOpen(!isFilterDrawerOpen)
  }

  const [appointmentDetails, setAppointmentDetails] = useState({
    abbr: '',
    therapistName: '',
    providerId: '',
  })
  const updateAppointmentDetails = (
    appointmentDetails: AppointmentDetailsInterface
  ) => {
    setAppointmentDetails(appointmentDetails)
  }

  const [isAppointmentConfirmed, setIsAppointmentConfirmed] = useState(false)
  const updateIsAppointmentConfirmed = (isAppointmentConfirmed: boolean) => {
    setIsAppointmentConfirmed(isAppointmentConfirmed)
  }

  const [selectedTimeBlock, setSelectedTimeBlock] = useState<number>(-1)
  const updateSelectedTimeBlock = (selectedTimeBlock: number) => {
    setSelectedTimeBlock(selectedTimeBlock)
  }

  const [selectedSpecialisationItems, setSelectedSpecialisationItems] =
    useState<string[]>([])
  const updateSelectedSpecialisationItems = (
    selectedSpecialisationItems: string[]
  ) => {
    setSelectedSpecialisationItems(selectedSpecialisationItems)
  }

  const [selectedPopulationItems, setSelectedPopulationItems] = useState<
    string[]
  >([])
  const updateSelectedPopulationItems = (selectedPopulationItems: string[]) => {
    setSelectedPopulationItems(selectedPopulationItems)
  }

  const [isRetryWithoutFilters, setIsRetryWithoutFilters] =
    useState<boolean>(false)
  const updateIsRetryWithoutFilters = (isRetryWithoutFilters: boolean) => {
    setIsRetryWithoutFilters(isRetryWithoutFilters)
  }

  const resetFilters = (event?: React.MouseEvent) => {
    event && event.stopPropagation()
    setSelectedDaysArray(new Array(7).fill(false))
    setRange([7 * 60, 21 * 60])
    setLanguageSelection(AvailableLanguages[0])
    setSelectedPopulationItems([])
    if (true) {
      setSelectedTimeBlock(-1)
    }
    setSelectedSpecialisationItems([])
  }

  const resetAppointment = () => {
    setSearchData('')
    setBookingDate(null)
    setAppointmentType(AppointmentType.UNSELECTED)
    resetFilters()
  }

  const clearAppointmentContextAfterPatientCreation = () => {
    setAppointmentDetails({
      therapistName: '',
      abbr: '',
      providerId: '',
    })
    setBookingDate(null)
    setTherapistID(null)
  }

  const useTherapistInView = (callback: () => void) =>{
    const { ref, inView } = useInView({
      triggerOnce: true,
      threshold: 0,
    });

    useEffect(() => {
      if (inView) {
        callback();
      }
    }, [inView]);

    return ref;

  }

  const [reasonForChangeProvider, setReasonForChangeProvider] = useState('')

  const updateReasonForChangeProvider = (reason: string) => {
    setReasonForChangeProvider(reason)
  }

  return (
    <AppointmentPageContext.Provider
      value={{
        matchTherapist,
        updateMatchTherapist,
        bookingDate,
        updateBookingDate,
        searchData,
        updateSearchData,
        languageSelection,
        updateLanguageSelection,
        selectedDaysArray,
        updateSelectedDaysArray,
        range,
        updateRange,
        appointmentType,
        updateAppointmentDetails,
        appointmentDetails,
        updateAppointmentType,
        isAppointmentConfirmed,
        updateIsAppointmentConfirmed,
        therapistID,
        updateTherapistID,
        selectedTimeBlock,
        updateSelectedTimeBlock,
        selectedSpecialisationItems,
        updateSelectedSpecialisationItems,
        selectedPopulationItems,
        updateSelectedPopulationItems,
        isSpeciasilationFilterOpen,
        updateIsSpeciasilationFilterOpen,
        isPopulationFilterOpen,
        updateIsPopulationFilterOpen,
        isFilterDrawerOpen,
        toggleFilterDrawer,
        isRetryWithoutFilters,
        updateIsRetryWithoutFilters,
        resetFilters,
        resetAppointment,
        clearAppointmentContextAfterPatientCreation,
        reasonForChangeProvider,
        updateReasonForChangeProvider,
        therapistSlotsData,
        setTherapistSlotsData,
        useTherapistInView,
      }}
    >
      {children}
    </AppointmentPageContext.Provider>
  )
}

export default AppointmentPageMiddleware
