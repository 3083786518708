import { DatePicker, Form } from 'antd'
import React from 'react'
import locale from 'antd/es/date-picker/locale/en_US'
import {
  DATE_FORMAT_LIST,
  DATE_TIME_FORMAT,
  FORM_ITEM_VALIDATE_STATUS,
  INPUT_DATE_PICKER_TYPE,
  LABEL_ALIGN_TYPE,
} from '../../form/form.constants'
import { disabledDate, formRules, Validator } from '../../form/form.utils'
import classNames from 'classnames'
import './new-input-date.scss'
import moment from 'moment'
import NewFormLabel from '../new-form-label/new-form-label'
interface timeProps {
  format: string
}

interface InputDateProps {
  labelText?: string | null
  label: string
  htmlLabel?: string | any
  labelAlign?: keyof typeof LABEL_ALIGN_TYPE
  validator?: Validator
  onChange?: (value: moment.Moment | null, dateString: string) => void
  isDisabled?: boolean
  className?: string
  validateStatus?: keyof typeof FORM_ITEM_VALIDATE_STATUS
  name: string
  isRequired?: boolean
  helpMessage?: string
  inputContainerStyle?: string
  validateTrigger?: string | string[]
  defaultValue?: string
  showTime?: boolean | timeProps
  shouldDisablePastDates?: boolean
  shouldDisableFutureDates?: boolean
  placeholder?: string
}

const NewInputDate: React.FC<InputDateProps> = (props: InputDateProps) => {
  const {
    labelText = '',
    label,
    htmlLabel,
    name,
    isRequired,
    isDisabled,
    validator,
    onChange,
    labelAlign,
    className,
    validateStatus,
    helpMessage,
    validateTrigger,
    showTime = false,
    inputContainerStyle,
    defaultValue,
    placeholder,
    shouldDisableFutureDates = false,
  } = props

  const currentDefaultValue = defaultValue ? moment(defaultValue) : undefined

  if (validateStatus) {
    return (
      <div className="php-form-element">
        <Form.Item
          className="block"
          label={label}
          labelAlign={labelAlign}
          name={name}
          validateTrigger={validateTrigger}
          rules={formRules(
            labelText !== '' ? labelText : label,
            isRequired,
            validator
          )}
          validateFirst={true}
          validateStatus={validateStatus}
          help={helpMessage}
        >
          <DatePicker
            aria-label={label}
            defaultValue={currentDefaultValue}
            picker={INPUT_DATE_PICKER_TYPE}
            disabled={isDisabled}
            format={showTime ? DATE_TIME_FORMAT : DATE_FORMAT_LIST}
            showTime={showTime}
            placeholder={placeholder}
            className={classNames(
              'form-item-input-width',
              className,
              inputContainerStyle
            )}
            onChange={onChange}
            locale={{
              ...locale,
              lang: {
                ...locale.lang,
                ok: 'OK',
              },
            }}
            id={name}
          />
        </Form.Item>
      </div>
    )
  }
  return (
    <label
      htmlFor={htmlLabel ? htmlLabel : label}
      className="php-form-element flex flex-col md:basis-1/2 font-bold text-shade "
    >
      {isRequired ? (
        <NewFormLabel label={label} />
      ) : (
        <span className="pb-2">{label}</span>
      )}
      <Form.Item
        className="block"
        label={label}
        labelAlign={labelAlign}
        name={name}
        validateTrigger={validateTrigger}
        validateFirst={true}
        rules={formRules(
          labelText !== '' ? labelText : label,
          isRequired,
          validator
        )}
      >
        <DatePicker
          aria-label={label}
          disabledDate={(current) => {
            return shouldDisableFutureDates && disabledDate(current)
          }}
          defaultValue={currentDefaultValue}
          picker={INPUT_DATE_PICKER_TYPE}
          showTime={showTime}
          disabled={isDisabled}
          placeholder={placeholder}
          format={showTime ? DATE_TIME_FORMAT : DATE_FORMAT_LIST}
          className={classNames('form-item-input-width', className)}
          onChange={onChange}
          locale={{
            ...locale,
            lang: {
              ...locale.lang,
              ok: 'OK',
            },
          }}
          id={name}
        />
      </Form.Item>
    </label>
  )
}

export default NewInputDate
