const FormHeading = () => {
  return (
    <div>
      <div className="xs:text-sm sm:text-base md:text-lg xl:text-xl">
        <div className="text-2xl pb-4 text-[#303F5C]">
          <span className="font-semibold text-[#3F7A88]"> Uh oh! </span>
          <span className="font-semibold">
            {`Doesn't seem like we have your organization onboard yet.`}
          </span>
        </div>
        <p className="text-[#303F5C] text-base">
          If you would like to submit a request to get your organization on to
          Trusana, please fill the form below.
        </p>
      </div>
    </div>
  )
}
export default FormHeading
