import { useContext, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { EAPFlows, PHPFlows } from '../../pages/EAP/EAP-constants'
import { EAPRegistrationContext } from '../../pages/EAP/registration/eap-registrationMiddleware'
import { PHPRegistrationContext } from '../../pages/PHP/php-registration/php-registration-middleware'
import { UserFlowType } from '../../pages'

const useHandleRefresh = ({
  user,
  userFlowType,
  registrationPath,
  visitorId,
  shouldDisplayErrorRedirectionModal = false, // Remove default once new EAP flow is finalised
  toggleRedirectionModal = () => {
    console.log('Redirect toggled')
  }, // Remove default once new EAP flow is finalised
}: {
  user: EAPFlows | PHPFlows
  userFlowType: UserFlowType
  registrationPath: string
  visitorId: string | null
  shouldDisplayErrorRedirectionModal?: boolean
  toggleRedirectionModal?: () => void
}) => {
  const location = useLocation()
  const history = useHistory()
  const { caregiverDetails, householdMemberDetails } = useContext(
    EAPRegistrationContext
  )
  const { primarySubscriberDetails } = useContext(PHPRegistrationContext)

  /**
   * For when the user reloads and we lose caregiverDetails.state from context
   */

  let isStateNull = true
  if (userFlowType === UserFlowType.EAP) {
    if (user === EAPFlows.SELF) {
      isStateNull = !!caregiverDetails.state
    } else {
      isStateNull = !!householdMemberDetails.state
    }
  } else if (userFlowType === UserFlowType.PHP) {
    isStateNull = !!primarySubscriberDetails.state
  }

  useEffect(() => {
    if (shouldDisplayErrorRedirectionModal) {
      toggleRedirectionModal()
    }
    if (
      !location.pathname.includes(registrationPath) &&
      !location.pathname.includes('final-confirmation')
    ) {
      if (visitorId === null || !isStateNull) {
        toggleRedirectionModal()
        setTimeout(() => {
          history.push(registrationPath)
        }, 2000)
      }
    }
  }, [location])
}

export default useHandleRefresh
