import { useEffect, useState } from 'react'
import useScreenWidth from './use-screen-width'

const useActiveSection = (sectionRefs: React.RefObject<HTMLElement>[]) => {
  const [activeSection, setActiveSection] = useState<string | null>(null)
  const width = useScreenWidth()

  useEffect(() => {
    const handleIntersection = (entries: IntersectionObserverEntry[]) => {
      let anyIntersecting = false

      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          anyIntersecting = true
          setActiveSection(entry.target.id)
        }
      })

      if (!anyIntersecting) {
        setActiveSection(null)
      }
    }

    const observer = new IntersectionObserver(handleIntersection, {
      threshold: width > 768 ? 0.2 : 0.1,
    })

    sectionRefs.forEach((ref) => {
      if (ref.current) {
        observer.observe(ref.current)
      }
    })

    return () => observer.disconnect()
  }, [sectionRefs])
  return activeSection
}

export default useActiveSection
