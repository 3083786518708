import { useHistory } from 'react-router-dom'
import {
  TrusanaWhiteLogo,
  meditationGirlImage,
  PhpQuestionMark,
  PHPBranding,
} from '../../../assets'
import './branding-section.scss'
import { useContext } from 'react'
import HamburgerMenu from '../../../common/hamburger-menu/hamburger-menu'
import { PHPRegistrationContext } from '../php-registration/php-registration-middleware'

interface BrandingSectionInterface {
  isOpen: boolean
  toggleIsOpen: () => void
}

const BrandingSection: React.FC<BrandingSectionInterface> = ({
  isOpen,
  toggleIsOpen,
}) => {
  const history = useHistory()
  const onAppLogoClickHandler = () => {
    history.push('/')
  }
  const { isHamburgerOpen, toggleIsHamburgerOpen } = useContext(
    PHPRegistrationContext
  )

  const handleClick = () => {
    toggleIsHamburgerOpen()
    toggleIsOpen()
  }

  return (
    <>
      <div
        className={`hidden md:flex flex-col md:basis-[20%] bg-[#3F7A88] fixed left-0 top-0 bottom-0 z-[999] left-sidenav ${
          isOpen ? '' : 'closed-state justify-start'
        }`}
      >
        <HamburgerMenu
          isHamburgerMenuOpen={isHamburgerOpen}
          handleClick={handleClick}
        />
        {isOpen ? (
          <div className={`${isOpen ? 'opened-sidenav' : 'closed-sidenav'}`}>
            <div
              className={`text-center cursor-pointer flex flex-row justify-center items-center mr-4`}
            >
              <img
                src={TrusanaWhiteLogo}
                onClick={onAppLogoClickHandler}
                className="w-1/2 mx-auto mt-4"
              />
            </div>{' '}
            <img
              src={meditationGirlImage}
              className="w-full mt-2 px-8 max-w-[20rem]  md:flex md:max-w-sm lg:max-w-sm xl:max-w-[20rem] 2xl:max-w-xl mx-auto meditating-logo-class"
            ></img>{' '}
            <div className="flex flex-row justify-center  my-4 gap-4 pt-2 left-section-branding-container">
              <div style={{ paddingTop: '0.2rem' }}>Brought to you by</div>
              <div>
                <img src={PHPBranding} className="left-section-php-logo" />
              </div>
            </div>
            <div
              className="text-white text-left text-[14px] max-w-[40rem] px-4 mt-4"
              style={{ borderBottom: '0.5rem solid #3F7A88' }}
            >
              <div>
                Providence uses Trusana to deliver Behavioral Health Concierge
                (BHC) services. On Trusana, members can manage appointments
                online, participate in virtual visits, access their personalized
                plan of care, and track progress.
              </div>
              <div className="mt-2 text-left">
                <ul>
                  <li>
                    Virtual visits are covered at no cost (HSA plans are subject
                    to deductible first and then covered in full)
                  </li>
                  <li>
                    All our mental health professionals are licensed clinical
                    social workers.
                  </li>
                  <li>
                    For more info about Trusana, click{' '}
                    <a
                      href="/welcome/support"
                      target="_blank"
                      className="text-white underline hover:underline selected:underline active:underline visited:underline"
                    >
                      here
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="bg-[#376A76] text-white border-t-[rgba(255,255,255,0.14)] h-[5rem] px-4 pt-2 fixed bottom-0 w-[23rem]">
              <div className="flex flex-row">
                <div className="flex flex-col">
                  <img src={PhpQuestionMark} className="mr-2"></img>
                </div>
                <div className="flex flex-col">
                  <span>Need help? Reach out to us at</span>
                  <div>
                    <a href="tel:+18337249355" className="text-white underline">
                      (833) 724-9355
                    </a>{' '}
                    |{' '}
                    <a
                      href="mailto:support@trusana.com"
                      className="text-white underline"
                    >
                      support@trusana.com
                    </a>
                  </div>
                  <div className="mt- mb-4">
                    {' '}
                    ©2023 Providence. All rights reserved.
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </>
  )
}

export default BrandingSection
