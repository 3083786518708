interface ParamsInterface {
  [key: string]: string | number | boolean
}

export const flatQueryParams = (params: ParamsInterface): string => {
  let queryString = '?'
  for (const param in params) {
    if (param === 'Email') {
      queryString += `${param}=${encodeURIComponent(params[param])}&`
    } else queryString += `${param}=${params[param]}&`
  }
  return queryString.slice(0, queryString.length - 1)
}
