import { Suspense, useEffect } from 'react'
import { Route, Switch } from 'react-router-dom'
import AppHeader from '../app-header/app-header'
import { routes, RouteType } from '../../routing'
import { Loader, LoaderMiddleware } from '../../common'
import './app-layout.scss'

import { mixpanelInit } from '../../common/utils/mixpanel'
import AppFooter from '../app-footer/app-footer'
import { globalConfig } from '../../configuration/config'
import ErrorPage from '../../pages/ErrorPage'
import UseScrollTop from '../../common/hooks/use-scroll-top'
import { MetadataMiddleware } from '../../common/metadata/metadata-middleware'
import PopupTimerMiddleware from '../../pages/EAP/popup-timer/popup-timer-middleware'
import { Spin } from 'antd'

interface ComponentWithLoaderProps {
  children: JSX.Element
}

export const ComponentWithLoader: React.FC<ComponentWithLoaderProps> = ({
  children,
}) => {
  return (
    <LoaderMiddleware>
      <Loader>{children}</Loader>
    </LoaderMiddleware>
  )
}

const AppLayout: React.FC = () => {
  useEffect(() => {
    const { pathname, search } = window.location
    mixpanelInit(globalConfig.get().mixpanelToken, pathname, search)
  }, [])

  return (
    <>
      <MetadataMiddleware>
        <PopupTimerMiddleware>
          <main>
            <AppHeader />
            <section className="section-body">
              <Suspense
                fallback={
                  <div className="suspense-body">
                    <div className="flex items-center justify-center w-full">
                      <Spin size="large" className="mt-32" />
                    </div>
                  </div>
                }
              >
                <UseScrollTop>
                  <Switch>
                    {routes.map((route: RouteType) => (
                      <Route
                        key={route.path}
                        path={route.path}
                        exact={route.exact}
                        component={() => (
                          <ComponentWithLoader>
                            <>{route.component}</>
                          </ComponentWithLoader>
                        )}
                      />
                    ))}
                    <Route path="*" component={ErrorPage} />
                  </Switch>
                </UseScrollTop>
              </Suspense>
            </section>
            <AppFooter />
          </main>
        </PopupTimerMiddleware>
      </MetadataMiddleware>
    </>
  )
}

export default AppLayout
