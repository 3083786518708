/* eslint-disable @typescript-eslint/no-explicit-any */
import moment, { Moment } from 'moment'
import { FormInvalidMessage } from '../../common/form/form.utils'
import { PatientTypesInterface } from '../../common/metadata/metadata-middleware'
import { globalConfig } from '../../configuration/config'
import { isAboveAgeOfConsentForState } from '../../common/utils/date-utils'
import { FilterOptionsMobile } from '../../common/utils/mixpanel-constants'

export const existingMajorCreatingMinorMessage =
  'A new user profile will be created and linked to your existing account. You can access profiles of your minor household members using the same account.'
export const existingMinorCreatingMajorMessage =
  'A new user profile will be created and linked to your existing account. You can access profiles of all your minor household members along with yours using the same account.'
export const reasonForBookingLabel =
  'What would you like addressed during your visit?'

export const noTherapistsFoundForCurrentFiltersMessage =
  'Sorry, there are no therapists available at this moment for the selected state/filters. Here are a few other recommendations based on earliest availability.'
export const therapistsFoundForCurrentFiltersMessage =
  'Here are a few recommendations based on the selected filters, ordered by earliest availability.'
export const therapistsByEarliestAvailabilityMessage =
  'Here are a few recommendations based on earliest availability.'

export interface EAPSelfAPIDataType {
  firstName: string
  lastName: string
  email: string
  dateOfBirth: string
  providerId: string
  currentAddress: CurrentAddressType
  phoneNumber: string
  gender: string
  isPCPReferral: string
  patientType: string
}

export type CurrentAddressType = {
  street: string
  city: string
  state: string
  country: string
  zipCode: string
}

export enum BrandName {
  PROVIDENCE = 'providence',
  TESTORG = 'testorg',
  SEATTLESTORM = 'seattle-storm',
}

export enum NewEAPFlowPatientType {
  Employee = 'Providence Employee',
  HouseholdMember = 'Household Member of Providence Employee',
}

export enum EAPFlows {
  SELF = 'EAP',
  DEPENDENT = 'EAP Dependant',
  HOUSEHOLDMEMBER = 'EAP Household',
  NONE = 'NONE',
}
export enum PHPFlows {
  PHP_Primary = 'PHP',
  PHP_MajorDependent = 'PHP Dependant',
  PHP_MinorDependent = 'PHP MinorDependant',
}

export enum ExistenceType {
  HARD = 'hard',
  SOFT = 'soft',
}

export const formGenderOptions = (
  data: any,
  nameKey: string,
  idKey: string,
  codeKey: string
) => {
  return data.map((item: any) => {
    return { name: item[nameKey], value: `${item[idKey]}:${item[codeKey]}` }
  })
}

export const validatePhoneNumber = (_cc: any, value: any) => {
  if (`${value}`.length < 10) {
    return Promise.reject(FormInvalidMessage('Phone Number'))
  }
  return Promise.resolve()
}

export const getPatientTypeId = (
  flow: string,
  patientTypesList: PatientTypesInterface[],
  DOB?: Moment,
  state?: string,
  stateAges?: any
) => {
  flow = getEAPFlowBasedOnNewEAPSelection(flow, DOB, state, stateAges)

  const filter = patientTypesList.filter((item) => item.name == flow)
  if (filter && filter.length > 0) {
    return filter[0].id
  } else {
    return ''
  }
}

export const getEAPFlowBasedOnNewEAPSelection = (
  newFlow: string,
  DOB?: Moment,
  state?: string,
  stateAges?: any
) => {
  let eapFlow = newFlow
  //as per discussion with product team, mapped the two flows in new eap with older flows
  if (
    newFlow != null &&
    Object.values<string>(NewEAPFlowPatientType).includes(newFlow)
  ) {
    if (newFlow === NewEAPFlowPatientType.Employee) {
      eapFlow = EAPFlows.SELF
    }

    if (newFlow === NewEAPFlowPatientType.HouseholdMember) {
      if (isAboveAgeOfConsentForState(DOB!, state!, stateAges)) {
        eapFlow = EAPFlows.HOUSEHOLDMEMBER
      } else {
        eapFlow = EAPFlows.DEPENDENT
      }
    }
  }
  return eapFlow
}

export const extractGenderCode = (genderValueString: string) => {
  const GenderCodeArray = genderValueString.split(':')
  return GenderCodeArray[1]
}

export const formAPIJson = (
  flow: EAPFlows | PHPFlows,
  memberDetails: any,
  patientType: string,
  primaryCaregiverDetails?: any
) => {
  let data: any = {
    firstName: memberDetails.firstName,
    lastName: memberDetails.lastName,
    email: memberDetails.email,
    dateOfBirth: moment(memberDetails.dateOfBirth).format('MM/DD/YYYY'),
    phoneNumber: formatPhoneNumber(memberDetails.phoneNumber),
    currentAddress: {
      street: `${memberDetails.addressLine1}`,
      city: memberDetails.city,
      state: memberDetails.state,
      country: 'USA',
      zipCode: memberDetails.zipCode,
    },
    patientType: patientType,
    relationShip: memberDetails.relationShip,
    isPCPReferral: memberDetails.isPCPReferral === 'Yes' ? true : false,
  }
  if (primaryCaregiverDetails) {
    data = {
      ...data,
      primarySubscriberDetails: {
        firstName: primaryCaregiverDetails.firstName,
        lastName: primaryCaregiverDetails.lastName,
        email: primaryCaregiverDetails.email,
        phoneNumber: formatPhoneNumber(primaryCaregiverDetails.phoneNumber),
        gender: extractGenderCode(primaryCaregiverDetails.gender),
        dateOfBirth: primaryCaregiverDetails.dateOfBirth
          ? moment(primaryCaregiverDetails.dateOfBirth).format('MM/DD/YYYY')
          : '',
        currentAddress: {
          street: `${memberDetails.addressLine1}`,
          city: memberDetails.city,
          state: memberDetails.state,
          country: 'USA',
          zipCode: memberDetails.zipCode,
        },
      },
    }
  }
  data = {
    ...data,
    emergencyContact: {
      name: memberDetails.emergencyContactName,
      phoneNumber: formatPhoneNumber(memberDetails.emergencyContactPhoneNumber),
    },
  }

  if (
    flow == PHPFlows.PHP_Primary ||
    flow == PHPFlows.PHP_MajorDependent ||
    flow == PHPFlows.PHP_MinorDependent
  ) {
    data = {
      ...data,
      insuranceDetails: {
        // Hardcoding the insurance Name as it is ID
        // TODO: Need to configure this in CI/CD variables
        insurerName: memberDetails.insurerName || '2038',
        memberId: memberDetails.memberId,
      },
    }
  }

  return data
}

export const formatPhoneNumber = (phoneNumber: any) => {
  if (phoneNumber) {
    phoneNumber = phoneNumber.toString()

    if (phoneNumber.length <= 3) {
      return phoneNumber
    } else if (phoneNumber.length > 3 && phoneNumber.length <= 6) {
      return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`
    } else {
      return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(
        3,
        6
      )}-${phoneNumber.slice(6)}`
    }
  }
}
export const redirectToHelthieWebsite = () => {
  window.location.assign(globalConfig.get().memberLoginUrl)
}

export const signIn = () => {
  window.location.assign(globalConfig.get().memberLoginUrl)
}

export const redirectToForgotPassword = () => {
  const loginUrl = globalConfig.get().memberLoginUrl
  const passwordResetUrl = loginUrl.replace('/sign_in', '/password/forgot')
  window.open(passwordResetUrl)
}

export const goToHomePage = () => {
  window.location.replace('/')
}

export const formatTimeRange = (range: [number, number]): [string, string] => {
  return [formatTimeAPI(range[0]), formatTimeAPI(range[1] + 60)]
}

export const formatTime = (value: number | undefined): string => {
  if (value === undefined) {
    return ''
  }

  const hours24 = Math.floor(value / 60)
  const hours12 = hours24 % 12 || 12
  const minutes = value % 60
  const amPm = hours24 < 12 ? 'AM' : 'PM'
  return `${hours12 < 10 ? '0' + hours12 : hours12}:${
    minutes < 10 ? '0' + minutes : minutes
  } ${amPm}`
}
export const formatTimeAPI = (totalMinutes: number): string => {
  const hours = Math.floor(totalMinutes / 60)
  const minutes = totalMinutes % 60
  const seconds = 0 // Assuming seconds is always 0
  const formattedHours = hours < 10 ? `0${hours}` : hours
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes
  const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds
  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`
}

export const formatTimeUI = (value: number | undefined): string => {
  if (value === undefined) {
    return ''
  }

  const hours24 = Math.floor(value / 60)
  const hours12 = hours24 % 12 || 12
  const minutes = value % 60
  const amPm = hours24 < 12 ? 'AM' : 'PM'
  return `${hours12 < 10 ? '0' + hours12 : hours12}:${
    minutes < 10 ? '0' + minutes : minutes
  } ${amPm}`
}

export const alphabetPattern = new RegExp(/^[A-Za-z ]+$/)

export const getEAPSelfPayload = (
  caregiverDetails: any,
  visitorId: any,
  values: any,
  typeOfId: any,
  token: any,
  appointmentDetails: any,
  patientTypes: any,
  existenceType: any
) => {
  return {
    firstName: caregiverDetails.firstName,
    lastName: caregiverDetails.lastName,
    email: caregiverDetails.email,
    dateOfBirth: moment(caregiverDetails.dateOfBirth).format('MM/DD/YYYY'),
    currentAddress: {
      street: caregiverDetails.addressLine1,
      city: caregiverDetails.city,
      state: caregiverDetails.state,
      zipCode: caregiverDetails.zipCode,
    },
    phoneNumber: `${formatPhoneNumber(caregiverDetails.phoneNumber)}`,
    gender: caregiverDetails.gender
      ? caregiverDetails.gender.split(':')[0]
      : '',
    patientType: getPatientTypeId(EAPFlows.SELF, patientTypes),
    emergencyContact: {
      name: caregiverDetails.emergencyContactName,
      phoneNumber: `${formatPhoneNumber(
        caregiverDetails.emergencyContactPhoneNumber
      )}`,
    },
    visitorId: visitorId,
    password: values.password,
    typeOfID: typeOfId ? typeOfId : '',
    token: token,
    providerId: appointmentDetails.providerId,
    existenceType: existenceType,
    reasonforBookingAppointment: encodeURIComponent(
      caregiverDetails.reasonforBookingAppointment
    ),
  }
}

export const getEAPforHouseHoldPayload = (
  householdMemberDetails: any,
  visitorId: any,
  values: any,
  typeOfId: any,
  token: any,
  appointmentDetails: any,
  patientTypes: any,
  existenceType: any
) => {
  return {
    firstName: householdMemberDetails.firstName,
    lastName: householdMemberDetails.lastName,
    email: householdMemberDetails.email,
    dateOfBirth: moment(householdMemberDetails.dateOfBirth).format(
      'MM/DD/YYYY'
    ),
    currentAddress: {
      street: householdMemberDetails.addressLine1,
      city: householdMemberDetails.city,
      state: householdMemberDetails.state,
      zipCode: householdMemberDetails.zipCode,
    },
    phoneNumber: `${formatPhoneNumber(householdMemberDetails.phoneNumber)}`,
    gender: householdMemberDetails.gender
      ? householdMemberDetails.gender.split(':')[0]
      : '',
    patientType: getPatientTypeId(EAPFlows.DEPENDENT, patientTypes),
    relationShip: householdMemberDetails.relationShip,
    primarySubscriberDetails: {
      firstName: householdMemberDetails.PfirstName,
      lastName: householdMemberDetails.PlastName,
      dateOfBirth: moment(householdMemberDetails.PdateOfBirth).format(
        'MM/DD/YYYY'
      ),
    },
    emergencyContact: {
      name: householdMemberDetails.emergencyContactName,
      phoneNumber: `${formatPhoneNumber(
        householdMemberDetails.emergencyContactPhoneNumber
      )}`,
    },
    visitorId: visitorId,
    password: values.password,
    typeOfID: typeOfId ? typeOfId : '',
    token: token,
    providerId: appointmentDetails.providerId,
    existenceType: existenceType,
    reasonforBookingAppointment: encodeURIComponent(
      householdMemberDetails.reasonforBookingAppointment
    ),
  }
}

export const getEAPByHouseHoldPayload = (
  householdMemberDetails: any,
  visitorId: any,
  values: any,
  typeOfId: any,
  token: any,
  appointmentDetails: any,
  patientTypes: any,
  existenceType: any
) => {
  return {
    firstName: householdMemberDetails.firstName,
    lastName: householdMemberDetails.lastName,
    email: householdMemberDetails.email,
    dateOfBirth: moment(householdMemberDetails.dateOfBirth).format(
      'MM/DD/YYYY'
    ),
    currentAddress: {
      street: householdMemberDetails.addressLine1,
      city: householdMemberDetails.city,
      state: householdMemberDetails.state,
      zipCode: householdMemberDetails.zipCode,
    },
    phoneNumber: `${formatPhoneNumber(householdMemberDetails.phoneNumber)}`,
    gender: householdMemberDetails.gender
      ? householdMemberDetails.gender.split(':')[0]
      : '',
    patientType: getPatientTypeId(EAPFlows.HOUSEHOLDMEMBER, patientTypes),
    relationShip: householdMemberDetails.relationShip,
    primarySubscriberDetails: {
      firstName: householdMemberDetails.PfirstName,
      lastName: householdMemberDetails.PlastName,
      dateOfBirth: moment(householdMemberDetails.PdateOfBirth).format(
        'MM/DD/YYYY'
      ),
    },
    emergencyContact: {
      name: householdMemberDetails.emergencyContactName,
      phoneNumber: `${formatPhoneNumber(
        householdMemberDetails.emergencyContactPhoneNumber
      )}`,
    },
    visitorId: visitorId,
    password: values.password,
    typeOfID: typeOfId ? typeOfId : '',
    token: token,
    providerId: appointmentDetails.providerId,
    existenceType: existenceType,
    reasonforBookingAppointment: encodeURIComponent(
      householdMemberDetails.reasonforBookingAppointment
    ),
  }
}

export const getNewEAPPayload = (
  householdMemberDetails: any,
  visitorId: any,
  values: any,
  typeOfId: any,
  token: any,
  appointmentDetails: any,
  patientTypes: any,
  existenceType: any,
  careMemberType: string,
  relationShip: string,
  userFlow: string,
  pEmail?: string
) => {
  return {
    firstName: householdMemberDetails.firstName,
    lastName: householdMemberDetails.lastName,
    email: householdMemberDetails.email,
    dateOfBirth: moment(householdMemberDetails.dateOfBirth).format(
      'MM/DD/YYYY'
    ),
    currentAddress: {
      street: householdMemberDetails.addressLine1,
      city: householdMemberDetails.city,
      state: householdMemberDetails.state,
      zipCode: householdMemberDetails.zipCode,
    },
    phoneNumber: `${formatPhoneNumber(householdMemberDetails.phoneNumber)}`,
    gender: householdMemberDetails.gender
      ? householdMemberDetails.gender.split(':')[0]
      : '',
    patientType: getPatientTypeId(userFlow, patientTypes),
    relationShip: relationShip,
    primarySubscriberDetails: householdMemberDetails.PfirstName
      ? {
          firstName: householdMemberDetails.PfirstName,
          lastName: householdMemberDetails.PlastName,
          dateOfBirth:
            householdMemberDetails.PdateOfBirth !== undefined
              ? moment(householdMemberDetails.PdateOfBirth).format('MM/DD/YYYY')
              : undefined,
          email: pEmail,
        }
      : null,
    emergencyContact: {
      name: householdMemberDetails.emergencyContactName,
      phoneNumber: `${formatPhoneNumber(
        householdMemberDetails.emergencyContactPhoneNumber
      )}`,
    },
    visitorId: visitorId,
    password: values.password,
    typeOfID: typeOfId ? typeOfId : '',
    token: token,
    providerId: appointmentDetails.providerId,
    existenceType: existenceType,
    reasonforBookingAppointment: encodeURIComponent(
      householdMemberDetails.reasonforBookingAppointment
    ),
    careMemberType: careMemberType,
  }
}

export const EAPFlowURLS = {
  [EAPFlows.SELF]: '/eap-flow/pgc-caregiver/myself',
  [EAPFlows.DEPENDENT]: '/eap-flow/pgc-caregiver/pgc-household-member',
  [EAPFlows.HOUSEHOLDMEMBER]: '/eap-flow/household-member/pgc-household-member',
}

export enum Themes {
  LIGHT = 'light',
  DARK = 'dark',
}

// Appointment Filter Constants

export const AvailableLanguages = ['English', 'Spanish']

/** TODO: Use CSS to take first 3 chars instead of maintaining 2 arrays */
export const DaysOfTheWeekArray = {
  Mobile: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
  Desktop: [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ],
}

export const reasonForBookingRegex = /^[a-zA-Z0-9\s:;,?/%\\().&\-\'!\""]*$/

export enum FeatureFlags {
  SearchFeature = 'SearchFeature',
  HideQuestionnaireFeature = 'HideInitialQuestionnaire',
  HidePHPFeature = 'HidePHPFeature',
}

export const FilterOptions = [
  FilterOptionsMobile.SPECIALISATION,
  FilterOptionsMobile.DAYS_OF_THE_WEEK,
  FilterOptionsMobile.TIME_RANGE,
  FilterOptionsMobile.POPULATION,
]

/**
 * Represents a step in a list.
 */
export interface ListInterface {
  /**
   * The title of the step.
   */
  title: string

  /**
   * The path associated with the step (optional).
   */
  path: string

  /**
   * The step number.
   */
  step: number

  /**
   * The text shown on the heading for the step.
   */
  text: string

  /**
   * The timeout value (in minutes) for the step. A function will
   * convert this to milliseconds when calling setTimeout. This determines
   * how long the step remains active before progressing to the next step.
   */
  timeout: number

  /**
   * A callback function that will be executed when the user navigates to the previous step.
   * This function can be used to perform specific actions when the user goes back in the list.
   */
  onPrevious: () => void
}

export const getStepTimes = (stepsList: ListInterface[]) => {
  return stepsList.map((step) => step.timeout * 60 * 1000)
}

export const handleBeforeunload = (event: BeforeUnloadEvent) => {
  event.preventDefault()
  // mixpanelTrackPageCloseAttempted()
  event.returnValue = ''
}

export const createEmailTemplate = (
  date: string,
  time: string,
  timezone: string
) => {
  const subject = `Appointment Request for ${date} at ${time} ${timezone}`
  const body = `Hi Team,

I attempted to book an appointment through your platform but couldn't find a suitable slot. Requesting you to assist me with the appointment on ${date} at ${time} ${timezone}.
  
  
Best regards,
Name`

  return { subject, body }
}

export const supportEmail = 'support@trusana.com'

export const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' })
}

export const isValidZip = new RegExp(/(^\d{5}$)/)
export const providerSearchFailError = [
  {
    description:
      'We are having trouble fetching providers. Please try again later.',
  },
]

export const executeScroll = (ref: any) => {
  ref.current?.scrollIntoView({ behavior: 'smooth' })
}

export const isNewEAPFlow = (org: string | undefined) => {
  //Since only Providence follows the new changes
  if (org === 'f7a7d2b1-ec91-490f-9238-e1c99578637e') {
    return true
  }

  return false
}

export const emailRegex =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
export const InValidZipCodeState =
  'Entered ZIP Code is not valid for the selected state'
export const InvalidZipCode = 'Invalid ZIP Code'
export const NoResult = 'No records found'
