import { mixpanelTrackSupportLinkClicked } from '../../common/utils/mixpanel'
import './app-footer.scss'

const AppFooterSmall = () => {
  return (
    <footer
      className="app-footer md:block md:!flex md:!flex-row items-center justify-between p-1 px-7 md:fixed md:bottom-0 left-0 w-full text-left xs:h-[8vh] md:h-[5vh]"
      style={{ zIndex: '1000' }}
      data-testid="app-footer-small"
    >
      <div className="flex flex-col md:flex-row  items-center text-white whitespace-pre">
        <div className="md:pr-1 font-bold">Customer Service:</div>
        <div className="xs:mb-1 md:mb-0 font-semibold support-mail">
          <a
            className="text-white cursor-pointer hover:underline "
            href="tel:+1(833)-724-9355"
          >
            (833) 724-9355
          </a>{' '}
          |{' '}
          <span className="my-2 support-mail text-left">
            <a
              href="mailto:support@trusana.com"
              className="font-semibold py-2 text-white cursor-pointer hover:underline"
              onClick={mixpanelTrackSupportLinkClicked}
            >
              {'support@trusana.com'}
            </a>
          </span>
        </div>
      </div>
      <div className="hidden sm:block text-white align-middle font-semibold flex items-center">
        ©2023 Providence. All rights reserved.
      </div>
    </footer>
  )
}

export default AppFooterSmall
