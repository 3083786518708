import React from 'react'
import { useHistory } from 'react-router-dom'
import { marketingTrusanaLogo, memberLoginIcon } from '../../../assets'
import { redirectToHelthieWebsite } from '../../EAP/EAP-constants'
import '../php-form-flow/php-form-flow.scss'

interface PHPHeaderProps {
  shouldDisplayLogo: boolean
  isOpen: boolean
}

const PhpHeader: React.FC<PHPHeaderProps> = ({ shouldDisplayLogo, isOpen }) => {
  const history = useHistory()
  const onAppLogoClickHandler = () => {
    history.push('/')
  }
  return (
    <nav className="bg-white fixed flex flex-row justify-between top-0 left-0 z-[100] w-full drop-shadow-sm">
      <img
        src={marketingTrusanaLogo}
        alt="logo"
        className={`header-logo md:cursor-pointer h-6 md:h-9 block inline mx-2 my-2 ml-4 md:ml-16
        ${shouldDisplayLogo ? '!ml-4' : isOpen ? 'md:invisible-logo' : ''}
        `}
        onClick={onAppLogoClickHandler}
      />
      <button
        className="flex flex-row rounded-full items-center justify-center border-black py-1 px-1 md:p-2 bg-white poppins font-semibold my-2 mr-2 cursor-pointer focus-button float-right"
        onClick={redirectToHelthieWebsite}
      >
        <div className="flex flex-row items-center justify-center">
          <img
            src={memberLoginIcon}
            alt="Member Login"
            className="mr-1 max-h-[30px] max-w-[30px]"
          />
          <div>Member Login</div>
        </div>
      </button>
    </nav>
  )
}

export default PhpHeader
