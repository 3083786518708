/* istanbul ignore file */
import { WarningFilled } from '@ant-design/icons'
import { Progress } from 'antd'
import './red-error-icon.scss'

interface RedIconErrorProps {
  isPhpTimeoutPage?: boolean
}
const RedErrorIcon: React.FC<RedIconErrorProps> = ({ isPhpTimeoutPage }) => {
  const showRemainingTime = (percent: number) => {
    return (
      <div className="text-color">
        <div className="font-bold text-xl text-[#DE0202]">{percent / 5}</div>
        <div className="text-xs font-normal text-[#DE0202]">MIN</div>
      </div>
    )
  }

  return (
    <>
      <div
        className={`${
          isPhpTimeoutPage ? 'md:h-44' : 'md:h-36 '
        } modal-icon flex flex-row items-center justify-center  md:max-w-2xl h-20 md:w-36  rounded-t md:rounded-l md:rounded-tr-none bg-[#DE0202]`}
      >
        {isPhpTimeoutPage ? (
          <Progress
            type="circle"
            width={70}
            percent={100}
            format={() => showRemainingTime(0)}
            strokeColor="#FDA598"
            className="php-progress"
          />
        ) : (
          <WarningFilled style={{ fontSize: '4rem', color: 'white' }} />
        )}
      </div>
    </>
  )
}

export default RedErrorIcon
