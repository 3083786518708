import React from 'react'
import './terms-and-conditions.scss'

const NonDiscrimination = () => {
  interface OneBodyHyperlinksType {
    label: string
    pathToRoute: string
  }

  const linksData: OneBodyHyperlinksType[] = [
    {
      label: 'English',
      pathToRoute:
        'https://nam11.safelinks.protection.outlook.com/?url=https%3A%2F%2Fwww.providence.org%2F-%2Fmedia%2Fproject%2Fpsjh%2Fshared%2Ffiles%2Fnondiscrimination%2Fnondiscrimination_notice_english.pdf%3Fla%3Den%26rev%3Dd30a6c887aa3474bab277a6585c8c4d1%26hash%3D8D63D714FA67BDB5FD15BFC7C3B4809B&data=05%7C01%7CNagaVenkataRaviTeja.Gundu%40providence.org%7Cabd23bcfe75f4c33c2e808dacd34cb28%7C2e3190869a2646a3865f615bed576786%7C0%7C0%7C638047923893756728%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C3000%7C%7C%7C&sdata=M5ZbuHDpaRBF4oc5w%2FvhLL7vLgkCduagwUKtXeWxanw%3D&reserved=0',
    },
    {
      label: 'Hindi',
      pathToRoute:
        'https://nam11.safelinks.protection.outlook.com/?url=https%3A%2F%2Fwww.providence.org%2F-%2Fmedia%2Fproject%2Fpsjh%2Fshared%2Ffiles%2Fnondiscrimination%2Fnondiscrimination_notice_hindi.pdf%3Fla%3Den%26rev%3D54b086e1e3e94b10b4f6938f7fcb2f0a%26hash%3D6987C67D7A3CDF336174A40EC70F3ABA&data=05%7C01%7CNagaVenkataRaviTeja.Gundu%40providence.org%7Cabd23bcfe75f4c33c2e808dacd34cb28%7C2e3190869a2646a3865f615bed576786%7C0%7C0%7C638047923893756728%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C3000%7C%7C%7C&sdata=exKmB6iNJ6OBiBYn6smz96tjBWZqJeaU8aHYo5AcX%2Bs%3D&reserved=0',
    },
    {
      label: 'Russian',
      pathToRoute:
        'https://nam11.safelinks.protection.outlook.com/?url=https%3A%2F%2Fwww.providence.org%2F-%2Fmedia%2Fproject%2Fpsjh%2Fshared%2Ffiles%2Fnondiscrimination%2Fnondiscrimination_notice_russian.pdf%3Fla%3Den%26rev%3Dc2f2ea98dc8b4a8597d874cf3d1c9a1b%26hash%3D589EBD1C97166E6B3D616AAD15A5B997&data=05%7C01%7CNagaVenkataRaviTeja.Gundu%40providence.org%7Cabd23bcfe75f4c33c2e808dacd34cb28%7C2e3190869a2646a3865f615bed576786%7C0%7C0%7C638047923893756728%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C3000%7C%7C%7C&sdata=g%2FxgzfbFPoxqwsYO4ZUw0m%2FARcBnX%2FfoKViZPiFdvAA%3D&reserved=0',
    },
    {
      label: 'Simplified Chinese',
      pathToRoute:
        'https://nam11.safelinks.protection.outlook.com/?url=https%3A%2F%2Fwww.providence.org%2F-%2Fmedia%2Fproject%2Fpsjh%2Fshared%2Ffiles%2Fnondiscrimination%2Fnondiscrimination_notice_simplified-chinese.pdf%3Fla%3Den%26rev%3D48f4573e5bbc442683e52ba8b8ee820d%26hash%3D1CF6AB41F64F988FDAD06FF770DF5A02&data=05%7C01%7CNagaVenkataRaviTeja.Gundu%40providence.org%7Cabd23bcfe75f4c33c2e808dacd34cb28%7C2e3190869a2646a3865f615bed576786%7C0%7C0%7C638047923893756728%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C3000%7C%7C%7C&sdata=%2B2WssTK4ryjMA6wZX4eHq%2B2NcQ3cDqIziN%2BRiYzkE2k%3D&reserved=0',
    },
    {
      label: 'Spanish',
      pathToRoute:
        'https://nam11.safelinks.protection.outlook.com/?url=https%3A%2F%2Fwww.providence.org%2F-%2Fmedia%2Fproject%2Fpsjh%2Fshared%2Ffiles%2Fnondiscrimination%2Fnondiscrimination_notice_spanish.pdf%3Fla%3Den%26rev%3D05fe4b06c5c44648a9bb513390e83c63%26hash%3D43CBB1DDBE9114FC4A70C2E85B9E42EA&data=05%7C01%7CNagaVenkataRaviTeja.Gundu%40providence.org%7Cabd23bcfe75f4c33c2e808dacd34cb28%7C2e3190869a2646a3865f615bed576786%7C0%7C0%7C638047923893756728%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C3000%7C%7C%7C&sdata=TyWTsdB66vii2JFKhmsYoGzyuA4S%2FKaDgP2XrTJtmsc%3D&reserved=0',
    },
    {
      label: 'Tagalog',
      pathToRoute:
        'https://nam11.safelinks.protection.outlook.com/?url=https%3A%2F%2Fwww.providence.org%2F-%2Fmedia%2Fproject%2Fpsjh%2Fshared%2Ffiles%2Fnondiscrimination%2Fnondiscrimination_notice_tagalog.pdf%3Fla%3Den%26rev%3D5f1d48d0871a401e82fd648aadd74b61%26hash%3D17D619DF6125042FFC09AED6D014A5BE&data=05%7C01%7CNagaVenkataRaviTeja.Gundu%40providence.org%7Cabd23bcfe75f4c33c2e808dacd34cb28%7C2e3190869a2646a3865f615bed576786%7C0%7C0%7C638047923893756728%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C3000%7C%7C%7C&sdata=2m4GUFu7Zb3mS14%2BGu65qBdHXni0hiu3OyJtL6jxKvQ%3D&reserved=0',
    },
    {
      label: 'Ukrainian',
      pathToRoute:
        'https://nam11.safelinks.protection.outlook.com/?url=https%3A%2F%2Fwww.providence.org%2F-%2Fmedia%2Fproject%2Fpsjh%2Fshared%2Ffiles%2Fnondiscrimination%2Fnondiscrimination_notice_ukrainian.pdf%3Fla%3Den%26rev%3D1e06d2d80728496a90b72b625655eb15%26hash%3D72A17E0FA14C9EC94DBADF01ED042206&data=05%7C01%7CNagaVenkataRaviTeja.Gundu%40providence.org%7Cabd23bcfe75f4c33c2e808dacd34cb28%7C2e3190869a2646a3865f615bed576786%7C0%7C0%7C638047923893912961%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C3000%7C%7C%7C&sdata=v7Iv9yk90202Z63cKTen%2BSwagOSSZk2dIH3fmtUV5bs%3D&reserved=0',
    },
    {
      label: 'Vietnamese',
      pathToRoute:
        'https://nam11.safelinks.protection.outlook.com/?url=https%3A%2F%2Fwww.providence.org%2F-%2Fmedia%2Fproject%2Fpsjh%2Fshared%2Ffiles%2Fnondiscrimination%2Fnondiscrimination_notice_vietnamese.pdf%3Fla%3Den%26rev%3De0cb0b1a3eba40e88b139decf046d378%26hash%3DE45229AFE2D16859AB12A56EEDFB22F0&data=05%7C01%7CNagaVenkataRaviTeja.Gundu%40providence.org%7Cabd23bcfe75f4c33c2e808dacd34cb28%7C2e3190869a2646a3865f615bed576786%7C0%7C0%7C638047923893912961%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C3000%7C%7C%7C&sdata=TK33nGGsIH2ScV0wBSZ9Jf%2BQCExg4a4ZcWVOC9SkHdw%3D&reserved=0',
    },
  ]

  return (
    <>
      <div className="container bg-light-sun">
        <div className="header-data text-xl xl:text-3xl mb-4">
          {'Notice of Nondiscrimination and Communication Assistance'}
        </div>
        <div className="mb-3">
          {`
         Trusana is an operating division within Providence. Providence St. Joseph Health and its Affiliates (collectively “PSJH”) comply with applicable Federal civil rights laws and do not discriminate against, exclude, or treat differently any individuals accessing any PSJH Program or Activity. Additionally, in compliance with the Americans with Disabilities Act (ADA), PSJH provides qualified interpreters and other auxiliary aids and services free of charge. 
         For more information, download the full notice in the following languages:             
          `}
        </div>
        <div>
          <ul>
            {linksData.map((linkDataItem: OneBodyHyperlinksType) => {
              return (
                <li key={linkDataItem.label}>
                  <a href={linkDataItem.pathToRoute}>{linkDataItem.label}</a>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    </>
  )
}

export default NonDiscrimination
