import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { homePath } from '../../routing'
import { termsPath } from '../../routing/route.constants'

const useMarketingPageTracker = () => {
  const location = useLocation()
  const [isMarketingPage, setIsMarketingPage] = useState<boolean>(true)

  useEffect(() => {
    if (
      location.pathname === homePath ||
      location.pathname.includes(termsPath)
    ) {
      setIsMarketingPage(true)
    } else {
      setIsMarketingPage(false)
    }
  }, [location])

  return isMarketingPage
}

export default useMarketingPageTracker
