import { changeProviderApptPath } from '../../routing/route.constants'

interface TagDescriptionContainerInterface {
  tag: string
  className?: string
  isHighlighted?: boolean
  isCurrentProviderCard?: boolean
}

const TagDescriptionContainer: React.FC<TagDescriptionContainerInterface> = ({
  tag,
  className,
  isHighlighted = false,
  isCurrentProviderCard,
}) => {
  const isChangeProviderFlow = location.pathname.includes(
    changeProviderApptPath
  )
  return (
    <div
      className={`tag py-1 px-2 m-1 bg-[#F0F3F4] text-[#3A4754] text-sm font-normal rounded-md select-none ${className} ${
        (isChangeProviderFlow && isCurrentProviderCard) || !isHighlighted
          ? ''
          : 'bg-[#FEDA9A] border-solid border-[#FC6E4D]'
      }`}
    >
      {tag}
    </div>
  )
}

export default TagDescriptionContainer
