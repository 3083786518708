import { Divider, RadioChangeEvent } from 'antd'
import React, { useContext, useState, useEffect } from 'react'
import NewInputText from '../../../common/new-form/new-input-text/new-input-text'

import InsuranceVerificationUpload from '../../../common/insurance-upload/insurance-verification-upload'
import { PHPRegistrationContext } from '../php-registration/php-registration-middleware'
import { fromSelectOptions } from '../../../common/form/input-select/input-select'
import NewInputSelect from '../../../common/new-form/new-input-select/new-input-select'
import StaticRadioButton from '../../../common/new-form/static-radio-button/static-radio-button'
import PhpFormSectionInfo from '../php-form-section-info/php-form-section-info'
import {
  alphanumberPattern,
  inputRadioOptions,
  insuranceCompanyNameList,
  numberPattern,
} from '../php-constants'
import ImageUploadValidation from '../../../common/id-upload/image-upload-validation'

interface InsuranceDetailsProps {
  shouldDisplayValidation: boolean
  setShouldDisplayValidation: React.Dispatch<boolean>
  updateIsPrimarySubscriber: (isPrimarySubscriber: boolean) => void
}
const InsuranceDetails: React.FC<InsuranceDetailsProps> = ({
  shouldDisplayValidation,
  setShouldDisplayValidation,
  updateIsPrimarySubscriber,
}) => {
  const {
    imgData,
    setImgData,
    isImageUploaded,
    setIsImageUploaded,
    setIsInsuranceDetailsPresent,
    isInsuranceImageUploaded,
  } = useContext(PHPRegistrationContext)
  const [radioValue, setRadioValue] = useState()

  const [insuranceFieldsDetails, setInsuranceFieldsDetails] = useState({
    memberID: '',
    groupID: '',
  })

  const onPrimarySubscriberRadioChange = (e: RadioChangeEvent) => {
    updateIsPrimarySubscriber(e.target.value === 1)
    setRadioValue(e.target.value)
  }

  const onChangeDetails = (e: any) => {
    setInsuranceFieldsDetails({
      ...insuranceFieldsDetails,
      [e.target.id]: e.target.value,
    })
  }

  useEffect(() => {
    setShouldDisplayValidation(false)
    setIsInsuranceDetailsPresent(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insuranceFieldsDetails.memberID, insuranceFieldsDetails.groupID])

  const groupIDValidator = (_: any, value: any) => {
    if (isInsuranceImageUploaded) {
      setShouldDisplayValidation(false)
      setIsInsuranceDetailsPresent(true)
      return Promise.resolve()
    }
    if (!(value?.length > 0) && !isInsuranceImageUploaded) {
      setShouldDisplayValidation(true)
      setIsInsuranceDetailsPresent(false)

      return Promise.reject(new Error('Group ID is missing'))
    }
    if (value.length < 6 && !isInsuranceImageUploaded) {
      setShouldDisplayValidation(true)
      setIsInsuranceDetailsPresent(false)

      return Promise.reject(new Error('Minimum length of 6'))
    }
    if (!alphanumberPattern.test(value) && !isInsuranceImageUploaded) {
      setShouldDisplayValidation(true)
      setIsInsuranceDetailsPresent(false)

      return Promise.reject(new Error('Group ID Field is Invalid'))
    }

    return Promise.resolve()
  }

  const memberIDValidator = (_: any, value: any) => {
    if (isInsuranceImageUploaded) {
      setShouldDisplayValidation(false)
      setIsInsuranceDetailsPresent(true)
      return Promise.resolve()
    }
    if (!(value?.length > 0) && !isInsuranceImageUploaded) {
      setShouldDisplayValidation(true)
      setIsInsuranceDetailsPresent(false)

      return Promise.reject(new Error('Member / Subscriber ID is missing'))
    }

    if (!numberPattern.test(value) && !isInsuranceImageUploaded) {
      setShouldDisplayValidation(true)
      setIsInsuranceDetailsPresent(false)

      return Promise.reject(new Error('Member / Subscriber ID is Invalid'))
    }
    if (value.length < 11 && !isInsuranceImageUploaded) {
      setShouldDisplayValidation(true)
      setIsInsuranceDetailsPresent(false)

      return Promise.reject(new Error('Minimum length of 11'))
    }
    return Promise.resolve()
  }

  return (
    <div className="flex flex-col mt-4">
      <div className="flex flex-col md:flex-row md:gap-8">
        <div className="flex flex-col md:basis-1/2">
          <PhpFormSectionInfo
            sectionNumber={2}
            title={'Insurance Information'}
            description={`Provide insurance details of the person who will be receiving care`}
            wbreak={true}
          />
        </div>
        <div className="flex flex-col md:basis-1/2">
          <div className="font-bold text-shade  py-2 md:pb-2 md:pt-0">
            Who is the person receiving care?
            <span style={{ content: '*', color: '#DE0202' }}>*</span>
          </div>
          <StaticRadioButton
            label={'Primary Subscriber'}
            name={'primarySubscriber'}
            onChange={(e: RadioChangeEvent) => {
              onPrimarySubscriberRadioChange(e)
            }}
            value={radioValue}
            className="php-form-element"
            radioOptions={inputRadioOptions}
            isRequired={true}
          />
        </div>
      </div>

      <div className="flex flex-col md:flex-row md:gap-6">
        <div className="flex flex-col md:basis-[38%] md:justify-center">
          <InsuranceVerificationUpload
            imgData={imgData}
            setImgData={setImgData}
            isImageUploaded={isImageUploaded}
            setIsImageUploaded={setIsImageUploaded}
            shouldDisplayValidation={shouldDisplayValidation}
            setShouldDisplayValidation={setShouldDisplayValidation}
          />
        </div>
        {/* Vertical rule desktop */}
        <div className="flex-col hidden md:flex php-divider md:basis-[9%]">
          <Divider type="vertical" className="text-black  md:mx-auto" />
          <div className="bg-[#3f7a88] text-white h-[2rem] w-[2rem] pt-[0.35rem] text-center md:mx-auto">
            Or
          </div>
          <Divider type="vertical" className="text-black  md:mx-auto" />
        </div>

        {/* Vertical rule desktop */}
        {/* Horizontal rule mobile */}
        <div className="flex flex-row md:hidden php-divider pb-6">
          <Divider className="text-black  flex flex-col" />
          <div className="bg-[#3f7a88] text-white h-[2rem] w-[4rem] pt-[0.35rem] text-center flex flex-col mx-2">
            Or
          </div>
          <Divider className="text-black flex flex-col" />
        </div>
        {/* Horizontal rule mobile */}
        <div className="flex flex-col md:basis-[48.5%]">
          <NewInputSelect
            label={'Insurance Company'}
            name={'InsuranceCompany'}
            placeholder={'Select Insurance company'}
            options={fromSelectOptions(
              insuranceCompanyNameList,
              'name',
              'code'
            )}
          />

          <div className="flex flex-col md:basis-[48.5%]">
            <NewInputText
              label={'Member / Subscriber ID'}
              name={'memberID'}
              placeholder={'Enter Member / Subscriber ID'}
              // pattern={numberPattern}
              // patternMessage={FormInvalidMessage('Member / Subscriber ID')}
              maxLength={11}
              // min={{
              //   value: 11,
              //   message: FormInvalidMessage(''),
              // }}
              validator={memberIDValidator}
              validateTrigger={['onChange', 'onSubmit']}
              onChange={(e) => onChangeDetails(e)}
              value={insuranceFieldsDetails?.memberID}
            />
          </div>

          <div className="flex flex-col md:basis-[48.5%]">
            <NewInputText
              label={'Group ID'}
              name={'groupID'}
              placeholder={'Enter Group ID'}
              maxLength={50}
              validator={groupIDValidator}
              validateTrigger={['onChange', 'onSubmit']}
              onChange={(e) => onChangeDetails(e)}
              value={insuranceFieldsDetails?.groupID}
            />
          </div>
        </div>
      </div>

      {shouldDisplayValidation ? (
        <ImageUploadValidation
          text={'Please upload insurance card or fill in the insurance details'}
          isInsuranceUpload={true}
        />
      ) : null}
    </div>
  )
}

export default InsuranceDetails
