import { CloseCircleOutlined } from '@ant-design/icons'
import { Card } from 'antd'
import React, { FunctionComponent } from 'react'
import { PDFFileIcon } from '../../assets'

interface ImageThumbNailProps {
  thumbnailText: string
  shouldDisplayImage: boolean
  imgData?: any
  handleImageUploadCancel: () => void
  isPDF: boolean
}

const PhpImageThumbNail: FunctionComponent<ImageThumbNailProps> = ({
  thumbnailText,
  shouldDisplayImage,
  imgData,
  handleImageUploadCancel,
  isPDF,
}) => {
  return (
    <div data-testid="image-thumbnail">
      {shouldDisplayImage ? (
        <Card
          className="card-background h-auto m-4"
          data-testid="image-thumbnail-card"
        >
          <div className="float-right mt-0 mr-0">
            <CloseCircleOutlined
              onClick={() => handleImageUploadCancel()}
              data-testid="close-icon"
            />
          </div>
          <div className="mb-4 text-[#5B798C]">{thumbnailText}</div>
          <div className="flex flex-row">
            <div
              className="flex flex-col"
              data-testid={isPDF ? 'pdf-image' : 'regular-image'}
            >
              <img
                src={isPDF ? PDFFileIcon : imgData?.data}
                className={`${
                  isPDF
                    ? 'h-[4rem] w-[4rem] pdf-image'
                    : 'w-[5rem] h-[3rem] regular-image'
                } object-contain `}
              />
            </div>
            <div className="flex flex-col pl-4">
              <div className="text-[#495B6F]">{imgData?.fileName}</div>
              <div className="text-[#495B6F]">
                {(imgData?.size / 1048576).toFixed(4)}
                MB
              </div>
            </div>
          </div>
        </Card>
      ) : null}
    </div>
  )
}

export default PhpImageThumbNail
