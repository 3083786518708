import React, { useContext, useEffect, useRef, useState } from 'react'
import { Modal, Drawer, Calendar, Row, Button } from 'antd'
import dayjs from 'dayjs'
import { useHistory } from 'react-router-dom'

import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import moment from 'moment'
import { SendMail } from '../../../assets'
import {
  handleBeforeunload,
  createEmailTemplate,
} from '../../../pages/EAP/EAP-constants'
import useScreenWidth from '../../hooks/use-screen-width'
import {
  mixpanelTrackOpenedSlotSelection,
  //   mixpanelTrackRetentionPopUpShown,
  mixpanelTrackSentMailForSlot,
  mixpanelTrackDateSelectedForSupport,
  mixpanelTrackTimeSlotChosenFromDrawer,
} from '../../utils/mixpanel'
import { PopupTimerContext } from '../../../pages/EAP/popup-timer/popup-timer-middleware'

interface InactiveModalInterface {
  stepTimes?: number[] // denotes the no of seconds to wait in each step
  current: number // denotes the current step
  timezone?: string | undefined // Indicates the timezone for which the user is selecting the time
}
const InactivityModal: React.FC<InactiveModalInterface> = ({
  current,
  timezone,
}) => {
  const history = useHistory()
  const {
    registerationTimerPopup,
    appointmentTimerPopup,
    handleRegisterationTimerPopup,
    handleAppointmentTimerPopup,
  } = useContext(PopupTimerContext)
  const width = useScreenWidth()
  const [selectedDate, setSelectedDate] = useState(new Date())
  const [selectedTimeSlot, setSelectedTimeSlot] = useState<string | null>(null)
  const emailRef = useRef<HTMLDivElement>(null)
  const [showTimeslotMessage, setShowSelectTimeslotMessage] =
    useState<boolean>(false)

  const [isChooseSlotDrawerVisible, setIsChooseSlotDrawerVisible] =
    useState<boolean>(false)

  const toggleModal = () => {
    current === 0
      ? handleRegisterationTimerPopup({
          ...registerationTimerPopup,
          isAlreadyDisplayed: true,
        })
      : handleAppointmentTimerPopup({
          ...appointmentTimerPopup,
          isAlreadyDisplayed: true,
        })
  }
  const pushToHome = () => {
    current === 0
      ? handleRegisterationTimerPopup({
          ...registerationTimerPopup,
          isAlreadyDisplayed: true,
        })
      : handleAppointmentTimerPopup({
          ...appointmentTimerPopup,
          isAlreadyDisplayed: true,
        })
    history.push('/')
  }
  useEffect(() => {
    window.removeEventListener('beforeunload', handleBeforeunload)
    return () => {
      window.addEventListener('beforeunload', handleBeforeunload)
    }
  }, [])

  const onCancelHandle = () => {
    current === 0
      ? handleRegisterationTimerPopup({
          isModalDisplaying: false,
          isAlreadyDisplayed: true,
        })
      : handleAppointmentTimerPopup({
          isModalDisplaying: false,
          isAlreadyDisplayed: true,
        })
  }

  const toggleChooseSlotDrawer = () => {
    if (!isChooseSlotDrawerVisible) {
      mixpanelTrackOpenedSlotSelection()
    }
    setIsChooseSlotDrawerVisible(
      (isChooseSlotDrawerVisible) => !isChooseSlotDrawerVisible
    )
    handleAppointmentTimerPopup({
      ...appointmentTimerPopup,
      isModalDisplaying: false,
    })
    setSelectedTimeSlot(null)
  }

  const handleScrollIntoView = () => {
    if (emailRef.current) {
      emailRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      })
    }
  }

  const generateTimeSlots = () => {
    const today = moment().startOf('day')
    const startTime = moment(selectedDate).hour(7).minute(0)
    const endTime = moment(selectedDate).hour(20).minute(0)
    const currentTime = moment().add(1, 'hour')

    const timeslots = []

    while (startTime.isSameOrBefore(endTime)) {
      if (
        (today.isSame(selectedDate, 'day') && startTime.isAfter(currentTime)) ||
        today.isBefore(selectedDate, 'day')
      ) {
        timeslots.push(startTime.format('hh:mm A'))
      }
      startTime.add(1, 'hour')
    }

    return timeslots
  }

  /** Calendar component helper functions */

  const handlePrev = () => {
    const newDate = dayjs(selectedDate)
      .subtract(1, 'month')
      .isAfter(dayjs().toDate())
      ? dayjs(selectedDate).subtract(1, 'month').toDate()
      : dayjs().toDate()
    setSelectedDate(newDate)
  }

  const handleNext = () => {
    const newDate = dayjs(selectedDate).add(1, 'month').toDate()
    setSelectedDate(newDate)
  }

  const disabledDate = (currentDate: moment.Moment) => {
    /** Return true if the current date is before today's date*/
    const today = moment()
    return currentDate.isBefore(today, 'day')
  }

  const timeslots = generateTimeSlots()

  const handleSendEmail = () => {
    if (!selectedTimeSlot) {
      setShowSelectTimeslotMessage(true)
      return
    }

    const { subject, body } = createEmailTemplate(
      moment(selectedDate).format('MM/DD'),
      selectedTimeSlot,
      timezone
    )

    const emailAddress = 'support@trusana.com'
    const mailtoLink = `mailto:${emailAddress}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`

    toggleChooseSlotDrawer()
    toggleModal()
    mixpanelTrackSentMailForSlot()
    window.location.href = mailtoLink // or window.open(mailtoLink, '_blank')
  }

  const buttonOptions =
    current === 0
      ? [
          {
            text: 'Continue with the website',
            action: toggleModal,
          },
          {
            text: 'Need more info on Trusana',
            action: pushToHome,
          },
        ]
      : [
          {
            text: 'Continue booking appointment',
            action: toggleModal,
          },
          {
            text: "Couldn't find my preferred slot",
            action: toggleChooseSlotDrawer,
          },
        ]

  return (
    <div>
      <Modal
        footer={null}
        destroyOnClose={true}
        visible={
          current == 0
            ? registerationTimerPopup.isModalDisplaying
            : appointmentTimerPopup.isModalDisplaying
        }
        maskClosable={false}
        closable={true}
        onCancel={onCancelHandle}
        className="max-w-sm md:max-w-2xl !w-fit error-modal mt-[10%] px-8 rounded-lg bg-opacity-50"
      >
        <div className="flex flex-col justify-center pb-4 px-4 py-6 mx-8 my-2">
          <div className="text-teal-blue font-bold text-2xl my-2">
            {current === 0 ? "You're almost there!" : 'Need Support?'}
          </div>
          <div className="text-lg mb-6">
            {current === 0
              ? 'Please continue to proceed to the appointment page.'
              : "You're one step away from booking an appointment!"}
          </div>
          <div className="flex flex-col gap-2 justify-center items-center">
            {buttonOptions.map((buttonOption, index) => {
              return (
                <div
                  className="my-1 px-2 w-full max-w-[20rem] rounded-full bg-white  cursor-pointer hover:bg-shade text-shade hover:text-white p-1 m-1 text-center border-shade border-solid"
                  onClick={buttonOption.action}
                  key={index}
                >
                  {buttonOption.text}
                </div>
              )
            })}
          </div>
        </div>
      </Modal>
      {current === 1 ? (
        <Drawer
          className="flex flex-col items-center justify-center"
          placement="right"
          width={width > 600 ? '600px' : '400px'}
          visible={isChooseSlotDrawerVisible}
          onClose={toggleChooseSlotDrawer}
          closable={false}
        >
          <div className="text-2xl ml-6 mb-2 2xl:mb-4 font-semibold">
            Choose your preferred slot
          </div>
          <div className="text-lg ml-6 mb-4 text-dark-gray font-light">
            Select a date and time
          </div>
          <div
            className={`mx-auto border-solid border-shade rounded-[0.2rem] ${
              width > 600 ? 'w-[502px]' : 'w-[350px]'
            }`}
          >
            <Calendar
              fullscreen={false}
              className="cal"
              disabledDate={disabledDate}
              headerRender={() => (
                <Row justify="space-between" className="p-1">
                  <Button
                    type="primary"
                    onClick={handlePrev}
                    className="ml-2 mt-2 mb-2 calendar-arrow-button"
                    aria-label="Calendar-left"
                  >
                    <LeftOutlined />
                  </Button>
                  <div className="datetime mt-2">
                    {dayjs(selectedDate).format('MMMM YYYY')}
                  </div>
                  <Button
                    type="primary"
                    onClick={handleNext}
                    className="mr-2 mt-2 mb-2 calendar-arrow-button"
                    aria-label="Calendar-right"
                  >
                    <RightOutlined />
                  </Button>
                </Row>
              )}
              value={moment(selectedDate)}
              onSelect={(value) => {
                setSelectedDate(value.toDate())
                mixpanelTrackDateSelectedForSupport(
                  moment(value).format('MM/DD')
                )
              }}
            />
          </div>
          <div className="text-md text-dark-gray font-light text-start px-6 my-1 2xl:my-4 flex flex-row justify-between">
            <div>Choose time</div>
            <div>*{timezone}</div>
          </div>
          <div className="flex flex-col justify-between items-start">
            <div className="flex flex-row w-fit justify-start items-center flex-wrap mx-4 gap-1 my-2 2xl:my-4">
              {timeslots.map((timeslot, index) => {
                return (
                  <div
                    key={index}
                    className={`rounded-full cursor-pointer my-2 min-w-[100px] border-shade border-solid flex items-center justify-center py-1 mx-2 
                 ${
                   timeslot === selectedTimeSlot
                     ? 'bg-teal-blue text-white'
                     : 'hover:text-white hover:bg-teal-blue'
                 }`}
                    onClick={() => {
                      setShowSelectTimeslotMessage(false)
                      setSelectedTimeSlot(timeslot)
                      mixpanelTrackTimeSlotChosenFromDrawer(
                        timeslot,
                        timezone ?? ''
                      )
                      handleScrollIntoView()
                    }}
                  >
                    {' '}
                    {timeslot}
                  </div>
                )
              })}
            </div>
            <div
              className={`flex w-full text-rose-600 text-start ml-6 my-2 ${
                showTimeslotMessage ? 'block' : 'hidden'
              }`}
            >
              Please choose a timeslot
            </div>
            <div className="text-start pl-6 text-md my-2">
              Our support team will reach out to you after we receive your mail.
            </div>
            <div
              className="rounded-full flex flex-row justify-center max-w-[14rem] w-full items-center cursor-pointer hover:bg-shade ml-6 bg-teal-blue p-2 pl-4 gap-2"
              onClick={handleSendEmail}
              ref={emailRef}
            >
              <SendMail />
              <div className="text-white mx-4">Send</div>
            </div>
          </div>
        </Drawer>
      ) : null}
    </div>
  )
}

export default InactivityModal
