import './app-footer.scss'
import { useLocation } from 'react-router-dom'
import { faqPath, phpFromPath } from '../../routing/route.constants'
import { FooterSection } from '../../pages/landing-page'
import AppFooterSmall from './app-footer-small'
import useMarketingPageTracker from '../../common/hooks/use-marketing-page-tracker'
import { Suspense } from 'react'

const AppFooter = () => {
  const location = useLocation()
  const isAppointmentPage = location.pathname.includes('appointment')
  const isMarketingPage = useMarketingPageTracker()
  return (
    <div id="app-footer" data-testid="footer-section">
      {location.pathname.includes(phpFromPath) ? null : isMarketingPage ||
        location.pathname.includes(faqPath) ? (
        <Suspense fallback={<div></div>}>
          <FooterSection />
        </Suspense>
      ) : (
        <div className={`${isAppointmentPage && 'xs:hidden'}  `}>
          <div className="clear-footer" />
          <AppFooterSmall data-testid="app-footer-small" />
        </div>
      )}
    </div>
  )
}

export default AppFooter
