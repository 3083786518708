import { CloseOutlined } from '@ant-design/icons'
import { Button, Card } from 'antd'
import moment from 'moment'
import { useContext } from 'react'
import { LoaderContext } from '../../../common'
import './appointment-page.scss'
import { AppointmentPageContext } from './appointment-page-middleware'

interface SelectedSlotInterface {
  handleSlotSelection: (isSlotSelected: boolean) => void
  therapistName?: string
  dateofBooking?: string
  timeSlot: { slot: string; abbr: string }
  bookAppointment: (
    date: any,
    clickNextPath: any,
    therapistID: any,
    providerName?: string
  ) => void
  therapistId: string
  clickNextPath: string
}

const parseDate = (_, time) => {
  return time
}

const SelectedSlot: React.FC<SelectedSlotInterface> = ({
  handleSlotSelection,
  therapistName,
  dateofBooking,
  timeSlot,
  therapistId,
  bookAppointment,
  clickNextPath,
}) => {
  const { loading } = useContext(LoaderContext)
  const { appointmentType } = useContext(AppointmentPageContext)

  return (
    <Card className="selected-slot-card p-4 ml-0 md:ml-6 mt-4 md:mt-0 max-h-80">
      <div className="text-end mb-3 text-xl">
        <Button
          className="mr-1 cross-button"
          onClick={() => handleSlotSelection(false)}
          type="primary"
          aria-label="close"
        >
          <CloseOutlined style={{ color: 'white' }} />
        </Button>
      </div>
      <div className="selected-slot-card-text">
        <p>
          You are booking an appointment with {therapistName} ({appointmentType}
          ) on {moment.parseZone(timeSlot.slot).format('dddd, LL')} at{' '}
          {moment.parseZone(timeSlot.slot).format('LT')} {timeSlot.abbr}
        </p>
        <Button
          onClick={() => {
            bookAppointment(
              parseDate(dateofBooking, timeSlot.slot),
              clickNextPath,
              therapistId,
              therapistName
            )
          }}
          className="book-now-button hover:!bg-white hover:!text-shade"
          size="large"
          loading={loading}
        >
          {loading ? 'Processing' : 'Confirm'}
        </Button>
      </div>
    </Card>
  )
}

export default SelectedSlot
