/* istanbul ignore file */
import { Checkbox, Tooltip, Grid } from 'antd'
import { FormInstance } from 'antd/es/form/Form'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { FunctionComponent, useContext, useState } from 'react'
import { InputSelect, InputText, InputNum } from '..'
import { fromSelectOptions } from '../form/input-select/input-select'
import { MetadataContext } from '../metadata/metadata-middleware'
import { alphabetPattern } from '../../pages/EAP/EAP-constants'
import { FormInvalidMessage } from '../form/form.utils'
import { InfoCircleOutlined } from '@ant-design/icons'

export interface AddressType {
  addressLine1: string | undefined
  addressLine2: string | undefined
  city: string
  state: string
  zipCode: string
}
interface AddressProps {
  shouldDisplayCheckbox?: boolean
  addressData?: AddressType
  shouldDisplayEmergencyContact?: boolean
  shouldDisplayStateOfResidence?: boolean
  shouldDisableState?: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: FormInstance<any>
}

const { useBreakpoint } = Grid

const Address: FunctionComponent<AddressProps> = ({
  shouldDisplayCheckbox,
  form,
  addressData,
  shouldDisplayEmergencyContact,
  shouldDisplayStateOfResidence = false,
  shouldDisableState = false,
}) => {
  // const PhoneNumberPattern = new RegExp(`^\\([0-9]{3}\\)-[0-9]{3}-[0-9]{4}$`)
  const isValidZip = new RegExp(/(^\d{5}$)/)
  const { states } = useContext(MetadataContext)
  const [isAddressDisabled, setIsAddressDisabled] = useState(false)
  const onCheckboxChange = (e: CheckboxChangeEvent) => {
    if (e.target.checked && addressData) {
      form.setFieldsValue({ ...addressData })
      setIsAddressDisabled(true)
    } else {
      setIsAddressDisabled(false)
    }
  }

  const checkEmergencyContactName = (_cc: any, value: any) => {
    if (value.length < 2) {
      return Promise.reject(new Error(FormInvalidMessage('Name')))
    }
    if (!alphabetPattern.test(value)) {
      return Promise.reject(new Error(FormInvalidMessage('Name')))
    }
    return Promise.resolve()
  }

  const checkPhoneNumberNotEmergencyContact = (_cc: any, value: any) => {
    if (`${value}`.length < 10) {
      return Promise.reject(FormInvalidMessage('Phone Number'))
    }
    if (form.getFieldValue('phoneNumber') === value) {
      return Promise.reject(
        new Error('Emergency number cannot be the same as contact number')
      )
    }
    return Promise.resolve()
  }

  const validateAddress = (_cc: any, value: any) => {
    const invalidCharacterSet = `[\\""/?<>%()=;@\[\]\'\`:*]`
    const addressInvalidRegex = /[\\""/?<>%()=;@\[\]\'`:*]/
    if (addressInvalidRegex.test(value)) {
      return Promise.reject(
        `Please enter a valid address, without ${invalidCharacterSet}`
      )
    }
    if (value.length < 2) {
      return Promise.reject('Minimum address length of 2 characters')
    }
    return Promise.resolve()
  }
  const screens = useBreakpoint()

  return (
    <>
      <div className="text-lg font-semibold mb-2 text-shade xs:mt-4 mt-0">
        Address
      </div>
      <div className="formItems">
        {shouldDisplayCheckbox ? (
          <label>
            <Checkbox onChange={onCheckboxChange} className="mb-2">
              Same as your address
            </Checkbox>
          </label>
        ) : null}

        <label htmlFor="Address">
          <InputText
            label={'Address'}
            name={'addressLine1'}
            isDisabled={isAddressDisabled}
            isRequired={true}
            aria-required={true}
            placeholder={'Address (include Apt, Suite, Street, etc.)'}
            maxLength={100}
            validator={validateAddress}
            validateTrigger={['onBlur', 'onChange', 'onSubmit']}
            min={{
              value: 2,
              message: FormInvalidMessage(''),
            }}
            max={{
              value: 100,
              message: FormInvalidMessage(''),
            }}
          />
        </label>
        <label htmlFor="Locality/City">
          <InputText
            label={'Locality/City'}
            name={'city'}
            isRequired={true}
            aria-required={true}
            placeholder={'Locality/City'}
            isDisabled={isAddressDisabled}
            pattern={alphabetPattern}
            validator={validateAddress}
            validateTrigger={['onBlur', 'onChange', 'onSubmit']}
            patternMessage={FormInvalidMessage('Locality/City')}
            min={{
              value: 2,
              message: FormInvalidMessage(''),
            }}
            maxLength={35}
            // only chars
          />
        </label>
        <label
          htmlFor={`${
            shouldDisplayStateOfResidence ? 'State of Residence' : 'State'
          }`}
        >
          <InputSelect
            label={`${
              shouldDisplayStateOfResidence ? 'State of Residence' : 'State'
            }`}
            name={'state'}
            placeholder={`${
              shouldDisplayStateOfResidence ? 'State of Residence' : 'State'
            }`}
            isRequired={true}
            aria-required={true}
            options={fromSelectOptions(states, 'name', 'code')}
            isDisabled={shouldDisableState}
          />
        </label>
        <label htmlFor="ZIP Code">
          <InputText
            pattern={isValidZip}
            label={'ZIP Code'}
            placeholder={'ZIP Code'}
            name={'zipCode'}
            isRequired={true}
            aria-required={true}
            isDisabled={isAddressDisabled}
            maxLength={5}
            patternMessage={FormInvalidMessage('ZIP Code')}
          />
        </label>
      </div>
      {shouldDisplayEmergencyContact ? (
        <>
          <div className="text-lg font-semibold mb-2 text-shade">
            Emergency Contact
            <Tooltip
              title="Your emergency contact is the first person healthcare providers and emergency services will contact if a crisis or safety concern should arise."
              className="text-[#3A6975] ml-2"
              trigger={screens.md ? 'hover' : 'click'}
              overlayStyle={{
                maxWidth: screens.xs ? '250px' : screens.md ? '30%' : '50%',
              }}
            >
              <InfoCircleOutlined />
            </Tooltip>
          </div>
          <div className="formItems">
            <label htmlFor="Emergency Name">
              <InputText
                label={'Name'}
                pattern={alphabetPattern}
                placeholder={'Name'}
                isRequired={true}
                aria-required={true}
                name={'emergencyContactName'}
                min={{
                  value: 2,
                  message: FormInvalidMessage('Name'),
                }}
                max={{
                  value: 100,
                  message: FormInvalidMessage('Name'),
                }}
                validator={checkEmergencyContactName}
              />
            </label>
            <label htmlFor="Emergency Phone Number">
              <InputNum
                label={'Phone Number'}
                placeholder={'Phone Number'}
                name={'emergencyContactPhoneNumber'}
                isPhoneNumber={true}
                isRequired={true}
                aria-required={true}
                validator={checkPhoneNumberNotEmergencyContact}
              />
            </label>
          </div>
        </>
      ) : null}
    </>
  )
}

export default Address
