import { Avatar, Card, Col, Row } from 'antd'

interface ILoadingCardListProps {
  count: number
}

const LoadingCardList: React.FC<ILoadingCardListProps> = ({ count }) => {
  return (
    <div className="w-full min-h-fit skeleton-card-list">
      {Array(count)
        .fill(1)
        .map((_, index) => {
          return (
            <Card
              className="single-therapist-card rounded-2xl m-4 md:my-8 md:mx-8 fade-in-therapist-card "
              style={{ animationDelay: `${index * 0.2}s` }}
              key={index}
            >
              <Row className="single-therapist-container flex flex-row items-center justify-center">
                <Col
                  className="flex flex-row md:pt-4 justify-center p-7 px-0 pt-0 pb-2  
                      border-solid border-light-gray border-t-0 border-r-0 border-b border-l-0 
                      md:border-t-0 md:border-r md:border-b-0 md:border-l-0 single-therapist-card-left-section"
                >
                  <Col>
                    <Avatar
                      size={{
                        xs: 70,
                        sm: 80,
                        md: 100,
                        lg: 100,
                        xl: 150,
                        xxl: 160,
                      }}
                      className="rounded-full skeleton mr-2 md:ml-4"
                    />
                  </Col>
                  <Col className="w-full float-left pl-2 break-words mr-4 md:mr-8">
                    <Row className="mb-0 flex flex-col">
                      <div className="skeleton-text skeleton !w-4/5 lg:!w-3/5 xl:!w-1/5 !h-4 !mb-2"></div>
                      <div className="skeleton-text skeleton !w-2/3 lg:!w-3/5 xl:!w-1/6 !h-4 !mb-2"></div>
                    </Row>
                    <Row className="hidden md:block md:mt-4">
                      {Array(4)
                        .fill(0)
                        .map((_, index) => {
                          return (
                            <div
                              className="skeleton-text skeleton"
                              key={index}
                            ></div>
                          )
                        })}
                    </Row>
                  </Col>
                </Col>
                <Col className="w-full grid items-start px-12 hidden md:flex md:flex-col single-therapist-card-right-section">
                  <Row className="w-full h-fit">
                    <div className="skeleton skeleton-text h-4 !mb-2 !w-1/3"></div>
                  </Row>
                  {Array(4)
                    .fill(0)
                    .map((_, index) => {
                      return (
                        <div
                          className="skeleton-text skeleton"
                          key={index}
                        ></div>
                      )
                    })}
                </Col>
                <Col className="flex justify-center items-center md:hidden py-8">
                  Fetching Available Appointments
                </Col>
              </Row>
            </Card>
          )
        })}
    </div>
  )
}

export default LoadingCardList
