import { Progress } from 'antd'
import React from 'react'

interface StepperInterface {
  currentStep: number
  totalSteps: number
  currentStepPercentage: number
  isCentered: boolean
}

const NewStepper: React.FC<StepperInterface> = ({
  currentStep,
  totalSteps,
  currentStepPercentage,
  isCentered,
}) => {
  return (
    <div
      className={`mt-4 php-element ${
        isCentered ? 'flex justify-center' : 'w-full'
      }`}
    >
      {Array(currentStep)
        .fill(0)
        .map((_, index) => {
          return (
            <Progress
              key={index}
              percent={100}
              size="default"
              className="completed-step"
            />
          )
        })}
      <Progress percent={currentStepPercentage} size="default" />
      {Array(totalSteps - currentStep - 1)
        .fill(0)
        .map((_, index) => {
          return <Progress key={index} percent={0} size="default" />
        })}
    </div>
  )
}

export default NewStepper
