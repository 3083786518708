import { ReactElement } from 'react'
import { createRoot } from 'react-dom/client'

import {
  defaultConfig,
  globalConfig,
  globalConfigUrl,
} from '../../configuration/config'

const globalConfigUtils = (
  rootElement: ReactElement,
  rootElementId: string,
  errorElement: ReactElement
) => {
  fetch(globalConfigUrl)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .then((response: any) => {
      if (response.ok) {
        return response.json()
      } else {
      }
    })
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .then((data: any) => {
      if (process.env.NODE_ENV === 'development') {
        globalConfig.set(defaultConfig)
      } else {
        globalConfig.set(data)
      }
      return true
    })
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .catch((e: any) => {
      console.log('Error while fetching the configuration', e)
      return false
    })
    .then((configFetchResult: boolean) => {
      const container = document.getElementById(rootElementId)
      const root = createRoot(container!)
      if (configFetchResult) {
        root.render(rootElement)
      } else {
        root.render(errorElement)
      }
    })
}

export default globalConfigUtils
