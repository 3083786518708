import React from 'react'
import './terms-and-conditions.scss'

const PrivacyPractices = () => {
  interface OneBodyHyperlinksType {
    label: string
    pathToRoute: string
  }

  const linksData: OneBodyHyperlinksType[] = [
    {
      label: 'English',
      pathToRoute:
        'https://nam11.safelinks.protection.outlook.com/?url=https%3A%2F%2Fwww.providence.org%2F-%2Fmedia%2Fproject%2Fpsjh%2Fprovidence%2Fsocal%2Ffiles%2Fnotice-of-privacy-practices%2Fnopp-english.pdf%3Fla%3Den%26rev%3D847e1a90000345b2b867706b4cac25a0%26hash%3DAEB0F3316FFF344B0DCCCF546010D0AC&data=05%7C01%7CNagaVenkataRaviTeja.Gundu%40providence.org%7Cabd23bcfe75f4c33c2e808dacd34cb28%7C2e3190869a2646a3865f615bed576786%7C0%7C0%7C638047923893912961%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C3000%7C%7C%7C&sdata=TdlYXa5P9JhjFPyuJc5mWgcTynu73O%2Bl34wWdwk%2FLfg%3D&reserved=0',
    },
    {
      label: 'Spanish',
      pathToRoute:
        'https://nam11.safelinks.protection.outlook.com/?url=https%3A%2F%2Fwww.providence.org%2F-%2Fmedia%2Fproject%2Fpsjh%2Fprovidence%2Fsocal%2Ffiles%2Fnotice-of-privacy-practices%2Fnopp-spanish.pdf%3Fla%3Den%26rev%3D79a96110545c4c41a8442a3cf889cc7e%26hash%3D6469A320A47071361239E967CFD44167&data=05%7C01%7CNagaVenkataRaviTeja.Gundu%40providence.org%7Cabd23bcfe75f4c33c2e808dacd34cb28%7C2e3190869a2646a3865f615bed576786%7C0%7C0%7C638047923893912961%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C3000%7C%7C%7C&sdata=GZ%2BvDLE01R1f5OOWtIC%2F7vgAwD4PWQQ9%2FpS%2BRKo36T8%3D&reserved=0',
    },
    {
      label: 'Chinese',
      pathToRoute:
        'https://nam11.safelinks.protection.outlook.com/?url=https%3A%2F%2Fwww.providence.org%2F-%2Fmedia%2Fproject%2Fpsjh%2Fprovidence%2Fsocal%2Ffiles%2Fnotice-of-privacy-practices%2Fnopp-chinese.pdf%3Fla%3Den%26rev%3D69eb8e447eb347678769007ba5639a5c%26hash%3DC46F65D9366839AB60DA5EA7A6177F02&data=05%7C01%7CNagaVenkataRaviTeja.Gundu%40providence.org%7Cabd23bcfe75f4c33c2e808dacd34cb28%7C2e3190869a2646a3865f615bed576786%7C0%7C0%7C638047923893912961%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C3000%7C%7C%7C&sdata=GjXp1XrPGP8UORwg2UrwDyGEJY%2B5ejZi9PC3E9rA9eY%3D&reserved=0',
    },
    {
      label: 'Russian',
      pathToRoute:
        'https://nam11.safelinks.protection.outlook.com/?url=https%3A%2F%2Fwww.providence.org%2F-%2Fmedia%2Fproject%2Fpsjh%2Fprovidence%2Fsocal%2Ffiles%2Fnotice-of-privacy-practices%2Fnopp-russian.pdf%3Fla%3Den%26rev%3D4c8d9de7ba054bebbaaa38ec5068ed62%26hash%3D0FB6987189FE6AE673FA126AF524F12A&data=05%7C01%7CNagaVenkataRaviTeja.Gundu%40providence.org%7Cabd23bcfe75f4c33c2e808dacd34cb28%7C2e3190869a2646a3865f615bed576786%7C0%7C0%7C638047923893912961%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C3000%7C%7C%7C&sdata=1SLrp35OfR9pwKocF6tYkLLumiecgkF3r%2B3stRzSgUA%3D&reserved=0',
    },
    {
      label: 'Tagalog',
      pathToRoute:
        'https://nam11.safelinks.protection.outlook.com/?url=https%3A%2F%2Fwww.providence.org%2F-%2Fmedia%2Fproject%2Fpsjh%2Fprovidence%2Fsocal%2Ffiles%2Fnotice-of-privacy-practices%2Fnopp-tagalog.pdf%3Fla%3Den%26rev%3D66150813ae11494886b36e8f69385b9d%26hash%3DF4CF9F611C378951DE4AF3229EBDA18E&data=05%7C01%7CNagaVenkataRaviTeja.Gundu%40providence.org%7Cabd23bcfe75f4c33c2e808dacd34cb28%7C2e3190869a2646a3865f615bed576786%7C0%7C0%7C638047923893912961%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C3000%7C%7C%7C&sdata=lyEvF3wt91%2BQJURrZfJik%2BMLekoc5T1jGtxGh8JmJPY%3D&reserved=0',
    },
    {
      label: 'Vietnamese',
      pathToRoute:
        'https://nam11.safelinks.protection.outlook.com/?url=https%3A%2F%2Fwww.providence.org%2F-%2Fmedia%2Fproject%2Fpsjh%2Fprovidence%2Fsocal%2Ffiles%2Fnotice-of-privacy-practices%2Fnopp-vietnamese.pdf%3Fla%3Den%26rev%3Df1898c1d05724dbbbd9573b3d2270647%26hash%3D00CD196ADE462E3CE1DB9B8B4A0F8956&data=05%7C01%7CNagaVenkataRaviTeja.Gundu%40providence.org%7Cabd23bcfe75f4c33c2e808dacd34cb28%7C2e3190869a2646a3865f615bed576786%7C0%7C0%7C638047923893912961%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C3000%7C%7C%7C&sdata=v17%2Fq1NqHz9IviuzFQ%2B1GjWLq3dZatZGise%2FrQHcWt4%3D&reserved=0',
    },
  ]

  return (
    <>
      <div className="container bg-light-sun">
        <div className="header-data text-xl xl:text-3xl mb-4">
          {'Notice of Privacy Practices'}
        </div>
        <div className="mb-3">
          {`
          Trusana is an operating division within Providence. Providence St. Joseph Health and its Affiliates (collectively “PSJH”) comply with applicable Federal and State privacy and information security laws. PSJH protects all individually identifiable health information that it holds or transmits directly or through an authorized third party, in any form or media, whether electronic, paper, or oral.
          In compliance with the Health Insurance Portability and Accountability Act (HIPAA), you will receive a copy of our Joint Notice of Privacy Practices. This document provides detailed information about your rights regarding your personal and health information and how that information may be used and disclosed by Providence St. Joseph Health.
          For more information, download the full notice in the following languages:          
          `}
        </div>
        <div>
          <ul>
            {linksData.map((linkDataItem: OneBodyHyperlinksType) => {
              return (
                <li key={linkDataItem.label}>
                  <a href={linkDataItem.pathToRoute}>{linkDataItem.label}</a>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    </>
  )
}

export default PrivacyPractices
