import { Form } from 'antd'
import React, { useContext, useEffect } from 'react'

import NewSubmitCancel from '../../../common/new-form/new-buttons/new-submit-cancel'
import PhpPrimarySubscriberInformation from '../php-primary-subscriber-information/php-primary-subscriber-information'
import { PHPRegistrationContext } from '../php-registration/php-registration-middleware'

interface PhpPrimarySubscriberProps {
  onFinish: (values: any) => void
  onPrevious: () => void
}

const PhpPrimarySubscriberDetails: React.FC<PhpPrimarySubscriberProps> = ({
  onPrevious,
  onFinish,
}) => {
  const { primarySubscriberDetails } = useContext(PHPRegistrationContext)
  const [form] = Form.useForm()
  useEffect(() => {
    if (primarySubscriberDetails) {
      form.setFieldsValue({ ...primarySubscriberDetails })
    }
  }, [primarySubscriberDetails, form])

  return (
    <Form form={form} layout="vertical" onFinish={onFinish} scrollToFirstError>
      <div className="mx-4 md:ml-6 md:mr-8">
        <div className="formItems mt-4">
          <PhpPrimarySubscriberInformation />
        </div>
      </div>
      <div className="bg-white md:bg-transparent">
        <NewSubmitCancel
          shouldDisplayPrevious={true}
          isSubmit={false}
          onPrevious={onPrevious}
        />
      </div>
    </Form>
  )
}

export default PhpPrimarySubscriberDetails
