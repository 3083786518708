import { Themes } from '../../pages/EAP/EAP-constants'

const PhoneCallVector: React.FC<{ theme: Themes; isSelected: boolean }> = ({
  theme,
  isSelected = false,
}) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 8"
      xmlns="http://www.w3.org/2000/svg"
      className={`${
        theme === Themes.DARK && isSelected === false
          ? '!fill-white group-hover:!fill-shade group-focus:!fill-shade'
          : isSelected === false
          ? '!fill-shade group-hover:!fill-white group-focus:!fill-white'
          : ''
      } ${isSelected === true ? '!fill-white' : ''}`}
    >
      <path d="M3.465 6.0925C4.545 8.215 6.285 9.9475 8.4075 11.035L10.0575 9.385C10.26 9.1825 10.56 9.115 10.8225 9.205C11.6625 9.4825 12.57 9.6325 13.5 9.6325C13.9125 9.6325 14.25 9.97 14.25 10.3825V13C14.25 13.4125 13.9125 13.75 13.5 13.75C6.4575 13.75 0.75 8.0425 0.75 1C0.75 0.5875 1.0875 0.25 1.5 0.25H4.125C4.5375 0.25 4.875 0.5875 4.875 1C4.875 1.9375 5.025 2.8375 5.3025 3.6775C5.385 3.94 5.325 4.2325 5.115 4.4425L3.465 6.0925Z" />
    </svg>
  )
}
export default PhoneCallVector
