export const hasValue = (value: any) => {
  return (
    value != null && value != undefined && value.toString().trim().length > 0
  )
}

export const tryParseInt = (value: string) => {
  return isNaN(parseInt(value)) ? 0 : parseInt(value)
}

export const debounce = (func: any, timeout = 300) => {
  let timer: NodeJS.Timeout
  return (...args: any) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      func.apply(this, args)
    }, timeout)
  }
}
