import { FormInstance } from 'antd'
import React from 'react'
import { FormInvalidMessage } from '../../../common/form/form.utils'
import NewInputNum from '../../../common/new-form/new-input-number/new-input-number'
import NewInputText from '../../../common/new-form/new-input-text/new-input-text'
import { alphabetPattern } from '../../EAP/EAP-constants'
import PhpFormSectionInfo from '../php-form-section-info/php-form-section-info'

interface EmergencyContactProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: FormInstance<any>
}
const PhpEmergencyContact: React.FC<EmergencyContactProps> = ({ form }) => {
  const checkEmergencyContactName = (_cc: any, value: any) => {
    if (value.length < 2) {
      return Promise.reject(new Error(FormInvalidMessage('Name')))
    }
    if (!alphabetPattern.test(value)) {
      return Promise.reject(new Error(FormInvalidMessage('Name')))
    }
    return Promise.resolve()
  }

  const checkPhoneNumberNotEmergencyContact = (_cc: any, value: any) => {
    if (`${value}`.length < 10) {
      return Promise.reject(FormInvalidMessage('Phone Number'))
    }
    if (form.getFieldValue('phoneNumber') === value) {
      return Promise.reject(
        new Error('Emergency number cannot be the same as contact number')
      )
    }
    return Promise.resolve()
  }
  return (
    <>
      <PhpFormSectionInfo
        sectionNumber={3}
        title={'Emergency Contact'}
        description={
          'Provide the emergency contact details of the person who will be receiving care'
        }
      />
      <div className="mt-4">
        <div className="flex flex-col md:flex-row md:gap-8">
          <NewInputText
            label={'Name'}
            htmlLabel={'Emergency Contact Name'}
            pattern={alphabetPattern}
            placeholder={'Enter Name'}
            isRequired={true}
            aria-required={true}
            name={'emergencyContactName'}
            min={{
              value: 2,
              message: FormInvalidMessage('Name'),
            }}
            max={{
              value: 100,
              message: FormInvalidMessage('Name'),
            }}
            validator={checkEmergencyContactName}
          />
          <NewInputNum
            label={'Phone Number'}
            htmlLabel="Emergency Phone Number"
            placeholder={'Enter Phone Number'}
            name={'emergencyContactPhoneNumber'}
            isPhoneNumber={true}
            isRequired={true}
            aria-required={true}
            validator={checkPhoneNumberNotEmergencyContact}
          />
        </div>
      </div>
    </>
  )
}

export default PhpEmergencyContact
