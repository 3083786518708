import { Button, Spin } from 'antd'
import Form from 'antd/es/form/Form'
import moment from 'moment'
import { ChangeEvent, useContext, useEffect, useState } from 'react'
import { LoaderContext } from '../../../common'
import { FormInvalidMessage } from '../../../common/form/form.utils'
import NewInputTextArea from '../../../common/new-form/new-input-text-area/new-input-text-area'
import { Themes } from '../EAP-constants'
import {
  AppointmentPageContext,
  AppointmentType,
} from './appointment-page-middleware'

import './appointment-page.scss'
import { AppointmentTypeSelectionButton } from './appointment-type-selection'
import { TimeSlotInterface } from './interfaces/single-therapist-slot-interface'
import {
  mixpanelChangeProviderReason,
  mixpanelTrackTimeSlotClicked,
} from '../../../common/utils/mixpanel'

interface ChooseAvailableSlotsInterface {
  timeSlots: TimeSlotInterface[] | undefined
  bookAppointment: (
    date: string | Date,
    clickNextPath: string,
    providerId: string,
    providerName?: string
  ) => void
  therapistId: string
  therapistName: string
  clickNextPath: string
  closeModal: (() => void) | undefined
  isLoadingTimeSlots: boolean
  isChangeProviderFlow?: boolean
}

const ChooseAvailableSlots: React.FC<ChooseAvailableSlotsInterface> = ({
  timeSlots,
  bookAppointment,
  therapistId,
  therapistName,
  clickNextPath,
  closeModal,
  isLoadingTimeSlots,
  isChangeProviderFlow,
}) => {
  const [slotSelected, setSlotSelected] = useState<{
    slot: string
    abbr: string
  } | null>(null)
  const [selectedSlotIndex, setSelectedSlotIndex] = useState(-1)
  const selectSlot = (time: { slot: string; abbr: string }) => {
    setSlotSelected(time)
  }
  const { loading } = useContext(LoaderContext)
  const {
    appointmentType,
    updateAppointmentType,
    updateReasonForChangeProvider,
    reasonForChangeProvider,
  } = useContext(AppointmentPageContext)
  //change provider flow
  const handleReasonChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    updateReasonForChangeProvider(e.target.value)
  }

  useEffect(() => {
    setSlotSelected(null)
  }, [timeSlots])

  return (
    <Form>
      <div className="available-slot-container ant-col-24 pt-16 sm:pt-6 w-4/5 ml-auto mr-auto">
        <p className="slots-heading ml-4">Choose time</p>
        <div className="available-slot-container md:p-8">
          <div className="ant-col-24">
            {isLoadingTimeSlots ? (
              <div className="flex items-center justify-center w-full">
                <Spin size="large" className="mt-32" />
              </div>
            ) : timeSlots ? (
              timeSlots.map((timeSlot, index) => {
                return (
                  <span key={index}>
                    <Button
                      className={`text-lg available-slot-button mr-2 md:mr-4 md:ml-4 mb-4 ${
                        index === selectedSlotIndex && !!slotSelected
                          ? 'selected-slot'
                          : ''
                      }`}
                      type="primary"
                      onClick={() => {
                        selectSlot(timeSlot)
                        setSelectedSlotIndex(index)
                        mixpanelTrackTimeSlotClicked(
                          moment.parseZone(timeSlot.slot).format('LT'),
                          isChangeProviderFlow
                        )
                      }}
                    >
                      <span className="slot-text">
                        {moment.parseZone(timeSlot.slot).format('LT')}
                      </span>
                    </Button>
                  </span>
                )
              })
            ) : (
              <>No available appointments</>
            )}
          </div>
        </div>
        {/*change provider flow */}
        {isChangeProviderFlow ? (
          <NewInputTextArea
            className="h-[5rem]"
            label={'Reason for changing provider'}
            name={'reasonForChangeProvider'}
            min={{
              value: 3,
              message: FormInvalidMessage(''),
            }}
            maxLength={200}
            isRequired={true}
            onChange={handleReasonChange}
            onFocus={mixpanelChangeProviderReason}
          />
        ) : null}
        {/*change provider flow*/}
        <>
          {slotSelected && (
            <div className="slot-message-box mt-12 ">
              <p className="slots-message pt-2 text-center">
                You selected{' '}
                {moment.parseZone(slotSelected.slot).format('LLLL')}{' '}
                {slotSelected.abbr}
              </p>
            </div>
          )}
        </>
      </div>
      {/*Dekstop view*/}
      <div className="text-center mt-6 hidden sm:block">
        <div className="flex flex-col md:flex-row justify-center items-center mb-4">
          <div className="text-shade">Appointment Type</div>
          <AppointmentTypeSelectionButton
            appointmentType={AppointmentType.VIDEO_CALL}
            updateAppointmentType={updateAppointmentType}
            selectedAppointmentType={appointmentType}
            theme={Themes.LIGHT}
          />
          <AppointmentTypeSelectionButton
            appointmentType={AppointmentType.PHONE_CALL}
            updateAppointmentType={updateAppointmentType}
            selectedAppointmentType={appointmentType}
            theme={Themes.LIGHT}
          />
        </div>
        <Button
          type="primary"
          className="mr-4 cancel-button"
          onClick={closeModal}
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            bookAppointment(
              slotSelected?.slot,
              clickNextPath,
              therapistId,
              therapistName
            )
          }}
          type="default"
          className="slot-book-now-button disabled:!opacity-40"
          disabled={
            isChangeProviderFlow
              ? slotSelected === null ||
                appointmentType === AppointmentType.UNSELECTED ||
                reasonForChangeProvider.length < 3
              : slotSelected === null ||
                appointmentType === AppointmentType.UNSELECTED
          }
          loading={loading}
        >
          {loading ? 'Processing' : 'Confirm'}
        </Button>
      </div>
      {/*Mobile view*/}
      <div className="block sm:hidden disabled:opacity-40 book-now-mobile-button ">
        <div className="flex flex-col md:flex-row justify-center items-center mb-4">
          <div className="text-shade">Appointment Type</div>
          <div className="flex xs:flex-col flex-row">
            <AppointmentTypeSelectionButton
              appointmentType={AppointmentType.VIDEO_CALL}
              updateAppointmentType={updateAppointmentType}
              selectedAppointmentType={appointmentType}
              theme={Themes.LIGHT}
            />
            <AppointmentTypeSelectionButton
              appointmentType={AppointmentType.PHONE_CALL}
              updateAppointmentType={updateAppointmentType}
              selectedAppointmentType={appointmentType}
              theme={Themes.LIGHT}
            />
          </div>
        </div>
        <div className="flex flex-row gap-2 justify-center	">
          <Button
            type="primary"
            className="cancel-button bottom-2 mr-0"
            onClick={closeModal}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              bookAppointment(
                slotSelected?.slot,
                clickNextPath,
                therapistId,
                therapistName
              )
            }}
            type="default"
            disabled={
              isChangeProviderFlow
                ? slotSelected === null ||
                  appointmentType === AppointmentType.UNSELECTED ||
                  reasonForChangeProvider.length < 3
                : slotSelected === null ||
                  appointmentType === AppointmentType.UNSELECTED
            }
            className=" slot-book-now-button-mobile disabled:!opacity-40 block sm:hidden"
            loading={loading}
          >
            {loading ? 'Processing' : 'Confirm'}
          </Button>
        </div>
      </div>
    </Form>
  )
}

export default ChooseAvailableSlots
