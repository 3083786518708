import React, { FunctionComponent } from 'react'
import { CloudUploadIcon } from '../../assets'
import { globalConfig } from '../../configuration/config'

interface ImageUploadButtonProps {
  shouldDisplayUpload: boolean
  shouldDisplayImage: boolean
  onChange: (e: any) => void
}

const PhpImageUploadButton: FunctionComponent<ImageUploadButtonProps> = ({
  // shouldDisplayUpload,
  shouldDisplayImage,
  onChange,
}) => {
  const handleDragOver = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
  }
  return (
    <div data-testid="image-upload-button">
      {shouldDisplayImage === false ? (
        <div
          onDragOver={handleDragOver}
          onDrop={(e) => {
            onChange(e)
            e.preventDefault()
          }}
        >
          <label className="custom-file-upload w-full h-[10rem] inline-block py-6 px-4 lg:px-12   py-4 border-2 border-dashed border-da7f71 rounded-lg cursor-pointer mr-1 md:-ml-2">
            <input
              className="fileSelector"
              type="file"
              accept="image/png, image/jpg, image/jpeg, application/pdf"
              onChange={onChange}
            />
            <div className="text-center">
              <img src={CloudUploadIcon} />
            </div>
            <div className="text-center text-[#314259] underline font-medium text-small">
              Please upload insurance card
            </div>
            <div className="text-center text-[#DA7F71] font-normal text-xs">
              only png, jpg, pdf with max size of{' '}
              {globalConfig.get().phpInsuranceFileSize / 1048576} MB
            </div>
          </label>
        </div>
      ) : null}
    </div>
  )
}

export default PhpImageUploadButton
