const SendMail: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="16"
      viewBox="0 0 32 16"
      fill="none"
    >
      <path
        d="M31.9099 0.562049C31.7546 0.220291 31.4128 0 31.0361 0H11.5645C11.1585 0 10.8049 0.246084 10.6637 0.626982C10.6144 0.759996 10.5951 0.898409 10.606 1.03847C10.6259 1.29145 10.7439 1.52284 10.9381 1.6899L17.9217 7.69609L10.9381 13.7021C10.7437 13.8692 10.6258 14.1006 10.606 14.3535C10.5951 14.4936 10.6144 14.632 10.6637 14.765C10.8049 15.1459 11.1585 15.392 11.5645 15.392H31.0361C31.4421 15.392 31.7957 15.1459 31.9369 14.7649C31.9544 14.7175 31.9684 14.6683 31.9784 14.6185C31.9925 14.5555 31.9997 14.4906 31.9997 14.4255V0.948346C31.9997 0.813532 31.9687 0.680218 31.9101 0.562049H31.9099ZM13.7951 2.6045L12.1269 1.16984H30.4734L21.3001 9.05922L13.7949 2.6045H13.7951ZM12.1269 14.2225L18.8184 8.46733L20.6739 10.0631C20.8489 10.2136 21.0712 10.2964 21.3001 10.2964C21.529 10.2964 21.7514 10.2135 21.9263 10.0631L23.7817 8.46733L30.4734 14.2225H12.1269ZM30.8303 12.9868L24.6786 7.69609L30.8303 2.40535V12.9868Z"
        fill="white"
      />
      <path
        d="M10.3192 3.15625H0.584548C0.261711 3.15625 0 3.41809 0 3.74109V3.74124C0 4.06424 0.261711 4.32609 0.584548 4.32609H10.3192C10.6421 4.32609 10.9038 4.06424 10.9038 3.74124V3.74109C10.9038 3.41809 10.6421 3.15625 10.3192 3.15625Z"
        fill="white"
      />
      <path
        d="M14.3417 7.17969H4.60701C4.28417 7.17969 4.02246 7.44153 4.02246 7.76453V7.76468C4.02246 8.08768 4.28417 8.34952 4.60701 8.34952H14.3417C14.6645 8.34952 14.9262 8.08768 14.9262 7.76468V7.76453C14.9262 7.44153 14.6645 7.17969 14.3417 7.17969Z"
        fill="white"
      />
    </svg>
  )
}

export default SendMail
