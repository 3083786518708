import { useContext } from 'react'
import { SingleTherapistModel } from '../interfaces/therapist-model'
import LoadingCardList from '../loading-states/loading-card-list'
import NoTherapistsFound from '../loading-states/no-therapist-found'
import TherapistCardList from './therapist-list-card'
import { AppointmentPageContext } from '../appointment-page-middleware'
import {
  noTherapistsFoundForCurrentFiltersMessage,
  therapistsFoundForCurrentFiltersMessage,
} from '../../EAP-constants'
import { useLocation } from 'react-router-dom'
import { changeProviderApptPath } from '../../../../routing/route.constants'

interface TherapistListInterface {
  isSearch: boolean
  isLoadingTherapists: boolean
  therapistResponseData: SingleTherapistModel[]
  predictionData: any
  shouldDisplayRecommendations: boolean
  isResetConfirmation: boolean
  bookAppointment: (
    date: any,
    clickNextPath: string,
    therapistId: any,
    providerName?: string
  ) => void
  clickNextPath: string
  visitorId: string
  updateSelectedTherapist: (
    selectedTherapist: SingleTherapistModel | null
  ) => void
  isPhpFlow: boolean
}

const TherapistList: React.FC<TherapistListInterface> = ({
  isPhpFlow,
  isLoadingTherapists,
  therapistResponseData,
  predictionData,
  shouldDisplayRecommendations,
  isResetConfirmation,
  bookAppointment,
  clickNextPath,
  visitorId,
  updateSelectedTherapist,
}) => {
  const checkAvailability = (id: string) => {
    updateSelectedTherapist(
      therapistResponseData.find((item: { id: string }) => item.id === id) ??
        null
    )
  }
  const {
    isRetryWithoutFilters,
    selectedDaysArray,
    range,
    selectedSpecialisationItems,
    selectedPopulationItems,
  } = useContext(AppointmentPageContext)

  const location = useLocation()
  const isChangeProviderFlow = location.pathname.includes(
    changeProviderApptPath
  )

  const shouldDisplayNoFiltersAppliedMessage =
    selectedDaysArray.every((item) => item === false) &&
    range[0] === 7 * 60 &&
    range[1] === 21 * 60 &&
    selectedPopulationItems?.length === 0 &&
    selectedSpecialisationItems?.length === 0

  if (isLoadingTherapists) {
    return (
      <div className="bg-light-sun therapist-card-rows w-full">
        <LoadingCardList count={4} />
      </div>
    )
  }

  if (
    !shouldDisplayRecommendations &&
    therapistResponseData &&
    therapistResponseData.length > 0
  ) {
    /**
     * The desctiption is as follows
     * 1. When no filters are applied
     * - Here are a few recommendations based on the selected filters,
     *   ordered by earliest availability.
     *
     * 2. When filters are applied and response contains therapists
     * - Here are a few recommendations based on the selected filters,
     *   ordered by earliest availability.
     *
     * 3. When filters are applied and there are no matches
     * - Sorry, there are no therapists available at this moment for the
     *   selected state/filters. Here are a few other recommendations based
     *   on earliest availability.
     */
    const therapistCardListDescription = (
      ifNoMatchFoundForFilters: boolean,
      ifNoFilterApplied: boolean,
      ifChangeProvider: boolean
    ) => {
      if (ifNoMatchFoundForFilters) {
        return noTherapistsFoundForCurrentFiltersMessage
      } else if (ifNoFilterApplied) {
        if (ifChangeProvider) {
          return 'Here are our top recommendations'
        } else {
          return ''
        }
      } else {
        return therapistsFoundForCurrentFiltersMessage
      }
    }
    return (
      <div className="bg-light-sun therapist-card-rows w-full">
        <TherapistCardList
          predictionData={predictionData}
          description={therapistCardListDescription(
            isRetryWithoutFilters,
            shouldDisplayNoFiltersAppliedMessage,
            isChangeProviderFlow
          )}
          data={therapistResponseData}
          isResetConfirmation={isResetConfirmation}
          bookAppointment={bookAppointment}
          checkAvailability={checkAvailability}
          clickNextPath={clickNextPath}
          shouldDisplayBottomBorder={false}
          visitorId={visitorId}
        />
      </div>
    )
  }
  if (therapistResponseData && therapistResponseData.length > 0) {
    // Check if search or tag filter and show accordingly
    return (
      <div className="bg-light-sun therapist-card-rows w-full">
        <div>
          <TherapistCardList
            predictionData={predictionData}
            description={'Here are our top recommendations'}
            data={therapistResponseData.filter(
              (therapist: SingleTherapistModel) => therapist.searchType === 1
            )}
            isResetConfirmation={isResetConfirmation}
            bookAppointment={bookAppointment}
            checkAvailability={checkAvailability}
            clickNextPath={clickNextPath}
            shouldDisplayBottomBorder={true}
            visitorId={visitorId}
          />
          <TherapistCardList
            predictionData={predictionData}
            description={'Few other recommendations'}
            data={therapistResponseData.filter(
              (therapist: SingleTherapistModel) => therapist.searchType === 2
            )}
            isResetConfirmation={isResetConfirmation}
            bookAppointment={bookAppointment}
            checkAvailability={checkAvailability}
            clickNextPath={clickNextPath}
            shouldDisplayBottomBorder={false}
            visitorId={visitorId}
          />
        </div>
      </div>
    )
  }

  return <NoTherapistsFound isPhpFlow={isPhpFlow} />
}

export default TherapistList
