import React from 'react'
import {
  TrusanaWhiteLogo,
  meditationGirlImage,
  PhpQuestionMark,
  PHPBranding,
} from '../../../assets'
import './branding-section.scss'

// *******************************WIP *************************************************

const BrandingSectionMobile = () => {
  return (
    <>
      <div className="flex flex-col bg-[#3F7A88] block md:hidden  z-[200]">
        <div className="text-center">
          <img src={TrusanaWhiteLogo} className="w-1/2 mx-auto mt-4"></img>
        </div>{' '}
        <img
          src={meditationGirlImage}
          className="w-full mt-2 px-8   md:flex md:max-w-sm lg:max-w-sm xl:max-w-[25rem] 2xl:max-w-xl mx-auto "
        ></img>{' '}
        <div className="flex flex-row justify-center  my-4 gap-4 pt-2 left-section-branding-container">
          <div style={{ paddingTop: '0.2rem' }}>Brought to you by</div>
          <div>
            <img src={PHPBranding} className="left-section-php-logo" />
          </div>
        </div>
        <div
          className="text-white text-center text-[14px] mt-2  px-4 pt-2"
          style={{ borderBottom: '0.5rem solid #3F7A88' }}
        >
          <div>
            Providence uses Trusana to deliver Behavioral Health Concierge (BHC)
            services. On Trusana, members can manage appointments online,
            participate in virtual visits, access their personalized plan of
            care, and track progress.
          </div>
          <div className="mt-6 text-left">
            <ul>
              <li>
                Virtual visits are covered at no cost (HSA plans are subject to
                deductible first and then covered in full)
              </li>
              <li>
                All our mental health professionals are licensed clinical social
                workers.
              </li>
              <li>
                For more info about Trusana, click{' '}
                <a href="/welcome/support" className=" text-white underline">
                  here
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="bg-[#376A76] text-white border-t-[rgba(255,255,255,0.14)] h-[8rem] p-4">
          <div className="flex flex-row">
            <div className="flex flex-col">
              <img src={PhpQuestionMark} className="mr-2"></img>
            </div>
            <div className="flex flex-col">
              <span>Need help? Reach out to us at</span>
              <div className="underline">
                <a href="tel:+18337249355" className="text-white">
                  (833) 724-9355
                </a>
                |
                <a href="mailto:support@trusana.com" className="text-white">
                  {' '}
                  support@trusana.com{' '}
                </a>
              </div>
              <div className="mt-4 mb-4">
                ©2023 Providence. All rights reserved.
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default BrandingSectionMobile
