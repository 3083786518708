import TagDescriptionContainer from '../../../../common/tag-description-container/tag-description-container'
interface TherapistPopulationTagSectionInterface {
  populationTags: any
  matchingPopulationTags: any
}

const TherapistPopulationTagSection: React.FC<
  TherapistPopulationTagSectionInterface
> = ({ populationTags, matchingPopulationTags }) => {
  return populationTags.length > 0 ? (
    <div
      className="flex flex-row flex-container flex-wrap "
      data-testid="population-tag-section"
    >
      {matchingPopulationTags.map((tag: string, index: number) => {
        return (
          // <div
          // key={index}>
          //     {tag}
          // </div>
          <TagDescriptionContainer
            className="child"
            tag={tag}
            key={index}
            isHighlighted={true}
          />
        )
      })}
    </div>
  ) : null
}

export default TherapistPopulationTagSection
