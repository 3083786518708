import { lazy } from 'react'

export enum UserFlowType {
  EAP = 'EAP',
  PHP = 'PHP',
}
export interface IActionButtonData {
  buttonText: string
  buttonClickLink: string
}

const NewEAPFlow = lazy(
  () => import('./EAP/neweap-registration-flow/neweap-flow')
)

export { NewEAPFlow }
