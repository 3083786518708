import React, { Component, ReactNode } from 'react'
import { homePath } from '../../routing'
import ErrorBoundaryRedirectionModal from './error-boundary-redirection-modal'

type ErrorBoundaryProps = {
  fallback?: ReactNode
  children?: React.ReactElement
  history?: History
}

type ErrorBoundaryState = {
  hasError: boolean
  shouldDisplayErrorRedirectionModal: boolean
  show: boolean
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props)

    this.state = {
      hasError: false,
      shouldDisplayErrorRedirectionModal: false,
      show: false,
    }
  }

  static getDerivedStateFromError() {
    // Updating state so the next render will show the fallback UI.
    return {
      hasError: true,
      shouldDisplayErrorRedirectionModal: true,
      show: false,
    }
  }

  componentDidCatch() {
    if (process.env.NODE_ENV !== 'development') {
      setTimeout(() => this.setState({ show: true }), 5000)
    }
  }

  render(): ReactNode {
    if (this.state.hasError) {
      // We can render any custom fallback UI here
      return (
        <>
          {!this.state.show && (
            <ErrorBoundaryRedirectionModal
              shouldDisplayErrorRedirectionModal={
                this.state.shouldDisplayErrorRedirectionModal
              }
            />
          )}
          {/* Modal should redirect to home page after 5s */}
          {this.state.show && (window.location.href = homePath)}
        </>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
