import { Button } from 'antd'
import { useHistory } from 'react-router-dom'
import { ErrorPageImage } from '../assets'

const ErrorPage: React.FC = () => {
  const history = useHistory()
  return (
    <div className="flex flex-col items-center justify-center h-full mt-12 pt-12 p-2">
      <img
        src={ErrorPageImage}
        alt="A drawing of an orange bunny holding a board with a green board with a question mark"
        className="xs:max-w-[10rem] sm:max-w-[12rem] xs:p-2 sm:p-4"
      />
      <div className="xs:p-2 sm:p-4 xs:text-lg xs:font-semibold md:text-3xl sm:font-semibold text-[#74320E]">
        You&apos;ve found a page that doesn&apos;t exist
      </div>
      <div className="xs:p-4 sm:p-4 xs:text-sm sm:text-lg md:text-xl text-center">
        Take a breath and try again.
      </div>
      <Button
        type="default"
        shape="round"
        className="md:mt-4"
        onClick={() => history.push('/')}
      >
        Go to home
      </Button>
    </div>
  )
}

export default ErrorPage
