import { Button } from 'antd'
import { useContext } from 'react'
import { LoaderContext } from '../../../common/loader/loader-middleware'

interface SubmitCancelProps {
  onClear: () => void
}
const SubmitClear: React.FC<SubmitCancelProps> = ({ onClear }) => {
  const { loading } = useContext(LoaderContext)
  return (
    <>
      <div className="flex flex-row justify-center mb-12 ml-6">
        <Button
          type="primary"
          shape="round"
          className="mr-8 mt-6"
          style={{ minWidth: '9rem' }}
          onClick={onClear}
        >
          Clear
        </Button>
        <Button
          type="default"
          shape="round"
          className="mr-8 mt-6 "
          htmlType="submit"
          loading={loading}
          style={{ minWidth: '9rem' }}
        >
          {loading ? 'Processing' : 'Submit'}
        </Button>
      </div>
    </>
  )
}

export default SubmitClear
