import React from 'react'
import { Form, InputNumber } from 'antd'
import { LABEL_ALIGN_TYPE } from '../../form/form.constants'
import { formRules, Validator } from '../../form/form.utils'
import classNames from 'classnames'
import NewFormLabel from '../new-form-label/new-form-label'

interface InputNumProps {
  label: string
  htmlLabel?: string | any
  isDisabled?: boolean
  name: string
  isRequired?: boolean
  labelAlign?: keyof typeof LABEL_ALIGN_TYPE
  pattern?: RegExp
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onBlur?: (e: any) => void
  onChange?: (value: number | string | Array<number | string>) => void
  className?: string
  validateTrigger?: string | string[]
  validator?: Validator
  patternMessage?: string
  defaultValue?: number
  step?: number
  max?: number
  min?: number
  prefix?: React.ReactNode
  addonBefore?: React.ReactNode
  value?: number
  maxLength?: number
  placeholder?: string
  shouldShowDollar?: boolean
  isPhoneNumber?: boolean
}

const NewInputNum: React.FC<InputNumProps> = (props: InputNumProps) => {
  const {
    label,
    htmlLabel,
    name,
    isRequired,
    validator,
    className,
    pattern,
    patternMessage,
    defaultValue,
    step,
    max,
    min,
    isDisabled,
    labelAlign,
    validateTrigger,
    prefix,
    addonBefore,
    value,
    maxLength,
    onChange,
    isPhoneNumber = false,
    placeholder,
  } = props

  return (
    <label
      htmlFor={htmlLabel ? htmlLabel : label}
      className="php-form-element flex flex-col md:basis-1/2 font-bold text-shade "
    >
      {isRequired ? (
        <NewFormLabel label={label} />
      ) : (
        <span className="pb-2">{label}</span>
      )}
      <Form.Item
        // label={label}
        name={name}
        labelAlign={labelAlign}
        validateTrigger={validateTrigger}
        className={classNames('block php-form-element', className)}
        rules={formRules(label, isRequired, validator, pattern, patternMessage)}
        validateFirst={true}
      >
        {isPhoneNumber ? (
          <InputNumber
            disabled={isDisabled}
            step={step}
            defaultValue={defaultValue}
            max={max}
            min={min}
            maxLength={12}
            onChange={onChange}
            className={classNames(
              'form-item-input-width pt-[0.25rem]',
              className
            )}
            prefix={prefix}
            addonBefore={addonBefore}
            value={value}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            formatter={(value: any) => {
              // const temp = `${value}`
              if (value.length <= 3) {
                return value
              } else if (value.length > 3 && value.length <= 6) {
                return `${value.slice(0, 3)}-${value.slice(3)}`
              } else {
                return `${value.slice(0, 3)}-${value.slice(3, 6)}-${value.slice(
                  6,
                  10
                )}`
              }
            }}
            parser={(value) =>
              value ? parseInt(value.replace(/\$\s?|(-*)/g, '')) : ``
            }
            id={name}
            placeholder={placeholder}
          />
        ) : (
          <InputNumber
            disabled={isDisabled}
            step={step}
            defaultValue={defaultValue}
            max={max}
            min={min}
            maxLength={maxLength}
            onChange={onChange}
            className={classNames(
              'form-item-input-width pt-[0.25rem]',
              className
            )}
            prefix={prefix}
            addonBefore={addonBefore}
            value={value}
            id={name}
            placeholder={placeholder}
          />
        )}
      </Form.Item>
    </label>
  )
}

export default NewInputNum
