import React from 'react'
import './terms-and-conditions.scss'

const TermsOfUse = () => {
  return (
    <>
      <div className="container bg-light-sun w-full terms-of-use-container">
        <div className="text-xl xl:text-4xl  mb-5">
          Terms of Use &amp; Privacy Policy
        </div>
        <div className="mb-3">
          <TermsOfUseBody />
        </div>
      </div>
    </>
  )
}

const TermsOfUseBody = () => {
  return (
    <>
      <section id="heading">
        <div>
          Trusana is a trade name for Providence Behavioral Health Concierge
          (BHC). Providence operates a system of hospitals, physician clinics,
          home care and other health care providers, a health plan, and
          associated services in the states of Alaska, California, Idaho,
          Montana, New Mexico, Oregon, Texas and Washington. In order to better
          serve its patients and beneficiaries and to help educate individuals
          and the general public about health care and medical issues,
          Providence owns and operates the Trusana Website.
        </div>
        <div>
          Providence provides online educational, communications, and virtual
          health and wellness services and tools which are described below.
          (These are called &ldquo;Services&rdquo; below.) Providence has
          published these Terms of Use and Privacy Policy to inform Users of
          their rights and responsibilities in using the Trusana Web site.
          <ol>
            <li>
              Trusana Web site&#160;
              <a href="/terms/termsofuse#terms-of-use">Terms of Use</a>
            </li>
            <li>
              Trusana Web site&#160;
              <a href="/terms/termsofuse#privacy-policy">Privacy Policy</a>
            </li>
          </ol>
        </div>
      </section>
      <div>
        {/** Terms of use */}
        <section id="terms-of-use">
          <span className="text-xl xl:text-3xl mb-4">Terms of Use</span>
          <div>
            Please note that these Terms of Use and linked policies only apply
            to the Trusana Web site. Providence provides links to third-party
            websites that are not operated by Providence. Providence users who
            link to a third-party Web site must recognize that our Terms of Use
            and other policies do not extend beyond the borders of the Trusana
            Web site. Other standards and policies may apply outside our Web
            site. We encourage you to read the terms of use, privacy policies
            and other policies which apply to these third-party websites before
            using them. Providence offers personalized, interactive tools to
            help you manage your health care through the Trusana site. Please
            read these Terms of Use carefully. If you have any questions about
            these Terms of Use, then please&#160;support@trusana.com. We
            encourage you to review these Terms of Use regularly as you use the
            Providence Web site.
          </div>
          <ol>
            <li>
              Services Available Through the Trusana Web site A variety of
              information and services are made available through the Trusana
              Web site, including:
              <ul>
                <li>
                  Educational information, including links to informative
                  third-party websites.
                </li>
                <li>
                  Information and news about Providence services health care
                  providers and employment opportunities
                </li>
                <li>
                  Online registration and engaging in virtual health and
                  wellness services
                </li>
                <li>
                  Using a web and mobile based platform to record your health
                  and wellness progress and goals
                </li>
              </ul>
            </li>
            <li>
              Personal Information Collection and Use
              <div>
                Providence understands that the privacy of your personal
                information is important. For information about cases in which
                personal information may be collected by Providence, and details
                about how that personal information is collected and used by
                Providence, please review our&#160;
                <a href="/terms/termsofuse#privacy-policy">Privacy Policy</a>.
              </div>
            </li>
            <li>
              <span>User Responsibilities</span>

              <div>
                The Trusana Web site is intended to help you be a
                better-informed health care services user and get access to
                Providence information and services easily, but we need your
                help to make it work well. You acknowledge that you must be 13
                years or older to provide personal information to Providence
                through the Trusana Web site, and you represent that you are 13
                years or older if you do so. We also need you to be responsible
                for doing your part to protect your personal information, and to
                understand that the educational information, tools and materials
                made available through the Trusana Web site are not and do not
                include medical advice, and are not designed or intended to
                address your personal health condition or issues. Users
                therefore must be responsible for the following:
              </div>
              <ol>
                <li>
                  <span>Be an Informed User</span>
                  Please read these Terms of Use carefully. We need your help in
                  making the Trusana Web site a safe and effective tool, and
                  protecting it against misuse. Your violation of these Terms of
                  Use is grounds for suspension or termination of your account.
                </li>
                <li>
                  <span>Maintain and Protect Your Own Equipment</span>

                  <div>
                    The Trusana Web site is intended to be accessible and all
                    content available through all standard commercial web
                    browsers operating on standard personal computers, through
                    standard commercially-available Internet access. The quality
                    of content presentation and download speeds may vary
                    depending on the software, equipment and Internet
                    connections used.
                  </div>
                  <div>
                    You may be able to use devices other than a personal
                    computer to access the Providence Web site, such as some
                    personal digital assistants, cell phones or other devices.
                    If you do choose to use another kind of device to access the
                    Trusana Web site, you must be responsible for protecting it
                    in accordance with these Terms of Use. Any requirement for
                    personal computer protection in these Terms of Use applies
                    equally to any other device you may use to access the
                    Trusana Web site.
                  </div>
                  <ul>
                    <li>
                      Providence does not provide any software, equipment or
                      Internet connections. You are solely responsible for
                      providing all software, computer equipment and other
                      hardware, and Internet connections necessary to access the
                      Providence Web site.
                    </li>
                    <li>
                      Some features on the Trusana Web site allow you to create
                      an online account. Your personal computer may store and
                      automatically upload your user name and password for
                      account access. You may also download information from the
                      Trusana Web site to your personal computer or other
                      devices. If someone else uses your personal computer or
                      other devices, they may be able to use these functions to
                      gain access to your account or personal information.
                      Providence cannot prevent or be responsible for protecting
                      your personal computer or other devices against
                      unauthorized use. You are solely responsible for
                      protecting the personal computers or other devices you use
                      against unauthorized use to access your account.
                    </li>
                    <li>
                      If you use a personal computer or other device provided by
                      or shared with your employer, parent, spouse, roommate or
                      other person, that other party may have the legal right to
                      view all information stored on it. Providence cannot
                      prevent or be responsible for such activities. If you use
                      a personal computer or other device that another person
                      has the legal right to control, you access your Providence
                      account at your own risk.
                    </li>
                    <li>
                      Whenever you are done using the Trusana Web site you
                      should log off the Web site, and you should not leave your
                      computer or other device unattended when it is logged on
                      to the Trusana Web site and someone else may be able to
                      view the screen or use the computer or other device. If
                      you leave a Trusana Web site session logged-in when
                      someone else can see your computer screen or use your
                      personal computer or other device, they may be able to
                      gain access to your account or personal information.
                      Providence cannot log you off your personal computer or
                      protect your personal computer against unauthorized
                      access. You are solely responsible for protecting the
                      personal computers or other devices you use against
                      unauthorized use to access your account.
                    </li>
                    <li>
                      Computer viruses, worms, spyware and other malicious
                      software on your computer can be used to gather passwords
                      and personal information from that computer, often without
                      the user&rsquo;s knowledge. Providence cannot prevent or
                      be responsible for detecting or removing malicious
                      software on computers you use to access the Trusana Web
                      site. Affordable antivirus and antispyware software is
                      readily available for personal computers. You should
                      maintain and regularly use current antivirus and
                      antispyware software on any personal computer you use to
                      access the Trusana Web site.
                    </li>
                    <li>
                      Unauthorized individuals (&ldquo;hackers&rdquo;) and
                      malicious software can sometimes gain access to computers
                      through Internet connections, unless the computer has a
                      working firewall. Unauthorized access of this kind may not
                      be easily detected and may allow passwords and personal
                      information to be gathered without the computer
                      user&rsquo;s knowledge. Providence does not provide and
                      cannot be responsible for implementing a firewall for your
                      computer. Firewall software is standard on many personal
                      computers and affordable firewall software for personal
                      computers is readily available. You should maintain a
                      working firewall on any computer you use to access the
                      Trusana Web site.
                    </li>
                    <li>
                      Computer criminals may sometimes send e-mails that appear
                      to come from legitimate organizations. These e-mails
                      typically ask you to log on to a Web site using a link in
                      the e-mail, and enter some personal information to
                      &ldquo;verify,&rdquo; &ldquo;correct&rdquo; or
                      &ldquo;update&rdquo; your account, or something similar.
                      This practice is known as &ldquo;phishing,&rdquo; and is
                      intended to get personal information from unwary users to
                      use for identity theft and other fraud.
                      <br />
                      <span className="uppercase">
                        IN ORDER TO HELP AVOID THE THREAT OF PHISHING,
                        PROVIDENCE WILL NEVER SEND YOU UNSOLICITED E-MAILS THAT
                        INCLUDE A LINK TO A TRUSANA WEB SITE WHERE YOU WILL BE
                        ASKED TO PROVIDE PERSONAL INFORMATION. IF YOU RECEIVE AN
                        E-MAIL THAT CLAIMS TO BE FROM PROVIDENCE WHICH INCLUDES
                        A LINK TO A WEB SITE WHICH APPEARS TO BE OPERATED BY
                        PROVIDENCE, OR BY ANY PARTY ON BEHALF OF PROVIDENCE, AND
                        WHICH ASKS YOU TO PROVIDE PERSONAL INFORMATION, PLEASE
                        DO NOT PROVIDE THAT PERSONAL INFORMATION.
                      </span>
                      <br />
                      If you receive any suspicious e-mail which claims or
                      appears to be from Providence, please notify us by calling
                      the Providence Integrity Line at 1-888-294-8455.
                    </li>
                  </ul>
                </li>
                <li>
                  <span>Protect Your Password</span>

                  <div>
                    Access to your account and to personal information about you
                    is password-protected. If your password is easy to guess, or
                    if you share your password or someone else sees or steals
                    it, someone else may be able to get access to your account.
                    Please use a strong password, and protect it from other
                    people. Providence relies upon your password in allowing
                    access to your account and cannot prevent unauthorized
                    access if your password is compromised. You are responsible
                    for all activity taking place under your password unless we
                    have been notified that it is compromised. Please notify us
                    at once if you believe your password has been compromised,
                    and we will help you change it. You can contact us about
                    possible password compromise at the Providence Integrity
                    Line, 1-888-294-8455.
                  </div>
                </li>
                <li>
                  <span>Do Not Provide Third Party Services</span>

                  <div>
                    You are not permitted to use the Trusana Web site to provide
                    or support informational, educational, messaging or other
                    services to other parties. The information and services made
                    available by and through the Trusana Web site are for
                    personal use only.
                  </div>
                </li>
                <li>
                  <span>
                    Do Not Distribute Copyrighted Materials for Commercial Use
                  </span>

                  <div>
                    Most of the information and materials made available by and
                    through the Trusana Web site is copyrighted and owned by
                    Providence or other parties. You may use this information
                    and materials for your own personal use, and may make
                    limited use of it for nonprofit educational purposes to the
                    extent permitted by applicable law. You may not sell or
                    modify any information or materials obtained from Providence
                    or reproduce, display, distribute, or otherwise use it in
                    any way for any public or commercial purpose. Use of
                    information or materials obtained from Providence on any
                    other Web site or in a networked environment is prohibited.
                    You must retain all copyright and other proprietary notices
                    contained in the original information or materials on any
                    copy you make of such material.
                  </div>
                </li>
                <li>
                  <span>Do Not Abuse Providence Services</span>
                  <div>
                    The Trusana Web site is provided for your convenience and
                    educational use. In order to keep these services working
                    efficiently and appropriately, you are not permitted to:
                  </div>
                  <ul>
                    <li>Use any other person&rsquo;s user name or password.</li>

                    <li>
                      Use the Trusana Web site in any way that could damage,
                      disable, overburden, or impair the Trusana Web site or
                      interfere with any other party&apos;s use of the Trusana
                      Web site.
                    </li>
                    <li>
                      Attempt to gain unauthorized access to any information,
                      documents, records, accounts, devices, systems or networks
                      connected to Providence through hacking, password
                      cracking, IP spoofing or any other means.
                    </li>
                    <li>
                      Restrict or inhibit anyone else from using the Providence
                      Web site.
                    </li>
                    <li>
                      Harvest or otherwise collect information about other
                      individuals, including e-mail addresses and personal
                      information.
                    </li>

                    <li>
                      Link to, frame or use any trademark, logo or other
                      proprietary information on the Providence Web site without
                      Providence&rsquo;s express written permission.
                    </li>
                  </ul>
                </li>
              </ol>
            </li>
            <li>
              <span>Health and Medical Educational Information</span>
              The Trusana Web site is used to publish information and tools
              about health issues and general medical care options to help
              educate users and increase public awareness. Some of these tools
              and information are provided by parties other than Providence, and
              Providence may provide links to third-party websites or
              incorporate such tools and information into the Providence Web
              site for this purpose.
              <ol>
                <li>
                  Information and Tools Are Not Medical Advice or Personal
                  Health Information
                </li>

                <div>
                  {' '}
                  All tools, information and links to information Web sites
                  provided by or through Providence are for educational purposes
                  only or for the purpose of providing information about point
                  of care options. You are responsible for your understanding
                  and use of all information.
                </div>

                <span className="uppercase">
                  INFORMATION PROVIDED BY OR THROUGH PROVIDENCE IS NOT
                  DIAGNOSTIC, MEDICAL ADVICE OR A SUBSTITUTE FOR MEDICAL ADVICE.
                  IF YOU ARE SEEKING INFORMATION BECAUSE YOU BELIEVE YOU OR
                  ANOTHER PERSON HAS HAVE A SPECIFIC MEDICAL CONDITION PLEASE
                  CONTACT YOUR HEALTH CARE PROVIDER. IF YOU HAVE REASON TO
                  BELIEVE THERE IS A MEDICAL EMERGENCY PLEASE CALL
                  &ldquo;911&rdquo; OR YOUR OTHER APPLICABLE EMERGENCY NUMBER AT
                  ONCE. YOU SHOULD ALWAYS SEEK THE ADVICE OF YOUR HEALTH CARE
                  PROVIDER WITH ANY QUESTIONS YOU MAY HAVE REGARDING A MEDICAL
                  CONDITION. YOU SHOULD NEVER DISREGARD THE ADVICE OF A HEALTH
                  CARE PROVIDER OR CANCEL AN APPOINTMENT WITH A HEALTH CARE
                  PROVIDER BECAUSE YOU ARE RELYING ON INFORMATION YOU HAVE READ
                  ON THE PROVIDENCE WEB SITE.
                </span>

                <div>
                  While Providence seeks to provide information which may be
                  helpful in understanding your own health care needs and
                  issues, none of the educational information, events, or
                  activities available through the Trusana Web site was or will
                  be developed for you personally. If you want or need
                  information related to your personal health condition, please
                  contact your health care provider.
                </div>

                <li>Information and Links are Not Necessarily Endorsed</li>

                <div>
                  The fact that information is published or a is link provided
                  by Providence does not mean that the views and opinions
                  expressed therein are necessarily shared by Providence.
                  Providence is a ministry of the Roman Catholic Church and does
                  not recommend, endorse or promote any medical procedures or
                  interventions which are prohibited by the Ethical and
                  Religious Directives for Catholic Health Care Services.
                </div>

                <li>Information and Tools Are Not Guaranteed</li>

                <div>
                  While Providence seeks to provide useful, high-quality
                  information and tools through the Trusana Web site, Providence
                  cannot guarantee that any information is accurate, complete,
                  current, error-free, or free of viruses, worms or other
                  malicious software. Information and tools are provided
                  &ldquo;as is,&rdquo; without warranties of any kind, express
                  or implied, including but not limited to warranties of fitness
                  and suitability for any particular purpose.
                </div>

                <li>Linked Websites Are Not Endorsed</li>

                <div>
                  Providence seeks to link only to trustworthy, valuable Web
                  sites, but provides Web site links for your convenience only.
                  A link to a Web site does not constitute an endorsement of the
                  linked site, its content, or any party affiliated with the
                  linked Web site. Providence makes no warranties or
                  representations, express or implied, about the accuracy,
                  completeness, currency, relevance, appropriateness, or
                  availability of any linked site, the information contained in
                  them, or any products or services they offer. Providence shall
                  not be liable, directly or indirectly, for any damages, losses
                  or harm incurred in connection with the use of a linked Web
                  site.
                </div>

                <li>Jurisdictional Limitation of Use</li>

                <div>
                  The Trusana Web site originates and is intended for use in the
                  United States. Providence does not claim that any tools,
                  information or materials on this Web site are appropriate or
                  may be used outside of the United States. Access to tools,
                  information or materials may not be legal by certain persons
                  or in certain countries. If you access the site from outside
                  the United States, you do so at your own risk and are
                  responsible for compliance with the laws of your jurisdiction.
                </div>
              </ol>
            </li>
            <li>
              Changes to Terms of Use Providence reserves the right to change
              these Terms of Use from time to time. We will make reasonable
              attempts to notify you of changes we think may be important to you
              by e-mail. All changes will be effective when published unless a
              different effective date is specified. Your continued use of the
              Trusana Web site after we have posted a change to these Terms of
              Use will be considered your consent to the change.
            </li>
            <li>
              Services Availability and Limitations Providence will attempt to
              keep the Trusana Web site available at all times, but availability
              may be interrupted from time to time for maintenance, repair,
              upgrade or other activities, due to technical problems, or due to
              natural disaster or other emergency. Providence is not responsible
              for and cannot control the availability of third party
              communications services necessary to connect to the Trusana Web
              site.
            </li>
            <li>
              Digital Millennium Copyright Act Provisions Owners of copyrighted
              works who believe that their rights under U.S. copyright law have
              been infringed by a use of Providence may report an alleged
              infringement to Providence by following the relevant provisions of
              the Digital Millennium Copyright Act of 1998 (the
              &quot;DMCA&quot;) rights, by sending Providence&rsquo; authorized
              agent a notification of alleged infringement that satisfies the
              requirements of the DMCA. Upon receipt of a satisfactory notice of
              alleged infringement Providence will promptly investigate the
              allegations, and respond appropriately, including removal of or
              blocking access to allegedly infringing content.
              <ol>
                <li>
                  If you are affected by the removal or blocking of allegedly
                  infringing content which you in good faith believe has been
                  removed or blocked in error, you may send a counter
                  notification to Providence. Upon Providence&rsquo;s receipt of
                  a counter-notification that satisfies the requirements of
                  DMCA, Providence will provide a copy of the counter
                  notification to the person who sent the original notification
                  of alleged infringement and will follow the DMCA&apos;s
                  procedures with respect to a received counter notification.
                </li>
                <li>
                  DMCA notifications of alleged infringement and
                  counter-notifications may be sent to Providence Health &amp;
                  Services, Department of Legal Affairs, 1801 Lind Avenue SW,
                  Suite 9016, Renton, WA 98057-9016
                </li>
                <li>
                  Providence will not be a party to any disputes or lawsuits
                  regarding alleged copyright infringement.
                </li>
              </ol>
            </li>
            <li>
              <div>Limitation of Liability </div>
              <span className="uppercase">
                TO THE GREATEST EXTENT PERMITTED BY LAW, PROVIDENCE&rsquo;S
                LIABILITY TO ANY PERSON FOR ANY CLAIMS, LOSSES, DAMAGES, HARM OR
                COSTS ARISING FROM OR PERTAINING TO THE USE, OPERATION OR
                FUNCTIONING OF THE PROVIDENCE WEB SITE, WHETHER ARISING FROM OR
                PERTAINING TO BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE),
                WARRANTY OR ANY OTHER LEGAL THEORY, SHALL BE LIMITED TO THE
                PERSON&rsquo;S DIRECT MONETARY DAMAGES AND SHALL NOT INCLUDE ANY
                INDIRECT, SPECIAL, INCIDENTAL, NON-ECONOMIC, CONSEQUENTIAL,
                PUNITIVE OR EXEMPLARY DAMAGES (INCLUDING BUT NOT LIMITED TO
                DAMAGES RELATED TO DELAYS, LOSS OF DATA, INTERRUPTION OF
                SERVICE, LOSS OF PRIVACY, REPUTATIONAL OR PHYSICAL HARM), EVEN
                IF PROVIDENCE HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
                DAMAGES.
              </span>
            </li>
            <li>
              Dispute Resolution These Terms of Use shall be interpreted
              consistently with federal law applicable to the parties, including
              but not limited to HIPAA, provided that state law issues shall be
              exclusively interpreted according to the laws of the State of
              Washington, without regard to choice of law principles.
              Jurisdiction and venue for any dispute arising from or pertaining
              to these Terms of Use shall be in the Superior Court of the State
              of Washington for King County or the U.S. District Court for the
              District of Western Washington. If any provision of these Terms of
              Use is found to be invalid by any court having competent
              jurisdiction, the invalidity of such provision shall not affect
              the validity of the remaining provisions of these Terms of Use,
              which shall remain in full force and effect. No waiver of any of
              these Terms of Use shall be deemed a further or continuing waiver
              of such term or any other term.
            </li>
            <li>
              Contact Information Providence Health &amp; Services Office of
              Legal Affairs 1801 Lind Avenue SW Renton, WA 98057-9016
            </li>
          </ol>
        </section>
        {/** Privacy Policy */}
        <section id="privacy-policy">
          <span className="text-xl xl:text-3xl mb-4">Privacy Policy</span>
          <div>
            Providence Health & Services (called “Providence” in this Privacy
            Policy) is committed to honoring the privacy of individuals who use
            the Trusana platform (including the Trusana web site and mobile
            applications). This Privacy Policy is intended to make you aware of
            the ways your personal information collected through the Trusana
            platform will be used and to explain to you how we collect, use, and
            share your personal information. We hope this Privacy Policy will
            help you make informed decisions about sharing personal information
            with us.
          </div>
          <ol>
            <li>
              <div>Services Subject to Notice</div>
              Providence provides a variety of information and communication
              services (“Services”). Some information and services are available
              to the general public.
              <ol>
                <li>
                  This Privacy Policy applies to personal information received
                  or created by Providence from the following Providence
                  Services:
                  <ul>
                    <li>
                      Access to online registration and engaging in virtual
                      health and wellness services
                    </li>
                    <li>
                      Access a web and mobile based platform to record your
                      health and wellness progress and goals
                    </li>
                    <li>
                      Access to educational information, including links to
                      informative Web sites.
                    </li>
                    <li>
                      Access to information and news services about Providence
                      services, health care providers, and employment
                      opportunities.
                    </li>
                  </ul>
                </li>
                <li>
                  This Privacy Policy does not apply to personal information
                  received or created by Providence from any other Providence
                  Services including:
                  <ul>
                    <li>
                      PHP Member Services. If you wish to review the Notice of
                      Privacy Practices which applies to PHP Member Services,
                      then{' '}
                      <a
                        href="https://healthplans.providence.org/about-us/privacy-notices-policies/notice-of-privacy-practices/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        please see this Web page.
                      </a>
                    </li>
                    <li>
                      Providence Doctor&apos;s Office Services. Personal
                      information received or created through this service is
                      subject to the Notice of Privacy Practices applicable to
                      the health care provider with whom you have enrolled for
                      this service. If you wish to review the Notice of Privacy
                      Practices applicable to Providence Health & Services
                      health care providers, including those participating in
                      Providence Doctor&apos;s Office Services, then{' '}
                      <a
                        href="https://www.providence.org/utility-pages/notice-of-privacy-practices"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        please see this Web page.
                      </a>
                    </li>
                  </ul>
                </li>
              </ol>
            </li>
            <li>
              <span>Use and Disclosure of Personal Information</span>
              <div>
                {' '}
                Providence collects and uses personal information subject to
                this Privacy Policy as follows:
              </div>
              <ul>
                <li>
                  The Trusana platform lets users access information stored in
                  their electronic health record (EHR), including activities,
                  notes and visit summaries. The app also lets users send
                  messages, schedule appointments, and conduct telehealth calls.
                </li>
                <li>
                  When you choose to view documents (such as letters or images)
                  using our mobile app, to make the files viewable for you we
                  temporarily store copies on your device in app-private
                  storage. The temporary copies are deleted when you close your
                  session on our mobile apps.
                </li>
                <li>
                  Our mobile app will ask for access to File, Photos and Media
                  to allow users to upload or download documents and message
                  attachments in correspondence with the patient&apos;s health
                  care team. Providence may store uploaded documents in your
                  electronic medical record.
                </li>
                <li>
                  Our mobile app will ask for access to camera and microphone to
                  allow users to access video-based virtual care with their
                  health care provider.
                </li>
                <li>
                  Our app uses a phone number to send patients an SMS to
                  coordinate care with their healthcare provider.
                </li>
                <li>
                  We have an option to disable the user account within the app.
                  As a result of account disabling via our app, your medical
                  record will not be deleted by Providence and will remain
                  available. Users who are patients can always re-enable their
                  account to re-obtain access to your medical records.
                </li>
                <li>
                  While you use our app, we collect non-identifying information
                  so we can provide customer service to you to understand how
                  people use our mobile app so we can improve our products. This
                  information includes the time you began using the app, any
                  error messages or codes, the model of device used and its
                  operating system, and the version of our mobile app used.
                </li>
                <li>
                  If you contact us, we may keep a record of the communication.
                  You can decide how much information you want to share with us
                  in those cases.
                </li>
                <li>
                  Providence will not disclose any personal information obtained
                  through the Trusana site for associated with marketing
                  purposes.
                </li>
                <li>
                  Providence will not collect or use personal information about
                  your use of the Services to obtain educational information,
                  news, information about Providence services, activities and
                  classes, or career or employment opportunities, to make
                  decisions about insurance underwriting or coverage, medical
                  care or treatment, or administration of health care for
                  individuals. Providence will not use such information to infer
                  or make any determinations about an individual&apos;s health
                  condition or medical needs. Providence may use personal
                  information collected during your registration for Trusana, or
                  provided by you in communicating with Providence about your
                  Trusana account, to administer your Trusana account.
                </li>
                <li>
                  Providence may use personal information, to administer such
                  services or activities.
                </li>
                <li>
                  The Trusana platform collects information from user entered
                  activities, forms and actions such as viewing information and
                  educational content. Data from these activities may be
                  recorded on your medical record and used by your provider in
                  the delivery of your care.
                </li>
                <li>
                  Providence will disclose personal information when required to
                  do so by law, for example, in response to a court order or a
                  subpoena or other legal obligation, in response to a law
                  enforcement agency&apos;s request, or in special cases when we
                  have reason to believe that disclosing this information is
                  necessary to identify, contact or bring legal action against
                  someone who may be causing injury to or interference (either
                  intentionally or unintentionally) with our rights or property.
                </li>
              </ul>
            </li>
            <li>
              <span>Use of Cookies</span>

              <div>
                A “cookie” is a small set of computer data that allows Web
                servers, like the Web servers used to host the Providence site,
                to maintain records of the activities of users visiting Web site
                and to help us serve you better by improving our Web site
                design. Cookies may be used to customize the delivery of content
                based on your usage patterns and interests. The cookies used by
                Providence do not identify users by name or any other personal
                identifier. Providence uses cookies as follows:
              </div>
              <ul>
                <li>
                  Unregistered users may have cookies placed on their computers
                  for use in customizing the delivery of content based on usage
                  patterns and interests.
                </li>
                <li>
                  Registered users may have cookies placed on their computers to
                  allow their experience of the Providence site to be customized
                  and to provide aggregate information about the use of the
                  website. For example, a class registration form may have the
                  User&apos;s name and address entered as a convenience to the
                  User.
                </li>
                <li>The Trusana App does not use cookies.</li>
              </ul>
            </li>
            <li>
              <span>Third-Party Advertising Partners</span>

              <div>We currently do not use any third-party advertisers.</div>
              <div>
                Some Internet browsers include the ability to transmit &quot;Do
                Not Track&quot; signals. As uniform standards have not been
                adopted, we do not currently respond to browser &quot;do not
                track&quot; signals. For more information regarding “Do Not
                Track”, please visit &quot;
                <a
                  href="https://www.allaboutdnt.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  All About Do Not Track.
                </a>{' '}
                &quot;
              </div>
            </li>
            <li>
              <span>Changes to this Privacy Policy </span>
              <div>
                Providence reserves the right to change this Privacy Policy from
                time to time. We will make reasonable attempts to notify you of
                changes we think may be important to you by e-mail.
              </div>
              <div>
                All changes will be effective when published unless a different
                effective date is specified. Your continued use of the Trusana
                platform after we have posted a change to this Privacy Policy to
                the Trusana Web site will be considered your consent to the
                change.
              </div>
            </li>
            <li>
              <span>Relationship to Terms of Use </span>
              <div>
                The provisions of the Trusana Web Site Terms of Use apply to and
                are incorporated in this Privacy Policy by reference. You may
                review the{' '}
                <a href="/terms/termsofuse#terms-of-use">Terms of Use</a> above.
              </div>
            </li>
          </ol>
        </section>
      </div>
    </>
  )
}

export default TermsOfUse
