import { Col, Row } from 'antd'
import React from 'react'
import './single-therapist-card.scss'

interface TherapistBioModelContentProps {
  image?: string
  name: string
  ex: string
  description: string
}

const TherapistBioModelContent: React.FC<TherapistBioModelContentProps> = (
  props
) => {
  const { image, name, ex, description } = props
  return (
    <Row className="ant-col-24 therapist-bio-modal-content__wrapper">
      <Col className="inline-flex">
        <span className="float-left">
          <img
            alt={`${name} + therapist-bio-avatar`}
            className="therapist-bio-avatar"
            src={image}
          ></img>
        </span>
        <span className="doctor-details w-full float-left pl-2 w-4/5">
          <p className="font-bold doctor-name mb-0">{name}</p>
          <div className="w-full inline-flex mb-4 break-all">
            <span className="doctor-ex">{ex}</span>
          </div>
        </span>
      </Col>
      <Col>
        <p className="font-normal text-xs leading-4 tracking-normal text-start therapist-bio-description-style pt-3">
          {description}
        </p>
      </Col>
    </Row>
  )
}

export default TherapistBioModelContent
