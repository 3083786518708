import { Button, Card, Row, Tabs } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import {
  getAvailableDateAndSlots,
  getDateToDisplay,
} from '../../../common'
import './appointment-page.scss'
import NoSlotAvailable from './loading-states/no-slot'
// import { EAPRegistrationContext } from '../registration/eap-registrationMiddleware'
import { useLocation } from 'react-router-dom'
import {
  mixpanelTrackMoreDatesClicked,
  mixpanelTrackTimeSlotClicked,
} from '../../../common/utils/mixpanel'
import { changeProviderApptPath } from '../../../routing/route.constants'
import { EarliestTimeSlotsInterface } from './interfaces/earliest-timeslots-interface'
import { FetchingAvailableAppointments } from './loading-states/loading-slots'
import useScreenWidth from '../../../common/hooks/use-screen-width'

const EarliestTimeSlots: React.FC<EarliestTimeSlotsInterface> = ({
  handleSlotSelection,
  checkAvailability,
  doctorId,
  doctorName,
  isCurrentProviderCard = false,
  isLoadingTherapistsSlots,
  therapistSlotsData,
  therapistavailableTimeSlots,
}) => {
  const { TabPane } = Tabs
  
  
  const location = useLocation()
  const isChangeProviderFlow = location.pathname.includes(
    changeProviderApptPath
  )

  const getTimeSlotAvailabilityCardHeader = () => {
    return (
      <p className="my-2">
        <span className="font-medium text-xs leading-4 text-black tracking-normal">
          Earliest Availablity &nbsp;
        </span>
        <span className="font-normal leading-4 tracking-normal time-slot-heading-style">
          (Click on time to book)
        </span>
      </p>
    )
  }

  if (isLoadingTherapistsSlots) {
    return <FetchingAvailableAppointments />
  }

  if (Object.keys(therapistSlotsData).length === 0)
    return <NoSlotAvailable therapistName={doctorName} />

  return (
    <>
      {/** Time slots in Dekstop view */}
      <div className="therapist-time-section hidden md:flex w-full md:justify-center min-h-[15rem]">
        <Card bordered={false} className=" w-full ml-4 pl-2">
          {getTimeSlotAvailabilityCardHeader()}
          <div className="flex flex-col">
            {Object.keys(therapistavailableTimeSlots).map((item: any) => {
              const dateToDisplay = getDateToDisplay(item)
              return (
                <div key={item} className="inline-flex">
                  <>
                    <div
                      className="text-lg leading-5 tracking-normal time-slot-date-label-style inline-block pt-1"
                      key={item}
                    >
                      {dateToDisplay}
                    </div>
                    <div className="ml-4 flex flex-wrap time-slot-style">
                      {therapistavailableTimeSlots[item].map(
                        (timeSlot: any) => {
                          return (
                            <div
                              className="buttons-position mr-4 mb-2"
                              key={timeSlot.slot}
                              onClick={() => {
                                if (isCurrentProviderCard) return
                                mixpanelTrackTimeSlotClicked(
                                  moment.parseZone(timeSlot.slot).format('LT'),
                                  isChangeProviderFlow
                                )
                              }}
                            >
                              <Button
                                className="text-lg time-slot-button"
                                type="primary"
                                disabled={isCurrentProviderCard}
                                onClick={() =>
                                  handleSlotSelection(true, item, timeSlot)
                                }
                                data-testid={`btn-time-slot-${moment
                                  .parseZone(timeSlot.slot)
                                  .format('LT')}`}
                              >
                                <p className="relative time-slot-button-label-style">
                                  {moment.parseZone(timeSlot.slot).format('LT')}
                                </p>
                              </Button>
                            </div>
                          )
                        }
                      )}
                    </div>
                  </>
                </div>
              )
            })}
            <Row>
              <Button
                className="mt-4 col-start-2 col-span-3 mb-4 ml-[100px] w-full check-availability-button"
                type="default"
                block
                size="large"
                onClick={() => {
                  checkAvailability(doctorId)
                  mixpanelTrackMoreDatesClicked()
                }}
                disabled={isCurrentProviderCard}
              >
                More dates
              </Button>
            </Row>
          </div>
        </Card>
      </div>
      {/** Time slots in mobile view */}
      <div className="float-left time-slot-mobile-view-style w-full overflow-hidden">
        <div className="ant-col-24 w-full">
          {getTimeSlotAvailabilityCardHeader()}
          <div className="time-slot-mobile-view">
            <Tabs>
              {Object.keys(therapistavailableTimeSlots).map(
                (currentKey: any) => {
                  const dateToDisplay = getDateToDisplay(currentKey)
                  return (
                    <TabPane tab={dateToDisplay} key={currentKey}>
                      <div className="parent">
                        <div className="magic-wrapper">
                          <div className="content-wrapper magic mr-10">
                            {therapistavailableTimeSlots[currentKey].map(
                              (timeSlot: any) => {
                                return (
                                  <div
                                    key={timeSlot.slot}
                                    onClick={() => {
                                      if (isCurrentProviderCard) return
                                      mixpanelTrackTimeSlotClicked(
                                        moment
                                          .parseZone(timeSlot.slot)
                                          .format('LT'),
                                        isChangeProviderFlow
                                      )
                                      handleSlotSelection(
                                        true,
                                        currentKey,
                                        timeSlot
                                      )
                                    }}
                                    className="time-slots-style mr-4 px-4 py-2"
                                    aria-disabled={isCurrentProviderCard}
                                  >
                                    {moment
                                      .parseZone(timeSlot.slot)
                                      .format('LT')}
                                  </div>
                                )
                              }
                            )}
                          </div>
                        </div>
                      </div>
                    </TabPane>
                  )
                }
              )}
            </Tabs>
            <div className="mt-2 text-center">
              <span
                onClick={() => {
                  if (isCurrentProviderCard) return
                  checkAvailability(doctorId)
                  mixpanelTrackMoreDatesClicked()
                }}
                aria-disabled={isCurrentProviderCard}
                className="font-bold text-shade p-2 cursor-pointer width-fit mx-auto my-auto inline-block underline"
              >
                More dates
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default EarliestTimeSlots
