import { Card, Progress } from 'antd'
import moment from 'moment'
import { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { infoFill } from '../../assets'
import './appointment-timer.scss'
import { AppointmentPageContext } from '../../pages/EAP/appointment-page/appointment-page-middleware'
import { mixpanelTrackUserTimedOut } from '../utils/mixpanel'

interface AppointmentTimerInterface {
  isPHP?: boolean
}
const AppointmentTimer: React.FC<AppointmentTimerInterface> = ({ isPHP }) => {
  const history = useHistory()
  const [minutesLeft, setMinutesLeft] = useState(20)
  const { appointmentDetails, bookingDate, isAppointmentConfirmed } =
    useContext(AppointmentPageContext)

  useEffect(() => {
    if (minutesLeft > 0) {
      const interval = setInterval(() => {
        setMinutesLeft(minutesLeft - 1)
      }, 60000)
      return () => clearInterval(interval)
    }
    if (minutesLeft === 0 && isAppointmentConfirmed === false) {
      mixpanelTrackUserTimedOut()
      history.push('timeout-page')
    }
  }, [minutesLeft])

  const showRemainingTime = (percent: number) => {
    return (
      <div className="text-color">
        <div className="font-bold text-xl">{percent / 5}</div>
        <div className="text-xs font-normal">MIN</div>
      </div>
    )
  }

  return (
    <Card
      className={
        isPHP
          ? 'card-color md:w-[94%] mt-8 php-timer-card drop-shadow-md rounded-lg'
          : 'card-color'
      }
    >
      <div className="flex flex-col text-center md:text-left md:flex-row">
        {!isPHP && (
          <span className="hidden md:block">
            <img src={infoFill} alt="info fill"></img>
          </span>
        )}
        <div
          className={
            isPHP
              ? 'w-full md:w-1/4 text-center block md:hidden mb-4'
              : 'hidden'
          }
        >
          <Progress
            type="circle"
            width={isPHP ? 70 : 80}
            percent={minutesLeft * 5}
            format={(percent) => percent && showRemainingTime(percent)}
            strokeColor="#B24938"
          />
        </div>
        <div
          className={`${
            isPHP ? 'md:w-4/5' : 'md:w-3/4'
          } px-4 w-full text-sm mb-4`}
        >
          Please complete your profile in the next <b>20 mins</b> to confirm
          your appointment. Your appointment is reserved with{' '}
          <b>{appointmentDetails?.therapistName} </b>on{' '}
          <b>{moment.parseZone(bookingDate).format('dddd, Do MMMM YYYY')}</b> at{' '}
          <b>
            {moment.parseZone(bookingDate).format('h A')}{' '}
            {appointmentDetails?.abbr}
          </b>
          .
        </div>
        <div
          className={
            isPHP
              ? 'w-full md:w-1/5 text-center hidden md:block'
              : 'w-full md:w-1/4'
          }
        >
          <Progress
            type="circle"
            width={isPHP ? 65 : 80}
            percent={minutesLeft * 5}
            format={(percent) => percent && showRemainingTime(percent)}
            strokeColor="#B24938"
          />
        </div>
      </div>
    </Card>
  )
}

export default AppointmentTimer
