import { useContext, useEffect, useState } from 'react'
import {
  AppointmentPageContext,
  AppointmentType,
} from './appointment-page-middleware'
import EarliestTimeSlots from './earliest-time-slots'
import SelectedSlot from './selected-slot'

import { useLocation } from 'react-router'
import { changeProviderApptPath } from '../../../routing/route.constants'
import ReasonForChange from '../../change-provider/change-provider-reason-for-change'
import AppointmentTypeSelection from './appointment-type-selection'
import { SingleTherapistSlotsInterface } from './interfaces/single-therapist-slot-interface'

interface TimeSlotsInterface {
  doctorName: string
  doctorId: string
  bookAppointment: (
    date: string | Date,
    clickNextPath: string,
    providerId: string,
    providerName?: string
  ) => void
  checkAvailability: (id: string) => void
  clickNextPath: string
  isResetConfirmation?: boolean
  visitorId: string
  isCurrentProviderCard?: boolean
  isLoadingTherapistsSlots?:boolean
  therapistSlotsData:SingleTherapistSlotsInterface[]
  therapistavailableTimeSlots:any
}

interface SingleTimeSlotInterface {
  slot: string
  abbr: string
}

const TimeSlots: React.FC<TimeSlotsInterface> = ({
  doctorName,
  checkAvailability,
  doctorId,
  bookAppointment,
  isResetConfirmation,
  clickNextPath,
  visitorId,
  isCurrentProviderCard = false,
  isLoadingTherapistsSlots,
  therapistSlotsData,
  therapistavailableTimeSlots,
}) => {
  const [slotSelectedFlag, setSlotSelectedFlag] = useState(false)
  const [selectedDate, setSelectedDate] = useState('')
  const [selectedTimeSlot, setSelectedTimeSlot] =
    useState<SingleTimeSlotInterface>({ slot: '', abbr: '' })
  const {
    matchTherapist,
    updateMatchTherapist,
    appointmentType,
    updateAppointmentType,
    reasonForChangeProvider,
    updateReasonForChangeProvider,
  } = useContext(AppointmentPageContext)

  const location = useLocation()
  const isChangeProviderFlow = location.pathname.includes(
    changeProviderApptPath
  )

  const handleSlotSelection = (
    isSlotSelected: boolean,
    date?: string,
    time?: { slot: string; abbr: string }
  ) => {
    isSlotSelected ? setSlotSelectedFlag(true) : setSlotSelectedFlag(false)
    updateAppointmentType(AppointmentType.UNSELECTED)
    updateReasonForChangeProvider('')
    date ? setSelectedDate(date) : setSelectedDate('')
    time
      ? setSelectedTimeSlot(time)
      : setSelectedTimeSlot({ slot: '', abbr: '' })
    updateMatchTherapist(doctorId)
  }
  useEffect(() => {
    if (matchTherapist !== doctorId) {
      setSlotSelectedFlag(false)
      updateAppointmentType(AppointmentType.UNSELECTED)
      updateReasonForChangeProvider('')
    }
  }, [matchTherapist])
  useEffect(() => {
    updateAppointmentType(AppointmentType.UNSELECTED)
    updateReasonForChangeProvider('')
    return setSlotSelectedFlag(false)
  }, [isResetConfirmation])

  return slotSelectedFlag &&
    (appointmentType === AppointmentType.PHONE_CALL ||
      appointmentType === AppointmentType.VIDEO_CALL) ? (
    <SelectedSlot
      handleSlotSelection={handleSlotSelection}
      therapistName={doctorName}
      dateofBooking={selectedDate}
      timeSlot={selectedTimeSlot}
      therapistId={doctorId}
      bookAppointment={bookAppointment}
      clickNextPath={clickNextPath}
    />
  ) : slotSelectedFlag && isChangeProviderFlow && !reasonForChangeProvider ? (
    <ReasonForChange {...{ handleSlotSelection }} />
  ) : slotSelectedFlag && appointmentType === AppointmentType.UNSELECTED ? (
    <AppointmentTypeSelection handleSlotSelection={handleSlotSelection} />
  ) : (
    <EarliestTimeSlots
      handleSlotSelection={handleSlotSelection}
      checkAvailability={checkAvailability}
      doctorId={doctorId}
      doctorName={doctorName}
      visitorId={visitorId}
      isCurrentProviderCard={isCurrentProviderCard}
      isLoadingTherapistsSlots={isLoadingTherapistsSlots}
      therapistSlotsData={therapistSlotsData}
      therapistavailableTimeSlots={therapistavailableTimeSlots}
    />
  )
}

export default TimeSlots
