import { Rule, RuleObject } from 'antd/lib/form'
import { StoreValue } from 'antd/lib/form/interface'
import moment, { Moment } from 'moment'
import React from 'react'
import {
  DaysOfTheWeekArray,
  formatTimeRange,
  reasonForBookingLabel,
} from '../../pages/EAP/EAP-constants'
import { AppointmentType } from '../../pages/EAP/appointment-page/appointment-page-middleware'
import { ContactTypesInterface } from '../metadata/metadata-middleware'
import { FiltersInterface } from '../../pages/EAP/appointment-page/interfaces/available-slots-request-interface'
import { FilterTypes } from '../utils/mixpanel-constants'

export const FormRequiredMessage = (
  elementName: string | React.ReactNode
): JSX.Element => {
  if (typeof elementName === 'string') {
    return (
      <span className="break-words block whitespace-normal text-[13px] w-66">
        {`${elementName
          ?.replace('Create', '')
          ?.replace('Confirm', '')
          ?.replace("Household member's", '')
          ?.replace("Employee's", '')
          ?.replace('(MM/DD/YYYY or MM-DD-YYYY)', '')} is missing`
          //The name of field label should be different to the label in the error message, added this extra condition for 'purpose of booking appointment' field
          ?.replace(reasonForBookingLabel, 'Purpose of visit')}
      </span>
    )
  }
  return (
    <span className="break-words block whitespace-normal text-[13px] w-66">{`${elementName} is missing`}</span>
  )
}
export const FormInvalidMessage = (
  elementName: string | React.ReactNode | any,
  message?: string
) => {
  if (message && typeof message === 'string') {
    return `${elementName
      ?.replace('Create', '')
      //The name of field label should be different to the label in the error message, added this extra condition for 'purpose of booking appointment' field
      ?.replace(reasonForBookingLabel, 'Purpose of visit')} ${message}`
  }
  return `${elementName} is invalid`
}
export declare type Validator = (
  rule: RuleObject,
  value: StoreValue,
  callback: (error?: string) => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
) => Promise<void | any> | void

export const formRules = (
  label: string | React.ReactNode,
  required?: boolean,
  validator?: Validator,
  pattern?: RegExp,
  patternMessage?: string,
  min?: {
    value: number
    message: string
  },
  max?: {
    value: number
    message: string
  },
  isURL?: boolean,
  isEmail?: boolean,
  isOTP?: boolean
): Rule[] => {
  const rules: Rule[] = []
  if (required) {
    rules.push({ required: required, message: FormRequiredMessage(label) })
  }
  if (validator) {
    rules.push({ validator: validator })
  }
  if (pattern) {
    rules.push({ pattern: pattern, message: patternMessage })
  }
  if (min) {
    rules.push({
      min: min.value,
      message: FormInvalidMessage(label, min.message),
    })
  }
  if (max) {
    rules.push({ max: max.value, message: FormInvalidMessage(label) })
  }
  if (isURL) {
    rules.push({ type: 'url', message: FormInvalidMessage(label) })
  }
  if (isEmail) {
    rules.push({
      type: 'email',
      message: FormInvalidMessage(label),
    })
  }
  if (isOTP) {
    rules.push({ message: FormInvalidMessage(label) })
  }
  return rules
}
export const disabledDate = (current: Moment) => {
  return (
    (current && current > moment(new Date())) ||
    current < moment('01/01/1800', 'MM/DD/YYYY')
  )
}

export const hasLowerCase = (str: string) => {
  return str.toUpperCase() != str
}

export const hasUpperCase = (str: string) => {
  return str.toLowerCase() != str
}

export const hasDigit = (str: string) => {
  const hasNumber = /\d/
  return hasNumber.test(str)
}

export const hasSymbol = (str: string) => {
  return str.match(/\W/)
}

export const getTypeOfAppointment = (
  appointmentType: AppointmentType,
  contactTypeIdList: ContactTypesInterface[]
) => {
  return appointmentType === AppointmentType.PHONE_CALL
    ? contactTypeIdList.find((e) => e.name === 'Phone Call')?.id
    : contactTypeIdList.find(
        (e) => e.name === 'Healthie Video Call' || e.name === 'Secure Videochat'
      )?.id
}

interface FilterRequestInterface {
  StartTime?: string
  EndTime?: string
  WeekDays?: number[]
  specializations?: string[]
  populations?: string[]
  language?: string
}

export const getAppliedFilters: (
  selectedDaysArray?: boolean[],
  range?: [number, number],
  selectedSpecialisationItems?: string[],
  selectedPopulationItems?: string[],
  languageSelection?: string
) => FilterRequestInterface = (
  selectedDaysArray?,
  range?,
  selectedSpecialisationItems?,
  selectedPopulationItems?,
  languageSelection?
) => {
  let Filters: FilterRequestInterface = {}
  if (selectedDaysArray) {
    if (
      !(
        selectedDaysArray?.every((value) => value === false) ||
        selectedDaysArray?.every((value) => value === true)
      )
    ) {
      Filters = {
        ...Filters,
        WeekDays: selectedDaysArray
          ?.map((isSelected: boolean, index: number) =>
            isSelected ? index : -1
          )
          .filter((weekday) => weekday != -1),
      }
    }
  }
  if (range) {
    const [startTime, endTime] = formatTimeRange(range)
    if (!range?.every((value, index) => value === [420, 1260][index])) {
      Filters = {
        ...Filters,
        StartTime: startTime,
        EndTime: endTime,
      }
    }
  }
  if (selectedSpecialisationItems && selectedSpecialisationItems.length > 0) {
    Filters.specializations = selectedSpecialisationItems
  }
  if (selectedPopulationItems && selectedPopulationItems.length > 0) {
    Filters.populations = selectedPopulationItems
  }

  if (languageSelection) {
    Filters.language = languageSelection
  }

  return Filters
}

export const getEncodedSearchQuery = (searchQuery: string) => {
  if (searchQuery != '') {
    return encodeURIComponent(searchQuery)
  }
  return null
}

/**
 * Gets the applied filters from the given filter object.
 * @param filter - The filter object containing specialisations, WeekDays, StartTime, and EndTime.
 * @returns The filter type based on the applied filters.
 */

export function getAppliedFiltersFromObject(filter: FiltersInterface) {
  const { specializations, populations, WeekDays, StartTime, EndTime } = filter

  const hasSpecialisations =
    Array.isArray(specializations) && specializations.length > 0
  const hasPopulations = Array.isArray(populations) && populations.length > 0
  const hasDays = Array.isArray(WeekDays) && WeekDays.length > 0
  const hasTime = Boolean(StartTime && EndTime)

  let selectedFilterType = ''
  if (hasPopulations) {
    if (selectedFilterType.length != 0) {
      selectedFilterType = selectedFilterType.concat(' + ')
    }
    selectedFilterType = selectedFilterType.concat(FilterTypes.POPULATION)
  }
  if (hasSpecialisations) {
    if (selectedFilterType.length != 0) {
      selectedFilterType = selectedFilterType.concat(' + ')
    }
    selectedFilterType = selectedFilterType.concat(FilterTypes.SPECIALISATION)
  }

  if (hasDays) {
    if (selectedFilterType.length != 0) {
      selectedFilterType = selectedFilterType.concat(' + ')
    }
    selectedFilterType = selectedFilterType.concat(FilterTypes.DAYS_OF_THE_WEEK)
  }

  if (hasTime) {
    if (selectedFilterType.length != 0) {
      selectedFilterType = selectedFilterType.concat(' + ')
    }
    selectedFilterType = selectedFilterType.concat(FilterTypes.TIME_RANGE)
  }

  return selectedFilterType
}

export function removeHighlightedTagStrings(
  a: string[],
  b: string[]
): string[] {
  // Use the filter function to return an array that only includes items not present in 'a'
  const result = b.filter((item) => !a.includes(item))
  return result
}

export function removeEmTags(array: string[]): string[] {
  return array.map((item) => item.replace(/<\/?em>/g, ''))
}

export const GetSpecialisationsObjectForLandingPage = (
  specialisations: string[]
) => {
  const specialisationsObject: { [key: string]: string[] } = {}

  specialisations.sort().forEach((specialisation) => {
    const firstLetter = specialisation[0]

    // Check if the key exists in the object
    if (specialisationsObject[firstLetter]) {
      specialisationsObject[firstLetter].push(specialisation)
    } else {
      // If not, create an array with the specialization as the first element
      specialisationsObject[firstLetter] = [specialisation]
    }
  })

  return specialisationsObject
}

export const getUrlParam = (name: string) => {
  const urlParams = new URLSearchParams(window.location.search)
  return urlParams.get(name)
}

export const calculateSelectedDays = (selectedDays: boolean[]): string => {
  // Using map to convert the boolean array to an array of 1s and 0s
  const binaryArray = selectedDays.map((day) => (day ? 1 : 0))

  // Using reduce to sum the 1s and 0s
  const sum = binaryArray.reduce((acc, num) => acc + num, 0)

  // Find the index of the '1' if there is only one '1' in the array
  const index = sum === 1 ? binaryArray.findIndex((value) => value === 1) : -1

  // Return based on conditions
  if (sum === 0) {
    return 'Select'
  } else if (sum === 1) {
    return DaysOfTheWeekArray.Desktop[index]
  } else {
    return `${sum} selected`
  }
}

export const encodeToBase64 = (obj: Record<string, unknown>): string | null => {
  try {
    const jsonString = JSON.stringify(obj)
    return btoa(jsonString)
  } catch (e) {
    console.error('Error encoding to Base64:', e)
    return null
  }
}

export const decodeFromBase64 = (
  base64String: string
): Record<string, unknown> | null => {
  try {
    const jsonString = atob(base64String)
    return JSON.parse(jsonString)
  } catch (e) {
    console.error('Error decoding from Base64:', e)
    return null
  }
}

export const getObjectFromURLParam = (
  paramName: string
): Record<string, unknown> | null => {
  const urlParams = new URLSearchParams(window.location.search)
  const encodedObj = urlParams.get(paramName)
  if (encodedObj) {
    return decodeFromBase64(encodedObj)
  }
  return null
}

export const isSafari = () => {
  var userAgent = navigator.userAgent.toLowerCase()

  // Safari's user agent string contains 'safari' and not 'chrome' or 'chromium'
  var isSafariBrowser =
    userAgent.indexOf('safari') !== -1 &&
    userAgent.indexOf('chrome') === -1 &&
    userAgent.indexOf('chromium') === -1

  return isSafariBrowser
}

export const isSafariOrFirefox = () => {
  var userAgent = navigator.userAgent.toLowerCase()

  var isSafariBrowser =
    userAgent.indexOf('safari') !== -1 &&
    userAgent.indexOf('chrome') === -1 &&
    userAgent.indexOf('chromium') === -1

  var isFirefoxBrowser = userAgent.indexOf('firefox') !== -1

  return isSafariBrowser || isFirefoxBrowser
}

export const trimInvalidCharactersFromAddressAutofill = (
  autofillString: string
) => {
  const addressInvalidRegex = /[\\""/?<>%()=;@\[\]\'`:*]+/g
  return autofillString.replace(addressInvalidRegex, ' ')
}
