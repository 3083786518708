import { CheckCircleOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import moment from 'moment'
import { useContext, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { mixpanelTrackViewMyAppointment } from '../../../common/utils/mixpanel'
import { handleBeforeunload, redirectToHelthieWebsite } from '../EAP-constants'
import { EAPRegistrationContext } from '../registration/eap-registrationMiddleware'
import './appointment-confirmation.scss'
import EmergencyBottomToast from './emergency-bottom-toast'
import FeedbackToast from './feedback-toast'
import { AppointmentPageContext } from './appointment-page-middleware'

const AppointmentConfirmation: React.FC = () => {
  const location = useLocation()
  const history = useHistory()
  const { clearContextAfterBooking } = useContext(EAPRegistrationContext)
  const { clearAppointmentContextAfterPatientCreation } = useContext(
    AppointmentPageContext
  )

  const { id, statusId, appointment, isRatingEnabled } = location.state

  const redirectToHome = () => {
    if (history.action === 'POP') {
      history.push('/')
    }
  }

  const Status_Active = 'ac17422f-8f08-4a2e-a7c1-7bfdf936e85a'
  // const Status_NotVerified = 'e44c2175-8a4f-4cf9-b26f-49ad691cd388'

  // bring from somewhere (context or location state, whenever its ready)

  useEffect(() => {
    redirectToHome()
    return () => {
      redirectToHome()
      clearContextAfterBooking()
      clearAppointmentContextAfterPatientCreation()
    }
  }, [history])

  useEffect(() => {
    window.removeEventListener('beforeunload', handleBeforeunload)
  }, [])

  const confirmationMessage =
    statusId === Status_Active
      ? 'Your Trusana account has been created successfully. You will receive a confirmation email that includes your account and appointment details.'
      : 'Your ID has been submitted for verification. Upon successful verification, you will receive an email with appointment confirmation.'

  return (
    <>
      <div className="confirmation-div">
        <CheckCircleOutlined className="center text-6xl mt-16 md:mt-0" />
        <p className="msg1">
          Your appointment is scheduled with{' '}
          <span className="font-bold"> {appointment.providerName}</span> on{' '}
          <span className="font-bold">
            {appointment.appointmentDate &&
              moment.parseZone(appointment.appointmentDate).format('LLLL')}{' '}
            {appointment.abbr}
          </span>
          .
        </p>
        <p className="msg2">{confirmationMessage}</p>
        {/* <p className="msg2">
        If you or your family member are not feeling safe (or are experiencing
        an imminent threat of self-harm or harm to others), you should call 911
        or go to the closest emergency department. You can also call 988 for the
        National Suicide and Crisis Lifeline.
      </p> */}
        {statusId === Status_Active ? (
          <Button
            type="default"
            className="goto-button"
            onClick={redirectToHelthieWebsite}
          >
            View my appointment
          </Button>
        ) : (
          <Button
            type="default"
            className="goto-button"
            onClick={() => {
              mixpanelTrackViewMyAppointment()
              history.push('/')
            }}
          >
            Go to home
          </Button>
        )}
        {isRatingEnabled && (
          <div className="feedback-toast">
            <FeedbackToast patientId={id} />
          </div>
        )}
        <div className="mx-8 md:mx-44 mt-8 pb-32">
          <EmergencyBottomToast />
        </div>
      </div>
    </>
  )
}

export default AppointmentConfirmation
