import React, { useEffect, useState } from 'react'
import {
  HeroImage,
  HeroImageFemale,
  HeroImageNeutral,
  MobileHeroMen,
  MobileHeroNeutral,
  MobileHeroWomen,
} from '../../../assets'
import '../landing-page.scss'
import { mixpanelTrackGetStartedClicked } from '../../../common/utils/mixpanel'
import useScreenWidth from '../../../common/hooks/use-screen-width'
import { companyCodePathIdentifier } from '../../../routing/route.constants'
import { useHistory } from 'react-router'

const HeroSection: React.FC = () => {
  const [svgIndex, setSvgIndex] = useState(0)
  const history = useHistory()
  const width = useScreenWidth()
  useEffect(() => {
    // Set up a timer to increment svgIndex every 5 seconds
    const intervalId = setInterval(() => {
      setSvgIndex((prevIndex) => (prevIndex + 1) % 3) // Cycle through 0, 1, 2
    }, 4000)

    // Clean up the interval when the component unmounts
    return () => clearInterval(intervalId)
  }, [])

  const onGetStarted = () => {
    history.push(companyCodePathIdentifier)
    mixpanelTrackGetStartedClicked()
  }

  const renderSvg = () => {
    if (width < 727) {
      switch (svgIndex) {
        case 0:
          return <MobileHeroMen />
        case 1:
          return <MobileHeroWomen />
        case 2:
          return <MobileHeroNeutral />
        default:
          return null
      }
    }
    switch (svgIndex) {
      case 0:
        return <HeroImage />
      case 1:
        return <HeroImageFemale />
      case 2:
        return <HeroImageNeutral />
      default:
        return null
    }
  }

  return (
    <div
      className="flex justify-center w-full items-center relative hero-section"
      id="heroSection"
    >
      {/* Gradient backgrounds */}

      {/* <div className="absolute top-0 left-0 bottom-0 right-0 sun-gradient opacity-60 z-20 "></div> */}
      <div className="absolute top-0 left-0 bottom-0 right-0 white-gradient opacity-100 z-10 "></div>

      <div className="flex flex-col lg:flex-row max-w-screen-xl lg:max-w-screen-2xl w-full md:mx-16 lg:mx-0 gap-6 xl:gap-8 justify-between my-10 md:my-8 items-center z-50">
        <div className="flex flex-col pb-10 md:py-8 mx-8 md:mr-0 pl-2 xl:pl-4 2xl:py-16 gap-2 md:basis-5/6 hero-left-section">
          <div className="text-5xl md:text-[3.6rem] font-[275] pb-4 leading-[64px] gradient-hero-text select-none">
            Simple and Exceptional
          </div>
          <div className="text-[28px] pr-2.5 md:pr-0 md:text-4xl font-[275] pb-6 leading-9 md:leading-8 text-slate">
            - your team for mental health
          </div>
          <div className="text-base max-w-sm md:max-w-lg xl:max-w-2xl leading-[26px] text-shade">
            Personal online mental health care from licensed therapists who
            provide solution-focused plans tailored to each individual on their
            journey to well-being
          </div>
          <a
            className="text-onyx hover:text-shade rounded-full select-none cursor-pointer mt-8 md:my-8 px-8 md:px-16 py-3.5 leading-5 md:py-4 bg-yellow-ochre text-[#1B1C20] w-fit text-base font-medium max-w-sm"
            onClick={onGetStarted}
          >
            Get started
          </a>
        </div>

        <div className="left-10 flex justify-center items-center md:w-full md:basis-1/6 hero-image">
          {renderSvg()}
          {/* <MobileHeroMen /> */}
        </div>
      </div>
    </div>
  )
}

export default HeroSection
