export const MIXPANEL: MixpanelType = {
  VISITOR: 'Visitor',
  POTENTIAL_VISITOR: 'Potential Visitor / Beta',
  MEMBER_LOGIN: 'Trusana User',
  EAP_SELF: 'EAP - Employee',
  EAP_DEPENDANT: 'EAP - Household member registered by Employee',
  EAP_HOUSEHOLD: 'EAP - Household member',
  PERSONAL_DETAILS: 'Personal Details',
  VALID_EMAIL: 'Valid email entered',
  DOB_ENTERED: 'Valid DoB entered',
  STATE_DROPDOWN_OPENED: 'States dropdown opened',
  STATE_SELECTED: 'State selected',
  LANDED_ON_APPOINTMENT_PAGE: 'Appointment Page Viewed',
  BOOKED_FROM_EARLIEST_SLOTS: 'Earliest Available Slots',
  SCROLL_TO_APPOINTMENT_PAGE_END: 'Scrolled to Appointment Page End',
  BOOK_APPOINTMENT: 'Book Appointment',
  BOOKED_FROM_MORE_DATES: 'Book Appointment - More Dates',
  BOOK_APPOINTMENT_MORE_DATES: 'Book Appointment - More Dates',
  MORE_DATES_MISSING_TIMES: 'Unavailable times on more dates',
  ACCOUNT_SETUP: 'Account Setup',
  UPLOAD_ATTEMPTED: 'Upload Attempted',
  UPLOAD_SUCCESS: 'Upload Successful',
  EMPLOYEE_DETAILS_COMPLETED: 'Employee Details Completed',
  DEPENDANT_DETAILS_COMPLETED: 'Dependant Details Completed',
  PASSWORD_SETUP_COMPLETED: 'Password Setup Complete',
  SURVEY_TAKEN: 'Survey Taken',
  VIEW_APPOINTMENT: 'View my Appointment',
  PATIENT_REGISTRATION: 'Patient Registration',
  AGE_ERROR_RAISED: 'Age Error Message Raised',
  DOB_INPUT: 'Date of Birth entered',
  MORE_DATES_CLICKED: 'More dates clicked',
  APPOINTMENT_SLOT_DETAILS: 'Appointment Slot Details',
  AVAILABLE_TIMES: 'Available Times',
  SEARCH_EXECUTED: 'Search Event',
  FILTER_APPLIED: 'Filter applied',
  SEARCH_RESPONSE: 'Search Response',
  PASSWORD_ERROR: 'Password Error',
  USER_DROP_OFF: 'User Drop Off',
  LANDING_ON_TRUSANA: 'Landed on Trusana',
  QUESTIONNAIRE_SCREEN: 'Questionnaire screen loaded',
  USER_ACCOUNT_SELECTION: 'User account selected',
  USER_TYPE_SELECTION: 'User type selected',
  SPLASH_SCREEN_SURPASSED: 'Splash screen surpassed',
  PHP_INITIAL_DETAILS: 'PHP Initial details filled',
  PHP_ACCOUNT_SETUP: 'PHP Account Setup',
  PHP_PRIMARY_SUBSCRIBER_DETAILS: 'PHP Primary Subscriber Details',
  PHP_PASSWORD_SUBMIT: 'PHP Password Submit',
  USER_TYPE: 'User Type',
  SUPPORT_LINK_CLICKED: 'Support link clicked',
  LANDED_ON_ORG_PAGE: 'Landed on organisation page',
  ORG_NAME_ENTERED: 'Organisation name entered',
  ORG_CODE_PAGE_INITIATED: 'Organisation code page initiated',
  ORG_CODE_ENTERED: 'Organisation code entered',
  SLOT_CLICKED: 'Time slot clicked',
  CALL_TYPE_SELECTED: 'Call type selected',
  PAGE_CLOSE_ATTEMPT: 'Page close attempted',
  SPECIALISATION_SEARCH: 'Concerns Search',
  SELECTED_SPECIALISTIONS: 'Selected Concerns',
  SELECTED_POPULATIONS: 'Selected Populations',
  USER_TIME_OUT: 'User time out',
  RETENTION_POP_UP: 'Retention pop up',
  OPENED_SLOT_SELECTION: 'Opened slot selection',
  TIME_SLOT_SELECTED_FROM_DRAWER: 'Time slot chosen',
  DATE_SELECTED_FOR_SUPPORT: 'Date selected in drawer',
  SENT_MAIL_FOR_SLOT: 'Sent mail for slot',
  GET_STARTED: 'Get Started',
  HEADER_SECTION_CLICKED: 'Header clicked',
  CONTACT_US: 'Contact Us',
  CONDITION: 'More Conditions We Treat',
  PROVIDERS_NO_RESULT: 'Providers No Result',
  LANDED_ON_CHANGE_PROVIDER: 'Landed on Change Provider',
  CHANGE_PROVIDER_REASON: 'Change Provider Reason Entered',
  CHANGE_PROVIDER_CONFIRM: 'Change Provider Confirmed',
  ACCOUNT_INFORMATION_ENTERED: 'Account Information Entered',
}

export enum FilterTypes {
  NONE = 'None',
  DAYS_OF_THE_WEEK = 'Days',
  TIME_RANGE = 'Time',
  SPECIALISATION = 'Concerns',
  DAYS_AND_SPECIALISATION = 'Days + Concerns',
  TIME_AND_SPECIALISATION = 'Time + Concerns',
  DAYS_TIME_SPECIALISATION = 'Days + Time + Concerns',
  DAYS_AND_POPULATION = 'Days + Populations',
  TIME_AND_POPULATION = 'Time + Populations',
  DAYS_TIME_POPULATION = 'Days + Time + Populations',
  SPECIALISATION_AND_POPULATION = 'Concerns + Populations',
  DAYS_TIME_SPECIALISATION_POPULATION = 'Days + Time + Concerns + Populations',
  DAYS_AND_TIME = 'Days + Time',
  //LANGUAGE = 'Language',
  POPULATION = 'Populations',
}

export enum FilterOptionsMobile {
  DAYS_OF_THE_WEEK = 'Preferred Day',
  TIME_RANGE = 'Preferred Time',
  SPECIALISATION = 'Concerns',
  //LANGUAGE = 'Language',
  POPULATION = 'Populations',
}

export type MixpanelType = {
  [key: string]: string
}
