import { Themes } from '../../pages/EAP/EAP-constants'

const VideoCallVector: React.FC<{ theme: Themes; isSelected: boolean }> = ({
  theme,
  isSelected = false,
}) => {
  return (
    <svg
      width="18"
      height="12"
      viewBox="0 0 18 12"
      className={`${
        theme === Themes.DARK && isSelected === false
          ? '!fill-white group-hover:!fill-shade group-focus:!fill-shade'
          : isSelected === false
          ? '!fill-shade group-hover:!fill-white group-focus:!fill-white'
          : ''
      } ${isSelected === true ? '!fill-white' : ''}`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M12.375 3.46875V10.2188C12.375 10.5171 12.2565 10.8033 12.0455 11.0142C11.8345 11.2252 11.5484 11.3438 11.25 11.3438H3.375C3.00566 11.3438 2.63993 11.271 2.2987 11.1297C1.95747 10.9883 1.64743 10.7812 1.38626 10.52C0.858816 9.99254 0.5625 9.27717 0.5625 8.53125V1.78125C0.5625 1.48288 0.681026 1.19673 0.892005 0.985755C1.10298 0.774776 1.38913 0.65625 1.6875 0.65625H9.5625C10.3084 0.65625 11.0238 0.952566 11.5512 1.48001C12.0787 2.00746 12.375 2.72283 12.375 3.46875ZM17.1562 2.13984C17.0715 2.08831 16.9742 2.06106 16.875 2.06106C16.7758 2.06106 16.6785 2.08831 16.5938 2.13984L13.7812 3.74297C13.695 3.79276 13.6235 3.86457 13.5741 3.95104C13.5247 4.03752 13.4991 4.13556 13.5 4.23516V7.76484C13.4991 7.86444 13.5247 7.96248 13.5741 8.04896C13.6235 8.13543 13.695 8.20724 13.7812 8.25703L16.5938 9.86016C16.6794 9.90933 16.7762 9.93594 16.875 9.9375C16.9739 9.9369 17.0709 9.91022 17.1562 9.86016C17.2424 9.81208 17.3141 9.74163 17.3636 9.65624C17.4131 9.57086 17.4386 9.47369 17.4375 9.375V2.625C17.4386 2.52631 17.4131 2.42914 17.3636 2.34376C17.3141 2.25837 17.2424 2.18792 17.1562 2.13984Z" />
    </svg>
  )
}

export default VideoCallVector
