import { Card } from 'antd'
import '../appointment-page.scss'

interface NoSlotAvailableInterface {
  therapistName: string
}

const NoSlotAvailable: React.FC<NoSlotAvailableInterface> = ({
  therapistName,
}) => {
  return (
    <Card
      bordered={false}
      className="therapist-time-section p-8"
      data-testd="no-slot-card"
    >
      <div className="selected-slot-card-text">
        <p>
          Sorry, {therapistName} does not have any slots available for the
          selected filters.
        </p>
      </div>
    </Card>
  )
}

export default NoSlotAvailable
