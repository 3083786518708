import { CloseOutlined } from '@ant-design/icons'
import { Button, Card, Form, Input, Row } from 'antd'
import { useContext } from 'react'
import { FormInvalidMessage } from '../../common/form/form.utils'
import { AppointmentPageContext } from '../EAP/appointment-page/appointment-page-middleware'
import { mixpanelChangeProviderReason } from '../../common/utils/mixpanel'

const { TextArea } = Input

interface ReasonForChangeProps {
  handleSlotSelection: (arg0: boolean) => void
}

const ReasonForChange: React.FC<ReasonForChangeProps> = ({
  handleSlotSelection,
}) => {
  const { updateReasonForChangeProvider } = useContext(AppointmentPageContext)

  const [form] = Form.useForm()

  const onSubmit = () => {
    form
      .validateFields()
      .then((values: any) => {
        updateReasonForChangeProvider(values.reasonForChange)
      })
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .catch((errors: any) => {
        //do nothing
      })
  }

  return (
    <Card className="bg-shade w-full p-4 ml-0 md:ml-6 mt-4 md:mt-0 rounded-lg h-[15rem]">
      {/* <div className="text-end mb-3 text-xl">

            </div> */}
      <div className="flex flex-col">
        <Form
          form={form}
          className="text-white"
          scrollToFirstError
          layout="vertical"
        >
          <div className="mb-2 text-base required">
            Reason for changing provider{' '}
            <span className="text-[#FC6E4D]">*</span>
            <Button
              className="m-0 p-0 cross-button float-right text-xl"
              onClick={() => handleSlotSelection(false)}
              type="primary"
              aria-label="close"
            >
              <CloseOutlined className="text-white text-base" />
            </Button>
          </div>
          <Form.Item
            label="Reason for changing provider"
            name="reasonForChange"
            validateFirst={true}
            validateTrigger={['onSubmit', 'onChange']}
            initialValue={''}
            rules={[
              {
                required: true,
                message: 'Reason for changing provider is missing',
              },
              {
                min: 3,
                message: FormInvalidMessage('Reason for changing provider'),
              },
            ]}
          >
            <TextArea
              className="resize-none rounded-md"
              rows={4}
              id="reasonForChange"
              aria-required={true}
              aria-label="Reason for changing provider"
              onClick={mixpanelChangeProviderReason}
            />
          </Form.Item>
          <Row>
            <Button
              className="mt-4 mb-4 rounded-full max-w-[95px] bg-white text-shade pt-2.25 ml-auto hover:!bg-white hover:!text-shade"
              block
              size="large"
              onClick={onSubmit}
            >
              Submit
            </Button>
          </Row>
        </Form>
      </div>
    </Card>
  )
}

export default ReasonForChange
