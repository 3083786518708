import { lazy } from 'react'

const ChangeProviderLogin = lazy(() => import('./change-provider-login-page'))
const ChangeProviderApptWrapper = lazy(
  () => import('./appt-page/change-provider-appt-wrapper')
)
const ChangeProviderAppointmentConfirmation = lazy(
  () => import('./appt-confirmation/change-provider-appt-confirmation')
)

export {
  ChangeProviderLogin,
  ChangeProviderApptWrapper,
  ChangeProviderAppointmentConfirmation,
}
