import { useHistory } from 'react-router'
import { BackButton } from '../../../assets'

interface EAPAppointmentPageTopSectionProps {
  current: number
  clickPreviousPath: string
}

const EAPAppointmentPageTopSection: React.FC<
  EAPAppointmentPageTopSectionProps
> = ({
  current,
  clickPreviousPath,
}: {
  current: number
  clickPreviousPath: string
}) => {
  const history = useHistory()
  return (
    <div className="hidden lg:block available-therapist-text-style bg-light-sun">
      <div
        className={`pl-8 pr-4 my-4 font-semibold md:text-xl lg:text-2xl 2xl:text-3xl text-[#376A77] flex flex-row items-center justify-start`}
      >
        <div className="cursor-pointer z-50 mr-2">
          <BackButton
            onClick={() => {
              history.push(clickPreviousPath)
            }}
          />
        </div>
        Step {current + 1}:
        <span className="text-shade ml-1">Book an Appointment</span>
      </div>
    </div>
  )
}

export default EAPAppointmentPageTopSection
