import React, { useContext } from 'react'
import { LoaderContext } from './loader-middleware'
import './loader.scss'

interface LoaderProps {
  children: JSX.Element
}

const Loader: React.FC<LoaderProps> = ({ children }) => {
  const { loading } = useContext(LoaderContext)

  return (
    <>
      {/* Put your loader here */}
      {loading && (
        <div className="loader-container" data-testid="loader-container"></div>
      )}
      {children}
    </>
  )
}

export default Loader
