import { Checkbox, Form } from 'antd'
import React, { FunctionComponent } from 'react'
import './primary-subscriber-consent.scss'
interface PrimarySubscriberConsentProps {
  message: any
  isChecked: boolean
  consentType: string
  onChange: (value: any) => void
}

const PrimarySubscriberConsent: FunctionComponent<
  PrimarySubscriberConsentProps
> = ({ message, isChecked, consentType, onChange }) => {
  return (
    <Form.Item
      label={'Consent'}
      name={consentType}
      valuePropName="checked"
      rules={[
        {
          validator: (_, value) => {
            return value
              ? Promise.resolve()
              : Promise.reject(new Error('Please select the checkbox'))
          },
        },
      ]}
    >
      <div
        className="php-consent w-full sm:w-[95%] border border-solid border-[#001c3840] rounded-[0.3rem]  pl-4 pr-0 pt-3 pb-4 font-normal text-sm"
        style={{ background: 'rgba(0, 0, 0, 0.04)' }}
      >
        <Checkbox checked={isChecked} onChange={onChange}>
          {message}
        </Checkbox>
      </div>
    </Form.Item>
  )
}

export default PrimarySubscriberConsent
