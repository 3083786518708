/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from 'react'
import { Form, Radio, RadioChangeEvent } from 'antd'
import classNames from 'classnames'
import { formRules } from '../form.utils'

export interface OptionsInterface {
  id: string
  order: number
  description: string
}
interface RadioButtonGroupProps {
  elementKey?: string | number
  label: string
  id?: string
  isDisabled?: boolean
  name?: string
  isRequired?: boolean
  onChange: (e: RadioChangeEvent) => void
  className?: string
  defaultValue?: boolean
  checked?: boolean
  radioGroupOption: OptionsInterface[]
}

export const formRadioOptions = (
  data: any,
  idKey: string,
  descriptionKey: string
) => {
  return (
    data &&
    data.map((item: any, index: number) => {
      return {
        id: item[idKey],
        order: index,
        description: item[descriptionKey],
      }
    })
  )
}
const InputRadioButtonGroup: FC<RadioButtonGroupProps> = (
  props: RadioButtonGroupProps
) => {
  const {
    label,
    onChange,
    className,
    isDisabled,
    radioGroupOption,
    name,
    elementKey,
    isRequired,
  } = props
  return (
    <Form.Item
      name={name}
      key={elementKey}
      rules={formRules(label, isRequired)}
      className={classNames('mt-4', className)}
      validateFirst={true}
    >
      <Radio.Group onChange={onChange} key={name}>
        {radioGroupOption.map((radioOption: OptionsInterface) => (
          <Radio
            key={radioOption.id}
            value={radioOption.id}
            disabled={isDisabled}
            checked={false}
            className={className}
            data-testid={radioOption.description}
          >
            {radioOption.description}
          </Radio>
        ))}
      </Radio.Group>
    </Form.Item>
  )
}

export default InputRadioButtonGroup
