import React from 'react'
import './password-rules-display.scss'

interface PasswordRulesDisplayProps {
  value?: string
}
const PasswordRulesDisplay: React.FC<PasswordRulesDisplayProps> = ({}) => {
  return (
    <>
      <div
        className="main-box flex flex-col gap-2 p-3 rounded"
        data-testid="password-rules-main-box"
      >
        <div style={{ fontSize: '0.8rem' }}>Creating a strong password</div>

        <ul style={{ fontSize: '0.75rem', color: '#7B7B7B' }}>
          <li>Avoid common passwords (e.g. “12345678”)</li>
          <li>Use uppercase and lowercase letters</li>
          <li>Use numbers and/or punctuation marks</li>
        </ul>
      </div>
    </>
  )
}
export default PasswordRulesDisplay
