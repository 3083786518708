import App from './app'
import 'antd/dist/antd.css'
import renderApplication from './core/global-configuration/global-config-utils'
import ErrorBoundary from './core/error-boundary/error-boundary'

const errorMessage = `Error while fetching global config, application wil not be rendered. Please contact Admin.`
const errorElement = (
  <p style={{ color: `red`, textAlign: `center` }}>{errorMessage}</p>
)

renderApplication(
  <ErrorBoundary>
    <App />
  </ErrorBoundary>,
  `root`,
  errorElement
)
