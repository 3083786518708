import { Button } from 'antd'
import * as React from 'react'
import { useHistory } from 'react-router-dom'
import ErrorIcon from '../error-icon/error-icon'
import CommonModal from '../modal/common-modal'
interface APIErrorMessagePopupProps {
  toggleModal: () => void
  shouldDisplayErrorModal: boolean
  errorMessage: string
  isRetry?: boolean
}

const APIErrorMessagePopup: React.FunctionComponent<
  APIErrorMessagePopupProps
> = ({ toggleModal, shouldDisplayErrorModal, errorMessage, isRetry }) => {
  const history = useHistory()
  const getActionButtons = () => {
    if (errorMessage) {
      if (
        isRetry ||
        JSON.parse(errorMessage)[0]?.description.includes('password')
      ) {
        return (
          <Button
            type="default"
            shape="round"
            className=" "
            onClick={toggleModal}
          >
            {'Retry'}
          </Button>
        )
      }
      if (JSON.parse(errorMessage)[0]?.description) {
        return (
          <Button
            type="default"
            shape="round"
            className=" "
            onClick={() => history.push('/')}
          >
            Go to home
          </Button>
        )
      }
      if (JSON.parse(errorMessage)?.errors) {
        return (
          <Button
            type="default"
            shape="round"
            className=" "
            onClick={toggleModal}
          >
            Okay
          </Button>
        )
      }
    }
    return ''
  }
  return (
    <CommonModal
      handleClose={toggleModal}
      isModalOpen={shouldDisplayErrorModal}
      title={''}
    >
      <div className="flex flex-col items-center mt-6">
        <ErrorIcon />

        <div className="text-lg mt-6 mb-4 ">
          {errorMessage
            ? JSON.parse(errorMessage)[0]?.description ||
              formatErrors(JSON.parse(errorMessage)?.errors)
            : ''}
        </div>
        <div>{getActionButtons()}</div>
      </div>
    </CommonModal>
  )
}

const formatErrors = (errorString: any) => {
  return Object.keys(errorString).map((errorKey, index) => {
    return (
      <p key={index}>
        {errorString[errorKey].reduce(
          (accumulator, currentValue) => accumulator + ' ' + currentValue,
          ' '
        )}
      </p>
    )
  })
}

export default APIErrorMessagePopup
