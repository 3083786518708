import { useState } from 'react'
import { contactUsIcon } from '../../assets'
import { MailOutlined, PhoneOutlined } from '@ant-design/icons'
import {
  mixpanelTrackContactUs,
  mixpanelTrackSupportLinkClicked,
} from '../utils/mixpanel'

const ContactUs: React.FC = () => {
  const [isActive, setIsActive] = useState(false)
  const toggleIsActive = () => {
    setIsActive((isActive) => !isActive)
    mixpanelTrackContactUs()
  }

  return (
    <div>
      <div
        className={`flex flex-col justify-start cursor-pointer px-4 py-4 border border-solid border-shade text-shade fixed px-4 md:px-8 bottom-20 lg:bottom-24 right-4 lg:right-8 rounded-lg bg-snow z-[992] transition-all ease-in-out duration-300 ${
          isActive
            ? 'opacity-100 pointer-events-auto z-[992]'
            : 'opacity-0 pointer-events-none z-[990]'
        }`}
      >
        <div className="mb-4 font-semibold text-base md:text-xl">
          Contact us at
        </div>
        <div className="flex flex-row gap-2 items-center just0fy-between border border-x-0 border-t-0 mb-2 border-solid border-fossil pb-2 text-base md:text-lg">
          {' '}
          <PhoneOutlined className="rotate-90" />{' '}
          <a
            href="tel:(833) 724-9355"
            className="text-shade hover:text-shade focus:text-shade"
            onClick={mixpanelTrackSupportLinkClicked}
          >
            (833) 724-9355
          </a>
        </div>
        <div className="flex flex-row gap-2 items-center just0fy-between text-base md:text-lg">
          <MailOutlined />{' '}
          <a
            href="mailto:support@trusana.com"
            className="text-shade hover:text-shade focus:text-shade"
            onClick={mixpanelTrackSupportLinkClicked}
          >
            support@trusana.com
          </a>
        </div>
      </div>
      <button
        className={`${
          isActive ? 'gradient-border' : 'border-none'
        } flex flex-row gap-4 justify-center items-center cursor-pointer text-shade text-base md:text-xl fixed drop-shadow-md px-4 border-none bottom-4 lg:bottom-8 right-4 lg:right-8 h-[3rem] rounded-full bg-snow z-[992] transition-all ease-in-out duration-1000`}
        onClick={toggleIsActive}
        data-testid="btn-contact-us"
      >
        Contact us
        <img src={contactUsIcon} alt="contact us" />
      </button>
    </div>
  )
}

export default ContactUs
