import React, { useState } from 'react'
import { Form, Select } from 'antd'
import { SelectValue } from 'antd/lib/select'
import { FORM_SELECT_MODE_PROPS, LABEL_ALIGN_TYPE } from '../form.constants'
import { formRules } from '../form.utils'
import classNames from 'classnames'
import '../form.scss'
import { CaretDownOutlined } from '@ant-design/icons'
import FloatingLabel from '../floating-lable/floating-lable'

export interface OptionProp {
  key?: number | string
  name: string
  value: number | string
}

export interface InputSelectProps {
  label: string
  name: string
  isRequired?: boolean
  isDisabled?: boolean
  onChange?: (value: SelectValue) => void
  options: OptionProp[] | []
  className?: string
  inputContainerStyle?: string
  labelAlign?: keyof typeof LABEL_ALIGN_TYPE
  validateTrigger?: string | string[]
  validator?: () => void
  mode?: keyof typeof FORM_SELECT_MODE_PROPS
  showArrow?: boolean
  allowClear?: boolean
  defaultValue?: string
  labelInValue?: boolean
  placeholder?: string
  onDropdownVisibleChange?: (visible: boolean) => void
}
export const fromSelectOptions = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any,
  nameKey: string,
  valueKey: string
) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return data.map((item: any) => {
    return { name: item[nameKey], value: item[valueKey] }
  })
}

const InputSelect: React.FC<InputSelectProps> = (props: InputSelectProps) => {
  const {
    label,
    name,
    onChange,
    options,
    isRequired,
    isDisabled,
    className,
    validator,
    validateTrigger,
    labelAlign,
    mode,
    showArrow = true,
    allowClear = false,
    defaultValue,
    labelInValue,
    inputContainerStyle,
    onDropdownVisibleChange,
  } = props
  const { Option } = Select

  const FormOptionsList = (options: OptionProp[]) => {
    return options.map((option: OptionProp) => {
      return (
        <Option value={option.value} key={`${option.name}  ${option.value}`}>
          {option.name}
        </Option>
      )
    })
  }
  const [active, setActive] = useState(false)

  const onBlur = () => {
    setActive(false)
  }

  const onFocus = () => {
    setActive(true)
  }
  return (
    <FloatingLabel active={active} labelText={label} name={name}>
      <Form.Item
        label={label}
        name={name}
        validateTrigger={validateTrigger}
        labelAlign={labelAlign}
        rules={formRules(label, isRequired, validator)}
        className={classNames('block', className)}
        validateFirst={true}
      >
        <Select
          aria-label={label}
          suffixIcon={<CaretDownOutlined />}
          labelInValue={labelInValue}
          className={classNames('form-item-input-width', inputContainerStyle)}
          onChange={onChange}
          id={name}
          mode={mode}
          onBlur={onBlur}
          onFocus={onFocus}
          onDropdownVisibleChange={onDropdownVisibleChange}
          maxTagCount={1}
          showArrow={showArrow}
          allowClear={allowClear}
          defaultValue={defaultValue}
          disabled={isDisabled}
          optionFilterProp="children"
          data-testid={name}
        >
          {FormOptionsList(options)}
        </Select>
      </Form.Item>
    </FloatingLabel>
  )
}

export default InputSelect
