import { RadioChangeEvent, Form, Radio } from 'antd'
import { FC } from 'react'
import { formRules, Validator } from '../../form/form.utils'
import classNames from 'classnames'

export interface radioOptionsInterface {
  name: string
  radioValue: number | string | undefined
}
interface StaticRadioButtonGroupProps {
  elementKey?: string | number
  label: string
  id?: string
  isDisabled?: boolean
  name?: string
  isRequired?: boolean
  onChange: (e: RadioChangeEvent) => void
  className?: string
  defaultValue?: boolean
  checked?: boolean
  value?: number | string
  radioOptions: radioOptionsInterface[]
  validateTrigger?: string | string[]
  validator?: Validator
}
const StaticRadioButton: FC<StaticRadioButtonGroupProps> = (
  props: StaticRadioButtonGroupProps
) => {
  const {
    label,
    onChange,
    className,
    isDisabled,
    name,
    elementKey,
    isRequired,
    value,
    radioOptions,
    validateTrigger,
    validator,
  } = props
  return (
    <Form.Item
      name={name}
      key={elementKey}
      rules={formRules(label, isRequired, validator)}
      validateTrigger={validateTrigger}
      className={classNames('text-small md:text-medium', className)}
      validateFirst={true}
    >
      <Radio.Group onChange={onChange} value={value} defaultValue={value}>
        {radioOptions.map((radio: radioOptionsInterface) => (
          <Radio
            key={radio.radioValue}
            disabled={isDisabled}
            checked={false}
            className={className}
            value={radio.radioValue}
          >
            {radio.name}
          </Radio>
        ))}
      </Radio.Group>
    </Form.Item>
  )
}

export default StaticRadioButton
