import { useEffect } from 'react'
import { handleBeforeunload } from '../../pages/EAP/EAP-constants'
// TODO: import { mixpanelTrackPageCloseAttempted } from '../utils/mixpanel'
// TODO: Add current as a dependency as appointment page may reset the listener
const useWindowCloseWarning = () => {
  useEffect(() => {
    // Add the event listener
    window.addEventListener('beforeunload', handleBeforeunload)
    // Remove event listener on cleanup
    return () => {
      window.removeEventListener('beforeunload', handleBeforeunload)
    }
  }, [])
}
export default useWindowCloseWarning
