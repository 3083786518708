import { Button } from 'antd'
import React, { FunctionComponent, useContext } from 'react'
import { LoaderContext } from '../../loader/loader-middleware'

interface SubmitCancelProps {
  shouldDisplayPrevious: boolean
  isSubmit: boolean
  onPrevious?: () => void
  isAgeErrorModalOpen?: boolean
}

const NewSubmitCancel: FunctionComponent<SubmitCancelProps> = ({
  shouldDisplayPrevious,
  isSubmit,
  onPrevious,
}) => {
  const { loading } = useContext(LoaderContext)

  return (
    <>
      <div className="pb-4 md:mb-8 php-button">
        {shouldDisplayPrevious ? (
          <>
            <Button
              type="primary"
              className="mr-4 mt-6 ml-8 font-medium"
              onClick={onPrevious}
              style={{ background: '#3f7a88 !important', minWidth: '9rem' }}
            >
              Previous
            </Button>
          </>
        ) : null}
        <>
          <Button
            type="default"
            className="mr-6 mt-6 float-right font-medium"
            htmlType="submit"
            loading={loading}
            style={{ minWidth: '9rem' }}
          >
            {loading && 'Processing'}
            {!loading && (isSubmit ? 'Create account' : 'Next')}
          </Button>
        </>
      </div>
    </>
  )
}

export default NewSubmitCancel
