import mixpanel from 'mixpanel-browser'
import { MIXPANEL } from './mixpanel-constants'
import { FiltersInterface } from '../../pages/EAP/appointment-page/interfaces/available-slots-request-interface'
import { getAppliedFiltersFromObject } from '../form/form.utils'
import { homePath } from '../../routing'
import {
  EAPPagePath,
  changeProviderLoginPath,
  mentalHealthPath,
} from '../../routing/route.constants'

let distinct_id: any

export function mixpanelInit(
  mixpanelTokenForEnv: string,
  entryUrl: string,
  queryString: string
) {
  mixpanel.init(mixpanelTokenForEnv, {
    loaded: function (mixpanel) {
      distinct_id = mixpanel.get_distinct_id()
    },
    debug: false,
    persistence: 'localStorage',
  })
  mixpanelTrackEntryPoint(entryUrl)
  mixpanelTrackEntryPage(entryUrl)
  const searchParams = new URLSearchParams(queryString)
  const utm_source = searchParams.get('utm_source')
  const utm_medium = searchParams.get('utm_medium')
  const utm_campaign = searchParams.get('utm_campaign')
  const utm_term = searchParams.get('utm_term')
  const isChangeProviderFlow = entryUrl.includes(changeProviderLoginPath)
  utm_source &&
    mixpanelTrackUTMs({
      utm_source: utm_source,
    })
  utm_medium &&
    mixpanelTrackUTMs({
      utm_medium: utm_medium,
    })
  utm_campaign &&
    mixpanelTrackUTMs({
      utm_campaign: utm_campaign,
    })
  utm_term &&
    mixpanelTrackUTMs({
      utm_term: utm_term,
    })
  mixpanel.track(MIXPANEL.LANDING_ON_TRUSANA, {
    isChangeProviderFlow: isChangeProviderFlow,
  })
}

export function mixpanelGetDistinctId() {
  return distinct_id
}

export function mixpanelTrackMentalHealthLanded() {
  mixpanel.track(MIXPANEL.VISITOR)
}

export function mixpanelTrackPhysicalHealthLanded() {
  mixpanel.track(MIXPANEL.POTENTIAL_VISITOR)
}

export function mixpanelTrackMemberLogin({ location }: { location: string }) {
  mixpanelTrackRegisterMemberLogin(true)
  mixpanel.track(MIXPANEL.MEMBER_LOGIN, {
    location: location,
  })
}

export function mixpanelTrackEAPSelf() {
  mixpanel.track(MIXPANEL.PATIENT_REGISTRATION)
}

export function mixpanelTrackEAPDependant() {
  mixpanel.track(MIXPANEL.PATIENT_REGISTRATION)
}

export function mixpanelTrackEAPHousehold() {
  mixpanel.track(MIXPANEL.PATIENT_REGISTRATION)
}

export function mixpanelTrackPatientRegistration() {
  mixpanel.track(MIXPANEL.PATIENT_REGISTRATION)
}

export function mixpanelTrackPersonalDetailsComplete() {
  mixpanel.track(MIXPANEL.PERSONAL_DETAILS)
}

export function mixpanelTrackBookAppointment({
  bookingTimeSlot,
  bookingTimeZone,
  bookingDay,
  bookedFrom,
  therapistName,
}: {
  bookingTimeSlot: string
  bookingTimeZone: string
  bookingDay: string
  bookedFrom: boolean
  therapistName: string
}) {
  mixpanel.track(MIXPANEL.BOOK_APPOINTMENT, {
    'Booking Time': bookingTimeSlot,
    'Booking Time Zone': bookingTimeZone,
    'Booking Day': bookingDay,
    'Booked From': bookedFrom
      ? MIXPANEL.BOOKED_FROM_EARLIEST_SLOTS
      : MIXPANEL.BOOKED_FROM_MORE_DATES,
    'Therapist Name': therapistName,
  })
}

export function mixpanelTrackMoreDates({
  bookingTimeSlot,
  bookingTimeZone,
  bookingDay,
}: {
  bookingTimeSlot: string
  bookingTimeZone: string
  bookingDay: string
}) {
  mixpanel.track(MIXPANEL.BOOK_APPOINTMENT_MORE_DATES, {
    'Booking Time': bookingTimeSlot,
    'Booking Time Zone': bookingTimeZone,
    'Booking Day': bookingDay,
  })
}

export function mixpanelTrackLandingOnAppointmentPage({
  availableSlots,
  availableTherapistCount,
}: {
  availableSlots: any
  availableTherapistCount: number
}) {
  mixpanel.track(MIXPANEL.LANDED_ON_APPOINTMENT_PAGE, {
    'Available Slots:': availableSlots,
    'Available Therapist Count': availableTherapistCount,
  })
}

export function mixpanelTrackSpecialisationSearch({
  searchText,
}: {
  searchText: string
}) {
  mixpanel.track(MIXPANEL.SPECIALISATION_SEARCH, {
    searchText,
  })
}

export function mixpanelTrackSelectedSpecialisations({
  selectedSpecialisations,
}: {
  selectedSpecialisations: string[]
}) {
  mixpanel.register({
    selectedSpecialisations,
  })
}

export function mixpanelTrackScrollToBottom({
  didScrollToBottom,
}: {
  didScrollToBottom: boolean
}) {
  mixpanel.track(MIXPANEL.SCROLL_TO_APPOINTMENT_PAGE_END, {
    'Reached List End': didScrollToBottom,
  })
}

export function mixpanelTrackAccountSetupComplete() {
  mixpanel.track(MIXPANEL.ACCOUNT_SETUP)
}

export function mixpanelTrackUploadAttempt() {
  mixpanel.track(MIXPANEL.UPLOAD_ATTEMPTED)
}

export function mixpanelTrackUploadSuccess() {
  mixpanel.track(MIXPANEL.UPLOAD_SUCCESS)
}

export function mixpanelTrackEmployeeDetails() {
  mixpanel.track(MIXPANEL.EMPLOYEE_DETAILS_COMPLETED)
}

export function mixpanelTrackDependantDetails() {
  mixpanel.track(MIXPANEL.DEPENDANT_DETAILS_COMPLETED)
}

export function mixpanelTrackPasswordSetup() {
  mixpanelRegisterExisitngUser()
  mixpanel.track(MIXPANEL.PASSWORD_SETUP_COMPLETED)
  mixpanelRegisterExisitngUser()
}

export function mixpanelTrackSurvey({ rating }: { rating: number }) {
  mixpanel.track(MIXPANEL.SURVEY_TAKEN, {
    rating: rating,
  })
}

export function mixpanelTrackViewMyAppointment() {
  mixpanel.track(MIXPANEL.VIEW_APPOINTMENT)
}

export function mixpanelRegisterAgeAndState({
  userAge,
  userState,
}: {
  userAge: number
  userState: string
}) {
  mixpanel.register({
    age: userAge,
    state: userState,
  })
}

export function mixpanelRegisterGender({ userGender }: { userGender: string }) {
  mixpanel.register({
    gender: userGender,
  })
}

export function mixpanelRegisterUserFlowType({
  userFlowType,
}: {
  userFlowType: string
}) {
  mixpanel.register({
    patientType: userFlowType,
  })
}

export function mixpanelTrackRegisterFilterProperty(used: boolean) {
  mixpanel.register({
    'Filters Used': used,
  })
}

export function mixpanelTrackValidEmailEntered() {
  mixpanel.track(MIXPANEL.VALID_EMAIL)
}

export function mixpanelTrackPersonalDetailsDropdownOpened() {
  mixpanel.track(MIXPANEL.STATE_DROPDOWN_OPENED)
}

export function mixpanelTrackAgeErrorMessageRaised(errorType: string) {
  mixpanel.track(MIXPANEL.AGE_ERROR_RAISED, {
    errorType: errorType,
  })
}

export function mixpanelTrackDoBFieldEntered() {
  mixpanel.track(MIXPANEL.DOB_INPUT)
}

export function mixpanelTrackMoreDatesClicked() {
  mixpanel.track(MIXPANEL.MORE_DATES_CLICKED)
}

export function mixpanelTrackStateValueSelected() {
  mixpanel.track(MIXPANEL.STATE_SELECTED)
}

export function mixpanelTrackAppointmentSlotsDetails(slotDetails: any) {
  mixpanel.track(MIXPANEL.APPOINTMENT_SLOT_DETAILS, {
    slotDetails: slotDetails,
  })
}

export function mixpanelTrackAvailableTimes(availableTimes: any) {
  mixpanel.track(MIXPANEL.AVAILABLE_TIMES, {
    availableTimes: availableTimes,
  })
}

export function resetMixpanelTracking() {
  mixpanel.reset()
}

export function mixpanelTrackSearchQuery(searchQuery: string) {
  mixpanel.track(MIXPANEL.SEARCH_EXECUTED, {
    searchQuery: searchQuery,
  })
}

export function mixpanelTrackFilterApplied(filterObject: FiltersInterface) {
  const { specializations, WeekDays, StartTime, EndTime, populations } =
    filterObject

  const hasSpecialisations =
    Array.isArray(specializations) && specializations.length > 0
  const hasDays = Array.isArray(WeekDays) && WeekDays.length > 0
  const hasTime = Boolean(StartTime && EndTime)
  const hasPopulations = Array.isArray(populations) && populations.length > 0

  mixpanelTrackRegisterFilterProperty(true)

  if (hasDays || hasSpecialisations || hasTime || hasPopulations) {
    mixpanelSetFilterAppliedProperty(getAppliedFiltersFromObject(filterObject))
    if (hasTime) {
      mixpanelRegisterTimeOfTheDayFilter(filterObject)
    }
    mixpanel.track(MIXPANEL.FILTER_APPLIED, {
      filter: filterObject,
    })
  }
}

export function mixpanelSetFilterAppliedProperty(filterType: string) {
  mixpanel.register({
    'Filter type': filterType,
  })
}

export function mixpanelTrackFilterAndSearchApplied(
  request: any,
  therapistList: any,
  therapistCount: number
) {
  mixpanel.track(MIXPANEL.SEARCH_RESPONSE, {
    request: request,
    therapistList: therapistList,
    therapistCount: therapistCount,
  })
}

export function mixpanelTrackPasswordSetupFailed(error: any) {
  mixpanel.track(MIXPANEL.PASSWORD_ERROR, {
    error: error,
  })
}

export function mixpanelTrackUserDropff(cause?: string) {
  mixpanel.track(MIXPANEL.USER_DROP_OFF, {
    cause: cause || '',
  })
}

export function mixpanelTrackQuestionnaireLoaded() {
  mixpanel.track(MIXPANEL.QUESTIONNAIRE_SCREEN)
}

export function mixpanelTrackUserAccountSelected() {
  mixpanel.track(MIXPANEL.USER_ACCOUNT_SELECTION)
}

export function mixpanelTrackUserTypeSelected() {
  mixpanel.track(MIXPANEL.USER_TYPE_SELECTION)
}

export function mixpanelTrackSplashScreenSurpassed(method: string) {
  mixpanel.register_once({
    splashScreenPassedBy: method,
  })
}

export function mixpanelTrackRegisterMemberLogin(isMember: boolean) {
  mixpanel.register({
    clickedOnMemberLogin: isMember,
  })
}

export function mixpanelTrackDoesEmailExistInDatabase(existence: boolean) {
  mixpanel.register({
    isAlreadyMember: existence,
  })
}

export function mixpanelTrackEntryPoint(entryPoint: string) {
  mixpanel.register_once({
    entryPoint: window.location.origin + entryPoint,
  })
}

export function mixpanelTrackEntryPage(entryPoint: string) {
  let entryPage
  if (entryPoint === homePath) {
    entryPage = 'Home Page'
  } else if (entryPoint === mentalHealthPath) {
    entryPage = 'Mental Health Page'
  } else if (entryPoint === EAPPagePath) {
    entryPage = 'EAP Page'
  } else {
    entryPage = 'Others'
  }

  mixpanel.register_once({
    entryPage: entryPage,
  })
}

export function mixpanelTrackUTMs({
  utm_source,
  utm_medium,
  utm_campaign,
  utm_term,
}: {
  utm_source?: string
  utm_medium?: string
  utm_campaign?: string
  utm_term?: string
}) {
  const props: any = {}
  if (utm_source) props.utm_source = utm_source
  if (utm_medium) props.utm_medium = utm_medium
  if (utm_campaign) props.utm_campaign = utm_campaign
  if (utm_term) props.utm_term = utm_term
  mixpanel.register(props)
}

export function mixpanelTrackPHPInitialDetailsComplete() {
  mixpanel.track(MIXPANEL.PHP_INITIAL_DETAILS)
}

export function mixpanelTrackPHPAccountSetup() {
  mixpanel.track(MIXPANEL.PHP_ACCOUNT_SETUP)
}

export function mixpanelTrackPHPPrimaruSubscriberDetails() {
  mixpanel.track(MIXPANEL.PHP_PRIMARY_SUBSCRIBER_DETAILS)
}

export function mixpanelTrackPHPPasswordSetup() {
  mixpanelRegisterExisitngUser()
  mixpanel.track(MIXPANEL.PHP_PASSWORD_SUBMIT)
  mixpanelRegisterExisitngUser()
}

export function mixpanelTrackUserType(userType: string) {
  mixpanel.register({
    'User Type': userType,
  })
}

export function mixpanelTrackSupportLinkClicked() {
  mixpanel.track(MIXPANEL.SUPPORT_LINK_CLICKED)
}

export function mixpanelTrackLandedOnOrganisationPage(orgName: string) {
  mixpanel.track(MIXPANEL.LANDED_ON_ORG_PAGE, {
    orgName: orgName,
  })
}

export function mixpanelTrackOrganisationNameEntered(orgName: string) {
  mixpanel.register({
    Organisation: orgName,
  })

  mixpanel.track(MIXPANEL.ORG_NAME_ENTERED, {
    orgName: orgName,
  })
}

export function mixpanelTrackOrganisationCodeEntered(orgCode: string) {
  mixpanel.track(MIXPANEL.ORG_CODE_ENTERED, {
    orgCode: orgCode,
  })
}

export function mixpanelTrackOrganisationPageInitiated() {
  mixpanel.track(MIXPANEL.ORG_CODE_PAGE_INITIATED)
}

export function mixpanelTrackTypeOfUser(userType: string) {
  mixpanel.register({ userType: userType })
}

export function mixpanelTrackTimeSlotClicked(
  slotTime: string,
  isChangeProvider: any
) {
  mixpanel.track(MIXPANEL.SLOT_CLICKED, {
    slotTime: slotTime,
    isChangeProvider: isChangeProvider,
  })
}

export function mixpanelTrackTypeOfCallSelected(callType: string) {
  mixpanel.track(MIXPANEL.CALL_TYPE_SELECTED, { callType: callType })
}

export function mixpanelTrackValidEmailFromAPI() {
  mixpanel.track(MIXPANEL.EMAIL_VERIFIED)
}

export function mixpanelTrackSupportPageLoaded() {
  mixpanel.track(MIXPANEL.SUPPORT_PAGE_LOADED)
}
export function mixpanelTrackPageCloseAttempted() {
  mixpanel.track(MIXPANEL.PAGE_CLOSE_ATTEMPT)
}

export function mixpanelRegisterTimeOfTheDayFilter(
  filterObject: FiltersInterface
) {
  const { StartTime, EndTime } = filterObject

  // Check if StartTime and EndTime are not null
  if (StartTime === null || EndTime === null) {
    return
  }

  /**
   * StartTime:"07:00:00" - EndTime:"11:59:00" - Morning
   * StartTime:"12:00:00" - EndTime:"15:59:00" - Afternoon
   * StartTime:"16:00:00" - EndTime:"21:00:00" - Evening
   */

  if (StartTime === '07:00:00' && EndTime === '11:59:00') {
    mixpanel.register({
      selectedTimeOfTheDay: 'Morning',
    })
  }
  if (StartTime === '12:00:00' && EndTime === '15:59:00') {
    mixpanel.register({
      selectedTimeOfTheDay: 'Afternoon',
    })
  }
  if (StartTime === '16:00:00' && EndTime === '21:00:00') {
    mixpanel.register({
      selectedTimeOfTheDay: 'Evening',
    })
  }
}

export function mixpanelRegisterExisitngUser() {
  mixpanel.register({
    isExistingUser: true,
  })
}

export function mixpanelTrackUserTimedOut() {
  mixpanel.register({
    didUserTimeOut: true,
  })
  mixpanel.track(MIXPANEL.USER_TIME_OUT)
}

export function mixpanelTrackRetentionPopUpShown() {
  mixpanel.track(MIXPANEL.RETENTION_POP_UP)
}

export function mixpanelTrackOpenedSlotSelection() {
  mixpanel.track(MIXPANEL.OPENED_SLOT_SELECTION)
}

export function mixpanelTrackSentMailForSlot() {
  mixpanel.track(MIXPANEL.SENT_MAIL_FOR_SLOT)
}

export function mixpanelTrackTimeSlotChosenFromDrawer(
  timeslot: string,
  timezone: string
) {
  mixpanel.track(MIXPANEL.TIME_SLOT_SELECTED_FROM_DRAWER, {
    timeslot: timeslot,
    timezone: timezone,
  })
}

export function mixpanelTrackDateSelectedForSupport(date: string) {
  mixpanel.track(MIXPANEL.DATE_SELECTED_FOR_SUPPORT, {
    date: date,
  })
}

export function mixpanelTrackProvidersNoResult() {
  mixpanel.track(MIXPANEL.PROVIDERS_NO_RESULT)
}

//Revamped website mixpanel functions

export function mixpanelTrackGetStartedClicked() {
  mixpanel.track(MIXPANEL.GET_STARTED)
}

export function mixpanelTrackHeaders(header: string) {
  mixpanel.track(MIXPANEL.HEADER_SECTION_CLICKED, {
    header: header,
  })
}

export function mixpanelTrackContactUs() {
  mixpanel.track(MIXPANEL.CONTACT_US)
}

export function mixpanelTrackConditions() {
  mixpanel.track(MIXPANEL.CONDITION)
}

export function mixpanelTrackAccountInformationEntered(existence: string) {
  mixpanel.track(MIXPANEL.ACCOUNT_INFORMATION_ENTERED, {
    existence: existence,
  })
}

//Change Provider Flow mixpanel function

export function mixpanelLandedOnChangeProvider() {
  mixpanel.track(MIXPANEL.LANDED_ON_CHANGE_PROVIDER)
}

export function mixpanelChangeProviderReason() {
  mixpanel.track(MIXPANEL.CHANGE_PROVIDER_REASON)
}

export function mixpanelChangeProviderConfirmed() {
  mixpanel.track(MIXPANEL.CHANGE_PROVIDER_CONFIRM)
}
