import { useContext, useEffect, useState } from 'react'
import { DaysOfTheWeekArray } from '../../../EAP-constants'
import { Selected } from '../../../../../assets'
import { AppointmentPageContext } from '../../appointment-page-middleware'

const DaysOfTheWeekSelect: React.FC = () => {
  const { selectedDaysArray, updateSelectedDaysArray } = useContext(
    AppointmentPageContext
  )

  const [selectAllText, setSelectAllText] = useState('Select all days')
  const [width, setWidth] = useState(window.innerWidth)

  const handleClick = (index: number) => {
    const newSelectedArray = [...selectedDaysArray]
    newSelectedArray[index] = !newSelectedArray[index]
    updateSelectedDaysArray(newSelectedArray)
  }

  const toggleSelectAll = () => {
    if (
      selectedDaysArray.reduce((count, currentValue) => {
        return count + (currentValue === true ? 1 : 0)
      }, 0) === 7
    ) {
      updateSelectedDaysArray(Array(7).fill(false))
    } else {
      updateSelectedDaysArray(Array(7).fill(true))
    }
  }

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth)
    }

    // Subscribe to window resize events
    window.addEventListener('resize', handleResize)

    // Unsubscribe from window resize events when the component is unmounted
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    if (
      selectedDaysArray.reduce((count, currentValue) => {
        return count + (currentValue === true ? 1 : 0)
      }, 0) === 7
    ) {
      setSelectAllText('Select none')
    } else {
      setSelectAllText('Select all days')
    }
  }, [selectedDaysArray])

  return (
    <div className="mt-1 lg:mt-0 lg:mx-2 lg:px-2 pb-2 lg:py-2 lg:w-fit border-[#DCD8D0] border-b border-solid border-x-0 border-t-0 lg:!border-b-0 flex flex-1 flex-col justify-center items-evenly lg:justify-evenly lg:!border-x">
      <div className="m-1 px-1 text-medium-grey font-medium text-sm flex flex-col lg:flex-row justify-between">
        <div className="hidden lg:block">Preferred Day</div>
        <div
          className="text-teal-blue underline cursor-pointer"
          onClick={toggleSelectAll}
        >
          {selectAllText}
        </div>
      </div>
      <div className="flex flex-col lg:flex-row items-center lg:max-w-sm lg:max-w-lg xl:max-w-2xl lg:justify-start overflow-x-auto text-xs days-container">
        {(width > 1023
          ? DaysOfTheWeekArray.Mobile
          : DaysOfTheWeekArray.Desktop
        ).map((day, index) => {
          return DayBlock(
            day,
            () => {
              handleClick(index)
            },
            selectedDaysArray[index],
            index
          )
        })}
      </div>
    </div>
  )
}

const DayBlock = (
  day: string,
  handleClick: (event: React.MouseEvent) => void,
  selected: boolean,
  index: number
) => {
  return (
    <div
      className={`flex-1 w-full m-[0.2rem] min-h-[2rem] select-none flex items-center justify-center rounded cursor-pointer 
      transition ease-in-out duration-300 hover:bg-teal-blue hover:text-white hover:border-[#285C69] hover:border-solid
      ${
        selected
          ? '!bg-teal-blue text-white px-2 py-1 border-[#285C69] border-solid'
          : 'px-2 py-1 !bg-[#FAFAFA] !border-[#DCD8D0] border-solid !text-black'
      }`}
      onClick={handleClick}
      key={index}
    >
      {selected ? (
        // <img
        //   src={SelectedTickIconSmall}
        //   alt="Selected Tick"
        //   className="max-w-[1.25rem] mr-1"
        // />
        <Selected />
      ) : null}
      <div className={`${selected ? 'ml-1' : ''}`}>{day}</div>
    </div>
  )
}

export default DaysOfTheWeekSelect
