import React, { useState } from 'react'
import { CloseCircleFilled } from '@ant-design/icons'
import { Button, Rate } from 'antd'
import { APICallType, useAPICall } from '../../../common'
import { API_URLs } from '../../../configuration/api-urls'

const RatingText = ['Terrible', 'Bad', 'Good', 'Very good', 'Excellent']

interface FeedbackToastProps {
  patientId?: string
  isChangeProviderFlow?: boolean
}
// PatientId
const FeedbackToast: React.FC<FeedbackToastProps> = ({
  patientId,
  isChangeProviderFlow,
}) => {
  const [isToastHidden, setIsToastHidden] = useState(false)
  const [rate, setRate] = useState(0)
  const [comments, setComments] = useState('')
  const { apiCall } = useAPICall()

  const postFeedback = () => {
    const postFeedbackPayload = {
      id: patientId,
      rating: `${rate}`,
      comments: comments,
    }
    apiCall(
      isChangeProviderFlow
        ? API_URLs.v1.POST_ChangeProviderFeedback
        : API_URLs.v1.POST_Feedback,
      APICallType.POST,
      JSON.stringify(postFeedbackPayload)
    ).catch((error) => {
      console.log(error)
    })
  }

  const hiddenStyle = { display: 'none' }
  return (
    <div
      className="flex flex-col p-2 rounded gap-2"
      style={
        isToastHidden
          ? hiddenStyle
          : {
              backgroundColor: 'white',
              color: 'black',
              border: '1px solid rgba(0, 0, 0, 0.13)',
              borderRadius: '4px',
            }
      }
    >
      <div
        className="flex flex-row justify-end "
        style={{ visibility: 'hidden' }}
      >
        <CloseCircleFilled
          style={{ fontSize: 20, color: '#B6B6B6' }}
          className="md:pr-2"
          onClick={() => {
            setIsToastHidden(true)
          }}
        />
      </div>
      <div className="font-medium text-lg -mt-4 mx-2 md:mx-8">
        {isChangeProviderFlow
          ? 'How would you rate your change provider experience?'
          : 'How would you rate your booking appointment experience?'}
      </div>
      <div className="flex flex-col">
        <Rate
          allowClear={false}
          onChange={setRate}
          value={rate}
          style={{ color: '#3F7A88', fontSize: '1.75rem' }}
        />
        {rate ? (
          <span className="ant-rate-text" style={{ color: '#3F7A88' }}>
            {RatingText[rate - 1]}
          </span>
        ) : (
          ''
        )}
      </div>
      <label htmlFor="comments" className="hidden">
        Comment
      </label>
      <textarea
        aria-label="comments"
        onChange={(e) => setComments(e.target.value)}
        style={{
          background: '#FBF9F9',
          border: '1px solid #B6B6B6',
          borderRadius: '4px',
          height: '5rem',
          margin: '0rem 10% 0rem',
          resize: 'none',
        }}
      />

      <Button
        type="default"
        className="submit-feedback-button disabled:opacity-40"
        disabled={rate === 0 ? true : false}
        onClick={() => {
          if (rate !== 0) {
            setIsToastHidden(true)
            postFeedback()
          }
        }}
      >
        Submit
      </Button>
    </div>
  )
}

export default FeedbackToast
