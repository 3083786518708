import { Button } from 'antd'
import { useHistory } from 'react-router-dom'

const NoTherapistsFound: React.FC<{ isPhpFlow: boolean }> = (isPhpFlow) => {
  const history = useHistory()
  return (
    <div className="flex flex-col w-11/12 md:w-1/2 items-center justify-center text-center text-xl m-auto mt-16">
      <div>
        {isPhpFlow ? (
          <p>
            Sorry, there are no therapists available at this moment for the
            selected state/filters. Please try again later or reach out to our
            customer Service at{' '}
            <a href="tel:+1(833)-724-9355" className="hover:underline">
              (833) 724-9355
            </a>{' '}
            |{' '}
            <a href="mailto:support@trusana.com" className="hover:underline">
              support@trusana.com
            </a>
            {''}.
          </p>
        ) : (
          <p>
            Sorry, there are no therapists available at the moment. But your
            details have been submitted for verification and you can book an
            appointment later using the details sent to your email address upon
            successful verification
          </p>
        )}
      </div>
      <div className="mt-6">
        {isPhpFlow ? (
          <Button
            type="default"
            shape="round"
            className="md:mr-8 "
            onClick={() => history.push('/')}
          >
            Go to Home
          </Button>
        ) : (
          <Button
            type="default"
            className="goto-button"
            onClick={() => history.push('/')}
          >
            Go to home
          </Button>
        )}
      </div>
    </div>
  )
}

export default NoTherapistsFound
