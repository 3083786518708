import { Form } from 'antd'
import React, { useContext, useEffect } from 'react'
import NewSubmitCancel from '../../../common/new-form/new-buttons/new-submit-cancel'
import AdditionalInformation from '../php-additional-information/php-additional-information'
import PhpAddress from '../php-address/php-address'
import PhpEmergencyContact from '../php-emergency-contact/php-emergency-contact'
import { PHPRegistrationContext } from '../php-registration/php-registration-middleware'

interface PhpAccountSetupProps {
  onPrevious: () => void
  onFinish: (values: any) => void
}

const PhpAccountSetup: React.FC<PhpAccountSetupProps> = ({
  onPrevious,
  onFinish,
}) => {
  const { primarySubscriberDetails } = useContext(PHPRegistrationContext)
  const [form] = Form.useForm()
  useEffect(() => {
    if (primarySubscriberDetails) {
      form.setFieldsValue({ ...primarySubscriberDetails })
    }
  }, [primarySubscriberDetails, form])
  return (
    <Form form={form} layout="vertical" onFinish={onFinish} scrollToFirstError>
      <div className="mx-4 md:ml-6 md:mr-8">
        <div className="formItems mt-4">
          <AdditionalInformation />
          <PhpAddress />
          <PhpEmergencyContact form={form} />
        </div>
      </div>
      <div className="bg-white md:bg-transparent">
        <NewSubmitCancel
          shouldDisplayPrevious={true}
          isSubmit={false}
          onPrevious={onPrevious}
        />
      </div>
    </Form>
  )
}

export default PhpAccountSetup
