import { ArrowUpOutlined } from '@ant-design/icons'

type ReturnToTopButtonProps = {
  onClick: () => void
  activeSection: string | null
}

const ReturnToTopButton: React.FC<ReturnToTopButtonProps> = ({
  onClick,
  activeSection,
}) => {
  return (
    <button
      className={`text-shade flex flex-row items-center justify-center text-xl cursor-pointer fixed drop-shadow-lg border-none bottom-20 lg:bottom-24 right-4 lg:right-8 w-[3rem] h-[3rem] rounded-full bg-snow z-[991] transition-all ease-in-out duration-1000 ${
        activeSection === 'whoWeServe' || activeSection === 'FAQ'
          ? 'opacity-100'
          : 'opacity-0'
      }`}
      data-testid={`btn-return-to-top`}
      onClick={onClick}
    >
      <ArrowUpOutlined />
    </button>
  )
}

export default ReturnToTopButton
