/* eslint-disable @typescript-eslint/no-explicit-any */
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { Avatar, Button, Calendar, Col, Divider, Row } from 'antd'
import dayjs from 'dayjs'
import moment from 'moment'
import { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { BackButton } from '../../../assets'
import { APICallType, flatQueryParams, useAPICall } from '../../../common'
import { useAPICallProps } from '../../../common/api-utils/use-api-call'
import { MetadataContext } from '../../../common/metadata/metadata-middleware'
import { API_URLs } from '../../../configuration/api-urls'
import { globalConfig } from '../../../configuration/config'
import { ChangeProviderContext } from '../../change-provider/change-provider-middleware'
import './calendar.scss'
import ChooseAvailableSlots from './choose-available-slots'
import { TimeSlotInterface } from './interfaces/single-therapist-slot-interface'
import { CalendarPageInterface } from './single-therapist-card'

interface SingleSlotType {
  date: string
  timeSlots: TimeSlotInterface[]
}

const CheckAvailability: React.FC<CalendarPageInterface> = ({
  name,
  ex,
  image,
  goBackToList,
  therapistId,
  bookAppointment,
  closeModal,
  userState,
  visitorId,
  clickNextPath,
  isChangeProviderFlow,
}) => {
  const { states, organizationData } = useContext(MetadataContext)
  const currentStateData = states.find((obj) => obj.code === userState)
  const calendarRef = useRef(null)
  const { apiCall } = useAPICall()
  const [slots, setSlots] = useState(new Array<SingleSlotType>())
  const [selectedDate, setSelectedDate] = useState(new Date())
  const providerAvailabilityDays = globalConfig.get().providerAvailabilityDays
  const [isLoadingTimeSlots, setIsLoadingTimeslots] = useState<boolean>(true)
  const [availableDays, setAvailableDays] = useState<SingleSlotType[]>([])

  const { userId } = useContext(ChangeProviderContext)

  useEffect(() => {
    setIsLoadingTimeslots(true)
    getNewSlots(new Date(moment.now()))

    let promise: Promise<useAPICallProps> | null = null
    let request = {}

    if (isChangeProviderFlow) {
      request = {
        providerId: therapistId,
        startDate: moment().format('YYYY-MM-DD'),
        endDate: moment()
          .add(providerAvailabilityDays, 'days')
          .format('YYYY-MM-DD'),
        userId: userId,
      }
      promise = apiCall(
        API_URLs.v1.POST_ChangeProviderAvailableTimeSlots,
        APICallType.POST,
        JSON.stringify(request)
      )
    } else {
      request = {
        providerId: therapistId,
        startDate: moment().format('YYYY-MM-DD'),
        endDate: moment()
          .add(providerAvailabilityDays, 'days')
          .format('YYYY-MM-DD'),
        visitorId: visitorId,
        isCalendarView: false,
        organizationId: `${organizationData?.id}`,
      }
      promise = apiCall(
        API_URLs.v7.POST_AppointmentAvailableTimeSlots,
        APICallType.POST,
        JSON.stringify(request)
      )
    }

    promise
      .then((data) => {
        setAvailableDays(data)
        setIsLoadingTimeslots(false)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])

  // Grey dates on Calendar
  useEffect(() => {
    const elems: NodeListOf<HTMLElement> =
      document.querySelectorAll('.grey-date')
    ;[].forEach.call(elems, function (el: HTMLElement) {
      el.classList.remove('grey-date')
    })
    if (Object.keys(availableDays).length > 0) {
      availableDays.map((item) => {
        if (item.timeSlots.length >= 0) {
          const ele = document.querySelector(`[title="${item.date}"]`)
          if (
            !ele?.classList.contains('ant-picker-cell-disabled') &&
            ele?.classList.contains('ant-picker-cell-in-view')
          ) {
            ele?.classList.add('grey-date')
          }
        }
      })
    }
  }, [availableDays, selectedDate, isLoadingTimeSlots])

  const timeSlots = useMemo(() => {
    if (Object.keys(slots).length > 0) {
      const timeslot =
        slots.find(
          (slot) => slot.date === moment(selectedDate).format('YYYY-MM-DD')
        ) || null
      return timeslot?.timeSlots
    }
  }, [slots, selectedDate])

  /** Calendar component helper functions */

  const handlePrev = () => {
    const newDate = dayjs(selectedDate)
      .subtract(1, 'month')
      .isAfter(dayjs().toDate())
      ? dayjs(selectedDate).subtract(1, 'month').toDate()
      : dayjs().toDate()
    getNewSlots(newDate)
    setSelectedDate(newDate)
  }

  const handleNext = () => {
    const newDate = dayjs(selectedDate)
      .add(1, 'month')
      .isBefore(dayjs().add(providerAvailabilityDays, 'days').toDate())
      ? dayjs(selectedDate).add(1, 'month').toDate()
      : dayjs().add(providerAvailabilityDays, 'days').toDate()
    getNewSlots(moment(newDate).startOf('month').toDate())
    setSelectedDate(newDate)
  }

  const disabledDate = (currentDate: moment.Moment) => {
    /** Return true if the current date is before today's date or if it is after providerAvailabilityDays (60) days from now */
    const today = moment()
    const providerAvailabilityLength = moment().add(
      providerAvailabilityDays,
      'days'
    )

    return (
      currentDate.isBefore(today, 'day') ||
      currentDate.isAfter(providerAvailabilityLength, 'day')
    )
  }

  const getNewSlots = async (value: Date) => {
    setIsLoadingTimeslots(true)
    setSelectedDate(value)

    let promise: Promise<useAPICallProps> | null = null
    let request = {}

    if (isChangeProviderFlow) {
      request = {
        providerId: therapistId,
        startDate: moment(value).format('YYYY-MM-DD'),
        endDate: moment(value).add(1, 'day').format('YYYY-MM-DD'),
        userId: userId,
      }
      promise = apiCall(
        API_URLs.v1.POST_ChangeProviderAvailableTimeSlots,
        APICallType.POST,
        JSON.stringify(request)
      )
    } else {
      request = {
        providerId: therapistId,
        startDate: moment(value).format('YYYY-MM-DD'),
        endDate: moment(value).add(1, 'day').format('YYYY-MM-DD'),
        visitorId: visitorId,
        organizationId: `${organizationData?.id}`,
        isCalendarView: true,
      }
      promise = apiCall(
        API_URLs.v7.POST_AppointmentAvailableTimeSlots,
        APICallType.POST,
        JSON.stringify(request)
      )
    }

    promise
      .then((data) => {
        setSlots((prevSlots) => {
          return Object.values(
            [...prevSlots, ...data].reduce((acc, obj) => {
              acc[obj.date] = obj
              return acc
            }, {})
          )
        })
        setIsLoadingTimeslots(false)
      })
      .catch((error) => {
        if (
          error.message.length > 0 &&
          JSON.parse(error.message)[0]?.description.includes('No records found')
        ) {
          setIsLoadingTimeslots(false)
        }
      })
  }

  const CircleIcon = (props: any) => {
    const { type } = props
    return (
      <div
        className={type === 'filled' ? 'circle-icon-filled' : 'circle-icon'}
      ></div>
    )
  }

  return (
    <div className="h-full md:p-4 check-availability-container bg-light-sun">
      <div className="book-heading bg-light-sun xs:mb-4">
        <div
          className={`md:ml-4 md:pl-4 flex flex-row items-center justofy-center font-semibold text-3xl text-[#3F7A88] poppins`}
        >
          <BackButton onClick={goBackToList} />
          <span className="text-shade my-4 ml-4">Select a date and time</span>
        </div>
      </div>
      <div className="hidden md:block">
        <div className="calendar-div hidden pb-4 sm:block">
          <span className="calendar-avatar">
            <Avatar
              size={{ xs: 30, sm: 40, md: 50, lg: 40, xl: 50, xxl: 60 }}
              icon={<img src={image} alt="" />}
            />
          </span>
          <div className="calendar-page-details">
            <p className="doctor-name">{name}</p>
            <p className="doctor-ex">{ex}</p>
          </div>
        </div>
        <Divider className="sm:block hidden" />
      </div>
      <span className="xs:pl-8 xs:mt-2 md:float-right font-normal text-xs leading-4 not-italic tracking-normal poppins text-black">
        *{currentStateData?.uiTimezone}
      </span>
      <Row>
        <Col sm={24} md={24} xl={10}>
          <div
            ref={calendarRef}
            className="calendar px-4 md:w-4/5 mt-5 sm:mt-8"
          >
            <span className="select-date-time-text flex justify-center mb-3.5">
              {/* Select a date and time */}
            </span>
            <Calendar
              fullscreen={false}
              className="cal"
              disabledDate={disabledDate}
              headerRender={() => (
                <Row justify="space-between" className="p-1">
                  <Button
                    type="primary"
                    onClick={handlePrev}
                    className="ml-2 mt-2 mb-2 calendar-arrow-button"
                    aria-label="Calendar-left"
                  >
                    <LeftOutlined />
                  </Button>
                  <div className="datetime mt-2">
                    {dayjs(selectedDate).format('MMMM YYYY')}
                  </div>
                  <Button
                    type="primary"
                    onClick={handleNext}
                    className="mr-2 mt-2 mb-2 calendar-arrow-button"
                    aria-label="Calendar-right"
                  >
                    <RightOutlined />
                  </Button>
                </Row>
              )}
              value={moment(selectedDate)}
              onSelect={(value) => getNewSlots(value.toDate())}
            />
            <div className="flex felx-row gap-6 mt-2">
              <div className="flex felx-row gap-1">
                <CircleIcon />
                <div>Available</div>
              </div>
              <div className="flex felx-row gap-1">
                <CircleIcon type="filled" />
                <div>Selected</div>
              </div>
            </div>
          </div>
        </Col>
        <Col sm={24} md={24} xl={14}>
          <div>
            <ChooseAvailableSlots
              timeSlots={timeSlots}
              bookAppointment={bookAppointment}
              therapistId={therapistId}
              therapistName={name}
              clickNextPath={clickNextPath}
              closeModal={closeModal}
              isLoadingTimeSlots={isLoadingTimeSlots}
              isChangeProviderFlow={isChangeProviderFlow}
            />
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default CheckAvailability
