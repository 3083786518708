import { Moment } from 'moment'
import React, {
  createContext,
  FunctionComponent,
  useContext,
  useState,
} from 'react'
import {
  MetadataContext,
  StateAgesInterface,
} from '../../../common/metadata/metadata-middleware'
import { calculateAge } from '../../../common/utils/date-utils'
import { ExistenceType } from '../../EAP/EAP-constants'

export interface PHPRegistrationContextInterface {
  currentStep: number
  updateCurrentStep: (step: number) => void
  imgData: any
  setImgData: React.Dispatch<any>
  isImageUploaded: boolean
  setIsImageUploaded: React.Dispatch<React.SetStateAction<boolean>>
  primarySubscriberDetails: any
  setPrimarySubscriberDetails: React.Dispatch<any>
  updatePrimarySubscriberDetails: (values: any) => void
  dependentDetails: any
  setDependentDetails: React.Dispatch<any>
  userState: string
  setUserState: React.Dispatch<React.SetStateAction<string>>
  isValidConsentAgeForState: (DOB: Moment, state: string) => boolean
  userAge: number
  isAgeErrorModalOpen: boolean
  toggleAgeErrorModal: () => void
  emailValidationForDependentOverAgeScenario: (
    dependentEmail: string,
    caregiverEmail: string,
    dependentAge: number,
    state: string
  ) => boolean
  shouldUseCaregiverEmail: (age: number, state: string) => boolean
  bookingDate: any
  setBookingDate: React.Dispatch<any>
  therapistID: any
  setTherapistID: React.Dispatch<any>
  insuranceDocumentID: string | null
  updateInsuranceDocumentID: (insuranceID: string | null) => void
  visitorId: string | null
  updateVisitorId: (visitorId: string | null) => void
  isInsuranceImageUploaded: boolean
  setIsInsuranceImageUploaded: React.Dispatch<React.SetStateAction<boolean>>
  isInsuranceDetailsPresent: boolean
  setIsInsuranceDetailsPresent: React.Dispatch<React.SetStateAction<boolean>>
  existenceType: ExistenceType | null
  updateExistenceType: (existenceType: ExistenceType | null) => void
  isHamburgerOpen: boolean
  toggleIsHamburgerOpen: () => void
  clearContextAfterPatientCreation: () => void
}
interface PHPRegistrationMiddlewareProps {
  children: React.ReactNode
}

export const PHPRegistrationContext = createContext(
  {} as PHPRegistrationContextInterface
)

const PHPRegistrationMiddleware: FunctionComponent<
  PHPRegistrationMiddlewareProps
> = ({ children }) => {
  const [currentStep, setCurrentStep] = useState<number>(0)
  const [imgData, setImgData] = useState<any>(null)
  const [isImageUploaded, setIsImageUploaded] = useState(false)
  const [existenceType, setExistenceType] = useState<ExistenceType | null>(null)
  const updateExistenceType = (existenceType: ExistenceType | null) => {
    setExistenceType(existenceType)
  }
  const [primarySubscriberDetails, setPrimarySubscriberDetails] = useState<any>(
    {}
  )

  const updatePrimarySubscriberDetails = (values: any) => {
    setPrimarySubscriberDetails((prevPrimarySubscriberDetails: any) => {
      return {
        ...prevPrimarySubscriberDetails,
        ...values,
      }
    })
  }
  const [householdMemberDetails, setHouseholdMemberDetails] = useState<any>({})
  const [isAgeErrorModalOpen, setIsAgeErrorModalOpen] = useState(false)
  const [userAge, setUserAge] = useState(0)
  const [userState, setUserState] = useState('')
  const { stateAges } = useContext(MetadataContext)
  const [bookingDate, setBookingDate] = useState(null)
  const [therapistID, setTherapistID] = useState(null)
  const [insuranceDocumentID, setInsuranceDocumentID] = useState<string | null>(
    null
  )
  const [isInsuranceImageUploaded, setIsInsuranceImageUploaded] =
    useState(false)
  const [isInsuranceDetailsPresent, setIsInsuranceDetailsPresent] =
    useState(false)
  const [isHamburgerOpen, setIsHamburgerOpen] = useState(true)
  const toggleIsHamburgerOpen = () => {
    setIsHamburgerOpen((isHamburgerOpen) => !isHamburgerOpen)
  }
  const [visitorId, setVisitorId] = useState<string | null>(null)
  const updateVisitorId = (visitorId: string | null) => {
    setVisitorId(visitorId)
  }
  const updateInsuranceDocumentID = (insuranceDocumentID: string | null) => {
    setInsuranceDocumentID(insuranceDocumentID)
  }
  const toggleAgeErrorModal = () => {
    setIsAgeErrorModalOpen(!isAgeErrorModalOpen)
  }
  const isValidConsentAgeForState = (DOB: Moment, state: string) => {
    const age = calculateAge(DOB)
    setUserAge(age)
    const DEFAULT_AGE = 15
    const filter = stateAges.filter(
      (item: StateAgesInterface) => item.state == state
    )
    if (filter && filter.length > 0) {
      return age >= filter[0].age
    } else {
      return age >= DEFAULT_AGE
    }
  }
  const emailValidationForDependentOverAgeScenario = (
    dependentEmail: string,
    caregiverEmail: string,
    dependentAge: number,
    state: string
  ) => {
    const DEFAULT_AGE = 15
    let stateAge = DEFAULT_AGE
    const filter = stateAges.filter((item) => item.state == state)
    if (filter && filter.length > 0) {
      stateAge = filter[0].age
    }
    if (dependentAge > stateAge && dependentEmail == caregiverEmail) {
      return true
    }
    return false
  }
  const shouldUseCaregiverEmail = (age: number, state: string) => {
    const DEFAULT_AGE = 15
    let stateAge = DEFAULT_AGE
    const filter = stateAges.filter((item) => item.state == state)
    if (filter && filter.length > 0) {
      stateAge = filter[0].age
    }

    if (age > stateAge) {
      return false
    }
    return true
  }

  const updateCurrentStep = (step: number) => {
    setCurrentStep(step)
  }

  const clearContextAfterPatientCreation = () => {
    setPrimarySubscriberDetails(null)
  }

  return (
    <PHPRegistrationContext.Provider
      value={{
        primarySubscriberDetails,
        isAgeErrorModalOpen,
        dependentDetails: householdMemberDetails,
        userAge,
        userState,
        setPrimarySubscriberDetails,
        updatePrimarySubscriberDetails,
        setDependentDetails: setHouseholdMemberDetails,
        setUserState,
        toggleAgeErrorModal,
        isValidConsentAgeForState,
        emailValidationForDependentOverAgeScenario,
        shouldUseCaregiverEmail,
        bookingDate,
        setBookingDate,
        therapistID,
        setTherapistID,
        currentStep,
        updateCurrentStep,
        imgData,
        setImgData,
        isImageUploaded,
        setIsImageUploaded,
        insuranceDocumentID,
        updateInsuranceDocumentID,
        visitorId,
        updateVisitorId,
        isInsuranceImageUploaded,
        setIsInsuranceImageUploaded,
        isInsuranceDetailsPresent,
        setIsInsuranceDetailsPresent,
        existenceType,
        updateExistenceType,
        isHamburgerOpen,
        toggleIsHamburgerOpen,
        clearContextAfterPatientCreation,
      }}
    >
      {children}
    </PHPRegistrationContext.Provider>
  )
}

export default PHPRegistrationMiddleware
