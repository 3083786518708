import Checkbox, { CheckboxChangeEvent } from 'antd/lib/checkbox'

interface SpecializationCheckboxItemInterface {
  specialisation: string
  checked: boolean
  updateSelectedItems: (specialisation: string, shouldAdd: boolean) => void
  selectedItems: string[]
}

const SpecializationCheckboxItem: React.FC<
  SpecializationCheckboxItemInterface
> = ({ specialisation, checked, updateSelectedItems, selectedItems }) => {
  const onChange = (e: CheckboxChangeEvent) => {
    e.stopPropagation()
    if (e.target.checked && selectedItems.length === 3) return
    updateSelectedItems(specialisation, e.target.checked)
  }

  return (
    <Checkbox
      checked={checked}
      onChange={onChange}
      className="select-none w-full text-xs leading-[18px] mb-2 max-w-[10rem] md:max-w-sm"
      data-testid={`filter-concerns-chk-${specialisation.replaceAll(' ', '')}`}
    >
      <div className="md:max-w-sm text-ellipsis">{specialisation}</div>
    </Checkbox>
  )
}

export default SpecializationCheckboxItem
