import { Moment } from 'moment'
import React, {
  createContext,
  FunctionComponent,
  useContext,
  useState,
} from 'react'
import { MetadataContext } from '../../../common/metadata/metadata-middleware'
import { calculateAge } from '../../../common/utils/date-utils'
export interface EAPRegistrationContextInterface {
  caregiverDetails: any
  setCaregiverDetails: React.Dispatch<any>
  householdMemberDetails: any
  setHouseholdMemberDetails: React.Dispatch<any>
  imgData: any
  setImgData: React.Dispatch<any>
  isImageUploaded: boolean
  setIsImageUploaded: React.Dispatch<React.SetStateAction<boolean>>
  current: number
  setCurrent: React.Dispatch<React.SetStateAction<number>>
  userState: string
  setUserState: React.Dispatch<React.SetStateAction<string>>
  typeOfId: string
  setTypeOfId: React.Dispatch<React.SetStateAction<string>>
  isValidConsentAgeForState: (DOB: Moment, state: string) => boolean
  userAge: number
  isAgeErrorModalOpen: boolean
  toggleAgeErrorModal: () => void
  onFinish?: (values: any) => void
  emailValidationForDependentOverAgeScenario: (
    dependentEmail: string,
    caregiverEmail: string,
    dependentAge: number,
    state: string
  ) => boolean
  isEmailOtpValidated: boolean
  isMobileOtpValidated: boolean
  toggleEmailOtpValidation: (isValidated: boolean) => void
  toggleMobileOtpValidation: (isValidated: boolean) => void
  shouldUseCaregiverEmail: (age: number, state: string) => boolean
  shouldDisplayEmailVerificationErrorMessage: () => boolean
  visitorId: any
  updateVisitorId: (visitorId: string | null) => void
  patientType: any
  setPatientType: React.Dispatch<any>
  updatePatientType: (patientType: string | null) => void
  shouldDisplayPassword: boolean
  setShouldDisplayPassword: React.Dispatch<boolean>
  existenceType: any
  setExistenceType: React.Dispatch<any>
  clearContextAfterBooking: () => void
  careMemberType: string
  setCareMemberType: React.Dispatch<React.SetStateAction<string>>
  accountExists: boolean | null
  setAccountExists: React.Dispatch<React.SetStateAction<boolean | null>>
  accInfoOpen: boolean | null
  setAccInfoOpen: React.Dispatch<React.SetStateAction<boolean | null>>
  additionalInfoOpen: boolean | null
  setAdditionalInfoOpen: React.Dispatch<React.SetStateAction<boolean | null>>
  disableConfirm: boolean | null
  updateDisableConfirm: (value: boolean | null) => void
}

export const EAPRegistrationContext = createContext(
  {} as EAPRegistrationContextInterface
)

const EAPRegistrationMiddleware: FunctionComponent<{
  children: React.ReactNode
}> = ({ children }) => {
  const { stateAges } = useContext(MetadataContext)
  const [caregiverDetails, setCaregiverDetails] = useState<any>({})
  const [householdMemberDetails, setHouseholdMemberDetails] = useState<any>({})
  const [current, setCurrent] = useState(0)
  const [isAgeErrorModalOpen, setIsAgeErrorModalOpen] = useState(false)
  const [isEmailOtpValidated, setIsEmailOtpValidated] = useState(false)
  const [isMobileOtpValidated, setIsMobileOtpValidated] = useState(false)
  const [userAge, setUserAge] = useState(0)
  const [userState, setUserState] = useState('')
  const [typeOfId, setTypeOfId] = useState('')
  const [imgData, setImgData] = useState<any>()
  const [isImageUploaded, setIsImageUploaded] = useState(false)
  const [visitorId, setVisitorId] = useState<string | null>(null)
  const [patientType, setPatientType] = useState<string | null>(null)
  const [shouldDisplayPassword, setShouldDisplayPassword] = useState(true)
  const [existenceType, setExistenceType] = useState(null)
  const [careMemberType, setCareMemberType] = useState('')
  const [accountExists, setAccountExists] = useState<null | boolean>(null)
  const [accInfoOpen, setAccInfoOpen] = useState<null | boolean>(null)
  const [additionalInfoOpen, setAdditionalInfoOpen] = useState<null | boolean>(
    null
  )
  const [disableConfirm, setDisableConfirm] = useState<boolean | null>(null)
  const toggleEmailOtpValidation = (isValidated: boolean) => {
    setIsEmailOtpValidated(isValidated)
  }
  const updatePatientType = (patientType: string | null) => {
    setPatientType(patientType)
  }

  const clearContextAfterBooking = () => {
    setCaregiverDetails(null)
    setHouseholdMemberDetails(null)
  }

  const updateVisitorId = (visitorId: string | null) => {
    setVisitorId(visitorId)
  }

  const updateDisableConfirm = (value: boolean | null) => {
    setDisableConfirm(value)
  }
  const toggleMobileOtpValidation = (isValidated: boolean) => {
    setIsMobileOtpValidated(isValidated)
  }
  const toggleAgeErrorModal = () => {
    setIsAgeErrorModalOpen(!isAgeErrorModalOpen)
  }
  const isValidConsentAgeForState = (DOB: Moment, state: string) => {
    const age = calculateAge(DOB)
    setUserAge(age)
    const DEFAULT_AGE = 15
    const filter = stateAges.filter((item) => item.state == state)
    if (filter && filter.length > 0) {
      return age >= filter[0].age
    } else {
      return age >= DEFAULT_AGE
    }
  }
  const emailValidationForDependentOverAgeScenario = (
    dependentEmail: string,
    caregiverEmail: string,
    dependentAge: number,
    state: string
  ) => {
    const DEFAULT_AGE = 15
    let stateAge = DEFAULT_AGE
    const filter = stateAges.filter((item) => item.state == state)
    if (filter && filter.length > 0) {
      stateAge = filter[0].age
    }

    if (dependentAge > stateAge && dependentEmail == caregiverEmail) {
      return true
    }
    return false
  }
  const shouldUseCaregiverEmail = (age: number, state: string) => {
    const DEFAULT_AGE = 15
    let stateAge = DEFAULT_AGE
    const filter = stateAges.filter((item) => item.state == state)
    if (filter && filter.length > 0) {
      stateAge = filter[0].age
    }

    if (age > stateAge) {
      return false
    }
    return true
  }
  const shouldDisplayEmailVerificationErrorMessage = () => {
    return isEmailOtpValidated
  }

  return (
    <EAPRegistrationContext.Provider
      value={{
        caregiverDetails,
        setCaregiverDetails,
        householdMemberDetails,
        setHouseholdMemberDetails,
        current,
        setCurrent,
        toggleAgeErrorModal,
        userState,
        setUserState,
        isValidConsentAgeForState,
        userAge,
        isAgeErrorModalOpen,
        emailValidationForDependentOverAgeScenario,
        shouldUseCaregiverEmail,
        isEmailOtpValidated,
        isMobileOtpValidated,
        toggleEmailOtpValidation,
        toggleMobileOtpValidation,
        shouldDisplayEmailVerificationErrorMessage,
        typeOfId,
        setTypeOfId,
        imgData,
        setImgData,
        isImageUploaded,
        setIsImageUploaded,
        visitorId,
        updateVisitorId,
        patientType,
        setPatientType,
        updatePatientType,
        shouldDisplayPassword,
        setShouldDisplayPassword,
        existenceType,
        setExistenceType,
        clearContextAfterBooking,
        careMemberType,
        setCareMemberType,
        accountExists,
        setAccountExists,
        accInfoOpen,
        setAccInfoOpen,
        additionalInfoOpen,
        setAdditionalInfoOpen,
        disableConfirm,
        updateDisableConfirm
      }}
    >
      {children}
    </EAPRegistrationContext.Provider>
  )
}

export default EAPRegistrationMiddleware
