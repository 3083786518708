import React, { FunctionComponent, createContext, useState } from 'react'

export interface IChangeProviderContext {
  userId: string
  setUserId: React.Dispatch<React.SetStateAction<string>>
  userState: string
  setUserState: React.Dispatch<React.SetStateAction<string>>
  appointmentDetails: any
  setAppointmentDetails: React.Dispatch<React.SetStateAction<any>>
  clearChangeProviderContext: () => void
}

export const ChangeProviderContext = createContext({} as IChangeProviderContext)

const ChangeProviderMiddleware: FunctionComponent<{
  children: React.ReactNode
}> = ({ children }) => {
  const [userId, setUserId] = useState('')
  const [userState, setUserState] = useState('')
  const [appointmentDetails, setAppointmentDetails] = useState({
    abbr: '',
    providerName: '',
    appointmentDate: '',
    isRatingEnabled: false,
  })

  const clearChangeProviderContext = () => {
    setAppointmentDetails({
      providerName: '',
      abbr: '',
      appointmentDate: '',
      isRatingEnabled: false,
    })
    setUserState('')
    setUserId('')
  }

  return (
    <>
      <ChangeProviderContext.Provider
        value={{
          userId,
          setUserId,
          userState,
          setUserState,
          appointmentDetails,
          setAppointmentDetails,
          clearChangeProviderContext,
        }}
      >
        {children}
      </ChangeProviderContext.Provider>
    </>
  )
}

export default ChangeProviderMiddleware
