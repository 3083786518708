/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FunctionComponent, useContext, useEffect } from 'react'
import { APICallType, useAPICall, useGet } from '../../common'
import {
  GenderInterface,
  MetadataContext,
  PatientTypesInterface,
  RelationsInterface,
  StateAgesInterface,
  StatesInterface,
  SpecializationsInterface,
} from '../../common/metadata/metadata-middleware'
import '../EAP/your-details.scss'
import { API_URLs } from '../../configuration/api-urls'
import AppointmentPageMiddleware from '../EAP/appointment-page/appointment-page-middleware'
import PhpFormFlow from './php-form-flow/php-form-flow'
import PHPRegistrationMiddleware from './php-registration/php-registration-middleware'
import useLoadOrganizationMetaData from '../../common/hooks/use-load-organization-metadata'
import { useParams } from 'react-router-dom'
import ErrorPage from '../ErrorPage'
import PhpComingSoon from './php-coming-soon/php-coming-soon'
import { Spin } from 'antd'
import ChangeProviderMiddleware from '../change-provider/change-provider-middleware'

const PHPFlow: FunctionComponent = () => {
  const {
    setStates,
    setStateAges,
    setGenderList,
    setPatientTypes,
    setFeatureFlags,
    setSpecializations,
    setOrganizationData,
    setRelationshipData,
    featureFlags,
    isPhpEnabled,
    organizationData,
  } = useContext(MetadataContext)
  const { apiCall } = useAPICall()
  const { step } = useParams<{ step: string }>()
  const statesData = useGet<StatesInterface[]>({
    url: API_URLs.v1.GET_MetadataStates,
  })
  const stateAgesData = useGet<StateAgesInterface[]>({
    url: API_URLs.v1.GET_MetadataStateAges,
  })
  const relationshipsData = useGet<RelationsInterface[]>({
    url: API_URLs.v1.GET_MetadataRelations,
  })
  const patientTypesData = useGet<PatientTypesInterface[]>({
    url: API_URLs.v1.GET_PatientTypes,
  })

  const genderData = useGet<GenderInterface[]>({ url: API_URLs.v1.GET_Genders })
  const specializations = useGet<SpecializationsInterface[]>({
    url: API_URLs.v1.GET_Specializations,
  })

  useLoadOrganizationMetaData()

  useEffect(() => {
    if (statesData && statesData.length > 0) {
      setStates(statesData)
    }
  }, [statesData])
  useEffect(() => {
    if (stateAgesData && stateAgesData.length > 0) {
      setStateAges(stateAgesData)
    }
  }, [stateAgesData])
  useEffect(() => {
    if (relationshipsData && relationshipsData.length > 0) {
      setRelationshipData(relationshipsData)
    }
  }, [relationshipsData])
  useEffect(() => {
    if (patientTypesData && patientTypesData.length > 0) {
      setPatientTypes(patientTypesData)
    }
  }, [patientTypesData])
  useEffect(() => {
    if (genderData && genderData.length > 0) {
      setGenderList(genderData)
    }
  }, [genderData])

  useEffect(() => {
    if (specializations && specializations.length > 0) {
      setSpecializations(specializations)
    }
  }, [specializations])

  useEffect(() => {
    if (organizationData === null) {
      apiCall(
        `${API_URLs.v1.GET_OrganizationValidate}?Text=${'Providence'}`,
        APICallType.GET
      ).then((response: any) => {
        if (response.id !== null || '') {
          setOrganizationData(response)
        }
      })
    }
  }, [])

  // re fetch the feature flags in eapflow - To handle direct landing or refresh of the page
  const featureFlagsData = useGet<any[]>({
    url: API_URLs.v1.GET_MetadataFeatureFlags,
    isCancelled: featureFlags.length > 0,
  })

  useEffect(() => {
    if (featureFlagsData && featureFlagsData.length > 0) {
      setFeatureFlags(featureFlagsData)
    }
  }, [featureFlagsData])

  return (
    <>
      {' '}
      {featureFlags.length > 0 ? (
        step ? (
          true ? (
            <PHPRegistrationMiddleware>
              <ChangeProviderMiddleware>
                <AppointmentPageMiddleware>
                  <PhpFormFlow />
                </AppointmentPageMiddleware>
              </ChangeProviderMiddleware>
            </PHPRegistrationMiddleware>
          ) : (
            <PhpComingSoon />
          )
        ) : (
          <ErrorPage />
        )
      ) : (
        <div className="flex items-center justify-center w-full">
          <Spin size="large" className="mt-32" />
        </div>
      )}
    </>
  )
}

export default PHPFlow
