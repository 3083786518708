import React from 'react'
import './password-rules-display.scss'

interface PasswordRulesDisplayProps {
  value?: string
}
const PhpPasswordRulesDisplay: React.FC<PasswordRulesDisplayProps> = ({}) => {
  return (
    <div className="md:float-right w-full">
      <div className="php-main-box flex flex-col">
        <div
          style={{ fontSize: '0.9rem' }}
          className="bg-[#F4F4F4] text-[#3f7a88] border-b-[#CBCBCB] border-b p-3 font-semibold text-[15px] "
        >
          Instructions for creating a strong password
        </div>
        <div className="bg-white py-3 pr-3 font-medium text-sm">
          <ul style={{ fontSize: '0.85rem', color: '#314259' }}>
            <li>Avoid common passwords (e.g. “12345678”)</li>
            <li>Use uppercase and lowercase letters</li>
            <li>Use numbers and/or punctuation marks</li>
          </ul>
        </div>
      </div>
    </div>
  )
}
export default PhpPasswordRulesDisplay
