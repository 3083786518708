import moment, { Moment } from 'moment'

export const calculateAge = (date: Moment) => {
  if (!moment.isMoment(date)) {
    return 0
  }
  const currentDate = moment()
  const age = currentDate.startOf('day').diff(date.startOf('day'), 'years')
  return age
}

export const getDateToDisplay = (item: any) => {
  const date = new Date(item.replace(/-/g, '/'))
  return moment.parseZone(date, 'YYYY-MM-DDTHH:mm').format('ddd, MMM D')
}

export const isShowIDUpload = (date: Moment) => {
  /**
   * === 18 - true
   * < 18 - true
   * > 18 - false
   */
  return !(moment().diff(date.endOf('day'), 'years', true) > 18)
}

export const isAboveAgeOfConsentForState = (
  DOB: Moment,
  state: string,
  stateAges: any
) => {
  const filter = stateAges.filter(
    (item: { state: string }) => item.state == state
  )
  if (filter && filter.length > 0) {
    return moment()
      .startOf('day')
      .isSameOrAfter(DOB.clone().startOf('day').add(filter[0].age, 'years'))
  }

  return false
}
