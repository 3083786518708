import React, { createContext, FunctionComponent, useState } from 'react'
import { globalConfig } from '../../../configuration/config'

interface PopupInterface {
  isModalDisplaying: boolean
  isAlreadyDisplayed: boolean
}
export interface PopupTimerContextInterface {
  remaining: number
  setRemaining: React.Dispatch<number>
  handleRemaining: (time: number) => void
  registerationTimerPopup: PopupInterface
  appointmentTimerPopup: PopupInterface
  handleRegisterationTimerPopup: (a: PopupInterface) => void
  handleAppointmentTimerPopup: (a: PopupInterface) => void
}

export const PopupTimerContext = createContext({} as PopupTimerContextInterface)

const PopupTimerMiddleware: FunctionComponent<{
  children: React.ReactNode
}> = ({ children }) => {
  const [remaining, setRemaining] = useState<number>(
    globalConfig.get().inactivityPopupTimer
  )

  const [registerationTimerPopup, setRegisterationTimerPopup] = useState({
    isModalDisplaying: false,
    isAlreadyDisplayed: false,
  })
  const [appointmentTimerPopup, setAppointmentTimerPopup] = useState({
    isModalDisplaying: false,
    isAlreadyDisplayed: false,
  })

  const handleRemaining = (time: number) => {
    setRemaining(time)
  }

  const handleRegisterationTimerPopup = ({
    isModalDisplaying,
    isAlreadyDisplayed,
  }: PopupInterface) => {
    setRegisterationTimerPopup({
      isModalDisplaying,
      isAlreadyDisplayed,
    })
  }

  const handleAppointmentTimerPopup = ({
    isModalDisplaying,
    isAlreadyDisplayed,
  }: PopupInterface) => {
    setAppointmentTimerPopup({
      isModalDisplaying,
      isAlreadyDisplayed,
    })
  }

  return (
    <PopupTimerContext.Provider
      value={{
        remaining,
        setRemaining,
        handleRemaining,
        registerationTimerPopup,
        handleRegisterationTimerPopup,
        appointmentTimerPopup,
        handleAppointmentTimerPopup,
      }}
    >
      {children}
    </PopupTimerContext.Provider>
  )
}

export default PopupTimerMiddleware
