import { LeftCircleFilled } from '@ant-design/icons'

interface BackButtonProps {
  onClick: () => void
  className?: string
}

const BackButton: React.FC<BackButtonProps> = ({ onClick, className = '' }) => {
  return (
    <LeftCircleFilled
      className={` ${className} flex items-center justify-center cursor-pointer text-lg md:text-xl lg:text-2xl xl:text-2xl text-center text-shade mr-2`}
      onClick={onClick}
      data-testid="back-button"
    />
  )
}

export default BackButton
