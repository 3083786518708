import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import {
  onboardingFormPath,
  userAccountTypePath,
  userFlowPath,
  companyCodePathIdentifier,
  changeProviderLoginPath,
} from '../../routing/route.constants'

export const useIsBrandLogoShown = () => {
  const location = useLocation()
  const [isBrandLogoShown, setIsBrandLogoShown] = useState<boolean>(false)

  const isBrandLogoNeedsToShown = () => {
    return (
      location.pathname.includes(userAccountTypePath) ||
      location.pathname.includes(userFlowPath) ||
      location.pathname.includes(onboardingFormPath) ||
      location.pathname.includes(companyCodePathIdentifier) ||
      location.pathname.includes(changeProviderLoginPath)
    )
  }

  useEffect(() => {
    if (isBrandLogoNeedsToShown()) setIsBrandLogoShown(false)
    else {
      setIsBrandLogoShown(true)
    }
  }, [location])

  return isBrandLogoShown
}

export default useIsBrandLogoShown
