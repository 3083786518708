import { Router } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import { AppLayout } from './core'
import './app.scss'

const App = () => {
  const history = createBrowserHistory()
  return (
    <Router history={history}>
      <AppLayout />
    </Router>
  )
}

export default App
