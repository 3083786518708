import {
  removeEmTags,
  removeHighlightedTagStrings,
} from '../../../../common/form/form.utils'
import TagDescriptionContainer from '../../../../common/tag-description-container/tag-description-container'

interface TherapistTagSectionInterface {
  keyPhraseTags: any
  shouldShowAllKeyPhrases: any
  setShouldShowAllKeyPhrases: any
  displayCount: number
  matchingKeyphrases?: string[]
  isCurrentProviderCard?: boolean
}

const TherapistTagSection: React.FC<TherapistTagSectionInterface> = ({
  keyPhraseTags,
  shouldShowAllKeyPhrases,
  setShouldShowAllKeyPhrases,
  displayCount,
  matchingKeyphrases = [],
  isCurrentProviderCard,
}) => {
  //TODO: Remove below line once backend returns tags without <em>tag</em> formatting, and use matchingKeyPhrases directly
  const matchedPhrases = removeEmTags(matchingKeyphrases)

  const unmatchedPhrases = removeHighlightedTagStrings(
    matchedPhrases,
    keyPhraseTags
  )

  return matchedPhrases.length + unmatchedPhrases.length > 0 ? (
    <div
      className={`flex flex-row flex-container flex-wrap overflow-y-scroll ${
        shouldShowAllKeyPhrases ? '' : 'mb-0'
      }`}
    >
      {matchedPhrases.length > 0
        ? matchedPhrases.map((tag: string, index: number) => {
            return (
              <TagDescriptionContainer
                className="child"
                tag={tag}
                key={index}
                isHighlighted={true}
                isCurrentProviderCard={isCurrentProviderCard}
              />
            )
          })
        : null}
      {unmatchedPhrases
        .slice(
          0,
          shouldShowAllKeyPhrases
            ? unmatchedPhrases.length
            : Math.min(
                unmatchedPhrases.length,
                Math.max(displayCount - matchedPhrases.length, 0)
              )
        )
        .map((tag: string, index: number) => {
          return (
            <TagDescriptionContainer
              className="child"
              tag={tag}
              key={index}
              isHighlighted={false}
              isCurrentProviderCard={isCurrentProviderCard}
            />
          )
        })}
      {matchedPhrases.length + unmatchedPhrases.length > displayCount ? (
        <div
          className="inline-flex items-center text-center pl-2 underline text-shade cursor-pointer"
          onClick={() => setShouldShowAllKeyPhrases(!shouldShowAllKeyPhrases)}
        >
          {`show ${shouldShowAllKeyPhrases ? 'less' : 'more'}`}
        </div>
      ) : null}
    </div>
  ) : null
}

export default TherapistTagSection
