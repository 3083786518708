import { Form } from 'antd'
import React, { useContext, useEffect } from 'react'
import NewSubmitCancel from '../../../common/new-form/new-buttons/new-submit-cancel'
import PhpInformation from '../../../common/php-information/php-information'
import InsuranceDetails from '../php-insurance-details/php-insurance-details'
import PersonalInformation from '../php-personal-information/php-personal-information'
import { PHPRegistrationContext } from '../php-registration/php-registration-middleware'
import moment from 'moment'

interface PhpInitialDetailsProps {
  onPrevious: () => void
  onFinish: (values: any) => void
  updatePatientType: (patientType: string) => void
  patientType: string
  updateUserState: (userState: string) => void
  updateUserDoB: (userDoB: moment.Moment) => void
  updateIsPrimarySubscriber: (isPrimarySubscriber: boolean) => void
  isFirstVisitToStep1: boolean
  shouldDisplayValidation: boolean
  setShouldDisplayValidation: React.Dispatch<React.SetStateAction<boolean>>
}

const PhpInitialDetails: React.FC<PhpInitialDetailsProps> = ({
  onPrevious,
  onFinish,
  updateUserState,
  updateUserDoB,
  updateIsPrimarySubscriber,
  isFirstVisitToStep1,
  shouldDisplayValidation,
  setShouldDisplayValidation,
}) => {
  const { primarySubscriberDetails, isInsuranceImageUploaded } = useContext(
    PHPRegistrationContext
  )
  const [form] = Form.useForm()

  const InitialFormValues = {
    InsuranceCompany: 'Providence Health Plan',
  }

  useEffect(() => {
    if (primarySubscriberDetails) {
      form.setFieldsValue({ ...primarySubscriberDetails })
    }
  }, [primarySubscriberDetails, form])

  /*
   * Run validation on member Id and groupID when image is uploaded.
   * Submit without document upload --> get validation error on the below field
   * then uploading the document will automatically clear the validations
   */
  useEffect(() => {
    if (isInsuranceImageUploaded) form.validateFields(['memberID', 'groupID'])
  }, [isInsuranceImageUploaded])

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={InitialFormValues}
      onFinish={onFinish}
      scrollToFirstError
    >
      <div className="mx-4 md:ml-6 md:mr-8">
        <div>
          {!isFirstVisitToStep1 && (
            <PhpInformation
              text={
                'Changing certain information here might lead to re-entry of data'
              }
            />
          )}
          <div className="mt-4">
            <PersonalInformation
              updateUserState={updateUserState}
              updateUserDoB={updateUserDoB}
            />
            <InsuranceDetails
              shouldDisplayValidation={shouldDisplayValidation}
              setShouldDisplayValidation={setShouldDisplayValidation}
              updateIsPrimarySubscriber={updateIsPrimarySubscriber}
            />
          </div>
        </div>
      </div>
      <div className="bg-white md:bg-transparent">
        <NewSubmitCancel
          shouldDisplayPrevious={true}
          isSubmit={false}
          onPrevious={onPrevious}
        />
      </div>
    </Form>
  )
}

export default PhpInitialDetails
