import { FunctionComponent } from 'react'
import ChangeProviderMiddleware from './change-provider-middleware'
import { useParams } from 'react-router'
import AppointmentPageMiddleware from '../EAP/appointment-page/appointment-page-middleware'
import {
  ChangeProviderLogin,
  ChangeProviderApptWrapper,
  ChangeProviderAppointmentConfirmation,
} from './index'

const ChangeProviderFlow: FunctionComponent = () => {
  const { step } = useParams<any>()

  return (
    <>
      <AppointmentPageMiddleware>
        <ChangeProviderMiddleware>
          {step.includes('login') ? (
            <ChangeProviderLogin />
          ) : step.includes('update-appointment') ? (
            <ChangeProviderApptWrapper />
          ) : step.includes('final-confirmation') ? (
            <ChangeProviderAppointmentConfirmation />
          ) : null}
        </ChangeProviderMiddleware>
      </AppointmentPageMiddleware>
    </>
  )
}

export default ChangeProviderFlow
