import {
  PersonalisedImage,
  convenientIcon,
  mentalHealthIcon,
} from '../../assets'
import { termsPath } from '../../routing/route.constants'

export const TogetherSectionElements = [
  {
    name: 'Convenient',
    imageUrl: convenientIcon,
    imageAlt:
      'A laptop with a profile icon, with a callout coming outside the frame',
    desctiption: `Your way - video or phone
    Your time - weekdays or weekends`,
  },
  {
    name: 'Personalized',
    imageUrl: PersonalisedImage,
    imageAlt:
      'A circular profile icon with a green background surrounded by 2 arrows revolving around the circle',
    desctiption: 'Your solution-focused plan will be as unique as you are',
  },
  {
    name: 'Exceptional',
    imageUrl: mentalHealthIcon,
    imageAlt:
      'A white coloured side silhouette of a head, with the brain pictured as a smiley, smiling happily',
    desctiption:
      'Over 90% of clients would highly recommend this service to friends and family',
  },
]

interface LandingPageHeaderNavLinkInterface {
  title: string
  path: string
}

export const navigationLinks: LandingPageHeaderNavLinkInterface[] = [
  {
    title: 'How it works',
    path: 'howItWorks',
  },
  {
    title: 'Who we serve',
    path: 'whoWeServe',
  },
  {
    title: 'FAQs',
    path: 'FAQ',
  },
]

interface FAQInterface {
  question: string
  answer: string
}

export const LandingPageFAQQuestions: FAQInterface[] = [
  {
    question: 'What is Trusana?',
    answer: `A Providence platform, Trusana, is a health system backed, industry-first, wellness platform designed to empower care for individuals needing mental health support. With Trusana, you will be able to book appointments online with Providence Behavioral Health Concierge care team, participate in virtual visits, access your personalized plan of care, and track your progress.`,
  },
  {
    question: 'What types of mental health issues are supported?',
    answer: `Our mental health professionals can help with a wide range of issues, from typical life stressors to serious mental concerns. Some of the common concerns we talk with clients about include:
    
    • stress and anxiety
    • depression
    • constant worry
    • grief and loss
    • parenting issues
    • burnout
    • insomnia
    • withdrawal
    • behavioral problems in children or teens
    • chronic fatigue
    • hopelessness
    • increased drug or alcohol use
    • crisis support
    • addiction
    • navigating the mental health system
    • and many other challenges`,
  },
  {
    question:
      'How can my organization partner with Trusana to provide mental health services?',
    answer: `If you are interested in partnering with Trusana or would like to add Trusana services as a benefit for your organization or health plan, please contact support@trusana.com.`,
  },
  {
    question: 'What happens on the first visit?',
    answer: `A visit includes a brief overview of our services and clarification of the issues you are calling about. Your therapist will ask questions to better understand your concerns and unique situation. Once the main issues have been clarified, your therapist will work with you to design your personalized care plan.

    We may offer digital tools to help you address your difficulties. As necessary, we will help you navigate the mental health system in your local area, which may include education about options available through your health plan and/or a referral directly to providers or resources. Follow-up visits with your therapist will be an opportunity to explore and determine any areas of difficulty, challenges or additional directions that might work best for you.`,
  },
  {
    question: 'What does a plan of care include?',
    answer: `Your personalized plan of care will be solution focused, action oriented and may include goals, objectives, treatment modalities and frequency of visits as recommended by your therapist. Your therapist will employ interventions and evidence-based approaches to address your particular concerns. Together, you'll work toward meeting the goals within your care plan.`,
  },
  {
    question: 'What services are offered?',
    answer: `We may offer digital tools to help you address your difficulties. As necessary, we will help you navigate the mental health system in your local area, which may include education about options available through your health plan and/or a referral directly to providers or resources. Follow-up visits with your therapist will be an opportunity to explore and determine any areas of difficulty, challenges or additional directions that might work best for you.`,
  },
  {
    question:
      'Who are the mental health professionals that provide this service?',
    answer: `All of our mental health professionals are trained at the master's level in clinical social work and have a professional license to practice in your state. Our mental health professionals have an extensive background working as counselors across a variety of settings, managing complex mental health needs, and working with individuals and families. They all have comprehensive training in evidence-based psychotherapies and other therapeutic interventions.`,
  },
  {
    question: 'Can this service connect me to a psychiatrist?',
    answer: `Yes, our team can help you navigate finding a psychiatrist within your health plan.`,
  },
  {
    question: 'Will I have access to prescribed medication through therapy?',
    answer: `No, we do not prescribe medication. However, if it is determined that you need a higher level of care including medication, our team can refer you to another provider within your health plan.`,
  },
  {
    question: 'Will I have access to a summary of my visit?',
    answer: `An after-visit summary will be available in the Trusana/member section. The mental health professional will provide tools and strategies to try on your own along with treatment recommendations and may include referrals to other health care professionals.`,
  },
  {
    question:
      'Is it possible to see a counselor in person through our program?',
    answer: `Our services are currently only offered virtually; however, our team can help support you as you identify needs and an in-person therapist within your health plan.`,
  },
  {
    question: 'Can children use Trusana services?',
    answer: `Yes, all our mental health professionals have experience and training working with children, adolescents, and families. We sometimes find that the most effective interventions are in supporting the parents of children with mental health issues and we'll work with you to identify the best approach for your unique family situation.`,
  },
  {
    question:
      'What do I do if I or my family member is having a mental health crisis or expressing suicidal thoughts?',
    answer: `Our mental health professionals are trained to address a wide range of mental health issues including crises and suicidal thoughts. If you or your family member are not feeling safe (or are experiencing an imminent threat of self-harm or harm to others), you should call 911 or go to the closest emergency department. You can also call 988 for the National Suicide and Crisis Lifeline.`,
  },
]

export const ProvidenceFAQQuestions: FAQInterface[] = [
  {
    question: 'What is Trusana?',
    answer: `A Providence platform, Trusana, is a health system backed, industry-first, wellness platform designed to empower care for individuals needing mental health support. With Trusana, you will be able to book appointments online with Providence Behavioral Health Concierge care team, participate in virtual visits, access your personalized plan of care, and track your progress.`,
  },
  {
    question: 'What types of mental health issues are supported?',
    answer: `Our mental health professionals can help with a wide range of issues, from typical life stressors to serious mental concerns. Some of the common concerns we talk with clients about include:
   
    • stress and anxiety
    • depression
    • constant worry
    • grief and loss
    • parenting issues
    • burnout
    • insomnia
    • withdrawal
    • behavioral problems in children or teens
    • chronic fatigue
    • hopelessness
    • increased drug or alcohol use
    • crisis support
    • addiction
    • navigating the mental health system
    • and many other challenges`,
  },
  {
    question: 'What happens on the first visit?',
    answer: `A visit includes a brief overview of our services and clarification of the issues you are calling about. Your therapist will ask questions to better understand your concerns and unique situation. Once the main issues have been clarified, your therapist will work with you to design your personalized care plan.

    We may offer digital tools to help you address your difficulties. As necessary, we will help you navigate the mental health system in your local area, which may include education about options available through your health plan and/or a referral directly to providers or resources. Follow-up visits with your therapist will be an opportunity to explore and determine any areas of difficulty, challenges or additional directions that might work best for you.`,
  },
  {
    question: 'What does a plan of care include?',
    answer: `Your personalized plan of care will be solution focused, action oriented and may include goals, objectives, treatment modalities and frequency of visits as recommended by your therapist. Your therapist will employ interventions and evidence-based approaches to address your particular concerns. Together, you'll work toward meeting the goals within your care plan.`,
  },
  {
    question: 'What services are offered?',
    answer: `We may offer digital tools to help you address your difficulties. As necessary, we will help you navigate the mental health system in your local area, which may include education about options available through your health plan and/or a referral directly to providers or resources. Follow-up visits with your therapist will be an opportunity to explore and determine any areas of difficulty, challenges or additional directions that might work best for you.`,
  },
  {
    question:
      'Who are the mental health professionals that provide this service?',
    answer: `All of our mental health professionals are trained at the master's level in clinical social work and have a professional license to practice in your state. Our mental health professionals have an extensive background working as counselors across a variety of settings, managing complex mental health needs, and working with individuals and families. They all have comprehensive training in evidence-based psychotherapies and other therapeutic interventions.`,
  },
  {
    question: 'Can this service connect me to a psychiatrist?',
    answer: `Yes, our team can help you navigate finding a psychiatrist within your health plan.`,
  },
  {
    question: 'Will I have access to prescribed medication through therapy?',
    answer: `No, we do not prescribe medication. However, if it is determined that you need a higher level of care including medication, our team can refer you to another provider within your health plan.`,
  },
  {
    question: 'Will I have access to a summary of my visit?',
    answer: `An after-visit summary will be available in the Trusana/member section. The mental health professional will provide tools and strategies to try on your own along with treatment recommendations and may include referrals to other health care professionals.`,
  },
  {
    question:
      'Is it possible to see a counselor in person through our program?',
    answer: `Our services are currently only offered virtually; however, our team can help support you as you identify needs and an in-person therapist within your health plan.`,
  },
  {
    question: 'Can children use Trusana services?',
    answer: `Yes, all our mental health professionals have experience and training working with children, adolescents, and families. We sometimes find that the most effective interventions are in supporting the parents of children with mental health issues and we'll work with you to identify the best approach for your unique family situation.`,
  },
  {
    question:
      'What do I do if I or my family member is having a mental health crisis or expressing suicidal thoughts?',
    answer: `Our mental health professionals are trained to address a wide range of mental health issues including crises and suicidal thoughts. If you or your family member are not feeling safe (or are experiencing an imminent threat of self-harm or harm to others), you should call 911 or go to the closest emergency department. You can also call 988 for the National Suicide and Crisis Lifeline.`,
  },
  {
    question:
      'Which way should I go if I am a Providence caregiver and a Providence Health Plan member?',
    answer: `Providence caregivers and their household members who are also Providence Health Plan members should choose the Employer sponsored option.`,
  },
  {
    question: 'Can I use my Providence Health Plan benefits with Trusana?',
    answer: `Trusana is not available for Providence Health Plan (PHP) members. To book a therapy appointment using your PHP benefits, call 877-744-WELL (877-744-9355) or visit the Providence Behavioral Health Concierge and request an appointment.`,
  },
  {
    question:
      'How does my information from Providence (Swedish) interface with Trusana?',
    answer: `We do not routinely share information with providers outside of Trusana. If you were referred to Trusana from another provider or request that we include other providers in your care, we will request a Release of Information form to authorize sharing your treatment notes.`,
  },
  {
    question: `Will my employer know that I'm seeing a therapist?`,
    answer: `No, your visits are not reported to your employer. Trusana aggregates the data and reports how many sessions are completed each month, but we do not share client names or treatment notes with employers.`,
  },
  {
    question: `Who is eligible for these services?`,
    answer: `Providence employees and their household members`,
  },
  {
    question: `Is there any cost for these services?`,
    answer: `There is no cost to you. Up to 25 visits per calendar are covered by Providence under the Lyra Caregiver Assistance Program `,
  },
]

export const StormFAQQuestions: FAQInterface[] = [
  {
    question: 'What is Trusana?',
    answer: `Trusana is a Providence/Swedish wellness platform to allow individuals to take charge of their mental health. With Trusana, you’ll be able to book virtual counseling appointments with a behavioral health expert to guide you through life’s challenges.`,
  },
  {
    question: 'What types of mental health issues are supported?',
    answer: `Our mental health professionals can help with a wide variety of issues, from typical life stressors to serious mental health concerns. Some of the common concerns we talk about include worry, insomnia, family issues, fatigue, hopelessness, drug or alcohol use, stress and anxiety, depression, grief and loss, or burnout.`,
  },
  {
    question: 'What happens on the first visit?',
    answer: `A visit includes a brief overview of our services and clarification of the issues you are calling about. Your therapist will ask questions to better understand your concerns and unique situation. Once the main issues have been clarified, your therapist will work with you to design your personalized care plan.
    
    We may offer digital tools to help you address your difficulties. As necessary, we will help you navigate the mental health system in your local area, which may include education about options available through your health plan and/or a referral directly to providers or resources. Follow-up visits with your therapist will be an opportunity to explore and determine any areas of difficulty, challenges or additional directions that might work best for you.`,
  },
  {
    question: 'What does a plan of care include?',
    answer: `The visit includes a review of the services offered and clarification of the concerns you want to address. Your therapist will work with you to meet your unique needs in a confidential, supportive, and solution focused manner.`,
  },
  {
    question: 'What services are offered?',
    answer: `We may offer digital tools to help you address your difficulties. As necessary, we will help you navigate the mental health system in your local area, which may include education about options available through your health plan and/or a referral directly to providers or resources. Follow-up visits with your therapist will be an opportunity to explore and determine any areas of difficulty, challenges or additional directions that might work best for you.`,
  },
  {
    question:
      'Who are the mental health professionals that provide this service?',
    answer: `All of our mental health professionals are trained at the master's level in clinical social work and have a professional license to practice in your state. Our mental health professionals have an extensive background working as counselors across a variety of settings, managing complex mental health needs, and working with individuals and families. They all have comprehensive training in evidence-based psychotherapies and other therapeutic interventions.`,
  },
  {
    question: 'Can this service connect me to a psychiatrist?',
    answer: `Yes, our team can help you navigate finding a psychiatrist within your health plan.`,
  },
  {
    question: 'Will I have access to prescribed medication through therapy?',
    answer: `No, we do not prescribe medication. However, if it is determined that you need a higher level of care including medication, our team can refer you to another provider within your health plan.`,
  },
  {
    question: 'Will I have access to a summary of my visit?',
    answer: `An after-visit summary will be available in the Trusana/member section. The mental health professional will provide tools and strategies to try on your own along with treatment recommendations and may include referrals to other health care professionals.`,
  },
  {
    question:
      'Is it possible to see a counselor in person through our program?',
    answer: `Our services are currently only offered virtually; however, our team can help support you as you identify needs and an in-person therapist within your health plan.`,
  },
  {
    question:
      'What do I do if I am having a mental health crisis or expressing suicidal thoughts?',
    answer: `Our mental health professionals are trained to address a wide range of mental health issues including crises and suicidal thoughts. If you are not feeling safe (or are experiencing an imminent threat of self-harm or harm to others), you should call 911 or go to the closest emergency department. You can also call 988 for the National Suicide and Crisis Lifeline.`,
  },
  {
    question:
      'How does my information from Providence (Swedish) interface with Trusana?',
    answer: `We do not routinely share information with providers outside of Trusana. If you were referred to Trusana from another provider or request that we include other providers in your care, we will request a Release of Information form to authorize sharing your treatment notes.`,
  },
  {
    question: `Will my employer know that I'm seeing a therapist?`,
    answer: `No, your visits are not reported to your employer. Trusana aggregates the data and reports how many sessions are completed each month, but we do not share client names or treatment notes with employers.`,
  },
  {
    question: `How do I access the Trusana Mental Health Services?`,
    answer: `An account can be setup online by going to https://www.trusana.com/seattle-storm/company-code or by calling the number listed below. Use the code SEAST in the right side in the Organizational Code section. We encourage clients to enter their personal email and phone. Currently services are only offered to clients who are in Washington state.`,
  },
  {
    question: `Who is eligible for these services?`,
    answer: `At this time, only full time Storm front office staff will have access to Trusana`,
  },
  {
    question: `Is there any cost for these services?`,
    answer: `There is no cost to the employee for up to 5 sessions in 2023, and up to 7 sessions in 2024.`,
  },
  {
    question: `How many visits are supported through EAP Services?`,
    answer: `Most clients use between 5-7 visits. Employees will have access for up to 5 sessions in 2023, and up to 7 sessions in 2024.  Trusana will work with employees to identify and transition a long term therapist should further sessions be necessary.`,
  },
]

// TODO: Add footer links
export const FooterLinks = [
  {
    name: 'Terms of Use & Privacy Policy',
    path: termsPath + '/termsofuse',
  },
  {
    name: 'Notice of Nondiscrimination and Accessibility Rights',
    path: termsPath + '/nondiscrimination',
  },
  {
    name: 'Notice of Privacy Practices',
    path: termsPath + '/privacy',
  },
]

export const specialisationsForLandingPage = [
  'ADHD',
  'Adjustment to changes / life transitions',
  'Aging',
  'Anger management',
  'Anxiety',
  'Bipolar disorder',
  'Boundaries',
  'Breakups',
  'Burnout',
  'Caregiving issues / stress',
  'Child / adolescent issues',
  'Chronic illness/chronic pain',
  'Codependency',
  'Communication concerns',
  'Coping skills',
  'Depression',
  'Disordered eating',
  'Divorce',
  'Domestic violence',
  'End of life',
  'Family related stress',
  'Gender',
  'Grief / loss / bereavement',
  'Guilt',
  'Health concerns',
  'Life transitions / purpose',
  "Men's issues",
  'Mind body connection',
  'Mindfulness',
  'Mood changes',
  'Obsessive compulsive (OCD)',
  'Parenting',
  'Perfectionism',
  'Pregnancy / Perinatal / Postpartum',
  'Prejudice / Racial trauma',
  'PTSD',
  'Relationship concerns / stressors',
  'Self esteem',
  'Self care',
  'Stress',
  'Substance use',
  'Suicidal ideation',
  'Trauma',
  "Women's issues",
  'Workplace stress / concerns',
  'Worries',
]

export const ExperienceSectionTestimonials = [
  {
    name: 'Vikram',
    role: 'Trusana Customer',
    testimony:
      'Everyone in my family has had a struggle and everyone in my family has leveraged this benefit.',
  },
  {
    name: 'Trusana User',
    role: 'Trusana Customer',
    testimony:
      'When people go to Trusana they should know what they are going to get: no judgement, someone to listen, skilled advice from providers who are trained and know how to help you.',
  },
  {
    name: 'Barbara',
    role: 'Trusana Customer',
    testimony:
      'I had a good fit with my provider, we really clicked, and it was easy to communicate with my provider.',
  },
  {
    name: 'Trusana User',
    role: 'Trusana Customer',
    testimony:
      "Having access to this resource has greatly improved my life and wellbeing. I can work better, have less anxiety, and I've learned tools to help me get through my day productively.",
  },
  {
    name: 'Trusana User',
    role: 'Trusana Customer',
    testimony:
      "When other people told me I should see someone I was nervous and kept putting it off. I didn't know what to expect but my provider made me feel heard, gave me a safe place, and made me feel important.",
  },
  {
    name: 'Trusana User',
    role: 'Trusana Customer',
    testimony:
      'It was easy to schedule, I liked being able to choose my provider myself. After my first visit, I feel more hopeful and like I am on track to feeling better.',
  },
  {
    name: 'Trusana User',
    role: 'Trusana Customer',
    testimony:
      'This is such a great resource that I am so grateful for. Working with my therapist has been huge in my personal growth.',
  },
  {
    name: 'Trusana User',
    role: 'Trusana Customer',
    testimony:
      "It's not as scary as I imagined it'd be. It's healing. My entire experience was efficient, friendly and non-judgmental.",
  },
  {
    name: 'Trusana User',
    role: 'Trusana Customer',
    testimony:
      "My provider and Trusana have helped me in ways I never thought possible. I used to be so hard on myself and riddled with anxiety. Now I'm happy, even when dealing with stressful situations.",
  },
  {
    name: 'Trusana User',
    role: 'Trusana Customer',
    testimony:
      'My provider is highly skilled and thorough, organized, motivating and talented. I feel like she is invested in my success and very good at her job.',
  },
  {
    name: 'Trusana User',
    role: 'Trusana Customer',
    testimony:
      'I came in with little experience or knowledge about therapy, but I was overly impressed. It felt very personal and comfortable.',
  },
  {
    name: 'Trusana User',
    role: 'Trusana Customer',
    testimony:
      'I love the platform and how easy it has been to schedule sessions. Weekend availability is a huge plus!',
  },
  {
    name: 'Trusana User',
    role: 'Trusana Customer',
    testimony:
      'My therapist is very compassionate and knowledgeable in her field. I trust her which is extremely important to me. I feel I am in a safe space during our sessions.',
  },
  {
    name: 'Trusana User',
    role: 'Trusana Customer',
    testimony:
      "This is the best experience I've had by far! I have always struggled with finding a good therapist, but I feel comfortable to share with my provider.",
  },
  {
    name: 'Trusana User',
    role: 'Trusana Customer',
    testimony:
      'Everything has exceeded my expectations. Has been a great experience so far and great resource.',
  },
]

export const getSpecialisationSplit = (width: number) => {
  if (width < 768) {
    return [
      ['A', 'G'],
      ['H', 'Z'],
    ]
  } else if (width < 1280) {
    return [
      ['A', 'C'],
      ['D', 'M'],
      ['N', 'Z'],
    ]
  } else {
    return [
      ['A', 'C'],
      ['D', 'L'],
      ['M', 'P'],
      ['R', 'Z'],
    ]
  }
}

export const getSpecialisationBubbleList = (width: number) => {
  if (width >= 1024) {
    return [
      ['Stress', 'Anxiety', 'Depression'],
      ['Grief / loss', 'Family issues', 'Burnout'],
    ]
  }

  return [
    ['Stress', 'Anxiety'],
    ['Depression', 'Grief / loss'],
    ['Family issues', 'Burnout'],
  ]
}

export const listedSpecialisationsTopRow = ['Stress', 'Anxiety']
export const listedSpecialisationsBottomRow = ['Depression', 'Grief / loss']
export const listedSpecialisationsThirdRow = ['Family issues', 'Burnout']

export const scrollFadeAnimationDelay = 300

export const linkStyle = {
  color: '#124E6E',
  textDecoration: 'underline',
  fontWeight: 600,
  display: 'inline',
}
