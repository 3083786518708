import { CaretDownFilled, CloseCircleOutlined } from '@ant-design/icons'
import { Dropdown, Tooltip } from 'antd'

interface IFilterContainer {
  // children: React.ReactNode
  overlay: any
  title: string
  currentText: string
  onClear: () => void
  onVisibleChange?: (visible: boolean, title: string) => void
}

const FilterContainer: React.FC<IFilterContainer> = ({
  overlay,
  title,
  currentText,
  onClear,
  onVisibleChange,
}) => {
  return (
    <div className="filter-container flex flex-col items-start justify-between h-full w-full mx-2 pb-2 ">
      <div className="flex flex-row py-2 justify-between items-center font-medium text-sm px-4 py-1 text-shade w-full">
        <div className="text-xs font-medium">{title}</div>
        <Tooltip title={'Reset'}>
          <CloseCircleOutlined
            className="mr-2"
            onClick={onClear}
            data-testid="close-circle-outlined"
          />
        </Tooltip>
      </div>
      <Dropdown
        onVisibleChange={(visible: boolean) => {
          !!onVisibleChange && onVisibleChange(visible, title)
        }}
        overlay={overlay}
        trigger={['click']}
        placement="bottomLeft"
        arrow
        data-testid={`filter-${title}`}
        zIndex={3}
      >
        <div className="flex  w-11/12 bg-[#FAFAFA] items-center cursor-pointer justify-between text-start gap-2 text-sm font-medium mx-2 px-4 py-2 rounded-lg border my-1 border border-teal-blue border-solid">
          {currentText} <CaretDownFilled />
        </div>
      </Dropdown>
    </div>
  )
}

export default FilterContainer
