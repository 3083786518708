import { Card, Progress } from 'antd'
import React, { FunctionComponent } from 'react'
import { globalConfig } from '../../configuration/config'

interface ImageUploadProgressProps {
  shouldDisplayProgress: boolean
  greaterThanLimit: boolean
  uploadProgress: number
}

const PhpImageUploadProgress: FunctionComponent<ImageUploadProgressProps> = ({
  shouldDisplayProgress,
  greaterThanLimit,
  uploadProgress,
}) => {
  return (
    <div data-testid="image-upload-progress">
      {shouldDisplayProgress ? (
        <Card
          className="card-background mt-6  mx-auto my-0"
          data-testid="image-upload-progress-card"
        >
          {greaterThanLimit ? (
            <div className="mb-4">{`Upload Failed`}</div>
          ) : (
            <div className="mb-4">{`Upload is in progress`}</div>
          )}
          {greaterThanLimit ? (
            <div>
              File size exceeded. Maximum file size should be{' '}
              {globalConfig.get().phpInsuranceFileSize / 1048576}MB
            </div>
          ) : (
            <div>
              <Progress
                percent={uploadProgress}
                data-testid="progress-component"
              />
            </div>
          )}
        </Card>
      ) : null}
    </div>
  )
}

export default PhpImageUploadProgress
