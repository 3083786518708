export const homePath = '/'
export const userFlowPath = '/user-flow-type'
export const defaultBrand = '/providence'
export const onboardingFormPath = '/new-service-request'
export const userFlowTypePath = `/:brand${userFlowPath}/:userFlowType`
export const userAccountTypePath = '/user-account-type'
export const userAccountSelectionPathWithParams = `/:brand${userAccountTypePath}/:userFlowType`
export const eapFormPath = '/eap-flow'
export const newEapFormPath = '/eap'
export const faqPath = '/faq'

export const eapFormPathWithParams = `/:brand${eapFormPath}/:preference/:userType/:step`
export const eapNewFormPathWithParams = `/:brand${newEapFormPath}/:step`

export const appointmentConfirmation = '/final-confirmation'
export const phpFromPath = '/health-plan'
export const phpFormPathWithParams = `/:brand${phpFromPath}/:step`
export const termsPath = '/terms'
export const companyCodePathIdentifier = `/company-code`

export const customFromRedirectionBHCPath = '/providence/user-flow-type/EAP'
export const customRedirectFromProvidenceOrgPath = '/user-flow-type/EAP'
export const customRedirectionToProvidencePath =
  '/providence/EAP/initial-details'
export const mentalHealthPath = '/welcome/mentalhealth'
export const EAPPagePath = '/providence/user-flow-type/EAP'

export const changeProviderPathWithParams = '/change-provider/:step'
export const changeProviderLoginPath = '/change-provider/login'
export const changeProviderApptPath = '/change-provider/update-appointment'
export const changeProviderFinalConfirmation =
  '/change-provider/final-confirmation'
