import { CloseOutlined } from '@ant-design/icons'
import { Button, Card } from 'antd'
import React, { useContext } from 'react'
import { PhoneCallVector, VideoCallVector } from '../../../assets'

import { Themes } from '../EAP-constants'
import {
  AppointmentPageContext,
  AppointmentType,
} from './appointment-page-middleware'

interface AppointmentTypeSelectionProps {
  handleSlotSelection: (arg0: boolean) => void
}
const AppointmentTypeSelection: React.FC<AppointmentTypeSelectionProps> = ({
  handleSlotSelection,
}) => {
  const { appointmentType, updateAppointmentType } = useContext(
    AppointmentPageContext
  )
  return (
    <Card className="bg-shade w-full p-4 ml-0 md:ml-6 mt-4 md:mt-0 rounded-lg h-[15rem]">
      <div className="text-end mb-3 text-xl">
        <Button
          className="mr-1 cross-button"
          onClick={() => handleSlotSelection(false)}
          type="primary"
          aria-label="close"
        >
          <CloseOutlined className="text-white" />
        </Button>
      </div>
      <div className="text-white flex flex-col items-center justify-center">
        <div className="mb-3 text-lg">Select your appointment type</div>
        <AppointmentTypeSelectionButtons>
          <AppointmentTypeSelectionButton
            appointmentType={AppointmentType.VIDEO_CALL}
            updateAppointmentType={updateAppointmentType}
            selectedAppointmentType={appointmentType}
            theme={Themes.DARK}
          />
          <AppointmentTypeSelectionButton
            appointmentType={AppointmentType.PHONE_CALL}
            updateAppointmentType={updateAppointmentType}
            selectedAppointmentType={appointmentType}
            theme={Themes.DARK}
          />
        </AppointmentTypeSelectionButtons>
      </div>
    </Card>
  )
}

const AppointmentTypeSelectionButtons: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  return <div className="flex flex-col md:flex-row px-4">{children}</div>
}

interface AppointmentTypeSelectionButtonInterface {
  appointmentType: AppointmentType
  updateAppointmentType: (appointmentType: AppointmentType) => void
  selectedAppointmentType: AppointmentType
  theme: Themes
}

export const AppointmentTypeSelectionButton: React.FC<
  AppointmentTypeSelectionButtonInterface
> = ({
  appointmentType,
  updateAppointmentType,
  theme,
  selectedAppointmentType = AppointmentType.UNSELECTED,
}) => {
  return (
    <Button
      className={`${
        theme === Themes.DARK && selectedAppointmentType !== appointmentType
          ? '!bg-shade !text-white hover:!bg-white hover:!text-shade focus:!bg-white focus:!text-shade'
          : selectedAppointmentType !== appointmentType
          ? '!bg-white !text-shade hover:!bg-shade hover:!text-white focus:!bg-shade focus:!text-white'
          : ''
      } ${
        selectedAppointmentType === appointmentType
          ? '!bg-shade !text-white'
          : ''
      } rounded-full flex flex-row items-center justify-center mx-2 my-2 py-2 group`}
      onClick={() => {
        updateAppointmentType(appointmentType)
      }}
    >
      <div className="mr-2 my-2 pr-2 py-2">
        {appointmentType === AppointmentType.PHONE_CALL ? (
          <PhoneCallVector
            theme={theme}
            isSelected={selectedAppointmentType === appointmentType}
          />
        ) : (
          <VideoCallVector
            theme={theme}
            isSelected={selectedAppointmentType === appointmentType}
          />
        )}
      </div>
      <div>{appointmentType}</div>
    </Button>
  )
}

export default AppointmentTypeSelection
