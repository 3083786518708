import React, { useContext } from 'react'
import { PhpInfoIcon } from '../../../assets'
import {
  hasLowerCase,
  hasUpperCase,
  hasDigit,
  hasSymbol,
  FormInvalidMessage,
} from '../../../common/form/form.utils'
import NewInputPassword from '../../../common/new-form/new-input-password/new-input-password'
import NewInputText from '../../../common/new-form/new-input-text/new-input-text'
import PhpPasswordRulesDisplay from '../../../common/password-rules-display/php-password-rules-display'
import PhpFormSectionInfo from '../php-form-section-info/php-form-section-info'
import { PHPRegistrationContext } from '../php-registration/php-registration-middleware'

interface PasswordInformationProps {
  email?: string
  confirmStep: number
}
const PhpPasswordInformation: React.FC<PasswordInformationProps> = ({
  email,
  confirmStep,
}) => {
  const { existenceType } = useContext(PHPRegistrationContext)
  const ConfirmEmailValidator = (_: any, value: any) => {
    if (email !== value) {
      return Promise.reject(
        new Error(
          `Email Address does not match the Email Address entered in Step ${confirmStep}`
        )
      )
    }
    return Promise.resolve()
  }

  const passwordValidator = (_: any, value: any) => {
    if (!hasLowerCase(value) || !hasUpperCase(value)) {
      return Promise.reject(
        new Error('Should contain both lower and uppercase')
      )
    }
    if (!(hasDigit(value) || hasSymbol(value))) {
      return Promise.reject(
        new Error('Should contain number and/or punctuation marks')
      )
    }
    return Promise.resolve()
  }
  return (
    <div className="mb-4">
      <PhpFormSectionInfo
        sectionNumber={1}
        title={'Password Creation'}
        description={'Setup password for the account being created'}
      />
      <div className="md:flex md:flex-row md:gap-8">
        <div className="mt-4 flex flex-col md:w-1/2  ">
          <NewInputText
            className="editable-email"
            label={'Confirm Email Address'}
            name={'email'}
            isEmail={true}
            isRequired={true}
            value="adsasd@gmail.com"
            maxLength={65}
            min={{
              value: 3,
              message: FormInvalidMessage(''),
            }}
            max={{
              value: 65,
              message: FormInvalidMessage(''),
            }}
            validator={ConfirmEmailValidator}
            validateTrigger={['onBlur', 'onChange', 'onSubmit']}
          />
          <NewInputPassword
            label={
              existenceType === 'soft' ? 'Confirm Password' : 'Create Password'
            }
            htmlLabel={
              existenceType === 'soft' ? 'Confirm Password' : 'Create Password'
            }
            name={'password'}
            isRequired={true}
            aria-required={true}
            validateTrigger={['onBlur', 'onChange', 'onSubmit']}
            min={{
              value: 8,
              message: 'length must be greater than 8',
            }}
            validator={passwordValidator}
          />
        </div>
        {existenceType != 'soft' && (
          <div className="md:flex md:flex-col md:ml-20">
            <PhpPasswordRulesDisplay />
          </div>
        )}
      </div>
      {existenceType === 'soft' ? (
        <div className="bg-white border border-solid border-[#3f7a88] flex flex-row  rounded-[0.4rem] w-full md:w-[95%] mb-4 mt-4 md:mt-0 md:gap-8">
          <div className="bg-[#3f7a88] flex flex-col  justify-center px-8 rounded-tl-[0.3rem] rounded-bl-[0.3rem]">
            <img src={PhpInfoIcon} />
          </div>
          <div className="flex flex-col pl-4 md:justify-center font-normal text-sm py-4">
            <div className="font-semibold text-sm md:text-base">
              Enter password of the existing account
            </div>
            <div className="font-normal text-xs md:text-sm">
              An account exists with the given email address. A new user profile
              will be created and linked to your existing account. You can
              access all profiles using the same account.
            </div>
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default PhpPasswordInformation
