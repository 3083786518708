import { Card, Col, Row, Avatar } from 'antd'
import { useContext, useRef, useState } from 'react'
import './appointment-page.scss'
import TimeSlots from './time-slots'
import './single-therapist-card.scss'
import CommonModal from '../../../common/modal/common-modal'
import TherapistBioModelContent from './therapist_bio_modal_content'
import { AppointmentPageContext } from './appointment-page-middleware'
import TherapistTagSection from './therapist-tags/therapist-tag-section'
import TherapistPopulationTagSection from './therapist-tags/therapist-population-tag-section'
import { SingleTherapistSlotsInterface } from './interfaces/single-therapist-slot-interface'

export interface TherapistInformation {
  name: string
  ex: string
  experience: number
  description: string
  image: string | undefined
  checkAvailability: (id: string) => void
  therapistId: string
  clickNextPath: string
  goBackToList?: () => void
  bookAppointment: (
    date: string | Date,
    clickNextPath: string,
    providerId: string,
    providerName?: string
  ) => void
  closeModal?: () => void
  isResetConfirmation?: boolean
  visitorId: string
  predictionData?: any
  matchingKeyphrases?: any
  keyPhraseTags?: string[]
  populationTags?: string[]
  rerankerScore?: any
  userState?: string
  style?: React.CSSProperties
  isCurrentProviderCard?: boolean
  isLoadingTherapistsSlots?: boolean
  therapistSlotsData:SingleTherapistSlotsInterface[]
  therapistavailableTimeSlots:any
}

export interface CalendarPageInterface extends TherapistInformation {
  goBackToList: () => void
  isChangeProviderFlow?: boolean
}

const SingleTherapistCard: React.FC<TherapistInformation> = (props) => {
  const {
    therapistId,
    isResetConfirmation,
    name,
    ex,
    experience,
    description,
    image,
    checkAvailability,
    bookAppointment,
    clickNextPath,
    // matchingKeyphrases,
    keyPhraseTags,
    populationTags = [] as string[],
    visitorId,
    style,
    isCurrentProviderCard = false,
    isLoadingTherapistsSlots,
    therapistSlotsData,
    therapistavailableTimeSlots,
  } = props

  const [showMore, setShowMore] = useState(true)
  const descriptionSection: any = useRef(null)
  const [isReadBioModalOpen, setisReadBioModalOpen] = useState(false)
  const [shouldShowAllKeyPhrases, setShouldShowAllKeyPhrases] =
    useState<boolean>(false)
  const { selectedSpecialisationItems, selectedPopulationItems } = useContext(
    AppointmentPageContext
  )
  const toggleModal = () => {
    setisReadBioModalOpen(!isReadBioModalOpen)
  }

  return (
    <div className="fade-in-therapist-card" style={style}>
      <Card className="single-therapist-card rounded-2xl m-4 md:my-8 md:mx-8">
        <Row className="single-therapist-container flex flex-col md:flex-row justify-between">
          <div className="p-7 inline-flex px-0 pt-0 pb-2 flex flex-col md:pt-8 justify-center single-therapist-card-left-section">
            <div className="flex flex-row justify-center">
              <Col className="">
                <Avatar
                  size={{ xs: 70, sm: 80, md: 100, lg: 100, xl: 150, xxl: 160 }}
                  icon={<img src={image} alt={name} />}
                  className="therapist-image-container mr-2 md:ml-4"
                />
              </Col>
              <Col className="doctor-details w-full float-left pl-2 break-words mr-4 md:mr-8">
                <Row className="font-bold doctor-name mb-2 ml-1">
                  <div className="flex flex-col items-start">
                    <div data-testid="lbl-provider-name">{name}</div>
                    <div className="flex flex-col lg:flex-row">
                      {ex ? (
                        <div className="flex font-normal pr-2">{`${ex} `}</div>
                      ) : null}

                      {!(experience === 0) ? (
                        <div className="flex font-light" data-testid="lbl-yoe">
                          <span className="flex font-thin lg:mr-1 lg:after:content-['|']"></span>
                          {`${experience} Years of Experience`}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </Row>
                <Row className="w-3/6 read_bio_text_wrapper_style">
                  <a
                    className="font-semibold text-sm leading-5 not-italic underline read_bio_link_style ml-1"
                    onClick={toggleModal}
                  >
                    Read bio
                  </a>
                </Row>
                <Row className="therapist-inline-bio-section">
                  <div className="ml-1 font-bold">Bio</div>
                  <div
                    ref={descriptionSection}
                    className={`doctor-description mb-2 ml-1 ${
                      showMore ? 'clip-content' : ''
                    }`}
                  >
                    {description}
                  </div>
                  <button
                    className="details-button"
                    onClick={() => setShowMore(!showMore)}
                    tabIndex={0}
                  >
                    <span className="button-text ml-1">
                      {showMore ? 'more details' : 'less details'}
                    </span>
                  </button>
                </Row>
                <Row className="therapist-inline-phrase-section">
                  <TherapistTagSection
                    keyPhraseTags={keyPhraseTags}
                    matchingKeyphrases={selectedSpecialisationItems.filter(
                      (item) =>
                        keyPhraseTags?.some(
                          (keyPhraseTag) =>
                            keyPhraseTag.toLowerCase().replaceAll(' ', '') ===
                            item.toLowerCase().replaceAll(' ', '')
                        )
                    )}
                    shouldShowAllKeyPhrases={shouldShowAllKeyPhrases}
                    setShouldShowAllKeyPhrases={setShouldShowAllKeyPhrases}
                    displayCount={4}
                    isCurrentProviderCard={isCurrentProviderCard}
                  />
                </Row>
                <Row className="therapist-inline-population-section">
                  <TherapistPopulationTagSection
                    populationTags={populationTags}
                    matchingPopulationTags={selectedPopulationItems.filter(
                      (item) =>
                        populationTags?.some(
                          (populationTag) =>
                            populationTag.toLowerCase().replaceAll(' ', '') ===
                            item.toLowerCase().replaceAll(' ', '')
                        )
                    )}
                  />
                </Row>
              </Col>
            </div>
            <Row className="bio-bottom-section ml-4 mr-4 mt-2">
              <div className="ml-1 font-bold">Bio</div>
              <div
                ref={descriptionSection}
                className={`doctor-description mb-2 ml-1 ${
                  showMore ? 'clip-content' : ''
                }`}
              >
                {description}
              </div>
              <button
                className="details-button"
                onClick={() => setShowMore(!showMore)}
                tabIndex={0}
              >
                <span className="button-text ml-1">
                  {showMore ? 'more details' : 'less details'}
                </span>
              </button>
            </Row>
            <Row className="phrases-mobile-bottom-section mx-4 mt-4 hidden md:flex">
              <TherapistTagSection
                keyPhraseTags={keyPhraseTags}
                matchingKeyphrases={selectedSpecialisationItems.filter((item) =>
                  keyPhraseTags?.some(
                    (keyPhraseTag) =>
                      keyPhraseTag.toLowerCase().replaceAll(' ', '') ===
                      item.toLowerCase().replaceAll(' ', '')
                  )
                )}
                shouldShowAllKeyPhrases={shouldShowAllKeyPhrases}
                setShouldShowAllKeyPhrases={setShouldShowAllKeyPhrases}
                displayCount={4}
                isCurrentProviderCard={isCurrentProviderCard}
              />
            </Row>
            <Row className="population-mobile-bottom-section mx-4 mt-0 hidden md:flex">
              <TherapistPopulationTagSection
                populationTags={populationTags}
                matchingPopulationTags={selectedPopulationItems.filter((item) =>
                  populationTags?.some(
                    (populationTag) =>
                      populationTag.toLowerCase().replaceAll(' ', '') ===
                      item.toLowerCase().replaceAll(' ', '')
                  )
                )}
              />
            </Row>
          </div>
          <div className="flex flex-col md:hidden">
            <TherapistTagSection
              keyPhraseTags={keyPhraseTags}
              matchingKeyphrases={selectedSpecialisationItems.filter((item) =>
                keyPhraseTags?.some(
                  (keyPhraseTag) =>
                    keyPhraseTag.toLowerCase().replaceAll(' ', '') ===
                    item.toLowerCase().replaceAll(' ', '')
                )
              )}
              shouldShowAllKeyPhrases={shouldShowAllKeyPhrases}
              setShouldShowAllKeyPhrases={setShouldShowAllKeyPhrases}
              displayCount={3}
              isCurrentProviderCard={isCurrentProviderCard}
            />
            <TherapistPopulationTagSection
              populationTags={populationTags}
              matchingPopulationTags={selectedPopulationItems.filter((item) =>
                populationTags?.some(
                  (populationTag) =>
                    populationTag.toLowerCase().replaceAll(' ', '') ===
                    item.toLowerCase().replaceAll(' ', '')
                )
              )}
            />
          </div>
          <div className="w-full flex justify-center md:border-solid border-l border-r-0 border-y-0 border-[#d8d8d8] single-therapist-card-right-section">
            <TimeSlots
              isResetConfirmation={isResetConfirmation}
              doctorId={therapistId}
              checkAvailability={checkAvailability}
              doctorName={name}
              bookAppointment={bookAppointment}
              clickNextPath={clickNextPath}
              visitorId={visitorId}
              isCurrentProviderCard={isCurrentProviderCard}
              isLoadingTherapistsSlots={isLoadingTherapistsSlots}
              therapistSlotsData={therapistSlotsData}
              therapistavailableTimeSlots={therapistavailableTimeSlots}
            />
          </div>
        </Row>
      </Card>
      <div>
        <CommonModal
          handleClose={toggleModal}
          isModalOpen={isReadBioModalOpen}
          title={''}
          className="therapist-bio-modal-close__button-style"
        >
          <TherapistBioModelContent
            image={image}
            name={name}
            ex={ex}
            description={description}
          ></TherapistBioModelContent>
        </CommonModal>
      </div>
    </div>
  )
}

export default SingleTherapistCard
