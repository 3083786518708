export const LABEL_ALIGN_TYPE = {
  left: 'left',
  right: 'right',
}

export const FORM_ITEM_VALIDATE_STATUS = {
  success: 'success',
  warning: 'warning',
  error: 'error',
  validating: 'validating',
}

export const FORM_SELECT_MODE_PROPS = {
  multiple: 'multiple',
  tags: 'tags',
}

export const DATE_FORMAT_LIST = [
  'MM-DD-YYYY',
  'MM/DD/YYYY',
  'MM/DD/YY',
  'MM-DD-YY',
  'M-D-YY',
  'M/D/YY',
  'M/D/YYYY',
  'M-D-YYYY',
]
export const DATE_TIME_FORMAT = 'MM-DD-YYYY, HH:mm'
export const TABLE_DATE_FORMAT = 'MMM DD, YYYY'
export const DISPLAY_DATE_FORMAT = 'MMM DD YYYY'

export const DEFAULT_INPUT_LENGTH = 51
export const INPUT_DATE_PICKER_TYPE = 'date'
