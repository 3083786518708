import { Steps } from 'antd'
import React, { FunctionComponent } from 'react'
import './stepper.scss'
interface FormStepperProps {
  current: number
  stepsList: StepOptions[]
  className?: string
}
export interface StepOptions {
  title: string
}

const FormStepper: FunctionComponent<FormStepperProps> = ({
  current,
  stepsList,
  className,
}) => {
  const { Step } = Steps
  return (
    <Steps
      type="navigation"
      current={current}
      responsive={false}
      className={`site-navigation-steps mb-6 ${className}`}
    >
      {stepsList.map((stepItem, index) => {
        return (
          <Step
            key={index}
            icon={index + 1}
            status={index === current ? 'finish' : 'process'}
            title={stepItem.title}
          />
        )
      })}
    </Steps>
  )
}

export default FormStepper
