import React, { useState } from 'react'
import { CloseCircleFilled, InfoCircleFilled } from '@ant-design/icons'
import { mixpanelTrackSupportLinkClicked } from '../../../common/utils/mixpanel'

const TherapistBottomToast: React.FC = () => {
  const [isToastHidden, setIsToastHidden] = useState(false)

  const hiddenStyle = { display: 'none' }
  return (
    <div
      className="flex flex-col bottom-toast p-2 rounded"
      style={isToastHidden ? hiddenStyle : {}}
    >
      <div className="flex flex-row justify-end">
        <CloseCircleFilled
          style={{ fontSize: 20 }}
          className="md:pr-2"
          onClick={() => {
            setIsToastHidden(true)
          }}
        />
      </div>
      <div className="flex flex-col md:flex-row justify-center md:justify-end gap-6 md:gap-8 md:px-16  pb-4 items-center">
        <div>
          <InfoCircleFilled style={{ fontSize: 50 }} />
        </div>
        <div className="flex flex-col items-center md:items-start text-center md:text-start	text-[#FFFFFF] ">
          <div className="text-lg md:text-2xl font-medium">
            Looking for a different provider?
          </div>
          <div className="text-sm md:text-lg px-4 md:px-0">
            Please reach out to our customer service at{' '}
            <span>
              {' '}
              <a
                href="tel:833-724-9355"
                className="text-white hover:text-white hover:underline"
              >
                (833) 724-9355
              </a>{' '}
              |{' '}
              <a
                href="mailto:support@trusana.com"
                className="text-white hover:text-white hover:underline"
                onClick={() => {
                  mixpanelTrackSupportLinkClicked()
                }}
              >
                support@trusana.com{' '}
              </a>
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TherapistBottomToast
