import { WarningFilled } from '@ant-design/icons'
import React from 'react'

const ErrorIcon = () => {
  return (
    <div
      className="modal-icon flex flex-row items-center justify-center"
      style={{
        borderRadius: '50%',
        background: '#E9BC48',
        width: '4rem',
        height: '4rem',
      }}
    >
      <WarningFilled style={{ fontSize: '2rem', color: 'white' }} />
    </div>
  )
}

export default ErrorIcon
