import { useContext, useEffect, useState } from 'react'
import { AppointmentPageContext } from '../../../appointment-page-middleware'
import { Space, Tooltip, Popover } from 'antd'
import { CaretDownFilled, CloseCircleOutlined } from '@ant-design/icons'
import '../../search-and-filter.scss'
import SpecialisationMenuItems from './specialisation-menu-items'
import SpecialisationPopover from './specalisation-popover'

const SpecializationDropdown: React.FC = () => {
  const {
    isSpeciasilationFilterOpen,
    updateIsSpeciasilationFilterOpen,
    selectedSpecialisationItems,
    updateSelectedSpecialisationItems,
    isFilterDrawerOpen,
  } = useContext(AppointmentPageContext)

  const [selectedItems, setSelectedItems] = useState<string[]>(
    selectedSpecialisationItems
  )
  const updateSelectedItems = (item: string, shouldAdd: boolean) => {
    setSelectedItems((prevSelectedItems) => {
      if (shouldAdd) {
        return [...prevSelectedItems, item]
      } else {
        return prevSelectedItems.filter((existingItem) => existingItem !== item)
      }
    })
  }

  useEffect(() => {
    updateSelectedSpecialisationItems(selectedItems)
  }, [isSpeciasilationFilterOpen, isFilterDrawerOpen])

  useEffect(() => {
    setSelectedItems(selectedSpecialisationItems)
  }, [selectedSpecialisationItems])

  const handleOpenChange = (flag: boolean) => {
    updateIsSpeciasilationFilterOpen(flag)
  }
  return (
    <div className="flex flex-col justify-around items-start lg:items-center w-full font-bold mr-2 lg:mx-2 specialisation-dropdown pb-2 max-h-full overflow-y-auto">
      <div className="hidden lg:flex flex-row py-2 justify-between items-center font-medium text-sm pl-5 pr-4 py-1 text-shade w-full">
        <div className="text-xs font-medium">Concerns</div>
        <Tooltip title={'Reset'}>
          <CloseCircleOutlined
            className=""
            onClick={() => updateSelectedSpecialisationItems([])}
          />
        </Tooltip>
      </div>
      <Space className="w-full hidden lg:block flex flex-row py-2 justify-around text-shade bg-[#FAFAFA] w-11/12 my-1 border border-teal-blue border-solid font-medium p-1 rounded-lg text-sm select-none">
        <Popover
          content={
            <SpecialisationPopover
              setSelectedItems={setSelectedItems}
              selectedItems={selectedItems}
              updateSelectedItems={updateSelectedItems}
            />
          }
          onVisibleChange={handleOpenChange}
          visible={isSpeciasilationFilterOpen}
          trigger="click"
          placement="bottomLeft"
          zIndex={3}
          destroyTooltipOnHide={true}
          data-testid="filter-concerns"
        >
          <div className="cursor-pointer">
            <div className="ml-3 text-start inline-block">
              {selectedItems.length > 0
                ? selectedItems.length + ' Selected'
                : 'Select'}
            </div>
            <CaretDownFilled className="text-shade mr-3 mt-[3px] text-md float-right" />
          </div>
        </Popover>
      </Space>

      <div className="flex flex-col w-full lg:hidden">
        <SpecialisationMenuItems
          setSelectedItems={setSelectedItems}
          selectedItems={selectedItems}
          updateSelectedItems={updateSelectedItems}
        />
      </div>
    </div>
  )
}

export default SpecializationDropdown
