import React, { useContext } from 'react'
import { FormInvalidMessage } from '../../../common/form/form.utils'
import {
  formGenderOptions,
  reasonForBookingLabel,
  validatePhoneNumber,
} from '../../EAP/EAP-constants'
import NewInputSelect from '../../../common/new-form/new-input-select/new-input-select'
import { MetadataContext } from '../../../common'
import PhpFormSectionInfo from '../php-form-section-info/php-form-section-info'
import NewInputNum from '../../../common/new-form/new-input-number/new-input-number'
import NewInputTextArea from '../../../common/new-form/new-input-text-area/new-input-text-area'

const AdditionalInformation = () => {
  const { genderList } = useContext(MetadataContext)
  return (
    <>
      <PhpFormSectionInfo
        sectionNumber={1}
        title={'Additional Information'}
        description={'Provide details of the person who will be receiving care'}
      />
      <div className="mt-4">
        <div className="flex flex-col md:flex-row md:gap-8">
          <NewInputNum
            label={'Phone Number'}
            placeholder={'Enter Phone Number'}
            name={'phoneNumber'}
            isPhoneNumber={true}
            isRequired={true}
            aria-required={true}
            validator={validatePhoneNumber}
          />

          <NewInputSelect
            label={' Gender'}
            placeholder={'Select Gender'}
            name={'gender'}
            options={formGenderOptions(genderList, 'name', 'id', 'code')}
          />
        </div>

        <div className="flex flex-col md:flex-row md:gap-8">
          <NewInputTextArea
            className="patient-textarea h-[5rem]"
            label={reasonForBookingLabel}
            name={'reasonforBookingAppointment'}
            min={{
              value: 3,
              message: FormInvalidMessage(''),
            }}
            //   validator={validateReasonForBooking}
            maxLength={200}
            isRequired={true}
            placeholder={'Enter reason for visit'}
          />
          <NewInputNum
            label={''}
            placeholder={'Enter Phone Number'}
            name={'phoneNumber'}
            isPhoneNumber={true}
            validator={validatePhoneNumber}
            className={'hidden'}
          />
        </div>
      </div>
    </>
  )
}

export default AdditionalInformation
