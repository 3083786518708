import React from 'react'

interface PhpFormSectionInfoProps {
  sectionNumber: number
  title: string
  description: string | React.ReactNode
  wbreak?: boolean
}

const PhpFormSectionInfo: React.FC<PhpFormSectionInfoProps> = ({
  sectionNumber,
  title,
  description,
  wbreak,
}) => {
  return (
    <div className="flex flex-row">
      <div className="flex flex-col bg-[#DA7F71] h-[2rem] w-[2rem]  text-center text-white p-[0.4rem] rounded-[50%]">
        {sectionNumber}
      </div>
      <div className="flex flex-col ml-4">
        <div className="font-bold text-[#314259]">{title}</div>
        <div
          className={
            wbreak
              ? 'text-[#445670] font-normal md:w-4/5'
              : 'text-[#445670] font-normal'
          }
        >
          {description}
        </div>
      </div>
    </div>
  )
}

export default PhpFormSectionInfo
