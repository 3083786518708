import React from 'react'
import './hamburger-menu.scss'

interface HamburgerMenuInterface {
  isHamburgerMenuOpen: boolean
  handleClick: () => void
}

const HamburgerMenu: React.FC<HamburgerMenuInterface> = ({
  isHamburgerMenuOpen,
  handleClick,
}) => {
  return (
    <div
      id="nav-icon"
      className={`${isHamburgerMenuOpen ? 'open' : ''}`}
      onClick={handleClick}
      data-testid="hamburger-menu"
    >
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
  )
}

export default HamburgerMenu
