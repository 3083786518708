import React from 'react'

interface MessagePopupProps {
  title: string
  message: string | React.ReactNode
}

const MessagePopup: React.FC<MessagePopupProps> = ({ title, message }) => {
  return (
    <div
      className="p-4 mb-4 sm:mr-4 mt-8"
      style={{
        background: 'rgba(74, 215, 72, 0.03)',
        border: '1px solid #349634',
        borderRadius: '1rem',
      }}
    >
      <p className="font-semibold">{title}</p>
      <p className="font-normal">{message}</p>
    </div>
  )
}

export default MessagePopup
