import React, { useState } from 'react'
import { CloseCircleFilled, InfoCircleFilled } from '@ant-design/icons'

const EmergencyBottomToast: React.FC = () => {
  const [isToastHidden, setIsToastHidden] = useState(false)

  const hiddenStyle = { display: 'none' }
  return (
    <div
      className="flex flex-col p-2 rounded"
      style={
        isToastHidden
          ? hiddenStyle
          : { backgroundColor: '#3F7A88', color: 'white' }
      }
    >
      <div className="flex flex-row justify-end">
        <CloseCircleFilled
          style={{ fontSize: 20 }}
          className="md:pr-2"
          onClick={() => {
            setIsToastHidden(true)
          }}
        />
      </div>
      <div className="flex flex-col md:flex-row justify-center md:justify-end gap-6 md:gap-8 md:px-16  pb-4 items-center">
        <div>
          <InfoCircleFilled style={{ fontSize: 50 }} />
        </div>
        <div className="text-sm">
          If you or your family member are not feeling safe (or are experiencing
          an imminent threat of self-harm or harm to others), you should call
          911 or go to the closest emergency department. You can also call 988
          for the National Suicide and Crisis Lifeline.{' '}
        </div>
      </div>
    </div>
  )
}

export default EmergencyBottomToast
