import { RadioChangeEvent } from 'antd'
import React, { FunctionComponent, useContext } from 'react'
import { InputRadioButtonGroup } from '../../common'
import { formRadioOptions } from '../form/radio-button/radio-button'
import { MetadataContext } from '../metadata/metadata-middleware'
interface RelationshipProps {
  relationshipHeading: string
  relationshipSubQuestion: string
  isAgeErrorModalOpen?: boolean
  isNewEAPFlow?: boolean
}

const Relationship: FunctionComponent<RelationshipProps> = ({
  relationshipHeading,
  relationshipSubQuestion,
  isAgeErrorModalOpen,
  isNewEAPFlow,
}) => {
  const { relationshipData } = useContext(MetadataContext)

  let radioBtnLabel = 'Relationship with Employee'

  if (relationshipSubQuestion === 'Your relationship with household member')
    radioBtnLabel = 'Relationship with household member'

  if (isNewEAPFlow) radioBtnLabel = "I am Employee's"

  return (
    <div>
      <div className="text-lg font-semibold mb-2 text-shade">
        {relationshipHeading}
      </div>
      <div className="text-sm font-normal mb-2 text-[#495B6F]">
        {relationshipSubQuestion}
      </div>
      <InputRadioButtonGroup
        isDisabled={isAgeErrorModalOpen}
        label={radioBtnLabel}
        name={'relationShip'}
        isRequired={true}
        onChange={function (e: RadioChangeEvent): void {
          console.log(e.target.value)
        }}
        radioGroupOption={formRadioOptions(relationshipData, 'code', 'name')}
      />
    </div>
  )
}

export default Relationship
