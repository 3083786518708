import { SearchOutlined } from '@ant-design/icons'
import { Checkbox, Input, Typography } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import { MetadataContext } from '../../../../../../common'
import {
  mixpanelTrackSpecialisationSearch,
  mixpanelTrackSelectedSpecialisations,
} from '../../../../../../common/utils/mixpanel'
import { CheckboxValueType } from 'antd/lib/checkbox/Group'
import SpecializationCheckboxItem from './specialisation-checkbox'
import '../../search-and-filter.scss'
import { isSafariOrFirefox } from '../../../../../../common/form/form.utils'

/**
 * TODO: Set height - 13 nos / column
 * width should now be based on how many elements remain after the search query
 * Check the list, based on the number of elements remaining, do a rem/13 * max-width = rem/13 * 10 rem as total width
 */

interface SpecialisationPopoverInterface {
  selectedItems: string[]
  setSelectedItems: (items: string[]) => void
  updateSelectedItems: (specialisation: string, shouldAdd: boolean) => void
}

const { Text } = Typography

const SpecialisationPopover: React.FC<SpecialisationPopoverInterface> = ({
  selectedItems,
  setSelectedItems,
  updateSelectedItems,
}) => {
  const { specializations } = useContext(MetadataContext)

  const [menuItems, setMenuItems] = useState<string[]>(
    specializations.map((specialization) => specialization.name)
  )
  const [searchValue, setSearchValue] = useState<string>('')
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value)
  }

  useEffect(() => {
    if (searchValue === '') return
    const mixpanelSpecialisationSearchDebounce = setTimeout(() => {
      mixpanelTrackSpecialisationSearch({ searchText: searchValue })
    }, 3000)
    return () => {
      clearTimeout(mixpanelSpecialisationSearchDebounce)
    }
  }, [searchValue])

  useEffect(() => {
    if (selectedItems.length <= 0) return
    const mixpanelSpecialisationSelectDebounce = setTimeout(() => {
      mixpanelTrackSelectedSpecialisations({
        selectedSpecialisations: selectedItems,
      })
    }, 3000)
    return () => {
      clearTimeout(mixpanelSpecialisationSelectDebounce)
    }
  }, [selectedItems])

  useEffect(() => {
    if (searchValue === '') {
      setMenuItems(specializations.map((specialization) => specialization.name))
    } else {
      setMenuItems(
        specializations
          .map((specialization) => specialization.name)
          .filter((menuItem) => {
            return menuItem.toLowerCase().includes(searchValue.toLowerCase())
          })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue])

  const onChange = (checkedValues: CheckboxValueType[]) => {
    console.log('checked = ', checkedValues)
  }

  return (
    <>
      <div className="flex flex-col gap-y-2.5">
        <div className="flex flex-row items-start">
          <Input
            prefix={<SearchOutlined />}
            onClick={(e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
              e.stopPropagation()
            }}
            placeholder="Search"
            bordered={false}
            className="rounded-xl !bg-[#0000000D] h-8 max-w-[400px]"
            onChange={handleSearchChange}
            value={searchValue}
          />
          <div
            className="px-2 py-1 underline text-teal-blue hidden lg:block cursor-pointer"
            onClick={() => {
              setSelectedItems([])
            }}
          >
            Clear
          </div>
        </div>
        <div className="flex flex-row">
          <Text className="font-normal text-sm text-teal-blue">
            Note: Max selection is three
          </Text>
        </div>
        <div
          className={`flex flex-row w-full specalisation-popover-checkbox-group ${
            isSafariOrFirefox()
              ? 'specalisation-popover-checkbox-group-safari'
              : ''
          }`}
        >
          <Checkbox.Group
            className={`flex flex-auto ${
              menuItems.length > 12 ? 'h-[400px]' : ''
            }`}
            onChange={onChange}
          >
            <div
              className={`flex flex-col custom-basis-1-${Math.ceil(
                menuItems.length / 12
              )} flex-wrap `}
            >
              {menuItems.map((menuItem, index) => {
                return (
                  <SpecializationCheckboxItem
                    specialisation={menuItem}
                    key={index}
                    checked={
                      selectedItems.filter(
                        (selectedItem) => selectedItem === menuItem
                      ).length === 1
                    }
                    updateSelectedItems={updateSelectedItems}
                    selectedItems={selectedItems}
                  />
                )
              })}
            </div>
          </Checkbox.Group>
        </div>
      </div>
    </>
  )
}

export default SpecialisationPopover
