import { useContext } from 'react'
import { LoaderContext } from '../index'
import APIUtils from './api-utils'

export interface useAPICallProps {
  errors?: any
  ok?: boolean
  status?: number | string
  id?: any
  name?: string
}

interface UseAPICallReturnType<T> {
  apiCall: (
    url: string,
    type: string,
    body?: string,
    signal?: AbortSignal
  ) => Promise<T>
}

function useAPICall<T extends useAPICallProps>(): UseAPICallReturnType<T> {
  const { setLoading } = useContext(LoaderContext)

  const apiCall = async (
    url: string,
    type: string,
    body?: string,
    signal?: AbortSignal
  ) => {
    let result = {} as T
    try {
      setLoading(true)
      result = await APIUtils().makeAPICall(
        url,
        type,
        body || undefined,
        signal
      )
    } catch (error: any) {
      if (error.name === 'AbortError') {
        // Aborting a fetch throws an error, so ignoring error.
      } else {
        throw new Error(error.message)
      }
    } finally {
      setLoading(false)
    }
    return result
  }

  return { apiCall }
}

export default useAPICall
