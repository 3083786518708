/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { ProvidenceLogo, SeattleStormLogo, TestOrgLogo } from '../../assets'
import { BrandName, FeatureFlags } from '../../pages/EAP/EAP-constants'
import { newEapFormPath, userFlowPath } from '../../routing/route.constants'
import { useAPICallProps } from '../api-utils/use-api-call'

export interface StatesInterface {
  name: string
  code: string
  tzZoneName: string
  timezone: string
  uiTimezone: string
}

export interface StateAgesInterface {
  state: string
  age: number
}
export interface RelationsInterface {
  id: string
  name: string
  code: string
}
export interface GenderInterface {
  id: string
  name: string
  code: string
}
export interface PatientTypesInterface {
  id: string
  name: string
}

export interface ContactTypesInterface {
  id: string
  name: string
}

export interface HouseHoldConfigInterface {
  type: string
  isDependentBlocked: boolean
}
export interface OrganizationInterface extends useAPICallProps {
  id: string
  name: string
  header: string
  title: string
  brandingText: string
  secureLink: string
  houseHoldConfig: HouseHoldConfigInterface[]
  brandingText_Mobile: string
  eapStepsList: StepProps[]
}

export interface CareMemeberTypeInterface {
  id: string
  name: string
}

export interface SpecializationsInterface {
  id: string
  name: string
}

export interface PopulationsInterface extends useAPICallProps {
  key: string
  values: PopulationsInterfaceNames[]
}

export interface PopulationsInterfaceNames {
  id: string
  name: string
}

export interface StepProps {
  id: number
  stepNo: number
  stepName: string
  stepTitle: string
  stepPath: string
  timeout: number
  fieldsList: any[]
}
export interface OrgFieldsProps extends useAPICallProps {
  organizationId: string
  name: string
  eapStepsList: StepProps[]
}
interface MetadataContextInterface {
  orgFields: OrgFieldsProps
  setOrgFields: React.Dispatch<React.SetStateAction<OrgFieldsProps>>
  currentBrand: string | undefined
  states: StatesInterface[]
  stateAges: StateAgesInterface[]
  relationshipData: RelationsInterface[]
  genderList: GenderInterface[]
  patientTypes: PatientTypesInterface[]
  contactTypeIdList: ContactTypesInterface[]
  organizationData: OrganizationInterface | null
  organizationMetaDataList: OrganizationInterface[]
  setStates: React.Dispatch<React.SetStateAction<StatesInterface[]>>
  setStateAges: React.Dispatch<React.SetStateAction<StateAgesInterface[]>>
  setRelationshipData: React.Dispatch<
    React.SetStateAction<RelationsInterface[]>
  >
  setGenderList: React.Dispatch<React.SetStateAction<GenderInterface[]>>
  setPatientTypes: React.Dispatch<React.SetStateAction<PatientTypesInterface[]>>
  setContactTypeIdList: React.Dispatch<
    React.SetStateAction<ContactTypesInterface[]>
  >
  setOrganizationData: React.Dispatch<
    React.SetStateAction<OrganizationInterface | null>
  >
  setOrganizationMetaDataList: React.Dispatch<
    React.SetStateAction<OrganizationInterface[]>
  >
  featureFlags: any[]
  setFeatureFlags: React.Dispatch<React.SetStateAction<any[]>>
  isSearchEnabled: boolean
  isPhpEnabled: boolean
  checkCurrentBrand: (brand: string | undefined) => string
  checkDependentBlocked: () => boolean | undefined
  isFeatureEnabled(flagname: FeatureFlags): boolean
  getEAPFlowURL(): string
  careMemberTypes: CareMemeberTypeInterface[]
  setCareMemberTypes: React.Dispatch<
    React.SetStateAction<CareMemeberTypeInterface[]>
  >
  specializations: SpecializationsInterface[]
  setSpecializations: React.Dispatch<
    React.SetStateAction<SpecializationsInterface[]>
  >
  populations: PopulationsInterface[]
  setPopulations: React.Dispatch<React.SetStateAction<PopulationsInterface[]>>
}
export const MetadataContext = React.createContext(
  {} as MetadataContextInterface
)

export const MetadataMiddleware: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [orgFields, setOrgFields] = useState<OrgFieldsProps>({
    eapStepsList: [],
    organizationId: '',
    name: '',
  } as OrgFieldsProps)
  const [states, setStates] = useState<Array<StatesInterface>>([])
  const [stateAges, setStateAges] = useState<Array<StateAgesInterface>>([])
  const [relationshipData, setRelationshipData] = useState<
    Array<RelationsInterface>
  >([])
  const [genderList, setGenderList] = useState<Array<GenderInterface>>([])
  const [patientTypes, setPatientTypes] = useState<
    Array<PatientTypesInterface>
  >([])
  const [contactTypeIdList, setContactTypeIdList] = useState<
    Array<ContactTypesInterface>
  >([])
  const [organizationData, setOrganizationData] =
    useState<OrganizationInterface | null>(null)
  const [organizationMetaDataList, setOrganizationMetaDataList] = useState<
    Array<OrganizationInterface>
  >([])
  const [featureFlags, setFeatureFlags] = useState<Array<any>>([])

  const [isSearchEnabled, setIsSearchEnabled] = useState(false)
  const [isPhpEnabled, setIsPhpEnabled] = useState(false)
  const [careMemberTypes, setCareMemberTypes] = useState<
    Array<CareMemeberTypeInterface>
  >([])
  const [specializations, setSpecializations] = useState<
    SpecializationsInterface[]
  >([])
  const [populations, setPopulations] = useState<PopulationsInterface[]>([])
  useEffect(() => {
    // set search Feature
    const searchFeature = [...featureFlags].filter(
      (e) => e.name === FeatureFlags.SearchFeature
    )
    if (
      searchFeature[0]?.isEnabled === true ||
      searchFeature[0]?.isEnabled === false
    ) {
      setIsSearchEnabled(searchFeature[0]?.isEnabled)
    }

    // set hidePhp Feature
    const HidePHPFeature = [...featureFlags].filter(
      (e) => e.name === FeatureFlags.HidePHPFeature
    )
    if (
      HidePHPFeature[0]?.isEnabled === true ||
      HidePHPFeature[0]?.isEnabled === false
    ) {
      setIsPhpEnabled(!HidePHPFeature[0]?.isEnabled)
    }
  }, [featureFlags, featureFlags.length])

  const checkDependentBlocked = () => {
    return organizationData?.houseHoldConfig.some((obj) => {
      return obj.type === 'EAP' && obj.isDependentBlocked === true
    })
  }
  const checkCurrentBrand = (brand: string | undefined) => {
    switch (brand) {
      case BrandName.PROVIDENCE:
        return ProvidenceLogo
      case BrandName.SEATTLESTORM:
        return SeattleStormLogo
      default:
        return TestOrgLogo
    }
  }
  const isFeatureEnabled = (featureFlagName: FeatureFlags) => {
    const flagDetails = featureFlags.filter(
      (e) => e.name === featureFlagName.toString()
    )
    return flagDetails[0]?.isEnabled || false
  }

  const getEAPFlowURL = () => {
    if (isFeatureEnabled(FeatureFlags.HideQuestionnaireFeature)) {
      return `${newEapFormPath}/initial-details`
    } else {
      return `${userFlowPath}/EAP`
    }
  }

  return (
    <MetadataContext.Provider
      value={{
        orgFields,
        setOrgFields,
        stateAges,
        states,
        relationshipData,
        genderList,
        patientTypes,
        contactTypeIdList,
        organizationData,
        organizationMetaDataList,
        setStateAges,
        setStates,
        setRelationshipData,
        setGenderList,
        setPatientTypes,
        setContactTypeIdList,
        setOrganizationData,
        setOrganizationMetaDataList,
        currentBrand: organizationData?.name.toLowerCase().replaceAll(' ', '-'),
        featureFlags,
        setFeatureFlags,
        isSearchEnabled,
        isPhpEnabled,
        checkCurrentBrand,
        checkDependentBlocked,
        isFeatureEnabled,
        getEAPFlowURL,
        careMemberTypes,
        setCareMemberTypes,
        specializations,
        setSpecializations,
        populations,
        setPopulations,
      }}
    >
      {children}
    </MetadataContext.Provider>
  )
}
