/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form } from 'antd'
import React, { useContext, useEffect, useCallback, useState } from 'react'
import NewSubmitCancel from '../../../common/new-form/new-buttons/new-submit-cancel'
import PrimarySubscriberConsent from '../../../common/new-form/primary-subscriber-consent/primary-subscriber-consent'
import PhpPasswordInformation from '../php-password-information/php-password-information'
import { PHPRegistrationContext } from '../php-registration/php-registration-middleware'
import { Link } from 'react-router-dom'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

interface PasswordSetupProps {
  onPrevious: () => void
  onFinish: (values: any, token: any, handleReCaptchaVerify: any) => void
  email?: string
  confirmStep: number
}
const PhpPasswordSetup: React.FC<PasswordSetupProps> = ({
  onFinish,
  onPrevious,
  confirmStep,
}) => {
  const { executeRecaptcha } = useGoogleReCaptcha()
  const [token, SetToken] = useState<any>()

  const [form] = Form.useForm()
  const { primarySubscriberDetails } = useContext(PHPRegistrationContext)

  const [termsConsent, setTermsConsent] = React.useState(false)

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('recaptcha not yet available')
      return
    }

    const RecapchaToken = await executeRecaptcha()
    SetToken(RecapchaToken)
  }, [executeRecaptcha])

  useEffect(() => {
    handleReCaptchaVerify()

    const interval = setInterval(() => {
      handleReCaptchaVerify()
    }, 60000) // refresh capcha every 60 seconds

    return () => clearInterval(interval)
  }, [handleReCaptchaVerify])

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={(values) => onFinish(values, token, handleReCaptchaVerify)}
      scrollToFirstError
    >
      <div className="mx-4 md:ml-6 md:mr-8">
        <div className="formItems mt-4">
          <PhpPasswordInformation
            email={
              confirmStep === 4
                ? primarySubscriberDetails.psEmail
                : primarySubscriberDetails.email
            }
            confirmStep={confirmStep}
          />
          <PrimarySubscriberConsent
            message={
              <>
                I have read the Trusana{' '}
                <Link
                  to={'/terms/termsofuse'}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-[#0067C7] hover:underline"
                >
                  terms and conditions
                </Link>{' '}
                and consent to receive email/text communications from Trusana.
              </>
            }
            isChecked={termsConsent}
            consentType={'termsConsent'}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onChange={(event: any) => {
              setTermsConsent(event.target.checked)
            }}
          />
        </div>
      </div>
      <div className="bg-white md:bg-transparent mt-4">
        <NewSubmitCancel
          shouldDisplayPrevious={true}
          isSubmit={true}
          onPrevious={onPrevious}
        />
      </div>
    </Form>
  )
}

export default PhpPasswordSetup
