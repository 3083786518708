import React, { useState } from 'react'
import { Form, InputNumber } from 'antd'
import { LABEL_ALIGN_TYPE } from '../form.constants'
import { formRules, Validator } from '../form.utils'
import classNames from 'classnames'
import FloatingLabel from '../floating-lable/floating-lable'

interface InputNumProps {
  label: string
  isDisabled?: boolean
  name: string
  isRequired?: boolean
  labelAlign?: keyof typeof LABEL_ALIGN_TYPE
  pattern?: RegExp
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onBlur?: (e: any) => void
  onChange?: (value: number | string | Array<number | string>) => void
  className?: string
  validateTrigger?: string | string[]
  validator?: Validator
  patternMessage?: string
  defaultValue?: number
  step?: number
  max?: number
  min?: number
  prefix?: React.ReactNode
  addonBefore?: React.ReactNode
  value?: number
  maxLength?: number
  placeholder?: string
  shouldShowDollar?: boolean
  isPhoneNumber?: boolean
}

const InputNum: React.FC<InputNumProps> = (props: InputNumProps) => {
  const {
    label,
    name,
    isRequired,
    validator,
    className,
    pattern,
    patternMessage,
    defaultValue,
    step,
    max,
    min,
    isDisabled,
    labelAlign,
    validateTrigger,
    prefix,
    addonBefore,
    value,
    maxLength,
    onChange,
    isPhoneNumber = false,
  } = props
  const [active, setActive] = useState(false)

  const onBlur = () => {
    setActive(false)
  }

  const onFocus = () => {
    setActive(true)
  }
  return (
    <FloatingLabel active={active} labelText={label} name={name}>
      <Form.Item
        // label={label}
        name={name}
        labelAlign={labelAlign}
        validateTrigger={validateTrigger}
        className={classNames('block', className)}
        rules={formRules(label, isRequired, validator, pattern, patternMessage)}
        validateFirst={true}
      >
        {isPhoneNumber ? (
          <InputNumber
            disabled={isDisabled}
            step={step}
            defaultValue={defaultValue}
            max={max}
            min={min}
            maxLength={12}
            onChange={onChange}
            className={classNames('form-item-input-width', className)}
            prefix={prefix}
            addonBefore={addonBefore}
            onBlur={onBlur}
            onFocus={onFocus}
            value={value}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            formatter={(value: any) => {
              // const temp = `${value}`
              if (value.length <= 3) {
                return value
              } else if (value.length > 3 && value.length <= 6) {
                return `${value.slice(0, 3)}-${value.slice(3)}`
              } else {
                return `${value.slice(0, 3)}-${value.slice(3, 6)}-${value.slice(
                  6,
                  10
                )}`
              }
            }}
            parser={(value) =>
              value ? parseInt(value.replace(/\$\s?|(-*)/g, '')) : ``
            }
            id={name}
          />
        ) : (
          <InputNumber
            disabled={isDisabled}
            step={step}
            defaultValue={defaultValue}
            max={max}
            min={min}
            maxLength={maxLength}
            onChange={onChange}
            className={classNames('form-item-input-width', className)}
            prefix={prefix}
            addonBefore={addonBefore}
            onBlur={onBlur}
            onFocus={onFocus}
            value={value}
            id={name}
          />
        )}
      </Form.Item>
    </FloatingLabel>
  )
}

export default InputNum
