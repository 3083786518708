import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import {
  marketingTrusanaLogo,
  marketingTrusanaLogoTest,
  memberLoginIcon,
} from '../../assets'
import {
  BrandName,
  redirectToHelthieWebsite,
} from '../../pages/EAP/EAP-constants'
import { MetadataContext, useMarketingPageTracker } from '../../common'
import './app-header.scss'
import useIsBrandLogoShown from '../../common/hooks/use-is-brand-logo-shown'
import { homePath } from '../../routing'
import { faqPath, phpFromPath } from '../../routing/route.constants'
import { mixpanelTrackMemberLogin } from '../../common/utils/mixpanel'
import { replaceSpaceAndConvertToLowerCase } from '../../common/utils/string-functions'
import { isProductionEnv } from '../../configuration/config'
import { decodeFromBase64 } from '../../common/form/form.utils'

const AppHeader: React.FC = () => {
  const { isPhpEnabled } = useContext(MetadataContext)

  const isMarketingPage: boolean = useMarketingPageTracker()
  const location = useLocation()

  if (location.pathname.includes(phpFromPath) && !isPhpEnabled) {
    return <RegistrationAppHeader />
  } else if (isMarketingPage) {
    return null
  } else if (location.pathname.includes(phpFromPath) && isPhpEnabled) {
    return null
  } else {
    return <RegistrationAppHeader />
  }
}

const RegistrationAppHeader: React.FC = () => {
  const history = useHistory()
  const location = useLocation()
  const isRightContentHidden =
    location.pathname.includes('/final-confirmation') ||
    location.pathname.includes('/update-appointment')
  const onAppLogoClickHandler = () => {
    history.push(homePath)
  }
  const isBrandLogoShown = useIsBrandLogoShown()
  const { currentBrand, checkCurrentBrand } = useContext(MetadataContext)

  const currentUrl = window.location.origin + location.pathname
  const currentBrandForFAQInLocalStorage = localStorage.getItem(
    'currentBrandForFAQInLocalStorage'
  )

  const [currentBrandFromUrl, setCurrentBrandFromUrl] = useState<string>('')
  useEffect(() => {

    const urlParams = new URLSearchParams(window.location.search);
    const encodedObj = urlParams.get('brandDetails');
    if (encodedObj) {
      setCurrentBrandFromUrl((decodeFromBase64(encodedObj)!.brandName as string).toLowerCase().replaceAll(' ', '-'))
    }
  }, [currentBrandFromUrl])

  const handleBrandClassName = (brand: string | undefined) => {
    return replaceSpaceAndConvertToLowerCase(brand || '') ===
      BrandName.SEATTLESTORM
      ? 'max-w-[3rem] mr-6 object-contain hidden md:flex'
      : 'max-w-[10rem] mr-6 object-contain hidden md:flex'
  }
  const isChangeProviderFlow = location.pathname.includes('/change-provider')

  return (
    <nav className="bg-white top-0 header-style absolute w-full md:sticky h-20 drop-shadow-md flex flex-row justify-center">
      <div className="flex items-center justify-between h-full max-w-full w-full mx-auto">
        <div
          className={`flex justify-start md:justify-between z-50 xs:pl-2 xs:pr-2 xs:py-4 sm:pl-4 w-auto sm:w-[75%] md:w-auto lg:w-full mx-0 my-auto md:my-0 
          ${isChangeProviderFlow ? 'md:mx-5 sm:mx-0' : 'md:mx-12 2xl:mx-32'}`}
        >
          <img
            src={
              isProductionEnv()
                ? marketingTrusanaLogo
                : marketingTrusanaLogoTest
            }
            alt="logo"
            className="md:cursor-pointer h-7 ml-2 md:ml-0 md:h-9"
            onClick={onAppLogoClickHandler}
          />
        </div>
        {!isRightContentHidden ? (
          <div className="flex flex-row md:gap-2 pr-2 mr-2 md:mr-0 lg:mr-8 2xl:mr-12 md:pr-8">
            {isBrandLogoShown ? (
              <div className="flex flex-row">
                {currentBrand ? (
                  <img
                    src={checkCurrentBrand(currentBrand?.toLowerCase())}
                    alt="Brand Logo"
                    className={handleBrandClassName(currentBrand)}
                  />
                ) : location.pathname.includes(faqPath) ? (
                  <img
                    src={checkCurrentBrand(
                      currentBrandFromUrl?.toLowerCase()
                    )}
                    alt="Brand Logo"
                    className={handleBrandClassName(
                      currentBrandFromUrl || ''
                    )}
                  />
                ) : null}
                <div className="w-[1px] h-auto bg-teal-blue mr-4 hidden md:flex"></div>
              </div>
            ) : null}
            <a
              className="flex flex-row items-center justify-center rounded-full px-4 md:px-8 w-fit cursor-pointer select-none text-sm md:text-base font-medium max-w-sm flex items-center justify-center text-shade py-2 border-shade border border-solid hover:text-shade"
              onClick={() => {
                try {
                  mixpanelTrackMemberLogin({
                    location: currentUrl,
                  })
                } finally {
                  redirectToHelthieWebsite()
                }
              }}
            >
              <div className="flex flex-row items-center justify-center">
                <img
                  src={memberLoginIcon}
                  alt="Member Login"
                  className="mr-1 max-h-[30px] max-w-[30px]"
                />
                <div className="whitespace-nowrap font-medium">
                  Member login
                </div>
              </div>
            </a>
          </div>
        ) : null}
      </div>
    </nav>
  )
}

export default AppHeader
