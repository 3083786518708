import { useHistory } from 'react-router-dom'
import React, { useContext, useEffect, useRef, useState } from 'react'
import './appointment-page.scss'
import { useAPICall, APICallType, getAvailableDateAndSlots } from '../../../common'
import CheckAvailability from './check-availability'
import moment from 'moment'
import femaleDoctor from '../../../assets/femaleDoctor.png'
import { Col, Modal, Row } from 'antd'
import APIErrorMessagePopup from '../../../common/error-message-popup/api-error-message-popup'
import { API_URLs } from '../../../configuration/api-urls'
import { MetadataContext } from '../../../common/metadata/metadata-middleware'
import TherapistBottomToast from './therapist-bottom-toast'
import useOnScreen from '../../../common/hooks/useOnScreen'
import { AppointmentPageContext } from './appointment-page-middleware'
import AppFooterSmall from '../../../core/app-footer/app-footer-small'
import {
  mixpanelTrackBookAppointment,
  mixpanelTrackScrollToBottom,
} from '../../../common/utils/mixpanel'
import SearchAndFilter from './search-and-filter/search-and-filter'
import BookedAppointmentCard from './booked-appointment-state/booked-appointment-card'
import AppointmentPageProps from './interfaces/appointment-page-props'
import TherapistModel, {
  SingleTherapistModel,
} from './interfaces/therapist-model'
import { providerSearchFailError, getPatientTypeId } from '../EAP-constants'
import { ProviderRequestInterface } from './interfaces/available-slots-request-interface'
import {
  getAppliedFilters,
  // getEncodedSearchQuery,
  getTypeOfAppointment,
} from '../../../common/form/form.utils'
import TherapistList from './therapist-list/therapist-list'
import useTrackAppointmentPage from '../../../common/hooks-mixpanel/useTrackAppointmentPage'
import EAPAppointmentPageTopSection from './appointment-page-top-section'
import { PopupTimerContext } from '../popup-timer/popup-timer-middleware'
import InactivityModal from '../../../common/modal/inactivity-modal/inactivity-modal'
import { tryParseInt } from '../../../common/utils/common-utils'
import useScreenWidth from '../../../common/hooks/use-screen-width'
// import usePopupTimer from '../../../common/hooks/use-popup-timer'

const AppointmentPage: React.FC<AppointmentPageProps> = ({
  isPhpFlow = false,
  clickNextPath,
  clickPreviousPath,
  visitorId,
  state,
  current,
  patientType,
}) => {
  const {
    states,
    contactTypeIdList,
    organizationData,
    isSearchEnabled,
    patientTypes,
  } = useContext(MetadataContext)
  const {
    bookingDate,
    updateBookingDate,
    updateTherapistID,
    appointmentDetails,
    updateAppointmentDetails,
    appointmentType,
    searchData,
    selectedDaysArray,
    range,
    selectedTimeBlock,
    selectedSpecialisationItems,
    isSpeciasilationFilterOpen,
    selectedPopulationItems,
    updateIsRetryWithoutFilters,
    isFilterDrawerOpen,
    therapistSlotsData
  } = useContext(AppointmentPageContext)
  const { remaining, appointmentTimerPopup } = useContext(PopupTimerContext)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [isLoadingTherapists, setIsLoadingTherapists] = useState<boolean>(true)
  const [isResetConfirmation, setIsResetConfirmation] = useState<boolean>(false)
  const [selectedTherapist, setSelectedTherapist] =
    useState<SingleTherapistModel | null>(null)
  const updateSelectedTherapist = (
    selectedTherapist: SingleTherapistModel | null
  ) => {
    setSelectedTherapist(selectedTherapist)
  }
  const [shouldDisplayErrorModal, setShouldDisplayErrorModal] =
    useState<boolean>(false)
  const [shouldDisplayRecommendations, setShouldDisplayRecommendations] =
    useState<boolean>(false)
  const [therapistResponseData, setTherapistResponseData] = useState<
    SingleTherapistModel[]
  >([])
  const [predictionData, setPredictionData] = useState<any>()
  const [isNoMatch, setIsNoMatch] = useState(false)
  const [isRetry, setIsRetry] = useState(true)
  const therapistBottomToastRef = useRef<HTMLDivElement | null>(null)
  const isIntersecting = useOnScreen(therapistBottomToastRef)
  const { apiCall } = useAPICall<TherapistModel>()
  const history = useHistory()
  const currentStateData = states.find((obj) => obj.code === state)
  const width = useScreenWidth()

  // usePopupTimer()
  useEffect(() => {
    if (
      !organizationData?.id ||
      !visitorId ||
      bookingDate ||
      isSpeciasilationFilterOpen || isFilterDrawerOpen && width < 768
    )
      return

    updateIsRetryWithoutFilters(false)
    setIsNoMatch(false)
    if (isSpeciasilationFilterOpen === false) {
      setIsLoadingTherapists(true)
    }

    const abortController = new AbortController()

    const providerRequestParams: ProviderRequestInterface = {
      visitorId: visitorId,
      organizationId: organizationData.id,
      Filters: getAppliedFilters(
        selectedDaysArray,
        range,
        selectedSpecialisationItems,
        selectedPopulationItems
      ),
    }

    apiCall(
      API_URLs.v7.POST_Provider,
      APICallType.POST,
      JSON.stringify(providerRequestParams),
      abortController.signal
    )
      .then((data: TherapistModel) => {
        if (data?.providers?.length > 0) {
          setTherapistResponseData(data?.providers)
          setPredictionData(data?.prediction)
          setIsNoMatch(data?.isNoMatchFound)
          /*  this case is to handle where the search is disabled from feature flag
           *  when disabled don't show any recommendations */
          if (!isSearchEnabled) {
            // TODO: Fix this when search is live
            setShouldDisplayRecommendations(false)
          } else {
            setShouldDisplayRecommendations(
              !data.providers.every((obj) => obj.searchType === 0) &&
              !data.providers.every((obj) => obj.searchType === 2)
            )
          }
        }
        setIsLoadingTherapists(false)
      })
      .catch(() => {
        setErrorMessage(JSON.stringify(providerSearchFailError))
        toggleErrorModal()
        setIsRetry(false)
      })

    return () => {
      abortController.abort()
    }
  }, [
    searchData,
    range,
    selectedDaysArray,
    selectedTimeBlock,
    bookingDate,
    selectedSpecialisationItems,
    selectedPopulationItems,
  ])

  useEffect(() => {
    if (isNoMatch) {
      updateIsRetryWithoutFilters(true)
    }
  }, [isNoMatch])

  useTrackAppointmentPage({
    therapistResponseData,
    selectedDaysArray,
    range,
    selectedSpecialisationItems,
    selectedPopulationItems,
    isNoMatch,
  })

  const toggleErrorModal = () => {
    setShouldDisplayErrorModal(!shouldDisplayErrorModal)
    setSelectedTherapist(null)
    setIsResetConfirmation((prev) => !prev)
  }

  const bookAppointment = (
    date: any,
    clickNextPath: string,
    therapistId: any
  ) => {
    const typeOfAppointment = getTypeOfAppointment(
      appointmentType,
      contactTypeIdList
    )
    const request = {
      date,
      providerId: therapistId,
      patientType: getPatientTypeId(patientType, patientTypes),
      timezone: currentStateData?.tzZoneName,
      visitorId: visitorId,
      contactTypeId: typeOfAppointment,
      organizationId: `${organizationData?.id}`,
    }

    apiCall(
      API_URLs.v3.POST_AppointmentCreate,
      APICallType.POST,
      JSON.stringify(request)
    )
      .then((response: any) => {
        updateAppointmentDetails({
          abbr: response.abbr,
          therapistName: response.providerName,
          providerId: response.providerId,
        })
        updateBookingDate(date)
        updateTherapistID(therapistId)
        mixpanelTrackBookAppointment({
          bookingDay: moment(date).format('dddd'),
          bookingTimeSlot: date,
          bookingTimeZone: response.abbr,
          bookedFrom: !selectedTherapist,
          therapistName: response.providerName,
        })
        history.push(clickNextPath)
      })
      .catch((error) => {
        console.log(error.message.errors)
        setErrorMessage(String(error.message))
        toggleErrorModal()
        setIsRetry(true)
      })
  }

  const goBackToList = () => {
    setSelectedTherapist(null)
  }

  const checkAvailability = (id: string) => {
    updateSelectedTherapist(
      therapistResponseData.find((item: { id: string }) => item.id === id) ??
      null
    )
  }

  const GetTimeZoneToDisplay: React.FC = () => {
    return (
      <div
        className={` ${isPhpFlow ? 'text-end' : 'md:mt-[2.5rem]'
          } md:mr-10 font-normal text-xs text-black time-zone-position`}
      >
        *{currentStateData?.uiTimezone}
      </div>
    )
  }

  useEffect(() => {
    if (isIntersecting) {
      mixpanelTrackScrollToBottom({ didScrollToBottom: isIntersecting })
    }
  }, [isIntersecting])

  if (bookingDate != null) {
    return (
      <div className="mt-[-2rem]">
        <BookedAppointmentCard
          therapistName={appointmentDetails.therapistName}
          bookingDate={bookingDate}
          abbr={appointmentDetails.abbr}
          clickNextPath={clickNextPath}
          updateBookingDate={updateBookingDate}
          clickPreviousPath={clickPreviousPath}
        />
      </div>
    )
  }

  return (
    <div
      className={`overflow-y-auto xs:overflow-y-visible h-screen md:mt-0 ${isPhpFlow ? '' : 'mt-[10vh]'
        }`}
    >
      <div className={`${isPhpFlow ? '' : 'w-full'}`}>
        {isPhpFlow ? null : (
          <EAPAppointmentPageTopSection
            current={current}
            clickPreviousPath={clickPreviousPath}
          />
        )}
        {currentStateData?.uiTimezone && !isPhpFlow ? (
          <div className="block md:hidden ant-col-sm-12 ant-col-xs-12 mt-4 ml-auto mr-8 w-fit">
            <GetTimeZoneToDisplay />
          </div>
        ) : null}
        <Row className="hidden md:grid text-end">
          {!isPhpFlow && (
            <Col md={24} className="items-end">
              <GetTimeZoneToDisplay />
            </Col>
          )}
        </Row>
        <Row className={`${isPhpFlow ? '' : 'mt-4'}`}>
          {isSearchEnabled && (
            <Row className="w-full">
              <SearchAndFilter />
            </Row>
          )}
          {isPhpFlow ? (
            <Row className="w-full justify-end mr-4">
              <GetTimeZoneToDisplay />
            </Row>
          ) : null}
          <TherapistList
            isSearch={!(selectedSpecialisationItems.length > 0)}
            isLoadingTherapists={isLoadingTherapists}
            therapistResponseData={therapistResponseData}
            predictionData={predictionData}
            shouldDisplayRecommendations={shouldDisplayRecommendations}
            isResetConfirmation={isResetConfirmation}
            bookAppointment={bookAppointment}
            updateSelectedTherapist={updateSelectedTherapist}
            clickNextPath={clickNextPath}
            visitorId={visitorId}
            isPhpFlow={isPhpFlow}
          />
        </Row>
        <div className="bg-light-sun" ref={therapistBottomToastRef}>
          {therapistResponseData &&
            therapistResponseData.length > 0 &&
            !selectedTherapist ? (
            <div
              className={`justify-center flex md:pb-24  bg-light-sun m-8 md:m-0`}
              style={{
                opacity: isIntersecting ? 1 : 0,
                transition: 'opacity 0.5s ease-in-out ',
              }}
            >
              <TherapistBottomToast />
            </div>
          ) : null}
        </div>{' '}
        {!selectedTherapist ? <AppFooterSmall /> : null}
      </div>
      {selectedTherapist ? (
        <>
          <Modal
            footer={null}
            destroyOnClose={true}
            visible={!!selectedTherapist}
            onCancel={() => setSelectedTherapist(null)}
            centered
            className="booking-modal-style"
          >
            <CheckAvailability
              key={selectedTherapist.id}
              therapistId={selectedTherapist.id}
              name={selectedTherapist.fullName}
              ex={selectedTherapist.credentials}
              experience={tryParseInt(selectedTherapist.experience)}
              description={selectedTherapist.shortBio}
              image={
                selectedTherapist.avatarFileName
                  ? selectedTherapist.avatarUrl
                  : femaleDoctor
              }
              goBackToList={goBackToList}
              bookAppointment={bookAppointment}
              clickNextPath={clickNextPath}
              closeModal={() => setSelectedTherapist(null)}
              checkAvailability={checkAvailability}
              userState={state}
              visitorId={visitorId}
              isChangeProviderFlow={false}
              therapistSlotsData={therapistSlotsData[selectedTherapist.id] || []}
              therapistavailableTimeSlots={getAvailableDateAndSlots(therapistSlotsData[selectedTherapist.id])}           
            />
          </Modal>
        </>
      ) : null}
      <APIErrorMessagePopup
        toggleModal={toggleErrorModal}
        shouldDisplayErrorModal={shouldDisplayErrorModal}
        errorMessage={errorMessage}
        isRetry={isRetry}
      />
      {remaining === 0 && !appointmentTimerPopup.isAlreadyDisplayed && (
        <InactivityModal current={1} timezone={currentStateData?.uiTimezone} />
      )}
    </div>
  )
}

export default AppointmentPage
