import { Checkbox, Form } from 'antd'
import React, { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'

const Consent: FunctionComponent = () => {
  return (
    <Form.Item
      label={'Consent'}
      name={'consent'}
      valuePropName="checked"
      rules={[
        {
          validator: (_, value) => {
            return value
              ? Promise.resolve()
              : Promise.reject(
                  new Error('Please accept the terms and conditions')
                )
          },
        },
      ]}
    >
      <div className="consent w-full sm:w-4/5">
        <Checkbox className="mt-4 mb-2">
          I have read the Trusana{' '}
          <Link
            to={'/terms/termsofuse'}
            target="_blank"
            rel="noopener noreferrer"
            className="text-[#0067C7]"
          >
            terms and conditions
          </Link>{' '}
          and consent to receive email/text communications from Trusana.
        </Checkbox>
      </div>
    </Form.Item>
  )
}

export default Consent
