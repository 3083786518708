import { useEffect, useState } from 'react'

const useScreenWidth = () => {
  const [width, setWidth] = useState(window.innerWidth)

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth)
    }

    // Subscribe to window resize events
    window.addEventListener('resize', handleResize)

    // Unsubscribe from window resize events when the component is unmounted
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])
  return width
}

export default useScreenWidth
