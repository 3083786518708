import { Form } from 'antd'
import React, { FunctionComponent } from 'react'
import './floatingLabel.css'
interface FloatingLabelProps {
  labelText: string
  children: React.ReactElement
  active: boolean
  value?: string
  name: string
}

const FloatingLabel: FunctionComponent<FloatingLabelProps> = ({
  active,
  children,
  labelText,
  name,
}) => {
  return (
    <Form.Item
      noStyle
      shouldUpdate={(prevValues, currentValues) =>
        prevValues[name] !== currentValues[name]
      }
    >
      {({ getFieldValue }) => (
        <div style={{ display: 'flex', flexFlow: 'column-reverse' }}>
          {children}
          <span
            className={`label ${
              active || getFieldValue(name) ? 'floatingLabel' : ''
            }`}
          >
            {labelText}
          </span>
        </div>
      )}
    </Form.Item>
  )
}

export default FloatingLabel
