/* eslint-disable @typescript-eslint/no-explicit-any */
import { Col, Form, Row, Spin } from 'antd'
import { useContext, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import {
  APICallType,
  flatQueryParams,
  MetadataContext,
  useAPICall,
  useGet,
} from '../../common'
// import useIsBrandValid from '../../common/hooks/use-is-brand-valid'
import useLoadOrganizationMetaData from '../../common/hooks/use-load-organization-metadata'
import { API_URLs } from '../../configuration/api-urls'
import './company-code.scss'
import {
  mixpanelTrackOrganisationCodeEntered,
  mixpanelTrackOrganisationNameEntered,
} from '../../common/utils/mixpanel'
import { CheckCircleOutlined, LoadingOutlined } from '@ant-design/icons'
import { BrandName, alphabetPattern } from '../EAP/EAP-constants'
import { onboardingFormPath } from '../../routing/route.constants'
import { BackButton, girlMeditationImage } from '../../assets'
import classNames from 'classnames'
import {
  FormInvalidMessage,
  formRules,
  getUrlParam,
} from '../../common/form/form.utils'

// TODO: Wrapper for the component to make API calls and have data in state before mounting

const CompanyCode = () => {
  const location = useLocation()
  const { apiCall } = useAPICall()
  const queryParams = new URLSearchParams(location.search)
  const [orgNameForm] = Form.useForm()
  const [orgCodeForm] = Form.useForm()
  const history = useHistory()

  const company = queryParams.get('company')
  const initialValues = { companyName: `${company || ''}` }

  const [companyCode, setCompanyCode] = useState('')
  const [isValidCompany, setIsValidCompany] = useState(false)
  const [isCompanyBeingChecked, setIsCompanyBeingChecked] = useState(false)
  const [isCodeBeingChecked, setIsCodeBeingChecked] = useState(false)
  const [organisationName, setOrganisationName] = useState('')
  const [isInitialValue, setIsInitialValue] = useState(false)

  const {
    organizationData,
    currentBrand,
    featureFlags,
    setFeatureFlags,
    setOrganizationData,
    getEAPFlowURL,
  } = useContext(MetadataContext)

  useLoadOrganizationMetaData()

  const onCompanyCodeChange = (e: any) => {
    setCompanyCode(e.target.value)
  }

  // useIsBrandValid(brand)

  useEffect(() => {
    const textFieldValueParam = getUrlParam('company')?.replace('-', '')
    if (textFieldValueParam) {
      orgNameForm.setFieldsValue({ companyName: textFieldValueParam })
      setOrganisationName(textFieldValueParam)
      setIsInitialValue(true)
    }
  }, [])

  useEffect(() => {
    if (isInitialValue) {
      const syntheticEvent = {
        // eslint-disable-next-line
        preventDefault: () => {},
      }
      HandleGo(syntheticEvent)
      setIsInitialValue(false)
    }
  }, [isInitialValue])

  const handleSearchInputChange = (e: any) => {
    setIsValidCompany(false)
    orgCodeForm.setFieldsValue({ companyCode: '' })
    orgCodeForm.setFields([{ name: 'companyCode', errors: [] }])
    setCompanyCode('')
    setOrganisationName(e.target.value)
  }

  const featureFlagsData = useGet<any[]>({
    url: API_URLs.v1.GET_MetadataFeatureFlags,
    isCancelled: featureFlags.length > 0,
  })

  useEffect(() => {
    if (featureFlagsData && featureFlagsData.length > 0) {
      setFeatureFlags(featureFlagsData)
    }
  }, [featureFlagsData])

  const redirectToHomepage = () => {
    history.push('/')
  }

  const fetchFlowType = () => {
    return getEAPFlowURL()
  }

  const validateComapanyCode = (_cc: any, value: any) => {
    const invalidCharacterSet = `^[\\""/?<>%()=;@\[\]\'\`:*]`
    const companyCodeInvalidRegex = /[\\""/?<>%()=;@\[\]\'`:*]/
    if (companyCodeInvalidRegex.test(value)) {
      return Promise.reject(
        `Please enter a valid company code, without ${invalidCharacterSet}`
      )
    }
    mixpanelTrackOrganisationCodeEntered(value)
    setIsCodeBeingChecked(true)
    return apiCall(
      `${API_URLs.v1.GET_OrganizationValidate}${flatQueryParams({
        Id: `${organizationData?.id}`,
        CompanyCode: companyCode,
      })}`,
      APICallType.GET
    )
      .then(() => {
        setIsCodeBeingChecked(false)
        Promise.resolve()
      })
      .catch(() => {
        setIsCodeBeingChecked(false)
        return Promise.reject(new Error('Organization Code entered is invalid'))
      })
  }

  const onCompanyCodeFinish = () => {
    history.push(`/${currentBrand?.replaceAll(' ', '-')}/eap/initial-details`, {
      companyCode: companyCode,
    })
  }

  const HandleGo = async (e: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    orgNameForm.validateFields(['companyName']).catch((errors) => {
      //do nothing
    })

    if (!organisationName) {
      return
    }

    setIsCompanyBeingChecked(true)
    e.preventDefault()
    return await apiCall(
      `${
        API_URLs.v1.GET_OrganizationValidate
      }?Text=${organisationName.toLowerCase()}`,
      APICallType.GET
    )
      .then((response: any) => {
        if (response.id !== null || '') {
          setIsCompanyBeingChecked(false)
          mixpanelTrackOrganisationNameEntered(response.name)
          setOrganizationData(response)
          if (response.name.toLowerCase() === BrandName.PROVIDENCE) {
            return history.push(
              `/${response.name.toLowerCase()}` + fetchFlowType()
            )
          }
          setIsValidCompany(true)
        } else
          return history.push(onboardingFormPath, { OrgName: organisationName })
      })
      .catch(() => {
        history.push(onboardingFormPath, { OrgName: organisationName })
      })
  }

  const handleOrganizationCode = () => {
    if (currentBrand == BrandName.SEATTLESTORM && isValidCompany)
      return (
        // html code in organizationData.secureLink
        /* <div style=' color: #3F7A88; font-size: 1rem; line-height: 1.5rem; font-weight: 500;'> 
          Please email Amanda Chinn if you don't know your organizational code; 
          <a href="mailto:achinn@stormbasketball.com" style=' text-decoration: underline; color: #3F7A88;'> achinn@stormbasketball.com </a>
        </div> */
        <div
          dangerouslySetInnerHTML={{
            __html:
              organizationData?.secureLink || 'Organization link unavailable',
          }}
          data-testid="organization-secure-link"
        />
      )
    else
      return (
        <a
          href={organizationData?.secureLink}
          className={`underline text-base font-medium hover:underline ${
            isValidCompany
              ? 'text-teal-blue hover:text-teal-blue'
              : 'text-[#4F5763] pointer-events-none hover:text-[#4F5763]'
          }`}
          target="_blank"
          rel="noopener noreferrer"
          aria-disabled={!isValidCompany}
        >
          Don&apos;t know your organization&apos;s code?
        </a>
      )
  }

  return true ? (
    <Row className="flex sm:m-10 sm:mt-[3vh] xl:mt-[3vh] xs:rounded-2xl xs:-mt-4 justify-between">
      <Col
        md={12}
        lg={12}
        className="flex flex-col sm:items-center md:items-start justify-start md:pl-8 2xl:pl-40 w-full max-w-screen-md mt-32 md:mt-4 pr-2 sm:pt-[3vh] xl:pt-[3vh]"
      >
        {/* Mobile heading (below image) - Welcome to Trusana */}
        <div className="flex flex-row justify-start xs:justify-center lg:items-baseline gap-2 md:gap-4 font-bold text-3xl lg:text-3xl xl:text-4xl text-teal-blue">
          <BackButton onClick={redirectToHomepage} />
          <div>Welcome to Trusana.</div>
        </div>
        <img
          src={girlMeditationImage}
          alt="Meditating girl"
          className="max-w-xs md:max-w-sm lg:max-w-md xl:max-w-xl p-8"
        />
      </Col>
      <Col
        xs={24}
        sm={24}
        md={12}
        lg={12}
        className="flex flex-col items-center lg:max-w-[55%] 2xl:max-w-[45%] drop-shadow-md md:mt-8"
      >
        <div className="formCard flex flex-col justify-center items-center pt-12 xs:w-full md:w-[90%] mt-4 md:mt-0">
          <Form
            form={orgNameForm}
            layout="vertical"
            onFinish={HandleGo}
            scrollToFirstError
            initialValues={initialValues}
            className="max-w-md w-full"
          >
            <div className="text-3xl pb-4 text-[#303F5C] font-semibold">
              <span className=" text-[#3F7A88] font-bold">Begin</span> your care
              here
            </div>
            <div className="inline-grid gap-2 mt-4 w-full">
              <span className="text-base text-shade">
                Enter the organization sponsoring your access
              </span>
              <Form.Item
                label={''}
                name="companyName"
                className={classNames('block w-full')}
                rules={formRules(
                  'Organization Name',
                  true,
                  undefined,
                  alphabetPattern,
                  FormInvalidMessage('Organization Name'),
                  {
                    value: 3,
                    message: FormInvalidMessage(''),
                  },
                  {
                    value: 50,
                    message: FormInvalidMessage(''),
                  }
                )}
                validateFirst={true}
              >
                <div className="flex flex-row items-center justify-center border border-solid border-fossil rounded-lg">
                  <input
                    name="companyName"
                    placeholder="Enter your organization name here"
                    className="w-full rounded-l-lg border-none outline-none pl-4 py-2 px-4 md:py-1 m-2"
                    onChange={handleSearchInputChange}
                    pattern=".{3,50}"
                    autoComplete="off"
                    title="Organization name should have 3 to 50 characters"
                    maxLength={50}
                    value={organisationName}
                  />
                  {isValidCompany ? (
                    <CheckCircleOutlined className="!text-teal-blue text-xl m-1 p-2" />
                  ) : (
                    <button
                      type="submit"
                      className={`text-snow cursor-pointer text-base py-2 px-4 md:py-1 text-center bg-teal-blue m-2 rounded-full border border-none xs:w-[4rem] md:w-[6rem] md:px-4`}
                      onClick={HandleGo}
                    >
                      {isCompanyBeingChecked ? <LoadingOutlined spin /> : 'Go'}
                    </button>
                  )}
                </div>
              </Form.Item>
            </div>
          </Form>
          <Form
            form={orgCodeForm}
            layout="vertical"
            onFinish={onCompanyCodeFinish}
            scrollToFirstError
            initialValues={initialValues}
            className="max-w-md w-full mb-4"
          >
            <div className="inline-grid gap-2 mt-4 w-full">
              <span className="text-shade font-normal text-base ">
                Please enter your organization code below
              </span>
              {/** for a colour coming on autofull -
               *  https://stackoverflow.com/questions/2781549/removing-input-background-colour-for-chrome-autocomplete */}
              <Form.Item
                label={''}
                name="companyCode"
                className={classNames('block w-full')}
                rules={formRules(
                  'Organization Code',
                  true,
                  validateComapanyCode,
                  alphabetPattern,
                  FormInvalidMessage('Organization Code'),
                  {
                    value: 3,
                    message: FormInvalidMessage(''),
                  },
                  {
                    value: 50,
                    message: FormInvalidMessage(''),
                  }
                )}
                validateTrigger={['onSubmit']}
                validateFirst={true}
              >
                <div
                  className={`border border-solid border-fossil rounded-lg py-3 ${
                    isValidCompany
                      ? null
                      : 'bg-gray-200 border-none cursor-not-allowed'
                  }`}
                >
                  <input
                    disabled={!isValidCompany}
                    name={'companyCode'}
                    placeholder="Organization Code"
                    className="focus:bg-white rounded-l-lg border-none outline-none pl-4 py-2 px-4 md:py-1 ml-2 disabled:cursor-not-allowed"
                    onChange={onCompanyCodeChange}
                    pattern=".{3,50}"
                    title="Organization code should have 3 to 50 characters"
                    maxLength={50}
                    autoComplete="off"
                    value={!isValidCompany ? '' : companyCode}
                  />
                </div>
              </Form.Item>
              <div className="mt-2">{handleOrganizationCode()}</div>
              <div className="flex flex-row justify-end text-base">
                <button
                  className="text-white cursor-pointer disabled:cursor-not-allowed border border-shade border-solid px-12 py-2 rounded-full bg-shade px-4 min-w-[6rem] disabled:bg-light-gray disabled:border-none"
                  type="submit"
                  disabled={!isValidCompany}
                >
                  {isCodeBeingChecked ? <LoadingOutlined /> : 'Next'}
                </button>
              </div>
            </div>
          </Form>
        </div>
      </Col>
    </Row>
  ) : (
    <div className="flex items-center justify-center w-full">
      <Spin size="large" className="mt-[30vh]" />
    </div>
  )
}

export default CompanyCode
