import { Typography } from 'antd'
import { AppointmentPageContext } from '../../appointment-page-middleware'
import React, { useContext, useEffect, useState } from 'react'
import { MetadataContext } from '../../../../../common'
import SpecializationCheckboxItem from './specialisation/specialisation-checkbox'

const PopulationSelection: React.FC = () => {
  const {
    selectedPopulationItems,
    updateSelectedPopulationItems,
    isFilterDrawerOpen,
  } = useContext(AppointmentPageContext)

  const [selectedItems, setSelectedItems] = useState<string[]>(
    selectedPopulationItems
  )
  const updateSelectedItems = (item: string, shouldAdd: boolean) => {
    setSelectedItems((prevSelectedItems) => {
      if (shouldAdd) {
        return [...prevSelectedItems, item]
      } else {
        return prevSelectedItems.filter((existingItem) => existingItem !== item)
      }
    })
  }

  const { Text } = Typography

  useEffect(() => {
    setSelectedItems(selectedPopulationItems)
  }, [selectedPopulationItems])

  useEffect(() => {
    if (!isFilterDrawerOpen) updateSelectedPopulationItems(selectedItems)
    // eslint-disable-next-line
  }, [isFilterDrawerOpen])

  const { populations } = useContext(MetadataContext)
  const populationItems = populations[0].values.map(
    (population) => population.name
  )

  return (
    <div className="flex flex-col items-start justify-start bg-snow p-2 rounded">
      <div className="flex flex-row ">
        <Text className="font-normal text-sm text-teal-blue">
          Note: Max selection is three
        </Text>
      </div>

      <div className=" mt-2">
        {populationItems.map((population, index) => {
          return (
            <div
              key={index}
              className={`px-2 pt-1 pb-0.5 mb-2 w-full cursor-pointer gap-4 duration-300 font-normal text-xs `}
            >
              <SpecializationCheckboxItem
                specialisation={population}
                key={index}
                checked={
                  selectedItems.filter(
                    (selectedItem) => selectedItem === population
                  ).length === 1
                }
                updateSelectedItems={updateSelectedItems}
                selectedItems={selectedItems}
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default PopulationSelection
