import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const UseScrollTop = (props: any) => {
  const { children } = props

  const location = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location])

  return <>{children}</>
}

export default UseScrollTop
