import React from 'react'
import { PhpInfoIcon } from '../../assets'

interface PhpInformationProps {
  text: string
}

const PhpInformation: React.FC<PhpInformationProps> = ({ text }) => {
  return (
    <div className="bg-white border border-solid border-[#3f7a88] flex flex-row h-[4rem]  rounded-[0.4rem] w-[95%] mb-4">
      <div className="bg-[#3f7a88] flex flex-col  justify-center px-2 rounded-tl-[0.3rem] rounded-bl-[0.3rem]">
        <img src={PhpInfoIcon} />
      </div>
      <div className="flex flex-col pl-4 justify-center font-normal text-sm">
        {text}
      </div>
    </div>
  )
}

export default PhpInformation
