import React, { useContext } from 'react'
import { MetadataContext } from '../../../common'
import { FormInvalidMessage } from '../../../common/form/form.utils'
import NewInputSelect, {
  fromSelectOptions,
} from '../../../common/new-form/new-input-select/new-input-select'
import NewInputText from '../../../common/new-form/new-input-text/new-input-text'
import { alphabetPattern } from '../../EAP/EAP-constants'
import PhpFormSectionInfo from '../php-form-section-info/php-form-section-info'

const PhpAddress = () => {
  const isValidZip = new RegExp(/(^\d{5}$)/)
  const { states } = useContext(MetadataContext)
  const validateAddress = (_cc: any, value: any) => {
    const invalidCharacterSet = `[\\""/?<>%()=;@\[\]\'\`:*]`
    const addressInvalidRegex = /[\\""/?<>%()=;@\[\]\'`:*]/
    if (addressInvalidRegex.test(value)) {
      return Promise.reject(
        `Please enter a valid address, without ${invalidCharacterSet}`
      )
    }
    if (value.length < 2) {
      return Promise.reject('Minimum address length of 2 characters')
    }
    return Promise.resolve()
  }
  return (
    <>
      <PhpFormSectionInfo
        sectionNumber={2}
        title={'Address'}
        description={'Provide address of the person who will be receiving care'}
      />
      <div className="mt-4">
        <div className="flex flex-col md:flex-row md:gap-8">
          <NewInputText
            label={'Address (include Apt, Suite, Street, etc.)'}
            name={'addressLine1'}
            isRequired={true}
            aria-required={true}
            placeholder={'Enter Address'}
            maxLength={100}
            validator={validateAddress}
            validateTrigger={['onBlur', 'onChange', 'onSubmit']}
            min={{
              value: 2,
              message: FormInvalidMessage(''),
            }}
            max={{
              value: 100,
              message: FormInvalidMessage(''),
            }}
          />

          <NewInputText
            label={'Locality/City'}
            name={'city'}
            isRequired={true}
            aria-required={true}
            placeholder={'Enter Locality/City'}
            pattern={alphabetPattern}
            validator={validateAddress}
            validateTrigger={['onBlur', 'onChange', 'onSubmit']}
            patternMessage={FormInvalidMessage('Locality/City')}
            min={{
              value: 2,
              message: FormInvalidMessage(''),
            }}
            maxLength={35}
            // only chars
          />
        </div>
        <div className="flex flex-col md:flex-row md:gap-8">
          <NewInputSelect
            label={'State'}
            name={'state'}
            placeholder={'Select State'}
            isRequired={true}
            aria-required={true}
            options={fromSelectOptions(states, 'name', 'code')}
            isDisabled={true}
          />

          <NewInputText
            pattern={isValidZip}
            label={'ZIP Code'}
            placeholder={'Enter ZIP Code'}
            name={'zipCode'}
            isRequired={true}
            aria-required={true}
            maxLength={5}
            patternMessage={FormInvalidMessage('ZIP Code')}
          />
        </div>
      </div>
    </>
  )
}

export default PhpAddress
