/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import NewStepper from '../../../common/new-stepper/new-stepper'
import BrandingSection from '../php-branding-section/branding-section'
import BrandingSectionMobile from '../php-branding-section/branding-section-mobile'
import PhpHeader from '../php-header/php-header'
import PhpInitialDetails from '../php-initial-details/php-initial-details'
import PhpStepName from '../php-step-name/php-step-name'

import './php-form-flow.scss'
import { PHPRegistrationContext } from '../php-registration/php-registration-middleware'
import {
  APICallType,
  MetadataContext,
  flatQueryParams,
  useAPICall,
  useStepperTracker,
} from '../../../common'
import PhpPrimarySubscriberDetails from '../php-primary-subscriber-details/php-primary-subscriber-details'
import {
  AccountSetupIcon,
  initialDetails,
  PasswordSetupIcon,
  PrimarySubscriberDetailsIcon,
} from '../../../assets'
import { API_URLs } from '../../../configuration/api-urls'
import PhpAccountSetup from '../php-account-setup/php-account-setup'
import PhpPasswordSetup from '../php-password-setup/php-password-setup'
import AppointmentPage from '../../EAP/appointment-page/appointment-page'
import moment from 'moment'
import {
  ExistenceTypeResponseInterface,
  InitialDetailsRequestInterface,
} from '../php-interfaces'
import { isAboveAgeOfConsentForState } from '../../../common/utils/date-utils'
import { PHPFlows, getPatientTypeId } from '../../EAP/EAP-constants'
import { Modal } from 'antd'
import ErrorModalPHP from '../../../common/php-error-message-modal/php-error-modal'
import { AppointmentPageContext } from '../../EAP/appointment-page/appointment-page-middleware'
import {
  PHPPatientCreationRequestInterface,
  formatGroupID,
  formatMemberID,
  getPHPFinalPayload,
  insuranceCompanyNameList,
} from '../php-constants'
import PhpAppointmentConfirmation from '../php-appointment-confirmation/php-appointment-confirmation'
import {
  mixpanelRegisterUserFlowType,
  mixpanelTrackPHPAccountSetup,
  mixpanelTrackPHPInitialDetailsComplete,
  mixpanelTrackPHPPasswordSetup,
  mixpanelTrackPHPPrimaruSubscriberDetails,
} from '../../../common/utils/mixpanel'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import useHandleRefresh from '../../../common/hooks/use-handle-refresh'
import { UserFlowType } from '../..'
import { globalConfig } from '../../../configuration/config'
import AppointmentTimer from '../../../common/appointment-timer/appointment-timer'
import useWindowCloseWarning from '../../../common/hooks/use-window-close-warning'

const PhpFormFlow = () => {
  const {
    currentStep,
    primarySubscriberDetails,
    visitorId,
    isInsuranceImageUploaded,
    insuranceDocumentID,
    existenceType,
    updateCurrentStep,
    updateVisitorId,
    updatePrimarySubscriberDetails,
    updateExistenceType,
    isInsuranceDetailsPresent,
  } = useContext(PHPRegistrationContext)

  const { organizationData, stateAges, patientTypes } =
    useContext(MetadataContext)

  const {
    appointmentDetails,
    bookingDate,
    clearAppointmentContextAfterPatientCreation,
    isAppointmentConfirmed,
    updateIsAppointmentConfirmed,
    updateBookingDate,
    updateTherapistID,
  } = useContext(AppointmentPageContext)

  const { apiCall } = useAPICall<ExistenceTypeResponseInterface>()
  const history = useHistory()
  const location = useLocation()

  const { step } = useParams<{ step: string }>()

  const [isFirstVisitToStep1, setIsFirstVisitToStep1] = useState(true)
  const [shouldDisplayValidation, setShouldDisplayValidation] = useState(false)
  const [isOpen, setIsOpen] = useState<boolean>(true)
  const toggleIsOpen = () => {
    setIsOpen(!isOpen)
  }

  const [currentStepPercentage] = useState<number>(0)
  const [patientType, setPatientType] = useState<string | null>(null)
  const [isAppointmentPage, setIsAppointmentPage] = useState<boolean>(false)
  const [shouldDisplayErrorModal, setShouldDisplayErrorModal] =
    useState<boolean>(false)
  const [errorModalText, setErrorModalText] = useState<string>('')
  const [errorModalButtonText, setErrorModalButtonText] = useState<string>('')
  const [minutesLeft, setMinutesLeft] = useState<number>(20)
  const [shouldDisplayTimeoutErrorModal, setShouldDisplayTimeoutErrorModal] =
    useState<boolean>(false)
  const timeoutRef = useRef<NodeJS.Timer | null>(null)
  const isConfirmationPage = step == 'final-confirmation'

  const updatePatientType = (patientType: string) => {
    setPatientType(patientType)
  }

  const stepsList = [
    {
      title: 'Initial Details',
      path: 'initial-details',
      step: 0,
      stepImage: initialDetails,
      onPrevious: () => {
        history.replace('/welcome/mentalhealth')
      },
    },
    {
      title: 'Book an Appointment',
      path: 'appointment',
      step: 1,
      stepImage: initialDetails,
      onPrevious: () => {
        history.push('initial-details')
      },
    },
    {
      title: 'Account Setup',
      path: 'account-setup',
      step: 2,
      stepImage: AccountSetupIcon,
      onPrevious: () => {
        history.push('appointment')
      },
    },
    {
      title: 'Primary subscriber details',
      path: 'primary-subscriber-details',
      step: 3,
      stepImage: PrimarySubscriberDetailsIcon,
      onPrevious: () => {
        history.push('account-setup')
      },
    },
    {
      title: 'Password Setup',
      path: 'password-setup',
      step: 4,
      stepImage: PasswordSetupIcon,
      onPrevious: () => {
        if (
          patientType === PHPFlows.PHP_MinorDependent ||
          patientType === PHPFlows.PHP_MajorDependent
        ) {
          history.push('primary-subscriber-details')
        } else if (patientType === PHPFlows.PHP_Primary) {
          history.push('account-setup')
        } else {
          history.push('initial-details')
        }
      },
    },
  ]

  useStepperTracker(
    stepsList, //stepsList
    updateCurrentStep // updateContext
  )

  useEffect(() => {
    if (location.pathname.includes('appointment')) {
      setIsAppointmentPage(true)
    } else {
      setIsAppointmentPage(false)
    }
  }, [location])

  const [userState, setUserState] = useState<string | null>(null)
  const updateUserState = (userState: string) => {
    setUserState(userState)
  }
  const [userDoB, setUserDoB] = useState<moment.Moment | null>(null)
  const updateUserDoB = (userDoB: moment.Moment) => {
    setUserDoB(userDoB)
  }
  const [isPrimarySubscriber, setIsPrimarySubscriber] = useState<boolean>(false)
  const updateIsPrimarySubscriber = (isPrimarySubscriber: boolean) => {
    setIsPrimarySubscriber(isPrimarySubscriber)
  }

  useEffect(() => {
    if (isPrimarySubscriber) {
      updatePatientType(PHPFlows.PHP_Primary)
    } else if (
      isAboveAgeOfConsentForState(
        userDoB ?? moment(),
        userState ?? '',
        stateAges
      ) &&
      !isPrimarySubscriber
    ) {
      updatePatientType(PHPFlows.PHP_MajorDependent)
    } else {
      updatePatientType(PHPFlows.PHP_MinorDependent)
    }
  }, [userState, userDoB, isPrimarySubscriber])

  useEffect(() => {
    updateVisitorId(null)
    clearAppointmentContextAfterPatientCreation()
  }, [patientType])

  useEffect(() => {
    if (bookingDate != null) {
      if (isAppointmentConfirmed) {
        setMinutesLeft(0)
        timeoutRef.current && clearInterval(timeoutRef.current)
      }
      if (minutesLeft > 0) {
        timeoutRef.current = setInterval(() => {
          setMinutesLeft(minutesLeft - 1)
        }, 60000)
        return () => {
          timeoutRef.current && clearInterval(timeoutRef.current)
        }
      }
      if (minutesLeft === 0 && isAppointmentConfirmed === false) {
        setErrorModalText(
          `Sorry, we are unable to book your appointment for ${moment
            .parseZone(bookingDate)
            .format(
              'LLLL'
            )} as the required data couldn't be collected in time. Please book again!`
        )
        setErrorModalButtonText('Book again')
        setShouldDisplayTimeoutErrorModal(!shouldDisplayErrorModal)
        updateBookingDate(null)
      }
    } else {
      setMinutesLeft(20)
    }

    return () => {
      if (timeoutRef.current) {
        clearInterval(timeoutRef.current)
      }
    }
  }, [minutesLeft, bookingDate])

  const onInitialDetailsSubmit = (values: any) => {
    updatePrimarySubscriberDetails(values)
    if (!isInsuranceImageUploaded && !isInsuranceDetailsPresent) {
      setShouldDisplayValidation(true)
      return
    }
    if (isInsuranceImageUploaded || isInsuranceDetailsPresent) {
      setShouldDisplayValidation(false)
    }

    // condition to block primary user with age === below age of consent of his selected state
    if (
      values.primarySubscriber === 1 &&
      !isAboveAgeOfConsentForState(values.dateOfBirth, values.state, stateAges)
    ) {
      setErrorModalText(
        'Your state health laws limit us from providing you direct access, since you are below the approved age of consent.'
      )
      setShouldDisplayErrorModal(!shouldDisplayErrorModal)
      return
    }
    let requestPatientType: any
    if (values.primarySubscriber === 1) {
      requestPatientType = getPatientTypeId(PHPFlows.PHP_Primary, patientTypes)
      mixpanelRegisterUserFlowType({ userFlowType: PHPFlows.PHP_Primary })
    } else if (
      isAboveAgeOfConsentForState(
        values.dateOfBirth,
        values.state,
        stateAges
      ) &&
      values.primarySubscriber !== 1
    ) {
      requestPatientType = getPatientTypeId(
        PHPFlows.PHP_MajorDependent,
        patientTypes
      )
      mixpanelRegisterUserFlowType({
        userFlowType: PHPFlows.PHP_MajorDependent,
      })
    } else {
      requestPatientType = getPatientTypeId(
        PHPFlows.PHP_MinorDependent,
        patientTypes
      )
      mixpanelRegisterUserFlowType({
        userFlowType: PHPFlows.PHP_MinorDependent,
      })
    }

    const stepOneExistenceDetails = {
      Email: values?.email,
      FirstName: values?.firstName,
      LastName: values?.lastName,
      PatientType: requestPatientType,
    }

    apiCall(
      `${API_URLs.v2.GET_PatientExistence}${flatQueryParams(
        stepOneExistenceDetails
      )}`,
      APICallType.GET
    )
      .then((data: ExistenceTypeResponseInterface) => {
        if (data.existenceType?.toLowerCase() === 'hard') {
          setErrorModalText(
            'An account with the same details already exists. Please login to your account.'
          )
          setErrorModalButtonText('Login')
          setShouldDisplayErrorModal(!shouldDisplayErrorModal)
        } else {
          updateExistenceType(data.existenceType)
          const request: InitialDetailsRequestInterface = {
            email: values.email,
            dateOfBirth: moment(values.dateOfBirth).format('MM/DD/YYYY'),
            state: values.state,
            organizationId: organizationData?.id ?? '',
            patientType: requestPatientType,
          }

          request.insuranceId = insuranceDocumentID ?? null
          request.insuranceDetails = {
            insurerName: insuranceCompanyNameList[0].companyId ?? null,
            memberId: formatMemberID(values?.memberID),
            groupId: formatGroupID(values?.groupID),
          }

          apiCall(
            visitorId != null
              ? API_URLs.v1.POST_Visitors + flatQueryParams({ Id: visitorId })
              : API_URLs.v1.POST_Visitors,
            visitorId != null ? APICallType.PUT : APICallType.POST,
            JSON.stringify(request)
          )
            .then((response: any) => {
              setIsFirstVisitToStep1(false)
              updateVisitorId(response.id)
              mixpanelTrackPHPInitialDetailsComplete()
              history.push('appointment')
            })
            .catch((error) => {
              console.log(error)
              setErrorModalText('An error occured')
              setErrorModalButtonText('Try again')
              setShouldDisplayErrorModal(!shouldDisplayErrorModal)
            })
        }
      })
      .catch((error) => {
        parseErrorMessageAndShowModal(error)
      })
  }

  const parseErrorMessageAndShowModal = (error: any) => {
    const errorMessage = error.message
    let parsedError
    try {
      // Try parsing the error message as JSON
      parsedError = JSON.parse(errorMessage)
    } catch (parseError) {
      console.error('Failed to parse error message as JSON', parseError)
    }
    if (parsedError && parsedError.errors) {
      console.log('Errors:', parsedError.errors)
      setErrorModalText(parsedError.errors)
    } else {
      console.log('Error:', parsedError.errors)
    }

    setErrorModalButtonText('Try again')
    setShouldDisplayErrorModal(!shouldDisplayErrorModal)
  }

  const accountSetupSubmit = (values: any) => {
    updatePrimarySubscriberDetails(values)

    if (patientType === PHPFlows.PHP_Primary) {
      mixpanelTrackPHPAccountSetup()
      history.push('password-setup')
    }
    if (
      patientType === PHPFlows.PHP_MajorDependent ||
      patientType === PHPFlows.PHP_MinorDependent
    ) {
      mixpanelTrackPHPAccountSetup()
      history.push('primary-subscriber-details')
    }
  }
  const primarySubscriberDetailsSubmit = (values: any) => {
    // condition to block primary user with age === below age of consent of his selected state
    if (
      !isAboveAgeOfConsentForState(
        values.psDateOfBirth,
        primarySubscriberDetails.state,
        stateAges
      )
    ) {
      setErrorModalText("Employee's age is below age of consent")
      setShouldDisplayErrorModal(!shouldDisplayErrorModal)
      return
    }
    updatePrimarySubscriberDetails(values)
    if (patientType === PHPFlows.PHP_MinorDependent) {
      /**
       * Call patient existence API when the patient type is a minor dependant
       * to verify if the primary subscriber has an account
       */

      const existenceRequestBody = {
        email: values?.psEmail,
        firstName: values?.psFirstName,
        lastName: values?.psLastName,
        patientType: getPatientTypeId(
          PHPFlows.PHP_MinorDependent,
          patientTypes
        ),
      }
      apiCall(
        API_URLs.v2.GET_PatientExistence +
          flatQueryParams(existenceRequestBody),
        APICallType.GET
      )
        .then((data: ExistenceTypeResponseInterface) => {
          if (data?.existenceType?.toLocaleLowerCase() === 'hard') {
            setErrorModalText(
              'An account with the same details already exists. Please login to your account.'
            )
            setErrorModalButtonText('Login')
            setShouldDisplayErrorModal(!shouldDisplayErrorModal)
          } else {
            updateExistenceType(data.existenceType)
            mixpanelTrackPHPPrimaruSubscriberDetails()
            history.push('password-setup')
          }
        })
        .catch((error) => {
          parseErrorMessageAndShowModal(error)
        })
    } else {
      mixpanelTrackPHPPrimaruSubscriberDetails()
      history.push('password-setup')
    }
  }

  const passwordSetupSubmit = (values: any, token: any, refreshToken: any) => {
    const PHP_Payload: PHPPatientCreationRequestInterface = getPHPFinalPayload(
      primarySubscriberDetails,
      appointmentDetails,
      values,
      visitorId,
      getPatientTypeId(patientType ?? '', patientTypes),
      existenceType,
      insuranceDocumentID,
      token
    )

    apiCall(
      API_URLs.v2.POST_Patients,
      APICallType.POST,
      JSON.stringify(PHP_Payload)
    )
      .then((response) => response)
      .then((data) => {
        updateIsAppointmentConfirmed(true)
        mixpanelTrackPHPPasswordSetup()
        history.push({
          pathname: 'final-confirmation',
          state: data,
        })
      })
      .catch((error) => {
        refreshToken()
        if (
          JSON.parse(error.message)[0]?.description.includes(
            'Password is incorrect'
          )
        ) {
          setErrorModalText('Entered Password is incorrect')
          setErrorModalButtonText('Retry')
          setShouldDisplayErrorModal(!shouldDisplayErrorModal)
        } else if (
          JSON.parse(error.message)[0]?.description.includes(
            'Password is too weak'
          )
        ) {
          setErrorModalText(JSON.parse(error.message)[0].description)
          setErrorModalButtonText('Retry')
          setShouldDisplayErrorModal(!shouldDisplayErrorModal)
        } else {
          setErrorModalText('An error occured, please try again')
          setErrorModalButtonText('Retry')
          setShouldDisplayErrorModal(!shouldDisplayErrorModal)
        }
      })
  }

  useHandleRefresh({
    user: PHPFlows.PHP_Primary,
    userFlowType: UserFlowType.PHP,
    registrationPath: stepsList[0].path,
    visitorId: visitorId,
  })

  const handleOnCancel = () => {
    if (shouldDisplayTimeoutErrorModal) {
      updateBookingDate(null)
      setShouldDisplayTimeoutErrorModal(!shouldDisplayTimeoutErrorModal)
      updateTherapistID(null)
      history.push('appointment')
    } else {
      setShouldDisplayErrorModal(!shouldDisplayErrorModal)
    }
  }
  useWindowCloseWarning()

  return (
    <GoogleReCaptchaProvider reCaptchaKey={globalConfig.get().recaptchaKey}>
      <div className="php-flow">
        <div className="flex flex-row">
          {/* left section */}
          {!isAppointmentPage && !isConfirmationPage ? (
            <BrandingSection isOpen={isOpen} toggleIsOpen={toggleIsOpen} />
          ) : null}

          {/* right section */}
          <div
            className={`flex flex-col basis-full form-section ${
              isAppointmentPage || isConfirmationPage
                ? 'lg:ml-8'
                : isOpen
                ? 'md:ml-[27rem] md:basis-[80%]'
                : 'md:ml-20 md:px-32'
            }`}
          >
            <PhpHeader
              shouldDisplayLogo={isAppointmentPage || isConfirmationPage}
              isOpen={isOpen}
            />

            <div
              className={`flex flex-col justify-center mx-4 md:ml-6 my-4 mt-16 md:mt-20 ${
                isAppointmentPage ? 'items-center' : 'items-start md:mr-16'
              } ${isConfirmationPage ? 'hidden' : ''}`}
            >
              <PhpStepName
                stepNumber={currentStep + 1}
                stepName={stepsList[currentStep].title}
                stepImage={stepsList[currentStep].stepImage}
                isCentered={isAppointmentPage}
                isAppointmentPage={
                  stepsList[currentStep].step === 1 ? true : false
                }
              />
              <div className={`${isAppointmentPage ? 'w-4/5' : 'w-full'} `}>
                <NewStepper
                  totalSteps={stepsList.length}
                  currentStep={currentStep}
                  currentStepPercentage={currentStepPercentage}
                  isCentered={isAppointmentPage}
                />
              </div>
              {currentStep > 1 && <AppointmentTimer isPHP={true} />}
            </div>
            {/* All other steps will come under this div */}
            <div className="lg:mr-8">
              {step.includes('initial-details') ? (
                <>
                  <PhpInitialDetails
                    onFinish={onInitialDetailsSubmit}
                    onPrevious={stepsList[0].onPrevious}
                    updatePatientType={updatePatientType}
                    patientType={patientType ?? ''}
                    updateUserState={updateUserState}
                    updateUserDoB={updateUserDoB}
                    updateIsPrimarySubscriber={updateIsPrimarySubscriber}
                    isFirstVisitToStep1={isFirstVisitToStep1}
                    shouldDisplayValidation={shouldDisplayValidation}
                    setShouldDisplayValidation={setShouldDisplayValidation}
                  />
                </>
              ) : isAppointmentPage ? (
                <>
                  <AppointmentPage
                    clickPreviousPath={'initial-details'}
                    clickNextPath={'account-setup'}
                    visitorId={visitorId ?? ''}
                    state={primarySubscriberDetails?.state}
                    current={currentStep}
                    patientType={patientType ?? ''}
                    isPhpFlow={true}
                  />
                </>
              ) : step.includes('account-setup') ? (
                <>
                  <PhpAccountSetup
                    onFinish={accountSetupSubmit}
                    onPrevious={stepsList[2].onPrevious}
                  />
                </>
              ) : isConfirmationPage ? (
                <div className={`${isConfirmationPage ? 'mt-8 md:mt-12' : ''}`}>
                  <PhpAppointmentConfirmation />
                </div>
              ) : step.includes('primary-subscriber-details') &&
                primarySubscriberDetails.primarySubscriber === 2 ? (
                <>
                  <PhpPrimarySubscriberDetails
                    onFinish={primarySubscriberDetailsSubmit}
                    onPrevious={stepsList[3].onPrevious}
                  />
                </>
              ) : step.includes('password-setup') ? (
                <>
                  <PhpPasswordSetup
                    onFinish={passwordSetupSubmit}
                    onPrevious={stepsList[4].onPrevious}
                    email={
                      patientType === PHPFlows.PHP_MinorDependent
                        ? primarySubscriberDetails.psEmail
                        : primarySubscriberDetails.email
                    }
                    confirmStep={
                      patientType === PHPFlows.PHP_MinorDependent ? 4 : 1
                    }
                  />
                </>
              ) : (
                <></>
              )}
            </div>
            {shouldDisplayErrorModal || shouldDisplayTimeoutErrorModal ? (
              <Modal
                footer={null}
                destroyOnClose={true}
                visible={true}
                onCancel={handleOnCancel}
                className={`${
                  shouldDisplayTimeoutErrorModal ? 'php-timeout-modal' : null
                } max-w-sm md:max-w-2xl !w-fit h-10 error-modal mt-[10%] px-8 rounded-lg`}
              >
                <ErrorModalPHP
                  handleClick={
                    !shouldDisplayTimeoutErrorModal
                      ? () =>
                          setShouldDisplayErrorModal(!shouldDisplayErrorModal)
                      : () => {
                          updateBookingDate(null)
                          setShouldDisplayTimeoutErrorModal(
                            !shouldDisplayTimeoutErrorModal
                          )
                          updateTherapistID(null)
                          history.push('appointment')
                        }
                  }
                  mainText={errorModalText}
                  buttonText={errorModalButtonText}
                  isPhpTimeoutPage={shouldDisplayTimeoutErrorModal}
                />
              </Modal>
            ) : null}
            {!isAppointmentPage && !isConfirmationPage ? (
              <BrandingSectionMobile />
            ) : null}
          </div>
        </div>
      </div>
    </GoogleReCaptchaProvider>
  )
}

export default PhpFormFlow
