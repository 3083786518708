import { useRef } from 'react'
import ContactUs from '../../common/contact-us/contact-us'
import useActiveSection from '../../common/hooks/useActiveSection'
import ReturnToTopButton from '../../common/return-to-top-button/return-to-top-button'
import { scrollToTop } from '../EAP/EAP-constants'
import HeaderSection from './core/header-section'
import { FAQSection, HeroSection, HowItWorks, WhoWeServe } from './index'
import './landing-page.scss'

const LandingPage: React.FC = () => {
  const sectionRefs = [
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
  ]
  const activeSection = useActiveSection(sectionRefs)

  return (
    <div className="flex flex-col w-full mx-auto justify-center text-shade landing-page-container">
      <HeaderSection activeSection={activeSection} />
      <HeroSection />
      <HowItWorks refProp={sectionRefs[0]} />
      <WhoWeServe refProp={sectionRefs[1]} />
      <FAQSection refProp={sectionRefs[2]} />
      <ContactUs />
      <ReturnToTopButton onClick={scrollToTop} activeSection={activeSection} />
    </div>
  )
}

export default LandingPage
