import { lazy } from 'react'
import HeaderSection from './core/header-section'
import HeroSection from './core/hero-section'

const HowItWorks = lazy(
  () => import('./sections/how-it-works/how-it-works-section')
)
const WhoWeServe = lazy(
  () => import('./sections/who-we-serve/who-we-serve-section')
)
const FAQSection = lazy(() => import('./sections/FAQs/faq-section'))

const FooterSection = lazy(() => {
  return new Promise((resolve: any) => {
    setTimeout(() => resolve(import('./core/footer-section')), 300)
  })
})

export {
  HeaderSection,
  HeroSection,
  HowItWorks,
  WhoWeServe,
  FAQSection,
  FooterSection,
}
