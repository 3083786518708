/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from 'react'
import { API_URLs } from '../../configuration/api-urls'
import useGet from '../api-utils/use-get'
import {
  MetadataContext,
  OrganizationInterface,
} from '../metadata/metadata-middleware'

/*
We want to load org meta data at multiple places on Demand, 
either do it at the app level where loading context is not available
or do it at multiple places where ever its required 
This hook will facilitate the loading of meta data
 */

const useLoadOrganizationMetaData = (): void => {
  const { setOrganizationMetaDataList, organizationMetaDataList } =
    useContext(MetadataContext)

  const orgMetaDataList = useGet<OrganizationInterface[]>({
    url: API_URLs.v1.GET_Organizations,
    isCancelled: organizationMetaDataList.length > 0,
  })

  useEffect(() => {
    if (
      orgMetaDataList &&
      orgMetaDataList.length > 0 &&
      organizationMetaDataList.length < 2
    ) {
      setOrganizationMetaDataList(orgMetaDataList)
    }
  }, [orgMetaDataList, orgMetaDataList.length])
}

export default useLoadOrganizationMetaData
