import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

interface StepType {
  title: string
  path: string
  step: number
}

const useStepperTracker = (
  stepsList: StepType[] | null,
  updateActiveStep: (activeStep: number) => void
) => {
  const location = useLocation()
  useEffect(() => {
    if (stepsList) {
      stepsList.map((step: StepType) => {
        if (location.pathname.includes(step.path)) {
          updateActiveStep(step.step)
        }
      })
    }
  }, [location, updateActiveStep, stepsList])
}

export default useStepperTracker
