import React from 'react'
import { useHistory } from 'react-router-dom'
import { BackButton } from '../../../assets'

interface StepNameProps {
  stepNumber: number
  stepName: string
  stepImage: string | undefined
  isCentered: boolean
  isAppointmentPage?: boolean
}

const PhpStepName: React.FC<StepNameProps> = ({
  stepNumber,
  stepName,
  stepImage,
  isCentered,
  isAppointmentPage,
}) => {
  const history = useHistory()
  return (
    <div
      className={`flex flex-row ${
        isCentered ? 'items-start justify-start w-[74%] md:w-[80%]' : ''
      }`}
    >
      {isAppointmentPage ? (
        <BackButton
          onClick={() => {
            history.push('initial-details')
          }}
          className="mt-[0.2rem] ml-4 md:mt-0 md:ml-0"
        />
      ) : null}
      <div className="flex flex-col mr-4 md:ml-8">
        <img src={stepImage}></img>
      </div>
      <div className="flex flex-col">
        <div>Step {stepNumber}/5</div>
        <span> {stepName}</span>
      </div>
    </div>
  )
}

export default PhpStepName
