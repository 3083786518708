import React, { useState } from 'react'

interface LoaderContext {
  loading: boolean
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
}

export const LoaderContext = React.createContext({} as LoaderContext)

interface LoaderMiddlewareProps {
  children: JSX.Element
}

export const LoaderMiddleware: React.FC<LoaderMiddlewareProps> = ({
  children,
}) => {
  const [loading, setLoading] = useState<boolean>(false)

  return (
    <LoaderContext.Provider
      value={{
        loading,
        setLoading,
      }}
    >
      {children}
    </LoaderContext.Provider>
  )
}
