import React, { FC } from 'react'

interface ImageUploadValidationProps {
  text: string
  isInsuranceUpload?: boolean
}

const ImageUploadValidation: FC<ImageUploadValidationProps> = ({
  text,
  isInsuranceUpload,
}) => {
  return (
    <div
      className={
        isInsuranceUpload
          ? 'text-center text-[#CD4D3C] font-semibold mt-4'
          : 'text-xs text-[#CD4D3C] font-semibold'
      }
      data-testid="image-upload-validation"
    >
      {text}
    </div>
  )
}

export default ImageUploadValidation
