import { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import {
  AppointmentPageContext,
  AppointmentType,
} from '../appointment-page-middleware'
import moment from 'moment'
import { Button } from 'antd'
import { EditFilled } from '@ant-design/icons'

interface BookedAppointmentCardProps {
  therapistName: string | null
  bookingDate: string
  clickNextPath: string
  clickPreviousPath?: string
  updateBookingDate: (bookingDate: string | null) => void
  abbr: string
}

const BookedAppointmentCard: React.FC<BookedAppointmentCardProps> = ({
  therapistName,
  bookingDate,
  clickNextPath,
  updateBookingDate,
  clickPreviousPath,
  abbr,
}) => {
  const history = useHistory()

  const { updateAppointmentType, resetAppointment } = useContext(
    AppointmentPageContext
  )
  return (
    <div className="flex justify-center mt-32 md:mt-32">
      <div className="!bg-white shadow-lg rounded-lg w-4/5 py-12">
        <div className="flex flex-col gap-4 items-center mb-4">
          <p className="inline text-base mx-6 sm:mx-8 md:mx-12">
            Your appointment booking with {therapistName} on{' '}
            {moment.parseZone(bookingDate).format('LLLL')} {abbr} is under
            progress.
          </p>
          <span className="buttons">
            <Button
              type="default"
              shape="round"
              onClick={() => {
                updateBookingDate(null)
                updateAppointmentType(AppointmentType.UNSELECTED)
              }}
              icon={<EditFilled onClick={resetAppointment} />}
              className="text-sm h-10"
            >
              <span className="">Change Appointment</span>
            </Button>
          </span>
          <hr
            style={{
              width: '100%',
              border: '1px solid rgba(0, 0, 0, 0.1)',
            }}
          ></hr>
        </div>

        <div className="text-center pt-4 buttons-style">
          <Button
            type="primary"
            shape="round"
            onClick={() => {
              {
                clickPreviousPath && history.push(clickPreviousPath)
              }
            }}
            className="mr-4"
          >
            Previous
          </Button>
          <Button
            type="default"
            shape="round"
            onClick={() => {
              history.push(clickNextPath)
            }}
          >
            Next
          </Button>
        </div>
      </div>
    </div>
  )
}

export default BookedAppointmentCard
