/* eslint-disable @typescript-eslint/no-explicit-any */
/* istanbul ignore file */
import { Form, FormInstance, Checkbox } from 'antd'
import React, { useState, useEffect, useCallback, useContext } from 'react'
import {
  InputText,
  Consent,
  SubmitCancel,
  PasswordRulesDisplay,
  MetadataContext,
} from '..'
import {
  hasDigit,
  hasLowerCase,
  hasSymbol,
  hasUpperCase,
} from '../form/form.utils'
import InputPassword from '../form/input-password/input-password'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import './password-setup.scss'
import { EAPRegistrationContext } from '../../pages/EAP/registration/eap-registrationMiddleware'
import {
  isNewEAPFlow,
  redirectToForgotPassword,
} from '../../pages/EAP/EAP-constants'
import MessagePopup from '../message-popup/message-popup'
import { useHistory } from 'react-router-dom'
interface PasswordSetupProps {
  onFinish: (values: any, token: any, handleReCaptchaVerify: any) => void
  onPrevious?: () => void
  onStateChange?: (value: any) => void
  form?: FormInstance<any>
  caregiverDetails?: any
  email?: string
  isDifferentPassword?: boolean
  handleClick: () => void
  confirmStepNo?: number
}
const PasswordSetup: React.FC<PasswordSetupProps> = ({
  onPrevious,
  form,
  onFinish,
  email,
  isDifferentPassword,
  handleClick,
  confirmStepNo,
}) => {
  const { executeRecaptcha } = useGoogleReCaptcha()
  const [token, SetToken] = useState<any>()
  const { shouldDisplayPassword, existenceType } = useContext(
    EAPRegistrationContext
  )

  const handleClickPassword = () => {
    handleClick()
  }

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('recaptcha not yet available')
      return
    }

    const RecapchaToken = await executeRecaptcha()
    SetToken(RecapchaToken)
  }, [executeRecaptcha])

  useEffect(() => {
    handleReCaptchaVerify()

    const interval = setInterval(() => {
      handleReCaptchaVerify()
    }, 60000) // refresh capcha every 60 seconds

    return () => clearInterval(interval)
  }, [handleReCaptchaVerify])

  const passwordValidator = (_: any, value: any) => {
    if (!hasLowerCase(value) || !hasUpperCase(value)) {
      return Promise.reject(
        new Error('Should contain both lower and uppercase')
      )
    }
    if (!(hasDigit(value) || hasSymbol(value))) {
      return Promise.reject(
        new Error('Should contain number and/or punctuation marks')
      )
    }
    return Promise.resolve()
  }

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        onFinish={(values) => onFinish(values, token, handleReCaptchaVerify)}
        scrollToFirstError
        initialValues={{ email: email }}
      >
        <div className="formItems">
          <div className="flex flex-row items-center gap-1">
            <div className="w-4/5 confirm-email">
              {/* {!isEmailEditClicked && (
                <div style={{ color: '#84878E' }} className="-mb-5">
                  Confirm Email Address
                </div>
              )} */}
              <label htmlFor="Email Address">
                <InputText
                  className="editable-email"
                  label={'Confirm your email'}
                  isDisabled
                  name={'email'}
                  isEmail={true}
                  isRequired={true}
                />
              </label>
            </div>
          </div>
          <EmailPreference emailStep={confirmStepNo} />
          {existenceType === 'soft' && (
            <>
              <label htmlFor="Enter Password">
                <InputPassword
                  label={'Password'}
                  name={'password'}
                  isRequired={true}
                  aria-required={true}
                  validateTrigger={['onBlur', 'onChange', 'onSubmit']}
                  min={{
                    value: 8,
                    message: 'length must be greater than 8',
                  }}
                  validator={passwordValidator}
                  onChange={handleClickPassword}
                />
              </label>
              {isDifferentPassword && (
                <div className="font-medium text-sm text-[#C81C1C] mt-[-1rem] pb-6">
                  Password is incorrect, please try again
                </div>
              )}
              <button
                className="text-[#3A6975] font-medium text-sm underline bg-[#fff] border-none mb-4 cursor-pointer"
                onClick={redirectToForgotPassword}
              >
                Forgot Password?
              </button>
            </>
          )}
          {shouldDisplayPassword ? (
            <>
              <label htmlFor="Create Password">
                <InputPassword
                  label={'Create Password'}
                  name={'password'}
                  isRequired={true}
                  aria-required={true}
                  validateTrigger={['onBlur', 'onChange', 'onSubmit']}
                  min={{
                    value: 8,
                    message: 'length must be greater than 8',
                  }}
                  validator={passwordValidator}
                />
              </label>
              <PasswordRulesDisplay />
            </>
          ) : (
            <MessagePopup
              title="Enter password of the existing account"
              message="An account exists with the given email address. A new user profile will be created and linked to your existing account. You can access all profiles using the same account."
            />
          )}
          <Consent />
          <SubmitCancel
            shouldDisplayPrevious={true}
            isSubmit={true}
            onPrevious={onPrevious}
          />
        </div>
      </Form>
    </>
  )
}

export const EmailPreference: React.FC<{ emailStep: number | undefined }> = ({
  emailStep,
}) => {
  const history = useHistory()
  const [isChecked, setIsChecked] = useState(false)
  const { organizationData } = useContext(MetadataContext)

  const redirectToEapPage = () => {
    if (emailStep === 4) {
      history.push('employee-details')
    } else if (isNewEAPFlow(organizationData?.id)) {
      history.push('contact-details')
    } else history.push('initial-details')
  }

  const onCheck = (e: any) => {
    setIsChecked(e.target.checked) // Update state based on Checkbox checked status
  }

  return (
    <div className="flex flex-col text-xs email-preference">
      <Form.Item
        label={'emailCheck'}
        name={'emailCheck'}
        valuePropName="checked"
        className="!mb-0"
        validateTrigger="onChange"
        rules={[
          {
            validator: (_, value) => {
              return value
                ? Promise.resolve()
                : Promise.reject(new Error('Please confirm your email'))
            },
          },
        ]}
      >
        <Checkbox className="text-xs" onChange={onCheck}>
          {' '}
          {/* Added onChange handler */}I confirm the above email belongs to me.
        </Checkbox>
      </Form.Item>
      {/* Conditionally display based on isChecked state */}
      {!isChecked && (
        <p className="pl-6 pt-2 text-xs">
          <span
            className="text-[#945A51] cursor-pointer font-bold underline"
            onClick={redirectToEapPage}
          >
            Click here
          </span>{' '}
          to change the email address
        </p>
      )}
    </div>
  )
}

export default PasswordSetup
