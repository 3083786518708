import React, { useContext } from 'react'
import { MetadataContext } from '../../../common'
import { FormInvalidMessage } from '../../../common/form/form.utils'
import { fromSelectOptions } from '../../../common/form/input-select/input-select'
import NewInputDate from '../../../common/new-form/new-input-date/new-input-date'
import NewInputSelect from '../../../common/new-form/new-input-select/new-input-select'
import NewInputText from '../../../common/new-form/new-input-text/new-input-text'
import PrimarySubscriberConsent from '../../../common/new-form/primary-subscriber-consent/primary-subscriber-consent'
import PhpInformation from '../../../common/php-information/php-information'
import { isAboveAgeOfConsentForState } from '../../../common/utils/date-utils'
import { alphabetPattern } from '../../EAP/EAP-constants'
import PhpFormSectionInfo from '../php-form-section-info/php-form-section-info'
import { PHPRegistrationContext } from '../php-registration/php-registration-middleware'

const PhpPrimarySubscriberInformation = () => {
  const { primarySubscriberDetails, updatePrimarySubscriberDetails } =
    useContext(PHPRegistrationContext)
  const { stateAges, relationshipData } = useContext(MetadataContext)

  const checkEmail = () => {
    if (
      !isAboveAgeOfConsentForState(
        primarySubscriberDetails.dateOfBirth,
        primarySubscriberDetails.state,
        stateAges
      )
    ) {
      return true
    }
    return false
  }
  return (
    <>
      <PhpFormSectionInfo
        sectionNumber={1}
        title={'Primary Subscriber Information'}
        description={'Provide details of the primary subscriber'}
      />
      <div className="mt-4">
        <div className="flex flex-col md:flex-row md:gap-8">
          <NewInputText
            label={'First Name'}
            htmlLabel={'psFirstName'}
            name={'psFirstName'}
            placeholder={'Enter First Name'}
            isRequired={true}
            aria-required={true}
            pattern={alphabetPattern}
            patternMessage={FormInvalidMessage('First Name')}
            maxLength={50}
            min={{
              value: 2,
              message: FormInvalidMessage(''),
            }}
          />

          <NewInputText
            label={'Last Name'}
            htmlLabel={'psLastName'}
            name={'psLastName'}
            placeholder={'Enter Last Name'}
            isRequired={true}
            aria-required={true}
            pattern={alphabetPattern}
            patternMessage={FormInvalidMessage('Last Name')}
            maxLength={50}
            min={{
              value: 2,
              message: FormInvalidMessage(''),
            }}
          />
        </div>
        <div className="flex flex-col md:flex-row md:gap-8">
          <NewInputDate
            label={'Date of Birth'}
            htmlLabel={'psDateOfBirth'}
            name={'psDateOfBirth'}
            isRequired={true}
            aria-required={true}
            placeholder={'MM/DD/YYYY or MM-DD-YYYY'}
            //   onChange={onDateOfBirthChange}
            shouldDisableFutureDates={true}
          />

          <NewInputSelect
            label={'Relationship with Primary Subscriber'}
            htmlLabel={'Relationship'}
            name={'Relationship'}
            placeholder={'Select Relationship'}
            isRequired={true}
            aria-required={true}
            options={fromSelectOptions(relationshipData, 'name', 'code')}
          />
        </div>
        {checkEmail() && (
          <>
            <div className="flex flex-col md:flex-row md:gap-8">
              <NewInputText
                label={'Email Address'}
                htmlLabel={'psEmail'}
                name={'psEmail'}
                placeholder={'Enter Email Address'}
                isEmail={true}
                isRequired={true}
                maxLength={65}
                min={{
                  value: 3,
                  message: FormInvalidMessage(''),
                }}
                max={{
                  value: 65,
                  message: FormInvalidMessage(''),
                }}
              />
              <NewInputText
                label={''}
                htmlLabel={'psEmail'}
                name={'psEmail'}
                placeholder={'Enter Email Address'}
                isEmail={true}
                maxLength={65}
                min={{
                  value: 3,
                  message: FormInvalidMessage(''),
                }}
                max={{
                  value: 65,
                  message: FormInvalidMessage(''),
                }}
                className={'hidden'}
              />
            </div>
            <PhpInformation
              text={
                ' This email address will be used for account creation as the dependent is under the age of consent.'
              }
            />

            <PrimarySubscriberConsent
              message=" This account is being created under the guidance of the primary
          subscriber."
              isChecked={primarySubscriberDetails?.guidanceConsent}
              consentType={'guidanceConsent'}
              onChange={(event: any) => {
                updatePrimarySubscriberDetails({
                  guidanceConsent: event.target.checked,
                })
              }}
            />
          </>
        )}
      </div>
    </>
  )
}

export default PhpPrimarySubscriberInformation
