import { Button } from 'antd'
import React, { FunctionComponent, useContext } from 'react'
import { LoaderContext } from '../loader/loader-middleware'
import { useHistory, useLocation } from 'react-router-dom'
import { redirectToHelthieWebsite } from '../../pages/EAP/EAP-constants'

interface SubmitCancelProps {
  shouldDisplayPrevious: boolean
  isSubmit: boolean
  onPrevious?: () => void
  isAgeErrorModalOpen?: boolean
  nextButtonDisabled?: boolean
  accountExists?: boolean | null
  contactDetailsNextLoader?: boolean
}

const SubmitCancel: FunctionComponent<SubmitCancelProps> = ({
  shouldDisplayPrevious,
  isSubmit,
  onPrevious,
  isAgeErrorModalOpen,
  nextButtonDisabled,
  accountExists,
  contactDetailsNextLoader,
}) => {
  const { loading } = useContext(LoaderContext)
  const history = useHistory()

  const handleClickHome = () => {
    return history.push('/')
  }

  const location = useLocation()
  const contactDetailsPath = location.pathname.includes('contact-details')
  return (
    <>
      <hr className="lineSeparator"></hr>
      <div className="flex flex-row gap-4 justify-center mb-8">
        {shouldDisplayPrevious ? (
          <Button
            type="primary"
            shape="round"
            className="mt-6"
            style={{ minWidth: '9rem' }}
            onClick={onPrevious}
          >
            Previous
          </Button>
        ) : null}
        {isAgeErrorModalOpen ? (
          <Button
            type="default"
            shape="round"
            className="mt-6 "
            loading={loading}
            style={{ minWidth: '9rem' }}
            onClick={handleClickHome}
          >
            {loading ? 'Processing' : 'Go to home'}
          </Button>
        ) : accountExists && contactDetailsPath ? (
          <Button
            type="default"
            shape="round"
            className="mt-6"
            loading={nextButtonDisabled}
            style={{ minWidth: '9rem' }}
            onClick={redirectToHelthieWebsite}
          >
            {contactDetailsNextLoader ? 'Processing' : 'Go to Login'}
          </Button>
        ) : contactDetailsPath ? (
          <Button
            type={!nextButtonDisabled ? 'default' : 'dashed'}
            shape="round"
            className={
              nextButtonDisabled
                ? 'border-none cursor-not-allowed mt-6 disabled-button '
                : 'mt-6 '
            }
            htmlType="submit"
            loading={contactDetailsNextLoader}
            style={{ minWidth: '9rem' }}
            disabled={nextButtonDisabled}
          >
            {contactDetailsNextLoader && !nextButtonDisabled && 'Processing'}
            {!contactDetailsNextLoader && 'Next'}
          </Button>
        ) : (
          <Button
            type="default"
            shape="round"
            className="mt-6 "
            htmlType="submit"
            loading={loading}
            style={{ minWidth: '9rem' }}
          >
            {loading && 'Processing'}
            {!loading && (isSubmit ? 'Create account' : 'Next')}
          </Button>
        )}
      </div>
    </>
  )
}

export default SubmitCancel
